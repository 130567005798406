<template>
<div class="inner-header">
 <HeaderHome/>
 </div>
  <div class="ful-wdth">
         <Form @submit="contactfromsend" :validation-schema="schema" v-slot="{ errors }">
    <div class="container blog-container">
      <div class="contact-sec">
        <h2>Our team is here to help</h2>
        <!-- <p>we're online 24 hour a day, 7 days a week </p> -->
     
    <div class="form-part">
     <div class="row">
       <div class="col-lg-6">
         <Field name="fname" type="text" placeholder="First Name" :class="{ 'has-error': errors.fname }" />
          <ErrorMessage name="fname" class="text-danger" />
          </div>
          <div class="col-lg-6">
           <Field name="lname" type="text" placeholder="Last Name" :class="{ 'has-error': errors.lname }" />
           <ErrorMessage name="lname" class="text-danger" />
          </div>
         <div class="col-lg-6">
           <Field name="email" type="email" placeholder="Email" :class="{ 'has-error': errors.email }" />
          <ErrorMessage name="email" class="text-danger" />
          </div>
          <div class="col-lg-6"> <Field name="phone_no" type="tel" placeholder="Phone No" :class="{ 'has-error': errors.email }" />
          <ErrorMessage name="phone_no" class="text-danger" /></div>
          <div class="col-lg-12">

           <Field name="message" v-model="message"  placeholder="Message" :class="{ 'has-error': errors.message }" as="textarea">
          </Field>
         <ErrorMessage name="message" class="text-danger" />            
            </div>
          <div class="col-lg-12">
          <input type="submit" value="Submit">
          </div>
     </div>
      </div>
    </div>
    </div>
    </Form>
  </div>
<div class="inner-home">
<FooterHome/>
 </div>
</template>

<script>
import { Field, Form, ErrorMessage } from 'vee-validate'
import * as yup from 'yup'
import HeaderHome from '@/components/Mainpage/HeaderHome.vue'
import FooterHome from '@/components/Mainpage/FooterHome.vue'
import {  mapActions } from 'vuex'
export default {
    name: 'HomeContactUs',
     data() {
          const schema = yup.object({
              email: yup.string().trim().required().email().label('The email'),
              fname: yup.string().trim().required().label('The First Name'),
              lname: yup.string().trim().required().label('The Last Name'),
              phone_no: yup.string().trim().required().label('Phone no'),
              message: yup.string().trim().required().label('Message'),
          });
        return { 
            schema, 
            resultdata:[],
            googlingdata:{
              fname:'',
              lname:'',
              email:'',
              message:'',
              phone_no:'',
            }
        }
    },
   
    components: {
        HeaderHome,
        FooterHome,
        Field,
        Form,
        ErrorMessage,
     },
     methods: {
        ...mapActions({
            SendContactmail: 'authModule/SendContactmail',
        }),
       contactfromsend (params, { setFieldError }) {
          const vm = this;
          params.setFieldError = setFieldError;
          const form = new FormData();
          for (const field in params) {
              form.append(field, params[field]);
          }
          vm.SendContactmail(form);
        },
      },
}
</script>
<style scoped>
.text-danger{
  float: left;
  margin: 8px 1px;
}
</style>