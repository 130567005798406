<template>
 <div class="ful-wdth">
     <div class="growth-chh2 psrl1" v-if="!displayload">
           <div class="loader">
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="margin:auto;display:block;" width="200px" height="200px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
<circle cx="75" cy="50" fill="#00d1ff" r="5">
  <animate attributeName="r" values="3;3;5;3;3" times="0;0.1;0.2;0.3;1" dur="1s" repeatCount="indefinite" begin="-0.9166666666666666s"></animate>
  <animate attributeName="fill" values="#00d1ff;#00d1ff;#00d1ff;#00d1ff;#00d1ff" repeatCount="indefinite" times="0;0.1;0.2;0.3;1" dur="1s" begin="-0.9166666666666666s"></animate>
</circle><circle cx="71.65063509461098" cy="62.5" fill="#00d1ff" r="5">
  <animate attributeName="r" values="3;3;5;3;3" times="0;0.1;0.2;0.3;1" dur="1s" repeatCount="indefinite" begin="-0.8333333333333334s"></animate>
  <animate attributeName="fill" values="#00d1ff;#00d1ff;#00d1ff;#00d1ff;#00d1ff" repeatCount="indefinite" times="0;0.1;0.2;0.3;1" dur="1s" begin="-0.8333333333333334s"></animate>
</circle><circle cx="62.5" cy="71.65063509461096" fill="#00d1ff" r="5">
  <animate attributeName="r" values="3;3;5;3;3" times="0;0.1;0.2;0.3;1" dur="1s" repeatCount="indefinite" begin="-0.75s"></animate>
  <animate attributeName="fill" values="#00d1ff;#00d1ff;#00d1ff;#00d1ff;#00d1ff" repeatCount="indefinite" times="0;0.1;0.2;0.3;1" dur="1s" begin="-0.75s"></animate>
</circle><circle cx="50" cy="75" fill="#00d1ff" r="5">
  <animate attributeName="r" values="3;3;5;3;3" times="0;0.1;0.2;0.3;1" dur="1s" repeatCount="indefinite" begin="-0.6666666666666666s"></animate>
  <animate attributeName="fill" values="#00d1ff;#00d1ff;#00d1ff;#00d1ff;#00d1ff" repeatCount="indefinite" times="0;0.1;0.2;0.3;1" dur="1s" begin="-0.6666666666666666s"></animate>
</circle><circle cx="37.50000000000001" cy="71.65063509461098" fill="#00d1ff" r="5">
  <animate attributeName="r" values="3;3;5;3;3" times="0;0.1;0.2;0.3;1" dur="1s" repeatCount="indefinite" begin="-0.5833333333333334s"></animate>
  <animate attributeName="fill" values="#00d1ff;#00d1ff;#00d1ff;#00d1ff;#00d1ff" repeatCount="indefinite" times="0;0.1;0.2;0.3;1" dur="1s" begin="-0.5833333333333334s"></animate>
</circle><circle cx="28.34936490538903" cy="62.5" fill="#00d1ff" r="5">
  <animate attributeName="r" values="3;3;5;3;3" times="0;0.1;0.2;0.3;1" dur="1s" repeatCount="indefinite" begin="-0.5s"></animate>
  <animate attributeName="fill" values="#00d1ff;#00d1ff;#00d1ff;#00d1ff;#00d1ff" repeatCount="indefinite" times="0;0.1;0.2;0.3;1" dur="1s" begin="-0.5s"></animate>
</circle><circle cx="25" cy="50" fill="#00d1ff" r="5">
  <animate attributeName="r" values="3;3;5;3;3" times="0;0.1;0.2;0.3;1" dur="1s" repeatCount="indefinite" begin="-0.4166666666666667s"></animate>
  <animate attributeName="fill" values="#00d1ff;#00d1ff;#00d1ff;#00d1ff;#00d1ff" repeatCount="indefinite" times="0;0.1;0.2;0.3;1" dur="1s" begin="-0.4166666666666667s"></animate>
</circle><circle cx="28.34936490538903" cy="37.50000000000001" fill="#00d1ff" r="5">
  <animate attributeName="r" values="3;3;5;3;3" times="0;0.1;0.2;0.3;1" dur="1s" repeatCount="indefinite" begin="-0.3333333333333333s"></animate>
  <animate attributeName="fill" values="#00d1ff;#00d1ff;#00d1ff;#00d1ff;#00d1ff" repeatCount="indefinite" times="0;0.1;0.2;0.3;1" dur="1s" begin="-0.3333333333333333s"></animate>
</circle><circle cx="37.499999999999986" cy="28.349364905389038" fill="#00d1ff" r="5">
  <animate attributeName="r" values="3;3;5;3;3" times="0;0.1;0.2;0.3;1" dur="1s" repeatCount="indefinite" begin="-0.25s"></animate>
  <animate attributeName="fill" values="#00d1ff;#00d1ff;#00d1ff;#00d1ff;#00d1ff" repeatCount="indefinite" times="0;0.1;0.2;0.3;1" dur="1s" begin="-0.25s"></animate>
</circle><circle cx="49.99999999999999" cy="25" fill="#00d1ff" r="5">
  <animate attributeName="r" values="3;3;5;3;3" times="0;0.1;0.2;0.3;1" dur="1s" repeatCount="indefinite" begin="-0.16666666666666666s"></animate>
  <animate attributeName="fill" values="#00d1ff;#00d1ff;#00d1ff;#00d1ff;#00d1ff" repeatCount="indefinite" times="0;0.1;0.2;0.3;1" dur="1s" begin="-0.16666666666666666s"></animate>
</circle><circle cx="62.5" cy="28.349364905389034" fill="#00d1ff" r="5">
  <animate attributeName="r" values="3;3;5;3;3" times="0;0.1;0.2;0.3;1" dur="1s" repeatCount="indefinite" begin="-0.08333333333333333s"></animate>
  <animate attributeName="fill" values="#00d1ff;#00d1ff;#00d1ff;#00d1ff;#00d1ff" repeatCount="indefinite" times="0;0.1;0.2;0.3;1" dur="1s" begin="-0.08333333333333333s"></animate>
</circle><circle cx="71.65063509461096" cy="37.499999999999986" fill="#00d1ff" r="5">
  <animate attributeName="r" values="3;3;5;3;3" times="0;0.1;0.2;0.3;1" dur="1s" repeatCount="indefinite" begin="0s"></animate>
  <animate attributeName="fill" values="#00d1ff;#00d1ff;#00d1ff;#00d1ff;#00d1ff" repeatCount="indefinite" times="0;0.1;0.2;0.3;1" dur="1s" begin="0s"></animate>
</circle>
            </svg>
           </div>
        </div>

    <div class="tab_boxe" v-if="displayload">
      <div v-if="initialload && comparisonappsdata.length<=0 ">
        <h2 class="sidePageTittle">Compare Apps</h2>
        <p class="sidePagePara">Compare your performance and pricing plans</p>
      </div>
      <div v-if="loadafterselectapp">
      <h2 class="sidePageTittle">App Comparison side by side</h2>
      <p class="sidePagePara">Track your competitor's App and Analyze their App Performance</p>
    </div> 
      <div class="appsBox">
          <div class="row">
              <div class="col-lg-4 comGap comparingThree">
                  <div class="comThreeBox" v-if="compayerapplist.length<=0 && comparisonappsdata.length<=0">
                      <a href="javascript:void(0)">
                          <div class="threeDiv">
                              <button class="btn" type="button" data-bs-toggle="modal" data-bs-target="#exampleModal2" v-on:click="openModal">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-plus-lg" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z"></path>
                                  </svg>
                              </button>
                          </div>
                      </a>
                      <h3>Add your App</h3>
                  </div>
                  
                  <div class="appsSub" v-if="comparisonappsdata.length>0">
                      <div class="appSubHead">
                          <h2>Main App</h2>
                      </div>
                      <div class="appSubProfile">
                          <div class="proImg">

                            <router-link :to="'/about-details/' + comparisonappsdata[0].app_id" class="td_link"><img :src=comparisonappsdata[0].app_logo alt=""></router-link>
                                </div>
                          <div class="proText">
                              <h3><router-link :to="'/about-details/' + comparisonappsdata[0].app_id" class="td_link"> <span>{{comparisonappsdata[0].app_title}}</span></router-link>
                          </h3>
                              <h4><span>By</span><router-link :to="'/developers-team-name/' + comparisonappsdata[0].dev_id">{{comparisonappsdata[0].dev_name}}</router-link></h4> 
                              <p>{{comparisonappsdata[0].app_brief_description}}</p>
                              <h5><span><svg xmlns="http://www.w3.org/2000/svg" width="15" height="14" fill="#FEC33E" class="bi bi-star-fill" viewBox="0 0 16 16">
                                  <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
                                </svg></span>{{comparisonappsdata[0].app_rating==''?0:comparisonappsdata[0].app_rating}} ({{comparisonappsdata[0].totalreview}})</h5>
                                <a>{{comparisonappsdata[0].plane_text}}</a>
                          </div>
                      </div>
                      <div class="appSubOption">
                          <p><span><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#5AB4FF
                              " class="bi bi-check2" viewBox="0 0 16 16">
                              <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
                            </svg></span>Overall Rank: {{comparisonappsdata[0].ranktext}}</p>

                          <p><span><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#5AB4FF
                              " class="bi bi-check2" viewBox="0 0 16 16">
                              <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
                            </svg></span>Most Installed Rank: {{comparisonappsdata[0].mostinstall_current_rank}}</p>  

                          
                            <div v-if="comparisonappsdata[0].categorydetails">
                                <p :id="'row'+index" v-bind:key="result.cat_id" v-for="(result,index) in comparisonappsdata[0].categorydetails" ><span><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#5AB4FF
                              " class="bi bi-check2" viewBox="0 0 16 16">
                              <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
                            </svg></span>{{result.category_title}}: {{result.relevent_rank}}/ {{result.category_app_count}}</p>
                            
                            </div>   
                      </div>
                      <div class="appSubClose">
                          <a href="javascript:void(0)" @click="removesubscription(comparisonappsdata[0].app_id)">
                              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#000" class="bi bi-x" viewBox="0 0 16 16">
                                  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                                </svg>
                          </a>
                      </div>
                  </div>
              </div>
              
              <div class="col-lg-4 comGap comparingThree">
                  
                  <div class="comThreeBox"  v-if="compayerapplist.length==1 || comparisonappsdata.length==1">
                      <a href="javascript:void(0)">
                          <div class="threeDiv">
                              <button class="btn" type="button" data-bs-toggle="modal" data-bs-target="#exampleModal2" v-on:click="openModal">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-plus-lg" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z"></path>
                                  </svg>
                              </button>
                          </div>
                      </a>
                      <h3>Add your competitor's App</h3>
                  </div>
                  

                  <div class="appsSub" v-if="compayerapplist.length>=2 && comparisonappsdata.length>1">
                      <div class="appSubHead appSubGreen">
                          <h2>Competitor</h2>
                      </div>

                        <div class="appSubProfile">
                          <div class="proImg">

                            <router-link :to="'/about-details/' + comparisonappsdata[1].app_id" class="td_link"><img :src=comparisonappsdata[1].app_logo alt=""></router-link>
                                </div>
                          <div class="proText">
                              <h3><router-link :to="'/about-details/' + comparisonappsdata[1].app_id" class="td_link"> <span>{{comparisonappsdata[1].app_title}}</span></router-link>
                          </h3>
                              <h4><span>By</span><router-link :to="'/developers-team-name/' + comparisonappsdata[1].dev_id">{{comparisonappsdata[1].dev_name}}</router-link></h4>
                              <p>{{comparisonappsdata[1].app_brief_description}}</p>
                              <h5><span><svg xmlns="http://www.w3.org/2000/svg" width="15" height="14" fill="#FEC33E" class="bi bi-star-fill" viewBox="0 0 16 16">
                                  <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
                                </svg></span>{{comparisonappsdata[1].app_rating==''?0:comparisonappsdata[1].app_rating}} ({{comparisonappsdata[1].totalreview}})</h5>
                                <a>{{comparisonappsdata[1].plane_text}}</a>
                          </div>
                      </div>
                      <div class="appSubOption">
                          <p><span><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#5AB4FF
                              " class="bi bi-check2" viewBox="0 0 16 16">
                              <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
                            </svg></span>Overall Rank: {{comparisonappsdata[1].ranktext}}</p>

                              <p><span><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#5AB4FF
                              " class="bi bi-check2" viewBox="0 0 16 16">
                              <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
                            </svg></span>Most Installed Rank: {{comparisonappsdata[1].mostinstall_current_rank}}</p> 

                            <div v-if="comparisonappsdata[1].categorydetails">

                              <p :id="'row'+index" v-bind:key="result.cat_id" v-for="(result,index) in comparisonappsdata[1].categorydetails" ><span><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#5AB4FF
                              " class="bi bi-check2" viewBox="0 0 16 16">
                              <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
                            </svg></span>{{result.category_title}}: {{result.relevent_rank}}/ {{result.category_app_count}}</p>

                            
                            </div>  
                            
                      </div>
                      <div class="appSubClose appSubCloseGreen">
                          <a href="javascript:void(0)" @click="removesubscription(comparisonappsdata[1].app_id)">
                              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#000" class="bi bi-x" viewBox="0 0 16 16">
                                  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                                </svg>
                          </a>
                      </div>                       
                  </div>
              </div>

              <div class="col-lg-4 comGap comparingThree">

                  <div class="comThreeBox"  v-if="compayerapplist.length==2 && compayerapplist.length<3">
                      <a href="javascript:void(0)">
                          <div class="threeDiv">
                              <button class="btn" type="button" data-bs-toggle="modal" data-bs-target="#exampleModal2"  v-on:click="openModal">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-plus-lg" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z"></path>
                                  </svg>
                              </button>
                          </div>
                      </a>
                      <h3>Add your competitor's App</h3>
                  </div>

                  <div class="appsSub" v-if="compayerapplist.length>=3 && comparisonappsdata.length>2">
                      <div class="appSubHead appSubViolet">
                          <h2>Competitor</h2>
                      </div>
                      <div class="appSubProfile">
                          <div class="proImg">

                            <router-link :to="'/about-details/' + comparisonappsdata[2].app_id" class="td_link"><img :src=comparisonappsdata[2].app_logo alt=""></router-link>
                                </div>
                          <div class="proText">
                              <h3><router-link :to="'/about-details/' + comparisonappsdata[2].app_id" class="td_link"> <span>{{comparisonappsdata[2].app_title}}</span></router-link>
                          </h3>
                              <h4><span>By</span><router-link :to="'/developers-team-name/' + comparisonappsdata[2].dev_id">{{comparisonappsdata[2].dev_name}}</router-link></h4> 
                              <p>{{comparisonappsdata[2].app_brief_description}}</p>
                              <h5><span><svg xmlns="http://www.w3.org/2000/svg" width="15" height="14" fill="#FEC33E" class="bi bi-star-fill" viewBox="0 0 16 16">
                                  <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
                                </svg></span>{{comparisonappsdata[2].app_rating==''?0:comparisonappsdata[2].app_rating}} ({{comparisonappsdata[2].totalreview}})</h5>
                                <a>{{comparisonappsdata[2].plane_text}}</a>
                          </div>
                      </div>
                      <div class="appSubOption">
                          <p><span><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#5AB4FF
                              " class="bi bi-check2" viewBox="0 0 16 16">
                              <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
                            </svg></span>Overall Rank: {{comparisonappsdata[2].ranktext}}</p>

                              <p><span><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#5AB4FF
                              " class="bi bi-check2" viewBox="0 0 16 16">
                              <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
                            </svg></span>Most Installed Rank: {{comparisonappsdata[2].mostinstall_current_rank}}</p> 


                            <div v-if="comparisonappsdata[2].categorydetails">
                              <p :id="'row'+index" v-bind:key="result.cat_id" v-for="(result,index) in comparisonappsdata[2].categorydetails" ><span><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#5AB4FF
                              " class="bi bi-check2" viewBox="0 0 16 16">
                              <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
                            </svg></span>{{result.category_title}}: {{result.relevent_rank}}/ {{result.category_app_count}}</p>                              
                            </div> 
                      </div>
                      <div class="appSubClose appSubCloseViolet">
                          <a href="javascript:void(0)" @click="removesubscription(comparisonappsdata[2].app_id)">
                              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#000" class="bi bi-x" viewBox="0 0 16 16">
                                  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                                </svg>
                          </a>
                      </div>
                  </div>
              </div>
          </div>
      </div>

      <div class="col-lg-12 keyWordSecBox"  v-if="comparisonappsdataplane.length>0">
          <div class="heading">
              <h3>Compare Pricing Plans</h3>
          </div>
          <div class="table-section comparingTableLeft">
              <div class="resp-table">
                <table class="skyBackground">
                  <thead>
                    <tr>
                      <th>App Name</th>
                      <th>Free</th>
                      <th>Tier 1</th>
                      <th>Tier 2</th>
                      <th>Tier 3</th>
                      <th>Tier 4</th>
                    </tr>
                  
                  </thead>
                  <tbody>
                      <tr v-bind:key="result2.app_id" v-for="(result2,index) in comparisonappsdataplane"> 
                          <td><router-link :to="'/about-details/' + comparisonappsdata[index].app_id">{{comparisonappsdata[index].app_title}} </router-link></td>                         
                          <td><router-link :to="'/about-details/' + comparisonappsdata[index].app_id">{{result2.Free}} </router-link></td>
                          <td><router-link :to="'/about-details/' + comparisonappsdata[index].app_id">{{result2.Tier_1}} {{result2.Tier_0}}</router-link></td>
                          <td><router-link :to="'/about-details/' + comparisonappsdata[index].app_id">{{result2.Tier_2}} </router-link></td>
                          <td><router-link :to="'/about-details/' + comparisonappsdata[index].app_id">{{result2.Tier_3}} </router-link></td>
                          <td><router-link :to="'/about-details/' + comparisonappsdata[index].app_id">{{result2.Tier_4}} </router-link></td>
                          
                      </tr>
                  </tbody>
                </table>
              </div>
                  
          </div>
      </div>

      <div class="tab_Three_Box"  v-if="loadafterselectapp2 && comparisonappsdataplane.length>0">
          <div class="tabs_Head compareupper" v-if="loadafterselectapp2">
            <h2>Ranking Trends</h2>
          </div>
          <div class="comparingLink" v-if="loadafterselectapp2">
              <div class="comparingSelect">
                  <Form>
                    <Field name="selected_cat" v-model="selected_cat"  as="select" @change="selectsearchcat($event)">
                    <option v-bind:key="options.category_id" v-for="options in categoryoptions" :value="options.category_id">{{options.category_name}}</option>
                    </Field>
                  </Form>
              </div>
              <div class="comparingOption" v-if="comparisonappsdata.length>0">
                  <ul>
                      <li v-bind:key="result2.app_id" v-for="(result2,index) in comparisonappsdata"><router-link :to="'/about-details/' + result2.app_id">
                  <span><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" :fill="colorparam[index]" class="bi bi-circle-fill" viewBox="0 0 16 16">
                    <circle cx="8" cy="8" r="8"/>
                  </svg></span>
                  {{result2.app_title}} </router-link></li>
                  </ul>
              </div>
          </div>
          <div class="tab_Three_ChartTwo">
            <canvas id="myChartTwo"></canvas>
          </div>

          <div class="tabs_Head comparfningHeadUl" v-if="comparisonappsdata.length>0">
            <h2>Rating Over Time</h2>
            <div class="comparingOption">
              <ul>
                  <li v-bind:key="result2.app_id" v-for="(result2,index) in comparisonappsdata"><router-link :to="'/about-details/' + result2.app_id">
                  <span><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" :fill="colorparam[index]" class="bi bi-circle-fill" viewBox="0 0 16 16">
                    <circle cx="8" cy="8" r="8"/>
                  </svg></span>
                  {{result2.app_title}} </router-link></li>
              </ul>
            </div>
          </div>
          <div class="tab_Three_ChartOne">
            <canvas id="myChartOne"></canvas>
          </div>

          <div class="tabs_Head comparfningHeadUl" v-if="comparisonappsdata.length>0">
            <h2>Reviews Trends</h2>
            <div class="comparingOption">
              <ul>
                  <li v-bind:key="result2.app_id" v-for="(result2,index) in comparisonappsdata"><router-link :to="'/about-details/' + result2.app_id">
                  <span><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" :fill="colorparam[index]" class="bi bi-circle-fill" viewBox="0 0 16 16">
                    <circle cx="8" cy="8" r="8"/>
                  </svg></span>
                  {{result2.app_title}} </router-link></li>
                  
              </ul>
          </div>
          </div>
          <div class="tab_Three_ChartThree">
            <canvas id="myChartThree"></canvas>
          </div>
      </div>

    </div>

<div id="exampleModal2" class="modal-backdrop1 side-by modal fade" tabindex="-1" aria-labelledby="exampleModal2" aria-modal="true" role="dialog" 
>
  <div class="modal-dialog cuts-modal">
    <div class="modal-content">
      <div class="modal-header">
        <h3>Select your App</h3>
         <button type="button" ref="Close" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16"><path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"></path></svg></button>
  
      </div>
      <div class="modal-body">
          <div class="ful-wdth">
       <div class="serach-result-part cursor">
         <vue3-simple-typeahead 
                id="compayarpps"
                v-model="searchkey21"
                :value="searchkey21"
                placeholder="Search app name"
                ref="searchbar"
                
                :items="searchAppresult"
                autofocus="autofocus"
                class="searching form-control"
                :minInputLength="3"
                :itemProjection="
                (item) => {
                      var url1=item.app_logo;
                      url=url1.replace('%3', '');
                       var html=`<div class='compayarpps header-searching'><div class='img-sec'><img src='${url}'></div>`;
                      html=html+`<div class='text-part'><h3>`+item.app_title+`</h3>`;
                      html=html+'<p>'+item.app_rating+' of 5 stars ('+item.app_num_of_reviews+')</p></div><div>';
                      return html;
                }"
                    @selectItem="compayarppslist"
                    @onInput="onInputcompayarpp"
                    @onFocus="onInputcompayarpp"
                    @onBlur="onBlurompayarApps"
                >  

                  <template #list-item-text="slot">
                       <span v-html="slot.itemProjection(slot.item)" class="material-icons"></span>
                  </template>      
                </vue3-simple-typeahead>
         
        </div> 
       
    </div>
      </div>
      <div class="modal-footer">
       
      </div>
    </div>
  </div>
</div>
 </div>
</template>

<script>
import Chart from 'chart.js/auto';
import {mapState,mapActions} from 'vuex'
import { Field, Form } from 'vee-validate'
export default {
   name : 'SidebySideComparison',
   props: {
    visible: Boolean,
    variant:String,
  },
   components:{
        Field,
        Form,
    },
    
  data() {
        return {
           selectedaps:{
               appid:[]
           },
          searchItem:[],
            searchkey : null,
            searchkey21:'',
            filterdata: {
            input: '',
            selection: null,
            userid: null,
            appids:[],
          }, 
          
          initialload:true,
          loadafterselectapp:false,
          loadafterselectapp2:false,
          compayerapplist:[],
          displayload:false,
          param:{
            appids:[],
            searchcategoryid:'8cebe8ab5babb8ecc14f18eda09742c0'
          },
          colorparam:['#54B1FF','#51E9DA','#8173FF'],
          linechartoption:{
            rattingdataset:[],
            riviewdatasets:[],
            categorydatasets:[],
          },

          categoryoptions: [{'category_id':'','category_name':'Select Category'}],
          selected_cat:'',
          myChart2:null,
          myChart1:null,
          myChart:null,
          comparisonchratdatalabels:[],
          cattrendsdatelabels:[],
          totalreview:[],
          app_rating:[]
          
          
        }
  },

  computed: {
      ...mapState({
            comparisonappsearch: (state) => state.AppListModule.comparisonappsearch,
            user: (state) => state.authModule.user,
            userfollowapps: (state) => state.authModule.userfollowapps,
            searchAppresult: (state) => state.AppListModule.comparisonappsearch,
            comparisonappsdata: (state) => state.AppListModule.comparisonappsdata,
            comparisonappsdataplane: (state) => state.AppListModule.comparisonappsdataplane,
            comparisonchratdataoption: (state) => state.AppListModule.comparisonchratdataoption,
            categoryslists: (state) => state.AppListModule.categorylistrelatedwithappids,
            comparisonchratdatacategoryoption: (state) => state.AppListModule.comparisonchratdatacategoryoption,
       }),
   },

   
    methods:{
        ...mapActions({
            logoutUser: 'authModule/logout',
            GetApsforComparison: 'AppListModule/GetApsforComparison',
            GetComparisonAppsdata: 'AppListModule/GetComparisonAppsdata',
            GetComparisonchartOptioncatdataByAppid: 'AppListModule/GetComparisonchartOptioncatdataByAppid',
        }),
          OpenCloseFun(){
            this.OpenClose =false;
          }, 

          onInputcompayarpp(event){
          this.filterdata.selection = null;
          this.filterdata.input = event.input;
          // this.filterdata.userid = this.user.data.userid;
          // if(this.filterdata.input.length>2){
          //   this.GetApsforComparison(this.filterdata);
          //   this.searchItem=this.searchAppresult;
          //  } 
          },
          compayarppslist(item){
            var vm=this;
            this.searchkey21=item.app_title;
            this.compayerapplist.push(item.app_id);
            this.param.appids.push(item.app_id);
            this.filterdata.appids.push(item.app_id);
            this.$refs.Close.click();
            this.loadafterselectapp2=true;
            this.searchItem=[];
            this.GetComparisonAppsdata(this.param);
            setTimeout(function(){
                  vm.GetComparisonchartOptioncatdataByAppid(vm.param);
              }, 5000);
            
            this.GetApsforComparison(this.filterdata);
            // return item.app_title;
          }, 
          
          categorychart(){
               console.log('categorychart data',this.linechartoption.categorydatasets)
                var ctx2 = document.getElementById("myChartTwo").getContext('2d');        
                this.myChart2 = new Chart(ctx2, {
                    type: 'line',
                    data: {
                        labels:  this.cattrendsdatelabels,
                        datasets: this.linechartoption.categorydatasets
                        },                    
                    options: {
                      responsive: true, // Instruct chart js to respond nicely.
                      maintainAspectRatio: false, // Add to prevent default behaviour of full-width/height
                      plugins: {
                      legend: {
                        position: 'top',  align: 'right',
                        display: false,
                      }
                    }, 
                    }
                });
              this.myChart2; 
          },

          showreviewchart(){    
                console.log('chart1' ,this.linechartoption.riviewdatasets)       
                var ctx1 = document.getElementById("myChartThree").getContext('2d'); 
                this.myChart1 = new Chart(ctx1, {
                    type: 'line',
                    data: {
                        labels: this.comparisonchratdatalabels,
                        datasets: this.linechartoption.riviewdatasets
                        },
                    options: {
                      responsive: true, // Instruct chart js to respond nicely.
                      maintainAspectRatio: false, // Add to prevent default behaviour of full-width/height
                      plugins: {
                      legend: {
                        position: 'top',  align: 'right',
                        display: false,
                      }
                    }, 
                    }
                });
                this.myChart1;  
             },

          showrattingchart(){          
                var ctx = document.getElementById("myChartOne").getContext('2d');
                this.myChart = new Chart(ctx, {
                    type: 'line',
                    data: {
                        labels: this.comparisonchratdatalabels,
                        datasets: this.linechartoption.rattingdataset},
                    // options: {
                    //   responsive: true, // Instruct chart js to respond nicely.
                    //   maintainAspectRatio: false, // Add to prevent default behaviour of full-width/
                    //   legend: {

                    //     position: 'top',  align: 'right'
                    //   }
                    // },
                    options: {
                    responsive: true,
                    plugins: {
                      legend: {
                        position: 'top',  align: 'right',
                        display: false,
                      }
                    }
                  },
                });

              this.myChart;  
          },

         
          createratandreviewchart(){
          this.rattingdataset=[];
          this.riviewdatasets=[];
          this.comparisonchratdatalabels=[];
          var vm=this;
           if(vm.myChart1!=null && this.param.appids.length>0){
             vm.myChart1.destroy();
             vm.myChart1='';
           }
             
          if(vm.myChart!=null && this.param.appids.length>0) 
             { 
              vm.myChart.destroy();
              vm.myChart='';
            }

          this.linechartoption.riviewdatasets=[];
          this.linechartoption.rattingdataset=[];

          if(this.comparisonappsdata.length>0){
               
               if(this.comparisonchratdataoption.labels.length){
                  for(var ij=0;ij<this.comparisonchratdataoption.labels.length;ij++){
                    this.comparisonchratdatalabels.push(this.comparisonchratdataoption.labels[ij])
                  }
                   for(var ij=0;ij<this.comparisonappsdata.length;ij++){
                        var optionname=this.comparisonappsdata[ij].app_title;
                        
                        var colorcode=this.colorparam[ij];
                        this.totalreview=[];
                        this.app_rating=[];
                         for(var ii=0;ii<this.comparisonchratdataoption.labels.length;ii++){ 
                            var appid=this.comparisonappsdata[ij].app_id;
                            var appdate=this.comparisonchratdataoption.labels[ii];
                           
                            if(this.comparisonchratdataoption.review[appid][appdate]===undefined){
                               //alert(this.comparisonchratdataoption.review[appid][appdate][ii]);
                               this.totalreview.push(0)
                               this.app_rating.push(0)
                            }else{
                              // if(this.comparisonchratdataoption.review[appid][appdate][ii]===undefined){
                              //  this.totalreview.push(0)
                              //  this.app_rating.push(0)
                              // }else{
                                 this.totalreview.push(this.comparisonchratdataoption.review[appid][appdate][0])
                                 this.app_rating.push(this.comparisonchratdataoption.ratting[appid][appdate][0])
                             // }
                             
                            }
                              
                      }

                     // alert(this.totalreview);
                    this.linechartoption.riviewdatasets.push({label: optionname,alignItems:'flex-end', data: this.totalreview,fill: false, borderColor: colorcode,backgroundColor: colorcode, borderWidth: 1})
                    this.linechartoption.rattingdataset.push({label: optionname,alignItems:'flex-end', data: this.app_rating,fill: false, borderColor: colorcode,backgroundColor: colorcode, borderWidth: 1})
                  
                   } 
                  console.log('COMPEAR APP HISTORYCATDATA',this.linechartoption.riviewdatasets);  
                  setTimeout(function(){
                    vm.showreviewchart();
                    vm.showrattingchart();
                  }, 2000);
               } 
          }  

          if(this.categoryslists.length>0){
             this.categoryoptions=[{'category_id':'','category_name':'Select Category'}]; 
              for(var jj=0;jj<this.categoryslists.length;jj++)  
                { 
                  this.categoryoptions.push({'category_id':this.categoryslists[jj].category_id ,'category_name':this.categoryslists[jj].category_title }); 
                }
                        
          }

         
          },


          removesubscription(app_id){
             var vm=this;
              this.compayerapplist = this.compayerapplist.filter(item => item !== app_id);
              this.param.appids = this.param.appids.filter(item => item !== app_id);
              console.log('',this.param)
              this.filterdata.appids = this.filterdata.appids.filter(item => item !== app_id);             
              this.GetComparisonAppsdata(this.param);
              this.GetComparisonchartOptioncatdataByAppid(this.param);
              if(this.param.appids.length==0){
                 setTimeout(function(){
                    this.loadafterselectapp2=false;
                     vm.myChart1=null;
                     vm.myChart=null;
                     vm.myChart2=null;
                }, 5000);
              }
              
          },

          selectsearchcat(event){
            this.selected_cat=event.target.value;
            this.param.searchcategoryid=event.target.value;
            this.GetComparisonchartOptioncatdataByAppid(this.param);
          },

    
          openModal(){
            //this.$refs.focusitem.focus();
          setTimeout(function(){
                document.querySelector("#compayarpps").focus();
          }, 500);
        }
        
  },

   mounted(){

           if(this.comparisonappsdata.length>0) {
             this.loadafterselectapp2=true;
              this.comparisonappsdata.forEach(element => {
                 this.compayerapplist.push(element.app_id);
                 this.param.appids.push(element.app_id);
                 this.filterdata.appids.push(element.app_id);
              });
              this.loadafterselectapp=true;             
              this.param.searchcategoryid='';
              this.GetComparisonchartOptioncatdataByAppid(this.param);
            }
            this.GetApsforComparison(this.filterdata);
          },
   watch: {
        searchAppresult(){
            this.displayload=true;
        },

        categoryslists(){
          if(this.categoryslists.length>0){
             this.categoryoptions=[{'category_id':'','category_name':'Select Category'}]; 
              for(var jj=0;jj<this.categoryslists.length;jj++)  
                { 
                  this.categoryoptions.push({'category_id':this.categoryslists[jj].category_id ,'category_name':this.categoryslists[jj].category_title }); 
                }
                        
          }
        },
        
        loadafterselectapp() { 
           var vm=this;

           setTimeout(() =>  vm.createratandreviewchart(), 3000); 
            // setTimeout(function(){
            //      vm.createratandreviewchart();
            // }, 2000);
          
         },
         comparisonappsdata(){
            var vm=this;
            this.loadafterselectapp=true;
            this.loadafterselectapp2=true;
            this.initialload=false;
            this.searchkey21='';

            //setTimeout(() =>  vm.createratandreviewchart(), 5000); 
            // setTimeout(function(){
            //      vm.createratandreviewchart();
            // }, 7000);
            
        },

        comparisonchratdatacategoryoption(newval,oldval){
           if(this.myChart2!=null)
           this.myChart2.destroy();
           this.cattrendsdatelabels=[];
           this.linechartoption.categorydatasets=[]
           if(this.comparisonappsdata.length>0){ 
              if(this.comparisonchratdatacategoryoption.labels.length>0){
                this.cattrendsdatelabels=this.comparisonchratdatacategoryoption.labels
                for(var ij=0;ij<this.comparisonappsdata.length;ij++){
                        var optionname=this.comparisonappsdata[ij].app_title;
                        var colorcode=this.colorparam[ij];
                        this.rankingtrande=[];
                        var appid=this.comparisonappsdata[ij].app_id;
                         for(var ii=0;ii<this.comparisonchratdatacategoryoption.labels.length;ii++){                            
                            var appdate=this.comparisonchratdatacategoryoption.labels[ii];
                            if(this.comparisonchratdatacategoryoption[appid]===undefined){
                              this.rankingtrande.push(0)
                            }
                            else{
                               if(this.comparisonchratdatacategoryoption[appid][appdate]===undefined){
                               this.rankingtrande.push(0)
                              }
                              else{
                                  this.rankingtrande.push(this.comparisonchratdatacategoryoption[appid][appdate][0])
                              }
                            }
                      }

                     this.linechartoption.categorydatasets.push({label: optionname,alignItems:'flex-end', data: this.rankingtrande,fill: false, borderColor: colorcode,backgroundColor: colorcode, borderWidth: 1})
                 
                 
              }
                setTimeout(() =>  this.categorychart(), 3000);    
                //this.categorychart();
           }
           

        }

   }
} 
} 

</script>

<style scoped>
.side-by .modal-dialog.cuts-modal .modal-content .modal-header {
  padding: 10px 30px !important;
}
.side-by .modal-dialog.cuts-modal .modal-content .modal-header h3{ color:#fff; font-size: 20px;}
.light-theme-np .side-by .modal-dialog.cuts-modal .modal-content .modal-header h3{ color:#000;}
 .tab_boxe .sidePageTittle  {
    font-weight: 300;
    font-size: 44px;
    line-height: 66px;
    text-align: center;
    text-transform: capitalize;
    color: #FFFFFF;
}
.tab_boxe .sidePagePara  {
    font-weight: 300;
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    color: #FFFFFF;
}
.tab_boxe .appsBox {
    padding-top: 70px;
}

.black :deep(.form-control) {
  color: #fff !important;
}

.simple-typeahead > input.form-control{ color: #fff !important; text-indent: 10px;padding: 10px 0; width:100%;}


 .cuts-modal{
    width: 60%;
  }

.ful-wdth .serach-result-part {
  padding-top:0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.light-theme-np #compayarpps_wrapper{
    border: solid 1px #000;
}

.light-theme-np :deep(#compayarpps.searching.form-control) {
    color: #000 !important;
}

#compayarpps_wrapper {
 border: solid 1px #fff;}
  .serach-result-part button[type="submit"] {
  background: #469ae0;
  border-radius: 0;
}

#exampleModal2.form-control {
  display: block;
  width: 100%;
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #fff !important;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: .25rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

input#compayarpps {
    color: #fff;
}

.compayarpps{ display: flex !important;}

#exampleModal2 .modal-footer{ border: none;}

.appsBox .appsSub  {
    position: relative;
    background: rgba(48, 48, 64, 0.82);
    border: 0.5px solid rgba(116, 116, 116, 0.16);
    box-shadow: 0px 4px 67px rgba(0, 0, 0, 0.38);
    border-radius: 11px 11px 4px 4px;
    height: 100%;
}
.appsBox .appsSub .appSubHead h2  {
    font-family: 'Rubik';
    font-weight: 500;
    font-size: 28px;
    line-height: 33px;
    letter-spacing: 0.02em;
    text-transform: capitalize;
    color: #FFFFFF;
    background: #2571B0;
    border: 0.5px solid rgba(116, 116, 116, 0.16);
    border-radius: 9px 9px 0px 0px;
    padding: 20px 0px 20px 36px;
}
.appsBox .appsSub .appSubGreen h2  {
    background: #3CAEA3;
}
.appsBox .appsSub .appSubViolet h2  {
    background: #685DC9;
}
.appsBox .appsSub .appSubProfile  {
    padding: 46px 35px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    word-break: break-all;
}
.appsBox .appsSub .appSubProfile .proImg  {
    width: 80px !important;
}
.appsBox .appsSub .appSubProfile .proText  {
    width: calc(100% - 80px);
}
.appsBox .appsSub .appSubProfile .proText h3  {
    font-family: 'Rubik';
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    text-transform: capitalize;
    color: #FFFFFF;
}
.appsBox .appsSub .appSubProfile .proText h4  {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px; 
    text-transform: capitalize;  
    color: #C0C0C0;
}
.appsBox .appsSub .appSubProfile .proText h4 span  {
    color: #fff;
    padding-right: 5px;
}
.appsBox .appsSub .appSubProfile .proText p  {
    font-family: 'Rubik';
    font-weight: 400;
    font-size: 15px;
    line-height: 23px;
    text-transform: capitalize;
    color: rgba(255, 255, 255, 0.83);
    
}
.appsBox .appsSub .appSubProfile .proText h5  {
    font-family: 'Rubik';
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    color: #FFFFFF;
}
.appsBox .appsSub .appSubProfile .proText h5 span  {
    vertical-align: middle;
    padding-right: 5px;
    display:inline-block;
    margin-top: -5px;
}
.appsBox .appsSub .appSubProfile .proText a  {
    text-decoration: none;
    font-family: 'Rubik';
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    text-transform: capitalize;
    color: #1BF197;
}

.light-theme-np .appsBox .appsSub .appSubProfile .proText a{color:#2571b0;}

:deep(.light-theme-np .searching.form-control:focus) {

    color: #000 !important;

}

.appsBox .appsSub .appSubOption  {
    padding: 0 25px 20px 25px;
}
.appsBox .appsSub .appSubOption p  {
    font-family: 'Rubik';
     font-weight: 400;
    font-size: 17px;
    line-height: 55px;
    text-transform: capitalize;
    color: rgba(255, 255, 255, 0.74);
}
.appsBox .appsSub .appSubOption p span  {
    padding-right: 10px;
}
.appsBox .appsSub .appSubOption p:not(:last-child)  {
    border-bottom: 1px solid rgba(81, 81, 99, 0.31);
}
.appsBox .appSubClose  {
    position: absolute;
    top: -12px;
    right: -17px;
}
.appsBox .appSubClose a {
    background: #62B8FF;
    border: 2px solid #181826;
    border-radius: 50px;
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.appsBox .appSubCloseGreen a {
    background: #3CAEA3;
}
.appsBox .appSubCloseViolet a {
    background: #685DC9;
}
.comparingTableLeft table thead tr th  {
    text-align: center !important;
}
.comparingTableLeft table thead tr > :nth-child(1)  {
    text-align: left !important;
}
.comparingTableLeft table tbody tr td  {
    text-align: center !important;
}
.comparingTableLeft table tbody tr > :nth-child(1)  {
    text-align: left !important;
}
.main_right-pn .tab_boxe .tab_Three_Box canvas {
    width: 100% !important;
    max-height: 641px !important;
    background: #282838;
    padding: 25px 35px;
    border: 0.5px solid rgba(0, 0, 0, 0.31);
}
.tab_Three_Box .comparingLink  {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-bottom: 40px;
}
.tab_Three_Box .comparingLink .comparingSelect  {
    max-width: 243px;
    width: 100%;
}
.tab_Three_Box .comparingLink .comparingSelect select  {
    border: 1px solid #737389;
    border-radius: 4px;
    width: 100%;
    height: 40px;
    font-family: 'Rubik';
    font-weight: 400;
    font-size: 16px;
    line-height: 35px;
    letter-spacing: 0.02em;
    color: #FFFFFF;
    padding-left: 10px;
    outline: none;
    background: #191927;
}
.tab_Three_Box .comparingLink .comparingOption ul,
.comparfningHeadUl ul  {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.tab_Three_Box .comparingLink .comparingOption ul li:not(:last-child),
.comparfningHeadUl ul li:not(:last-child)  {
    padding-right: 30px;
}
.tab_Three_Box .comparingLink .comparingOption ul li a,
.comparfningHeadUl ul li a  {
    text-decoration: none;
    font-weight: 400;
    font-size: 17px;
    line-height: 26px;
    text-align: center;
    text-transform: capitalize;
    color: #FFFFFF;
}
.tab_Three_Box .comparingLink .comparingOption ul li a span  {
    padding-right: 6px;
}
.comparfningHeadUl {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 100px;
    padding-bottom: 42px;
}
.comparfningHeadUl h2  {
    padding: 0 !important;
}

.keyWordSecBox .heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-top: 120px;
  padding-bottom: 36px;
  position: relative;
}

.keyWordSecBox .heading h3 {
  font-weight: 300;
  font-size: 34px;
  text-transform: capitalize;
  color: #FFFFFF;
}

.keyWordSecBox .table-section .skyBackground thead {
  background: #00ADD2 !important;
}

.comparingTableLeft table thead tr > :nth-child(1) {
  text-align: left !important;
}

.light-theme-np .main_right-pn .appsBox .appsSub {
  background: #eaecee;
}

.light-theme-np .main_right-pn .tab_boxe h3, .light-theme-np .appsBox .appsSub .appSubProfile .proText p, .light-theme-np .appsBox .appsSub .appSubOption p, .light-theme-np .appsBox .appsSub .appSubProfile .proText h5, .light-theme-np .appsBox .appsSub .appSubProfile .proText h4, .light-theme-np .appsBox .appsSub .appSubProfile .proText h4 span
{color: #212529 !important;}
.appsBox .appsSub .appSubOption p:not(:last-child) {
  border-bottom: 1px solid rgba(81, 81, 99, 0.31);
}

.main_right-pn .tab_boxe {
  padding-top: 110px;
}

.main_right-pn .tab_boxe h2 {
  font-weight: 300;
  font-size: 40px;
  line-height: 60px;
  text-transform: capitalize;
  color: #FFFFFF;
  padding-bottom: 45px;
  margin: 0;
}

.light-theme-np .tab_boxe .sidePagePara, .light-theme-np .tab_Three_Box .comparingLink .comparingOption ul li a, .light-theme-np .comparfningHeadUl ul li a{color: #212529 !important;}

 .light-theme-np .main_right-pn .tab_boxe h2{ color:#fff;}
 .light-theme-np .main_right-pn .tab_boxe h2 {color: #212529;}
 .light-theme-np .main_right-pn .tab_boxe .appSubHead h2{color:#fff;}

 .light-theme-np .tab_Three_Box .comparingLink .comparingSelect select  {
    color: #212529;
    background-color: #e8e8e8e6;
    border: 1px solid #e7e7e757
 }
  .light-theme-np .main_right-pn .tab_boxe .tab_Three_Box canvas  {
    background: #fff;
    border-color: #d6d6d6;
 }

.main_right-pn .table-section table thead th:first-child{ padding-left: 12px;}
.tab_Three_Box{ margin-top:30px;}
.main_right-pn .table-section table tbody td{ padding: 12px;}

.light-theme-np .appsBox .appSubClose a{background: #fff;border: 2px solid #00d1ff;}
  @media (max-width: 1799px){
.main_right-pn .table-section table thead th {
  padding: 16px 3px;
}
  }

  @media (min-width: 1280px) and (max-width: 1400px) {
.appsBox .appsSub .appSubProfile .proText {
  width: calc(100% - 90px);
}
.main_right-pn .table-section table tbody td, .tab_Two_Box .skygren-table tbody td {
  
  padding: 12px;
}

.main_right-pn .tab_boxe h2 {
  font-size: 26px;
}
.main_right-pn .tab_boxe h2, .main_right-pn .tab_tittle_border {
  padding-bottom: 0px;
}
  }

  @media (max-width: 1366px) {
.appsBox .appsSub .appSubProfile {
  padding: 46px 11px;
}
.appsBox .appsSub .appSubProfile .proText h3 {
  font-size: 18px;
}
.appsBox .appsSub .appSubOption {
  padding: 0 25px 20px 25px;
}
}






/********************************* New Added Css************************************** */
 .tab_boxe .sidePageTittle  {
    font-weight: 300;
    font-size: 44px;
    line-height: 66px;
    text-align: center;
    text-transform: capitalize;
    color: #FFFFFF;
}
.tab_boxe .sidePagePara  {
    font-weight: 300;
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    color: #FFFFFF;
}
.tab_boxe .appsBox {
    padding-top: 70px;
}
.appsBox .appsSub  {
    position: relative;
    background: rgba(48, 48, 64, 0.82);
    border: 0.5px solid rgba(116, 116, 116, 0.16);
    box-shadow: 0px 4px 67px rgba(0, 0, 0, 0.38);
    border-radius: 11px 11px 4px 4px;
    height: 100%;
}
.appsBox .appsSub .appSubHead h2  {
    font-family: 'Rubik';
    font-weight: 500;
    font-size: 28px;
    line-height: 33px;
    letter-spacing: 0.02em;
    text-transform: capitalize;
    color: #FFFFFF;
    background: #2571B0;
    border: 0.5px solid rgba(116, 116, 116, 0.16);
    border-radius: 9px 9px 0px 0px;
    padding: 20px 0px 20px 36px;
}
.appsBox .appsSub .appSubGreen h2  {
    background: #3CAEA3;
}
.appsBox .appsSub .appSubViolet h2  {
    background: #685DC9;
}
.appsBox .appsSub .appSubProfile  {
    padding: 35px 15px 25px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}
.appsBox .appsSub .appSubProfile .proImg  {
    width: 110px;
}
.appsBox .appsSub .appSubProfile .proText  {
    width: calc(100% - 110px);
}
.appsBox .appsSub .appSubProfile .proText h3  {
    font-family: 'Rubik';
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    text-transform: capitalize;
    color: #FFFFFF;
}
.appsBox .appsSub .appSubProfile .proText h4  {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px; 
    text-transform: capitalize;  
    color: #C0C0C0;
}
.appsBox .appsSub .appSubProfile .proText h4 span  {
    color: #fff;
    padding-right: 5px;
}
.appsBox .appsSub .appSubProfile .proText p  {
    font-family: 'Rubik';
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    text-transform: capitalize;
    color: rgba(255, 255, 255, 0.83);
    
}
.appsBox .appsSub .appSubProfile .proText h5  {
    font-family: 'Rubik';
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    color: #FFFFFF;
}
.appsBox .appsSub .appSubProfile .proText h5 span  {
    vertical-align: middle;
    padding-right: 5px;
}
.appsBox .appsSub .appSubProfile .proText a  {
    text-decoration: none;
    font-family: 'Rubik';
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    text-transform: capitalize;
    color: #1BF197;
}
.appsBox .appsSub .appSubOption  {
    padding: 0 25px 20px 25px;
}
.appsBox .appsSub .appSubOption p  {
    font-family: 'Rubik';
     font-weight: 400;
    font-size: 17px;
    line-height: 25px;
    text-transform: capitalize;
    color: rgba(255, 255, 255, 0.74);
    margin: 0;
    padding:10px 0;
}
.appsBox .appsSub .appSubOption p span  {
    padding-right: 10px;
}
.appsBox .appsSub .appSubOption p:not(:last-child)  {
    border-bottom: 1px solid rgba(81, 81, 99, 0.31);
}
.appsBox .appSubClose  {
    position: absolute;
    top: -12px;
    right: -17px;
}
.appsBox .appSubClose a {
    background: #62B8FF;
    border: 2px solid #181826;
    border-radius: 50px;
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.appsBox .appSubCloseGreen a {
    background: #3CAEA3;
}
.appsBox .appSubCloseViolet a {
    background: #685DC9;
}
.comparingTableLeft table thead tr th  {
    text-align: center !important;
}
.comparingTableLeft table thead tr > :nth-child(1)  {
    text-align: left !important;
}
.comparingTableLeft table tbody tr td  {
    text-align: center !important;
}
.comparingTableLeft table tbody tr > :nth-child(1)  {
    text-align: left !important;
}
.main_right-pn .tab_boxe .tab_Three_Box canvas {
    width: 100% !important;
    max-height: 641px !important;
    background: #282838;
    padding: 25px 35px;
    border: 0.5px solid rgba(0, 0, 0, 0.31);
}
.tab_Three_Box .comparingLink  {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-bottom: 40px;
}
.tab_Three_Box .comparingLink .comparingSelect  {
    max-width: 243px;
    width: 100%;
}
.tab_Three_Box .comparingLink .comparingSelect select  {
    border: 1px solid #737389;
    border-radius: 4px;
    width: 100%;
    height: 40px;
    font-family: 'Rubik';
    font-weight: 400;
    font-size: 16px;
    line-height: 35px;
    letter-spacing: 0.02em;
    color: #FFFFFF;
    padding-left: 10px;
    outline: none;
    background: #191927;
}
.tab_Three_Box .comparingLink .comparingOption ul,
.comparfningHeadUl ul  {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.tab_Three_Box .comparingLink .comparingOption ul li:not(:last-child),
.comparfningHeadUl ul li:not(:last-child)  {
    padding-right: 30px;
}
.tab_Three_Box .comparingLink .comparingOption ul li a,
.comparfningHeadUl ul li a  {
    text-decoration: none;
    font-weight: 400;
    font-size: 17px;
    line-height: 26px;
    text-align: center;
    text-transform: capitalize;
    color: #FFFFFF;
}
.tab_Three_Box .comparingLink .comparingOption ul li a span  {
    padding-right: 6px;
}
.comparfningHeadUl {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 100px;
    padding-bottom: 42px;
}
.comparfningHeadUl h2  {
    padding: 0 !important;
}

.keyWordSecBox .heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-top: 120px;
  padding-bottom: 36px;
  position: relative;
}

.keyWordSecBox .heading h3 {
  font-weight: 300;
  font-size: 34px;
  text-transform: capitalize;
  color: #FFFFFF;
}

.keyWordSecBox .table-section .skyBackground thead {
  background: #00ADD2 !important;
}

.comparingTableLeft table thead tr > :nth-child(1) {
  text-align: left !important;
}

.light-theme-np .main_right-pn .appsBox .appsSub {
  background: #eaecee;
}

.light-theme-np .main_right-pn .tab_boxe h3, .light-theme-np .appsBox .appsSub .appSubProfile .proText p, .light-theme-np .appsBox .appsSub .appSubOption p, .light-theme-np .appsBox .appsSub .appSubProfile .proText h5, .light-theme-np .appsBox .appsSub .appSubProfile .proText h4, .light-theme-np .appsBox .appsSub .appSubProfile .proText h4 span
{color: #212529 !important;}
.appsBox .appsSub .appSubOption p:not(:last-child) {
  border-bottom: 1px solid rgba(81, 81, 99, 0.31);
}

.main_right-pn .tab_boxe {
  padding-top: 110px;
}

.main_right-pn .tab_boxe h2 {
  font-weight: 300;
  font-size: 40px;
  line-height: 60px;
  text-transform: capitalize;
  color: #FFFFFF;
  padding-bottom: 45px;
  margin: 0;
}

.light-theme-np .tab_boxe .sidePagePara, .light-theme-np .tab_Three_Box .comparingLink .comparingOption ul li a, .light-theme-np .comparfningHeadUl ul li a{color: #212529 !important;}

 .light-theme-np .main_right-pn .tab_boxe h2{ color:#fff;}
 .light-theme-np .main_right-pn .tab_boxe h2 {color: #212529;}
 .light-theme-np .main_right-pn .tab_boxe .appSubHead h2{color:#fff;}

 .tab_boxe .comparingThree .comThreeBox {
  background: #232335;
  border: 0.5px solid rgba(116, 116, 116, 0.16);
  box-shadow: 0px 4px 67px rgba(0, 0, 0, 0.38);
  border-radius: 4px;
  padding: 72px 80px;
  height: 100%;
}

.tab_boxe .comparingThree .comThreeBox a .threeDiv {
  width: 173.25px;
  height: 173.25px;
  background: rgba(61, 61, 79, 0.73);
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.tab_boxe .comparingThree .comThreeBox a .threeDiv .btn {
  width: 66px;
  height: 66px;
  background: #4C4C5C;
  border-radius: 50%;
  background: #4C4C5C;
  border: 1px solid #5A5A6B;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tab_boxe .comparingThree .comThreeBox h3 {
  font-weight: 300;
  font-size: 28px;
  line-height: 42px;
  text-align: center;
  color: #FFFFFF;
  padding-top: 55px;
}

.tab_boxe .comparingThree {
  padding-top: 44px;
}

.light-theme-np .tab_boxe .comparingThree .comThreeBox {
  background: #ececec7a;
  border: 0.5px solid #74747414;
  box-shadow: 0px 4px 4px #8f8f8f70;
}

.light-theme-np .tab_boxe .comparingThree .comThreeBox a .threeDiv {
  background: #d6d6d6;
  border: 1px solid #d6d6d6;
}

.light-theme-np .tab_boxe .comparingThree .comThreeBox a .threeDiv .btn {
  background: #e1e1e1;
  border: 1px solid #cacaca;
}

.black .searching.form-control {
  color: #fff;
}

.light-theme-np .searching.form-control {
  color: #000;
}



  @media (max-width: 1799px){
.main_right-pn .table-section table thead th {
  padding: 16px 3px;
}
  }

  @media (min-width: 1280px) and (max-width: 1400px) {
.appsBox .appsSub .appSubProfile .proText {
  width: calc(100% - 90px);
}
.main_right-pn .table-section table tbody td, .tab_Two_Box .skygren-table tbody td {
  
  padding: 12px;
}

.main_right-pn .tab_boxe h2 {
  font-size: 26px;
}
.main_right-pn .tab_boxe h2, .main_right-pn .tab_tittle_border {
  padding-bottom: 0px;
}
  }

  @media (max-width: 1366px) {
.appsBox .appsSub .appSubProfile {
  padding: 35px 15px 25px;
}
.appsBox .appsSub .appSubProfile .proText h3 {
  font-size: 18px;
}
.appsBox .appsSub .appSubOption {
  padding: 0 30px 20px 30px;
}
  }
  @media (max-width: 767px) {
 .tab_boxe .comparingThree .comThreeBox{ padding:40px 20px;}
 .tab_boxe .comparingThree .comThreeBox h3{ font-size: 24px;}
.cuts-modal{
    width: 90%;
}
.tab_Three_Box .comparingLink .comparingOption ul li a, .comparfningHeadUl ul li a{ font-size:12px;}
.appsBox .appsSub .appSubProfile{ padding: 35px 15px 20px;}
.tab_boxe .sidePagePara{ line-height: 24px;}

  }
 

 .growth-chh2{
  height: 300px;
}

</style>
