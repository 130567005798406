const mutations = {
  
    SET_BLOGS: (state, bloglist) => {
      state.bloglist = bloglist;
    },

    SET_TOTALBLOG: (state, totalblog) => {
      state.totalblog = totalblog;
    },
    SET_TOTALPAGE: (state, totalpage) => {
      state.totalpage = totalpage;
    },  

    SET_BLOGDETAILS: (state, blogdetails) => {
      state.blogdetails = blogdetails;
    },

    SET_POPULARPOST: (state, popularblog) => {
      state.popularblog = popularblog;
    },

   
};

export default mutations;