<template>
 <div class="ful-wdth">

    <div class="tab_boxe" v-if="isload">
        <h2 class="tab_Boxe_Tittle">{{appcurrentdata.app_title}} - Change Logs</h2>

        <div class="changeLogsBox">
            <div class="changeLogsStatus">
                <div class="changeLogsImage">
                    <img src="@/assets/images/table-icon/tabl-chngelog-22.png">
                </div>
                <div class="changeLogsText">
                    <h3>Current Status</h3>
                    <span>Overall rank: {{appcurrentdata.cat_current_rank}}</span>
                    <span>Reviews: {{appcurrentdata.app_num_of_reviews}}</span>
                    <span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="14" fill="#FEC33E" class="bi bi-star-fill" viewBox="0 0 16 16">
                            <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
                          </svg>
                        Ratings: {{appcurrentdata.app_rating}}</span>
                </div>
            </div>
            <router-link to="/changelog-applisting" class="bk-btn">Back</router-link>
            <div class="changeLogsSub" >
                <ul>

                    <li v-bind:key="result.app_id" v-for="(result,index) in appchangelogdata">
                        <div class="changeRound">
                            <span></span>
                        </div>
                        <div class="changeDiv">
                            <span>{{ moment(result.lastmod).format("MMM DD, YYYY") }}</span>
                            <span>Over all rank: {{appranddatewise[result.lastmod]}}</span>
                            <span>Reviews: {{result.app_num_of_reviews}}</span>
                            <span>
                                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="14" fill="#FEC33E" class="bi bi-star-fill" viewBox="0 0 16 16">
                                    <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
                                  </svg>
                                Ratings: {{result.app_rating}}</span>
                             <div class="changeDivBtn" v-if="appchangelogdata.length==index+1">
                                 <router-link :to="'/change-logs-Details/'+result.app_id+'/'+result.lastmod"> First Version Recorded</router-link>
                              
                            </div> 

                             <div class="changeDivBtn" v-else>
                               <router-link :to="'/change-logs-Details/'+result.app_id+'/'+result.lastmod"> Detected {{changecount[index]}} changes</router-link>
                            </div>

                        </div>
                    </li>

                </ul>
            </div>
        </div>
    </div>

     <div class="loading" v-else>
           <div class="loader1">
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="margin:auto;display:block;" width="200px" height="200px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
<circle cx="75" cy="50" fill="#00d1ff" r="5">
  <animate attributeName="r" values="3;3;5;3;3" times="0;0.1;0.2;0.3;1" dur="1s" repeatCount="indefinite" begin="-0.9166666666666666s"></animate>
  <animate attributeName="fill" values="#00d1ff;#00d1ff;#00d1ff;#00d1ff;#00d1ff" repeatCount="indefinite" times="0;0.1;0.2;0.3;1" dur="1s" begin="-0.9166666666666666s"></animate>
</circle><circle cx="71.65063509461098" cy="62.5" fill="#00d1ff" r="5">
  <animate attributeName="r" values="3;3;5;3;3" times="0;0.1;0.2;0.3;1" dur="1s" repeatCount="indefinite" begin="-0.8333333333333334s"></animate>
  <animate attributeName="fill" values="#00d1ff;#00d1ff;#00d1ff;#00d1ff;#00d1ff" repeatCount="indefinite" times="0;0.1;0.2;0.3;1" dur="1s" begin="-0.8333333333333334s"></animate>
</circle><circle cx="62.5" cy="71.65063509461096" fill="#00d1ff" r="5">
  <animate attributeName="r" values="3;3;5;3;3" times="0;0.1;0.2;0.3;1" dur="1s" repeatCount="indefinite" begin="-0.75s"></animate>
  <animate attributeName="fill" values="#00d1ff;#00d1ff;#00d1ff;#00d1ff;#00d1ff" repeatCount="indefinite" times="0;0.1;0.2;0.3;1" dur="1s" begin="-0.75s"></animate>
</circle><circle cx="50" cy="75" fill="#00d1ff" r="5">
  <animate attributeName="r" values="3;3;5;3;3" times="0;0.1;0.2;0.3;1" dur="1s" repeatCount="indefinite" begin="-0.6666666666666666s"></animate>
  <animate attributeName="fill" values="#00d1ff;#00d1ff;#00d1ff;#00d1ff;#00d1ff" repeatCount="indefinite" times="0;0.1;0.2;0.3;1" dur="1s" begin="-0.6666666666666666s"></animate>
</circle><circle cx="37.50000000000001" cy="71.65063509461098" fill="#00d1ff" r="5">
  <animate attributeName="r" values="3;3;5;3;3" times="0;0.1;0.2;0.3;1" dur="1s" repeatCount="indefinite" begin="-0.5833333333333334s"></animate>
  <animate attributeName="fill" values="#00d1ff;#00d1ff;#00d1ff;#00d1ff;#00d1ff" repeatCount="indefinite" times="0;0.1;0.2;0.3;1" dur="1s" begin="-0.5833333333333334s"></animate>
</circle><circle cx="28.34936490538903" cy="62.5" fill="#00d1ff" r="5">
  <animate attributeName="r" values="3;3;5;3;3" times="0;0.1;0.2;0.3;1" dur="1s" repeatCount="indefinite" begin="-0.5s"></animate>
  <animate attributeName="fill" values="#00d1ff;#00d1ff;#00d1ff;#00d1ff;#00d1ff" repeatCount="indefinite" times="0;0.1;0.2;0.3;1" dur="1s" begin="-0.5s"></animate>
</circle><circle cx="25" cy="50" fill="#00d1ff" r="5">
  <animate attributeName="r" values="3;3;5;3;3" times="0;0.1;0.2;0.3;1" dur="1s" repeatCount="indefinite" begin="-0.4166666666666667s"></animate>
  <animate attributeName="fill" values="#00d1ff;#00d1ff;#00d1ff;#00d1ff;#00d1ff" repeatCount="indefinite" times="0;0.1;0.2;0.3;1" dur="1s" begin="-0.4166666666666667s"></animate>
</circle><circle cx="28.34936490538903" cy="37.50000000000001" fill="#00d1ff" r="5">
  <animate attributeName="r" values="3;3;5;3;3" times="0;0.1;0.2;0.3;1" dur="1s" repeatCount="indefinite" begin="-0.3333333333333333s"></animate>
  <animate attributeName="fill" values="#00d1ff;#00d1ff;#00d1ff;#00d1ff;#00d1ff" repeatCount="indefinite" times="0;0.1;0.2;0.3;1" dur="1s" begin="-0.3333333333333333s"></animate>
</circle><circle cx="37.499999999999986" cy="28.349364905389038" fill="#00d1ff" r="5">
  <animate attributeName="r" values="3;3;5;3;3" times="0;0.1;0.2;0.3;1" dur="1s" repeatCount="indefinite" begin="-0.25s"></animate>
  <animate attributeName="fill" values="#00d1ff;#00d1ff;#00d1ff;#00d1ff;#00d1ff" repeatCount="indefinite" times="0;0.1;0.2;0.3;1" dur="1s" begin="-0.25s"></animate>
</circle><circle cx="49.99999999999999" cy="25" fill="#00d1ff" r="5">
  <animate attributeName="r" values="3;3;5;3;3" times="0;0.1;0.2;0.3;1" dur="1s" repeatCount="indefinite" begin="-0.16666666666666666s"></animate>
  <animate attributeName="fill" values="#00d1ff;#00d1ff;#00d1ff;#00d1ff;#00d1ff" repeatCount="indefinite" times="0;0.1;0.2;0.3;1" dur="1s" begin="-0.16666666666666666s"></animate>
</circle><circle cx="62.5" cy="28.349364905389034" fill="#00d1ff" r="5">
  <animate attributeName="r" values="3;3;5;3;3" times="0;0.1;0.2;0.3;1" dur="1s" repeatCount="indefinite" begin="-0.08333333333333333s"></animate>
  <animate attributeName="fill" values="#00d1ff;#00d1ff;#00d1ff;#00d1ff;#00d1ff" repeatCount="indefinite" times="0;0.1;0.2;0.3;1" dur="1s" begin="-0.08333333333333333s"></animate>
</circle><circle cx="71.65063509461096" cy="37.499999999999986" fill="#00d1ff" r="5">
  <animate attributeName="r" values="3;3;5;3;3" times="0;0.1;0.2;0.3;1" dur="1s" repeatCount="indefinite" begin="0s"></animate>
  <animate attributeName="fill" values="#00d1ff;#00d1ff;#00d1ff;#00d1ff;#00d1ff" repeatCount="indefinite" times="0;0.1;0.2;0.3;1" dur="1s" begin="0s"></animate>
</circle>
            </svg>
           </div>
     </div>

 </div>
</template>

<script>
import {mapState,mapActions} from 'vuex'
import { useRoute } from 'vue-router'
import store from '@/store/index.js';
import moment from "moment";
export default {
   name:'AppListingChangelogs',
   components: {
  },
  data() {
        return {
           app_id:'',
           userid:'',
           isload:false,  
           moment: moment,
           changecount:[],
           kebenifit1:[],  
           kebenifit2:[]  
        }
  },
   mounted() {  
        const vm = this;
        const route=useRoute();
        this.app_id=route.params.app_id;
        this.userid=this.user.data.userid;
        this.appcurrentdetails(this.app_id);
        this.appchangelogdetails(this.app_id);
  },

  computed: {
      ...mapState({
          user: (state) => state.authModule.user,
          userfollowapps: (state) => state.authModule.userfollowapps,
          appcurrentdata: (state) => state.AppListModule.appcurrentdata,
          appchangelogdata: (state) => state.AppListModule.appchangelogdata,
          appranddatewise: (state) => state.AppListModule.appranddatewise,
          appkeybenifit: (state) => state.AppListModule.keybenifitdetails,
      }), 
  },
  methods:{
        ...mapActions({
            appchangelogdetails: 'AppListModule/GetAppChangelogHistory',
            appcurrentdetails: 'AppListModule/GetAppCurrentdetails',
         }),
    },

    beforeRouteEnter(to, from, next) {
        const route=useRoute();        
        const userfollowappsdata = store.state.authModule.userfollowapps;
        if(to.params.app_id!='' && userfollowappsdata.some(item => item.app_id === to.params.app_id)){
            next(); 
        }else{
           next('/changelog-applisting');
        }
    },
    watch: {
        // appcurrentdata(newvalue,oldvalue){           
          
            
        // },

        appchangelogdata(newvalue,oldvalue){  
            if(this.appchangelogdata.length>0){
               this.appchangelogdata.forEach((value, index) => {
                this.changecount[index]=0; 
                if(this.appchangelogdata.length>(index+1)){
                 this.kebenifit1=this.appkeybenifit[value.lastmod];
                 this.kebenifit2=this.appkeybenifit[this.appchangelogdata[index+1].lastmod];
                if(value.app_logo!=this.appchangelogdata[index+1].app_logo)
                  this.changecount[index]=this.changecount[index]+1;
                if(value.app_title!=this.appchangelogdata[index+1].app_title)
                  this.changecount[index]=this.changecount[index]+1;
                if(value.app_intro_vid_url!=this.appchangelogdata[index+1].app_intro_vid_url)
                  this.changecount[index]=this.changecount[index]+1;                   
                if(value.app_illustration_image!=this.appchangelogdata[index+1].app_illustration_image)
                  this.changecount[index]=this.changecount[index]+1;
                if(value.app_brief_description!=this.appchangelogdata[index+1].app_brief_description)
                  this.changecount[index]=this.changecount[index]+1;
                if(value.app_full_description!=this.appchangelogdata[index+1].app_full_description)
                  this.changecount[index]=this.changecount[index]+1; 
                if(value.app_pricing_hint!=this.appchangelogdata[index+1].app_pricing_hint)
                  this.changecount[index]=this.changecount[index]+1;
                if(value.tagline!=this.appchangelogdata[index+1].tagline)                
                  this.changecount[index]=this.changecount[index]+1;
                var iscount=false;
                this.kebenifit1.forEach((value2, index) => {
                        if(this.kebenifit2.includes(value2)) {
                            iscount=true;
                    }
                 });

                if(iscount)
                     this.changecount[index]=this.changecount[index]+1;
                }                
            });
            
            }
             this.isload=true  
        },


     }
} 
 
</script>


<style scoped>
 .main_right-pn .tab_boxe .tab_Boxe_Tittle  {
    text-align: center;
    max-width: 1007px;
    margin: 0 auto;
    text-align: center;
}
.main_right-pn .tab_boxe .changeLogsBox  {
    width: 100%;
    max-width: 1035px;
    margin: 0 auto;
    padding-top: 90px;
}
.main_right-pn .tab_boxe .changeLogsBox .changeLogsStatus  {
    width: calc(100% - 175px);
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 45px;
    float: left;
}
a.bk-btn{ padding: 0 20px; display: inline-block; font-size: 16px; line-height:40px; border:1px solid #08AED2;   display: flex;
  width: 175px;
  justify-content: center;
  color: #fff;
  border-radius: 5px;
}
.light-theme-np a.bk-btn{ color: #43DDFF;}
.main_right-pn .tab_boxe .changeLogsBox .changeLogsStatus .changeLogsImage  {
    width: 70px;
}
.main_right-pn .tab_boxe .changeLogsBox .changeLogsStatus .changeLogsImage img  {
    display: block;
}
.main_right-pn .tab_boxe .changeLogsBox .changeLogsStatus .changeLogsText  {
    width: calc(100% - 70px);
}
.main_right-pn .tab_boxe .changeLogsBox .changeLogsStatus .changeLogsText h3  {
    font-family: 'Rubik';
    font-weight: 400;
    font-size: 31px;
    line-height: 27px;
    letter-spacing: 0.01em;
    text-transform: capitalize;
    color: #FFFFFF;
}
.main_right-pn .tab_boxe .changeLogsBox .changeLogsStatus .changeLogsText span  {
    font-family: 'Rubik';
    font-weight: 400;
    font-size: 17px;
    line-height: 25px;
    letter-spacing: 0.01em;
    text-transform: capitalize;
    color: #D2D2D2;
    position: relative;
    padding-right: 26px;
    margin-right: 26px;
}
.main_right-pn .tab_boxe .changeLogsBox .changeLogsStatus .changeLogsText span::after  {
    content: "";
    width: 5px;
    height: 5px;
    top: 10px;
    right: 0;
    background: #fff;
    position: absolute;
    border-radius: 50px;
}
.main_right-pn .tab_boxe .changeLogsBox .changeLogsStatus .changeLogsText span:last-child::after  {
    display: none;
}
.main_right-pn .tab_boxe .changeLogsBox .changeLogsSub ul li  {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.main_right-pn .tab_boxe .changeLogsBox .changeLogsSub ul li:not(:last-child)  {
    margin-bottom: 45px;
}
.main_right-pn .tab_boxe .changeLogsBox .changeLogsSub ul li .changeRound  {
    width: 50px;
}
.main_right-pn .tab_boxe .changeLogsBox .changeLogsSub ul li .changeRound span  {
    width: 15px;
    height: 15px;
    background: #191927;
    border: 2px solid #00D1FF;
    border-radius: 50px;
    display: block;
    position: relative;
}
.main_right-pn .tab_boxe .changeLogsBox .changeLogsSub ul li .changeRound span::after {
    content: "";
    width: 100%;
    height: 114px;
    top: 10px;
    left: 5px;
    background-image: url(@/assets/images/table-icon/Line.png);
    position: absolute;
    background-repeat: no-repeat;
}
.main_right-pn .tab_boxe .changeLogsBox .changeLogsSub ul li:last-child .changeRound span::after{
    background: unset;
}
.main_right-pn .tab_boxe .changeLogsBox .changeLogsSub ul li .changeRound span:last-child  {
    background-image: none;
}
/* .main_right-pn .tab_boxe .changeLogsBox .changeLogsSub ul li .changeRound span::after:last-child  {
    background-image: none;
} */
.main_right-pn .tab_boxe .changeLogsBox .changeLogsSub ul li .changeDiv  {
    width: calc(100% - 50px);
    background: #303040;
    border: 0.5px solid rgba(48, 48, 64, 0.16);
    border-radius: 3px;
    padding: 22px 22px 22px 29px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
}
.main_right-pn .tab_boxe .changeLogsBox .changeLogsSub ul li .changeDiv::after {
    content: "";
    width: 35px;
    height: 35px;
    top: 27%;
    left: -14px;
    background: #303040;
    border-radius: 2px;
    transform: rotate(-45deg);
    position: absolute;
}
.main_right-pn .tab_boxe .changeLogsBox .changeLogsSub ul li .changeDiv span  {
    font-family: 'Rubik';
    font-weight: 600;
    font-size: 17px;
    line-height: 25px;
    letter-spacing: 0.01em;
    text-transform: capitalize;
    color: #FFFFFF;
    position: relative;
    border-right: 1px solid #9292B0;
    flex-grow: 1;
    text-align: center;
}
.main_right-pn .tab_boxe .changeLogsBox .changeLogsSub ul li .changeDiv span:nth-child(4)  {
    border-right: none;
}
.main_right-pn .tab_boxe .changeLogsBox .changeLogsSub ul li .changeDiv .changeDivBtn a  {
    border: 1px solid #08AED2;
    border-radius: 3px;
    font-family: 'Rubik';
    font-weight: 400;
    font-size: 16px;
    text-align: center;
    letter-spacing: 0.01em;
    color: #08AED2;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3px 17px;
    text-decoration: none;
    transition: all ease-in-out 300ms;
    width: 212px;
}
.main_right-pn .tab_boxe .changeLogsBox .changeLogsSub ul li .changeDiv .changeDivBtn a:hover  {
    border: 1px solid #FF7301;
    background: #FF7301;
    color: #fff;
}

.main_right-pn .tab_boxe h2 {
  font-weight: 300;
  font-size: 40px;
  line-height: 60px;
  text-transform: capitalize;
  color: #FFFFFF;
  padding-bottom: 45px;
  margin: 0;
}
.light-theme-np .main_right-pn .tab_boxe h3, .light-theme-np .main_right-pn .tab_boxe .changeLogsBox .changeLogsStatus .changeLogsText span, .light-theme-np .main_right-pn .tab_boxe .changeLogsBox .changeLogsSub ul li, .light-theme-np .main_right-pn .tab_boxe .changeLogsBox .changeLogsSub ul li .changeDiv span,  .light-theme-np .main_right-pn .tab_boxe h2{color: #212529;}
.light-theme-np .main_right-pn .tab_boxe .changeLogsBox .changeLogsStatus .changeLogsText span::after{color: #212529;}

.light-theme-np .main_right-pn .tab_boxe .nav-tabs .nav-item .nav-link, .light-theme-np .main_right-pn .tab_boxe .changeLogsBox .changeLogsSub ul li .changeDiv {
  background-color: #f2f4f2e6;
  border: 1px solid #e7e7e757;
}

.light-theme-np .main_right-pn .tab_boxe .changeLogsBox .changeLogsSub ul li .changeDiv::after {

    background: #f2f4f2e6;

}
.light-theme-np .main_right-pn .tab_boxe .changeLogsBox .changeLogsSub ul li .changeRound span {
  background: #fff;}

.light-theme-np .main_right-pn .tab_boxe .changeLogsBox .changeLogsStatus .changeLogsText h3{ color:#000;}
.light-theme-np .main_right-pn .tab_boxe .changeLogsBox .changeLogsStatus .changeLogsText span::after {
  background: #212529;
}



  @media (min-width: 1280px) and (max-width: 1400px){
.main_right-pn .tab_boxe h2 {
  font-size: 26px;
}
.main_right-pn .tab_boxe h2, .main_right-pn .tab_tittle_border {
  padding-bottom: 0px;
}
  }
@media (max-width: 991px){
  .main_right-pn .tab_boxe .changeLogsBox .changeLogsSub ul li .changeDiv{ width:auto !important; padding: 22px !important;}
  
}
@media (max-width: 767px){
.main_right-pn .tab_boxe .changeLogsBox .changeLogsStatus{ width: 100%; margin-bottom:20px;}
a.bk-btn{ margin: 0 auto 25px;}
 .main_right-pn .tab_boxe .changeLogsBox .changeLogsSub ul li .changeDiv{ width:100% !important; padding: 22px 0 !important;}
}


</style>