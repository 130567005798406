<template>
  <div class="ful-wdth blk-bg">
     <div class="signup-box top-sign-pad " v-if="pageload">
       <div class="logo-login"><router-link to="/"><img src="@/assets/images/logo/Sas-rader-logo.png"></router-link></div>
          <h2>Password Reset Link</h2>
          <div class="row">                
            <div class="col-lg-12 col-md-12">
              <p>We've sent an email to your email id  with password reset link.</p>
              <p>If the email doesn't show up soon, check your spam box.</p>
              </div> 

          </div>  
             
    </div>

    <div class="signup-box top-sign-pad " v-else>
       <div class="logo-login"><router-link to="/"><img src="@/assets/images/logo/Sas-rader-logo.png"></router-link></div>
      <h2>Forgot Password</h2>
     <Form @submit="handleForgot" :validation-schema="schema" v-slot="{ errors }">
      <div class="row">                
        <div class="col-lg-12 col-md-12"><label>Email ID</label>  
          <Field name="email" type="email" placeholder="example@onboardme.io" :class="{ 'has-error': errors.email }" />
          <ErrorMessage name="email" class="text-danger" />
        </div> 

      </div>  
       <input type="submit" value="Submit">
     </Form>  
      <p class="pt-3"> <router-link to="/log-in">Login</router-link></p>

       
    </div> 

    

  </div>
</template>

<style scoped>
.signup-box.top-sign-pad {
  padding-top: 70px;
}
</style>

<script>
    import { Field, Form, ErrorMessage } from 'vee-validate'
    import * as yup from 'yup'
    import { mapState, mapActions } from 'vuex'
    //import router from '@/routes'
    export default {
        name: "userLogin",
        data () {
            const schema = yup.object({
                email: yup.string().required().email().label('The email'),
            });
            return {
                schema,
                pageload:false

            };
        },

        components: {
            Field,
            Form,
            ErrorMessage,
        },
        computed:{
           ...mapState({
                formLoader: state => state.authModule.formLoader,
                forgotPasswordMessage: state => state.authModule.forgotPasswordMessage,
            }),
        },
        methods: {
            ...mapActions({
                forgot: 'authModule/forgot',
            }),

            handleForgot (params, { setFieldError }) {
                const vm = this;
                params.setFieldError = setFieldError;
                vm.forgot(params);
            },
        },

        watch: {
           forgotPasswordMessage(newval,oldval) {
             //alert(newval) 
             this.pageload=true;
          },
        } 
    }
</script>