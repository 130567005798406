<template>
  <div class="tab-pane fade" id="pricing" role="tabpanel" aria-labelledby="pricing-tab">
         <Form @submit="deleteuseraccount" :validation-schema="schema" v-slot="{errors}" >
            <div class="tabeFour">
                <h3>Delete My Account</h3>
                    <div class="deleteBox">
                        <h4>Delete Account</h4>
                        <p>Are you sure you want to permanently delete your account?  To confirm, Please enter your password.</p>
                        
                        <div class="deleteInput">
                            <label for="">Password<span>*</span></label>
                             <Field name="password" type="password" placeholder="Type Password" :class="{ 'has-error': errors.password }" />
                             <ErrorMessage name="password" class="text-danger" />
                        </div>
                        <div class="deleteBtn">
                            <ul class="d-flex">
                                <li class="deleteBorder"><a href="#">Cancel</a></li>
                                <li class="deleteBackground">
                                    <input type="submit" class="submit_btn" value="Delete My Account">
                                    </li>
                            </ul>
                        </div>
                    </div>
            </div>
         </Form>

          </div>
</template>

<script>
import { Field, Form, ErrorMessage } from 'vee-validate'
import * as yup from 'yup'
import {mapState,mapActions} from 'vuex'
export default {
      name: 'DeSubscription',
      data() {
            const schema = yup.object({
                password: yup.string().trim().required().label('Please Enter your password'),
            });
            return {
              isload:false,
              schema,
              userdata:{
                user_id:'',
                password:'',
              },
              
            };
           
        },
    components: {
        Field,
        Form,
        ErrorMessage,
    },
    computed: {
        ...mapState({
             user:state=>state.authModule.user,
             accountdeleted:state=>state.authModule.accountdeleted,
        }),
    },
    mounted() { 
    },
    methods:{ 
       ...mapActions({
            UserAccountDelete:'authModule/UserAccountDelete',
            logoutUser: 'authModule/logout',
        }),
        
        deleteuseraccount (params, { setFieldError }) {
            const vm = this;
            params.setFieldError = setFieldError;
            if(confirm("Are you want to delete you account?")){
                this.userdata.user_id=this.user.data.userid;
                this.userdata.password=params.password;
                this.UserAccountDelete(this.userdata);
            }
        },
    },  
    watch: {
          accountdeleted(newval,oldval){
            this.logoutUser()
          }
    }
}
</script>

<style scoped>
.accManageProManageBox .accManageProManageContent .tabeFour h3  {
    font-weight: 500;
    font-size: 34px;
    line-height: 108px;
    text-transform: uppercase;
    color: #FFFFFF;
}
.accManageProManageBox .accManageProManageContent .tabeFour .deleteBox  {
    max-width: 860px;
    width: 100%;
    background: #323243;
    padding: 30px 45px;
}
.accManageProManageBox .accManageProManageContent .tabeFour .deleteBox h4  {
    font-weight: 300;
    font-size: 30px;
    line-height: 20px;
    text-transform: capitalize;
    color: #FFFFFF;
}
.accManageProManageBox .accManageProManageContent .tabeFour .deleteBox p  {
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 0.01em;
    color: #FFFFFF;
    padding-top: 15px;
    padding-bottom: 30px;
}
.accManageProManageBox .accManageProManageContent .tabeFour .deleteBox .deleteInput label  {
    display: block;
    font-weight: 400;
    font-size: 18px;
    line-height: 16px;
    text-transform: capitalize;
    color: #FFFFFF;
    padding-bottom: 15px;
}
.accManageProManageBox .accManageProManageContent .tabeFour .deleteBox .deleteInput label span  {
    color: #FEC33E;
} 
.accManageProManageBox .accManageProManageContent .tabeFour .deleteBox .deleteInput input  {
    width: 100%;
    height: 51.63px;
    outline: none;
    background: #4D4D64;
    border: 1px solid #7E7E95;
    border-radius: 4px;
    padding-left: 15px;
    font-family: 'Rubik';
    font-weight: 400;
    font-size: 17px;
    line-height: 35px;
    letter-spacing: 0.02em;
    color: #fff;
}
.light-theme-np .accManageProManageBox .accManageProManageContent .tabeFour .deleteBox .deleteInput input{  color:#323232}
.accManageProManageBox .accManageProManageContent .tabeFour .deleteBox .deleteBtn  {
    padding-top: 37px;
}
.accManageProManageBox .accManageProManageContent .tabeFour .deleteBox .deleteBtn ul  {
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
}
.accManageProManageBox .accManageProManageContent .tabeFour .deleteBox .deleteBtn ul li  {
    margin-right: 15px;
}
.accManageProManageBox .accManageProManageContent .tabeFour .deleteBox .deleteBtn ul .deleteBorder  {
    border: 1.5px solid #FFFFFF;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.accManageProManageBox .accManageProManageContent .tabeFour .deleteBox .deleteBtn ul .deleteBackground  {
    /* background: #ED3B3B;
    border: 1px solid #ED3B3B; */
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
} 
.accManageProManageBox .accManageProManageContent .tabeFour .deleteBox .deleteBtn ul li a  {
    padding: 13px 58px;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    text-transform: uppercase;
    color: #FFFFFF;
    display: block;
    text-decoration: none;
} 

.light-theme-np .accManageProManageBox .accManageProManageContent .tabeFour h3{color: #212529;}

.submit_btn{
    padding: 13px 58px;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    text-transform: uppercase;
    color: #FFFFFF;
    display: block;
    text-decoration: none;
    background: #ED3B3B;
    border: none;
     border: 1px solid #ED3B3B;
     border-radius: 5px;
}

@media (max-width: 767px){
.accManageProManageBox .accManageProManageContent .tabeFour .deleteBox .deleteBtn ul li a {
  padding: 13px 29px !important;
}
.submit_btn{
  padding: 13px 14px;}
}

</style>