<template>
 <div class="ful-wdth">
  <div class="section__head section__line">
        <h4>Keyword: {{searchkey}}</h4>
    </div>
    <div class="table-section" v-if="isloded">
        <div class="tbl-head">
          <p class="min-para">These are apps you're following:</p>
          
        </div>
        <div class="resp-table" v-if="applistbykeywords.length>0">
            <table class="product-table">
                <thead>
                    <tr>
                        <th>Plot</th>
                        <th>Rank</th>
                        <th>App Logo</th>
                        <th>App Name</th>
                        <th>Rating</th>
                        <th>Reviews</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-bind:key="result.app_id" v-for="result in applistbykeywords">
                        <td>
                            <div class="form-check tableChek">
                                <input checked="checked" disabled class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                            </div>
                        </td>
                        <td>{{result.cat_current_rank}}</td>
                        <td><router-link :to="'/about-details/' + result.app_id"><img :src=result.app_logo alt=""> </router-link></td>
                        <td><router-link :to="'/about-details/' + result.app_id"> {{result.app_title}} </router-link></td>
                        <td> {{result.app_rating}}</td>
                        <td> {{result.app_num_of_reviews}}</td>
                    </tr>
                    
                </tbody>
            </table>
        </div>
        <div></div>
    </div>
 
      <div class="grap-item" v-if="applistbykeywords.length>0">
          <div class="head-flex"  >
              <h5>Weekly <!--or Monthly---> Ranking Trends</h5>
              <ul class="d-flex">
                  <template v-bind:key="result.app_id" v-for="(result,index) in applistbykeywords">
                  <li :class="colorcode[index]"  >{{result.app_title}}</li>
                  </template>
              </ul>
          </div>
          <div class="growth-chh" >
              <canvas id="myChart32"></canvas>
          </div>
      </div>

      <div class="grap-item" v-if="applistbykeywords.length>0">
          <div class="head-flex">
              <h5>Ratings Over Time</h5>

              <ul class="d-flex">
                  <template v-bind:key="result.app_id" v-for="(result,index) in applistbykeywords">
                  <li :class="colorcode[index]"  >{{result.app_title}}</li>
                  </template>
              </ul>
          </div>
          <div class="growth-chh" >
              <canvas id="myChart11"></canvas>
          </div>
      </div>


      <div class="grap-item" v-if="applistbykeywords.length>0">
          <div class="head-flex">
              <h5>Weekly Reviews Trends</h5>
              <ul class="d-flex">
                  <template v-bind:key="result.app_id" v-for="(result,index) in applistbykeywords">
                  <li :class="colorcode[index]"  >{{result.app_title}}</li>
                  </template>
              </ul>
          </div>
          <div class="growth-chh" >
              <canvas id="myChart22"></canvas>
          </div>
      </div>


       <div class="table-section" v-if="nodata">
        <div class="tbl-head">
          <p class="min-para">Sorry No data found.</p>
        </div>
    </div>
  </div>
</template>

<style scoped>
   @media (max-width: 1440px){
.tab-ppr p, .main_right-pn .table-section .tbl-head p.min-para {
  font-size: 20px;
}
.main_right-pn .grap-item h5 {
  font-size: 28px;
}
   }
.tab-ppr p, .main_right-pn .table-section .tbl-head p.min-para {
  font-weight: 300;
  font-size: 24px;
  margin-bottom: 0px;
  color: #fff;
  line-height: 34px;
}
.main_right-pn .table-section table.product-table thead {
  border-left: 1px solid #0BA0FC;
  border-right: 1px solid #0BA0FC;
  background-color: #00ADD2;
}
.main_right-pn .table-section table thead th {
  font-family: 'Rubik';
  font-weight: 400;
  font-size: 17px;
  line-height: 1;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  color: #FFFFFF;
  padding: 20px 0;
  text-align: center;
  border-style: solid;
  border-right-width: 1px;
  white-space: nowrap;
}
.main_right-pn .table-section table.product-table thead tr > :nth-child(4), .main_right-pn .table-section table.product-table tbody tr > :nth-child(4) {
  text-align: left;
  padding-left: 80px;
}
.main_right-pn .grap-item .head-flex {
  display: flex;
  flex-wrap: wrap;
  row-gap: 14px;
  justify-content: space-between;
}
.main_right-pn .grap-item .head-flex ul {
  display: flex;
  align-items: center;
  gap: 28px;
}
.main_right-pn .grap-item .head-flex li {
  font-weight: 400;
  font-size: 17px;
  text-transform: capitalize;
  color: #FFFFFF;
  display: flex;
  align-items: center;
  gap: 8px;
}
.main_right-pn .grap-item .head-flex li[blue]::before {
  background-color: #00D1FF;
}

.main_right-pn .grap-item .head-flex li[yellow]::before {

    background-color: #FEC33E;

}
.main_right-pn .grap-item .head-flex li[green]::before {
  background-color: #0FF37D;
}

.main_right-pn .grap-item .head-flex li.blue::before {
  background-color: #00D1FF;
}

.main_right-pn .grap-item .head-flex li.yellow::before {

    background-color: #FEC33E;

}
.main_right-pn .grap-item .head-flex li.green::before {
  background-color: #0FF37D;
}
.main_right-pn .grap-item .head-flex li::before {
  content: '';
  display: block;
  width: 14px;
  height: 14px;
  border-radius: 50%;
}
.main_right-pn .grap-item h5 {
  font-weight: 300;
  font-size: 35px;
  text-transform: capitalize;
  color: #FFFFFF;
  margin-bottom: 0;
}
.light-theme-np .main_right-pn .table-section .tbl-head p.min-para, .light-theme-np .main_right-pn .grap-item h5,.light-theme-np .main_right-pn .grap-item .head-flex li {
  color: #212529;
}
  @media (max-width: 1440px){
.main_right-pn .grap-item .head-flex li::before {
  width: 12px;
  height: 12px;
}}

</style>

<script>
import Chart from 'chart.js/auto';
import { useRoute } from 'vue-router'
import { mapState,mapActions } from 'vuex';

export default {
name : 'KeyTrackingDetailsPage',


 data() {
        return {
          page: 1,
          searchkey:'',
          isloded:false,
          nodata:true,
          linechart1:false,
          linechart2:false,
          linechart3:false,
          chartload:false,
          keymyChart:null,
          keymyChart2:null,
          keymyChart3:null,
          colorcode:['blue','yellow','green','blue','yellow','green','blue','yellow','green','blue','yellow','green'],
          colorhexcode:['#00D1FF','#FFB40B','#10E678','#00D1FF','#FFB40B','#10E678','#00D1FF','#FFB40B','#10E678','#00D1FF','#FFB40B','#10E678'],
          params: {
                  page: 1,
                  per_page: 10,
                  user_id: '',
                  keyword:'',
              }, 
            userfollowapplist_key:[], 
            chartoption:{
               label:[],
               rattingdata:[],
               reviewdata:[],
               datasets1:[],
               datasets2:[],
               datasets3:[],
             }      
          }         
       },

        computed:{
              ...mapState({
                  user: (state) => state.authModule.user,
                  applistbykeywords: (state) => state.AppListModule.applistbykeywords,
                  userfollowapps: (state) => state.authModule.userfollowapps,
                  keywordmapdata: (state) => state.AppListModule.keywordmapdata,
              }),
           // userfollowkeywordsapp() {
                // return this.applistbykeywords.filter( (items) => this.userfollowapps.some((items2) => items2.app_id === items.app_id));
           // },
        },

        methods:{
            ...mapActions({
               GetApsListbyekeywordsearch: 'AppListModule/GetApsListbyekeywordsearch',
            }),

            initdata(){
                const vm=this;
                vm.isloded=true
                vm.nodata=false;          
            },

            chartint1(){
                  const vm=this;
                  const labels=["date 1","date 2","date 3","date 4"],
                  data={
                    labels:this.chartoption.label,
                    datasets:this.chartoption.datasets1
                     },
                  config={
                    type:"line",
                    data:data,
                    options:{
                      plugins:{
                        legend:{
                          display: false
                          },
                        },
                        scales: {
                            Y1: {
                                display: true,
                                min:0,
                                max:5,
                                ticks: {
                                    stepSize: 1,
                                },
                            },
                        },
                      }
                    }
                  this.keymyChart=new Chart(document.getElementById("myChart11"),config);
                  this.keymyChart
            },

            chartint2(){
                      const label2=["date 1","date 2","date 3","date 4"],
                      data2={
                        labels:this.chartoption.label,
                        datasets:this.chartoption.datasets2
                        },
                      config2={
                        type:"line",
                        data:data2,
                        options:{
                          plugins:{
                            legend:{
                              display:false
                              },
                            },
                            scales: {
                            Y2: {
                                display: true,
                                min:0,
                                ticks: {
                                    stepSize: 500,
                                },
                             },
                           },
                          },
                        }
                      this.keymyChart2=new Chart(document.getElementById("myChart22"),config2);
                      this.keymyChart2
                },

            chartint3(){ 
                const label3=["date 1","date 2","date 3","date 4"],
                data3={
                  labels:this.chartoption.label,
                  datasets:this.chartoption.datasets3
                },
                config3={
                type:"line",data:data3,
                options:{
                  responsive: true, // Instruct chart js to respond nicely.
                  //maintainAspectRatio: false, // Add to prevent default behaviour of full-width/height 
                  plugins:{
                    legend:{
                      display:false
                      },
                    },
                  scales: {
                            Y: {
                                display: true,
                                min:0,
                                ticks: {
                                    stepSize: 100,
                                    //reverse: true,  this worked as expected
                                },
                            },
                        },
                  },
                }  
                this.keymyChart3=new Chart(document.getElementById("myChart32"),config3);
                this.keymyChart3
            },

            
         },

        mounted(){
          
            // this.myChart1.destroy();
            // this.myChart1.destroy();
            // this.myChart1.destroy();
            const route=useRoute();
            this.params.keyword=route.params.keyword;
            this.searchkey=this.params.keyword.replace(/\_/g, ' ');
            this.params.user_id=this.user.data.userid;
            this.GetApsListbyekeywordsearch(this.params);
        },



         watch: {
            isloded(){
              //this.initdata();
              //this.getmapchartdata()
            },

            applistbykeywords(){
               this.chartoption.label=[]; 
               if(this.applistbykeywords.length>0)
                  {
                    
                    this.initdata();
                  }
               else{
                  this.isloded=false 
                  this.nodata=false;   
               } 
             },


            keywordmapdata(){
              // this.chartoption.datasets1=[];
              // this.chartoption.datasets2=[];
              // this.chartoption.datasets2=[];
              if(this.keywordmapdata.labels){
                this.applistbykeywords.forEach((element, index) => { 
                  var oppappid=element.app_id
                  var apptitle=element.app_title
                  var ranks=[];
                  var reviews=[];
                  var rattings=[];
                  var colorcode=this.colorhexcode[index];
                  this.keywordmapdata.labels.forEach((element, index) => { 
                      var labeldate=element
                      if(!this.chartoption.label.includes(element))
                         this.chartoption.label.push(element)
                      if(this.keywordmapdata[oppappid]){
                         if(this.keywordmapdata[oppappid]['rank'][labeldate]){
                          ranks.push(this.keywordmapdata[oppappid]['rank'][labeldate][0])
                         }else{
                            ranks.push(0)
                         } 

                         if(this.keywordmapdata[oppappid]['ratting'][labeldate]){
                          rattings.push(this.keywordmapdata[oppappid]['ratting'][labeldate][0])
                         }else{
                           rattings.push(0)
                         } 

                         if(this.keywordmapdata[oppappid]['reviews'][labeldate]){
                          reviews.push(this.keywordmapdata[oppappid]['reviews'][labeldate][0])
                         }else{
                           reviews.push(0)
                         }
                      }  
                          
                   });
               
                 
                  this.chartoption.datasets3.push({label:apptitle,backgroundColor:colorcode,borderColor:colorcode, tension: 0.35,data:ranks,yAxisID: 'Y',})
                  this.chartoption.datasets1.push({label:apptitle,backgroundColor:colorcode,borderColor:colorcode, tension: 0.35,data:rattings,yAxisID: 'Y1',})
                  this.chartoption.datasets2.push({label:apptitle,backgroundColor:colorcode,borderColor:colorcode, tension: 0.35,data:reviews,yAxisID: 'Y2',})
               }); 

                //setTimeout(function(){
                  // vm.chartint2();
                 // vm.chartint3();
                // }, 5000);    
               this.chartload=true;
              } 
            }, 
            chartload(){

                var vm=this;
                if(vm.keymyChart!=null && this.chartoption.datasets1.length>0){
                   vm.keymyChart.destroy();
                   vm.keymyChart=null;
                }

                setTimeout(() =>  this.chartint1(), 1000);

                if(vm.keymyChart2!=null){
                  vm.keymyChart2.destroy();
                  vm.keymyChart2=null;
                }
                setTimeout(() =>  this.chartint2(), 500);

                if(vm.keymyChart3!=null){
                  vm.keymyChart3.destroy();
                  vm.keymyChart3=null;
                }
                 setTimeout(() =>  this.chartint3(), 1500);

               // this.chartint1()
                //this.chartint2()
                //this.chartint3()
            },
         }

 }
</script>



