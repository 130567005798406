<template>
<div class="inner-header">
 <HeaderHome/>
 </div>
 <div class="ful-wdth grey-bg pt-5">
<div class="container blog-container">
 <h2>Thank You</h2>
 <div><p>Thank you for your interest. We will get in touch soon.</p></div>  
   </div>
</div>
<div class="inner-home">
<FooterHome/>
 </div>
</template>

<script>
import HeaderHome from '@/components/Mainpage/HeaderHome.vue'
import FooterHome from '@/components/Mainpage/FooterHome.vue'
export default {
    name: '404Error ',
    data() {      
      return {
            params:{
              slug: '',  
            },
            isloaded:false ,
         };
     }, 
    components: {
     HeaderHome,
     FooterHome,
    },
    methods:{
        
      },
   mounted(){
         
      },

   computed:{
      
   },
   watch: {
        
     } ,
     
   
}
</script>


