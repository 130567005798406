<template>
 <div class="ful-wdth">
    <div class="tab_boxe tab_box_margin adsKeypFont">
          <div class="tab-content">
            <article>
              <h4>Ad Radar - Active Scan (Beta)</h4>
              <p>Ad Radar is another great tool to help optimize search ad campaigns. Follow an app and run Ad Radar to detect surprising keywords your competitor's ads appear on.  We use our large keyword bank to detect competitors' search ads.</p>
            </article>
          </div>
        </div>

        <div class="app_InputBox d-flex adskeyWidth">
          <div class="app_InputBoxSub">
            <input class="adScanInput" type="text" placeholder="Search for apps to follow">
          </div>
          <div class="app_InputBoxBtn">
            <button type="submit" class="app_In_Btn">Follow</button>
          </div>
        </div>

        <div class="adsActiveandScanUlP">
            <p>New keywords are added daily.  Run the report weekly to discover new keywords to bolster your search ad campaign.</p>
        </div>

        <div class="changeLogosBtn adsActiveandScanUl">
            <ul>
              <li class="changeLogosyellowBtn"><a href="#">delete</a></li>
            </ul>
        </div>

        <div class="adsKeyWordsp adsActiveandScanUl">
            <p>Here are keywords you’re following. Some keywords might take 24 hours to process. </p>
        </div>

        <div class="tab_Two_Box table-section tab_TwoP_top adsKeyTableTopP adRaderScanTable">
          <div class="resp-table">
            <table class="skygren-table borderOffBtn">
              <tbody>
                <tr>
                  <td>
                    <div class="form-check tableChek">
                      <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                    </div>
                  </td>
                  <td>
                    <img src="@/assets/images/table-icon/tabl-2.png">
                  </td>
                  <td>
                    Keyword 1
                  </td>
                  <td>
                    <div class="borderBtn">
                     <router-link to="/ads-by-app-report"> View </router-link>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="form-check tableChek">
                      <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                    </div>
                  </td>
                  <td>
                    <img src="@/assets/images/table-icon/tabl-3.png">
                  </td>
                  <td>
                    Keyword 2
                  </td>
                  <td>
                    <div class="borderBtn">
                     <router-link to="/ads-by-app-report"> View </router-link>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="form-check tableChek">
                      <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                    </div>
                  </td>
                  <td>
                    <img src="@/assets/images/table-icon/tabl-4.png">
                  </td>
                  <td>
                    Keyword 3
                  </td>
                  <td>
                    <div class="borderBtn">
                     <router-link to="/ads-by-app-report"> View </router-link>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td style="text-align: center;">
                    <div class="form-check tableChek">
                      <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                    </div>
                  </td>
                  <td>
                    <img src="@/assets/images/table-icon/tabl-5.png">
                  </td>
                  <td>
                    Keyword 4
                  </td>
                  <td>
                    <div class="borderBtn">
                     <router-link to="/ads-by-app-report"> View </router-link>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="form-check tableChek">
                      <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                    </div>
                  </td>
                  <td>
                    <img src="@/assets/images/table-icon/tabl-2.png">
                  </td>
                  <td>
                    Keyword 5
                  </td>
                  <td>
                    <div class="borderBtn">
                     <router-link to="/ads-by-app-report"> View </router-link>
                    </div>
                  </td>
                </tr>        
              </tbody>
            </table>
          </div>
        </div>

 </div>
</template>
<style scoped>
 .adsActiveandScanUl ul  {
    padding: 82px 0 8px 0 !important;
}

.main_right-pn .tab_boxe .tab-content article h4{ color: #fff; font-size: 30px;
font-weight: 400;
padding-bottom: 20px;}

.adsActiveandScanUl p   {
    padding: 0px 0 36px 0 !important;
    margin: 0px !important;
}
.adsActiveandScanUlP p  {
    font-family: 'Rubik';
    font-weight: 300;
    font-size: 19px;
    line-height: 25px;
    color: #EDEDED;
    padding-top: 15px;
}
.adRaderScanTable table tbody tr > :nth-child(3)  {
    text-align: left !important;
}
.adRaderScanTable table tbody tr > :nth-child(4)  {
    text-align: right !important;
}
.adRaderScanTable table tbody tr > :nth-child(1),
.adRaderScanTable table tbody tr > :nth-child(2)  {
    width: 60px !important;
}
.adsKeypFont .tab-content article p {
  font-size: 21px !important;
  line-height: 29px !important;
}
.adskeyWidth .app_InputBoxSub {
  max-width: 476px;
  width: 100%;
}
.app_InputBox .app_InputBoxSub .adScanInput {
  width: 100%;
  height: 42px;
  background: #323243;
  border: 1px solid #5D5D76;
  font-family: 'Rubik';
  font-weight: 400;
  font-size: 17px;
  line-height: 20px;
  letter-spacing: 0.02em;
  color: rgba(255, 255, 255, 0.72);
  padding: 0 20px;
  outline: none;
}
.app_InputBox .app_InputBoxBtn .app_In_Btn {
  background: #00C1EC;
  border-radius: 4px;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  text-transform: uppercase;
  color: #FFFFFF;
  text-shadow: 0px 0.5px 1px rgba(0, 0, 0, 0.25);
  padding: 8px 43px;
  border: none;
}
.app_InputBox .app_InputBoxSub {
  margin-right: 10px;}
.main_right-pn .changeLogosBtn ul {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;}
  .adsActiveandScanUl ul {
  padding: 82px 0 8px 0 !important;
}
.main_right-pn .changeLogosBtn ul .changeLogosyellowBtn {
  background: #FF7301;
  border-radius: 4px;
  padding: 7px 33px;
}
.main_right-pn .changeLogosBtn ul a {
  text-decoration: none;
  font-weight: 500;
  font-size: 19px;
  line-height: 28px;
  text-align: center;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  color: #FFFFFF;
}
.adsActiveandScanUl p {
  padding: 0px 0 36px 0 !important;
  margin: 0px !important;
}
.adsKeyWordsp p {
  font-family: 'Rubik';
  font-weight: 300;
  font-size: 19px;
  line-height: 25px;
  color: #EDEDED;}
  .main_right-pn .tab_Two_Box .borderOffBtn tbody tr td {
  border-left: none !important;
}
.main_right-pn .table-section table tbody td, .tab_Two_Box .skygren-table tbody td {
 
  padding: 12px;
}
.main_right-pn .tab_Two_Box .borderOffBtn tbody .borderBtn a {
  text-decoration: none;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #FFFFFF;
  background: #4BCCC0;
  border-radius: 4px;
  padding: 7px 30px;
}
.light-theme-np .main_right-pn .tab_boxe .tab-content article h4, .light-theme-np .main_right-pn .tab_boxe .tab-content article p, .light-theme-np .adsActiveandScanUlP p, .light-theme-np .adsKeyWordsp p{color: #212529 !important;}
.light-theme-np .app_InputBox .app_InputBoxSub input[type="text"]{background: #f2f4f2e6;
border: 1px solid #e7e7e757;
color: rgba(48, 48, 64, 0.73);}
@media (max-width:767px){
.adsKeypFont .tab-content article p{ font-size: 16px !important; line-height: 22px !important;}
.app_InputBox .app_InputBoxBtn .app_In_Btn {
  background: #00C1EC;
  border-radius: 4px;
  font-weight: 600;
  font-size: 14px;
  line-height: 27px;
  text-align: center;
  text-transform: uppercase;
  color: #FFFFFF;
  text-shadow: 0px 0.5px 1px rgba(0, 0, 0, 0.25);
  padding: 8px 20px;
  border: none;
}
.adsActiveandScanUl ul{ padding: 32px 0 8px 0 !important;}
}
</style>