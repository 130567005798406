<template>
    <div class="ful-wdth">
       <div class="serach-result-part" v-if="keywordsearchresult.length>0">
         <div class="serach-itm" v-bind:key="result.app_id" v-for="result in keywordsearchresult">
              <div class="item"  v-if="result.app_id" >

              <router-link :to="'/about-details/' + result.app_id"><img :src=result.app_logo></router-link>
                 <article><h4>{{result.app_title}}</h4> 

                <p><span>by</span> <router-link :to="'/developers-team-name/' + result.dev_id">{{result.dev_support_name}}</router-link></p>
                          <p><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
                            <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path>
                          </svg>{{result.app_rating==null?'0':result.app_rating}} ({{result.totalreview}})</p>
                         
            <router-link :to="'/about-details/' + result.app_id" class="details-btn">Details</router-link>
              </article>
              </div>
          </div> 

        </div>
        <div class="not_found-header" v-else> <p>Sorry no search result found.</p></div> 
        <nav aria-label="navigation-cuts" class="search-pagination">
         <pagination 
          v-model="page" 
          :records="searchtotaldata" 
          :per-page="24" 
          :options={chunk:5} 
          @paginate="nextprevious($event)"
        />   
      </nav>
    </div>
</template>

<script>
import moment from "moment";
import { mapState,mapActions } from 'vuex';
import { useRoute } from 'vue-router';
export default {
  name: 'PageHeader',
  data() {
    return {
          searchdata: {
             keyvalue: '',
             page: 1,
             showdata: 24,
				}, 
        issearch:false,
        startindex:1,
        page: 1,
    };
  },
  mounted() {  
      const route = useRoute();
      this.searchdata.keyvalue=route.query.key;
      this.SearchresultBykeyword(this.searchdata);
      this.issearch=true;

    //  alert(this.searchtotaldata);
  },
  computed:{
      ...mapState({
            keywordsearchresult: (state) => state.AppListModule.keywordsearchresult,
            searchtotaldata: (state) => state.AppListModule.searchtotaldata,
            searchresulttype: (state) => state.AppListModule.searchresulttype,
      }),
    },
  methods:{
      ...mapActions({
          SearchresultBykeyword: 'AppListModule/SearchresultBykeyword',
        }),

        init(){
                if(this.keywordsearchresult) {
                  if(this.keywordsearchresult.length==1 && this.searchdata.page==1){
                    if(this.searchresulttype=='dev') {
                      this.$router.push({path: '/developers-team-name/'+this.keywordsearchresult[0].dev_id})
                    }else {
                      //this.$router.push({path: '/about-details/'+this.keywordsearchresult[0].app_id})
                      this.$router.replace({ name: "AboutDetails", params: {appid:this.keywordsearchresult[0].app_id}})
                    }
                }
              }  
          },

        nextprevious($data){ 
          const vm = this; 
          vm.searchdata.page=$data;
          vm.startindex=(($data-1)*24)+1;
          vm.SearchresultBykeyword(vm.searchdata);
          
        },

 /*
          nextprevious($data){ 
                const vm = this; 
                vm.params.page=$data;
                vm.startindex=(($data-1)*24)+1;
               
          },

          selectsubcat(event){
            this.catparams.catid=event.target.value
             this.selected_subcat=''
            if(event.target.value!=''){
                this.getAppSubCategory(this.catparams);
            }              
            else{             
              this.subcatoptions=[{'subcategory_id':'','subcategory_name':'Select Subcategory','subcategory_description':''}]; 
            }   
              
          },

           addFollow(event){
              this.isfollowdataparams.user_id=this.user.data.userid;
              this.isfollowdataparams.app_id=event.target.value;
              this.isfollowdataparams.action='ADD';
              this.userfollowaps(this.isfollowdataparams)
           },
           unflowapps(event){
                this.isfollowdataparams.user_id=this.user.data.userid;
                this.isfollowdataparams.app_id=event.target.value;
                this.isfollowdataparams.action='Delete';
                this.userfollowaps(this.isfollowdataparams)
           },
        filterapps(parameters){
              //console.log('parameters',parameters);
              this.params.page=1;
              if(parameters.selected_cat!='')
                 this.params.catid=parameters.selected_cat;
              else   
                 this.params.catid='';

              if(parameters.selected_subcat!='')
                 this.params.subcatid=parameters.selected_subcat;
              else   
                 this.params.subcatid='';

                this.startindex=1; 
                this.getapplistByReviews(this.params);
                this.int();
         },
         
         filterreset(){
            this.params.page=1;
            this.startindex=1; 
            this.selected_cat='';
            this.selected_subcat='';
            this.params.catid='';
            this.params.subcatid='';
            this.subcatoptions=[{'subcategory_id':'','subcategory_name':'Select Subcategory','subcategory_description':''}]; 
            this.getapplistByReviews() 
         }*/
    },
    
     watch: {
          keywordsearchresult(newval,oldval){  
             //alert(this.searchtotaldata)          
              this.init();
          },
        '$route.query'(newId, oldId) { 
          // console.log('querydata',newId.key)
             this.searchdata.keyvalue=newId.key;
             this.searchdata.page=1;
             this.SearchresultBykeyword(this.searchdata);
        }
     }      
}
 
</script>
<style scoped>
.not_found-header p{color: red; text-align: center; font-weight:500 ;}
.not_found-header{ position: absolute;left: 50%; top:50%; transform: translateY(-50%);}
</style>