<template>
 <div class="ful-wdth">
    <div class="tab_boxe">
        <div class="tab_tittle_border">
            <a style="cursor: pointer;" v-if="userfollowapps.some(userfollowapps => userfollowapps.app_id === params.appid)"  @click="unflowapps(params.appid)">Unfollow</a> 
            <a style="cursor: pointer;" v-else  @click="addFollow(params.appid)">Follow</a>            
        </div>
        <ul class="nav nav-tabs" id="myTab" role="tablist">
          <li class="nav-item" role="presentation">
            <button class="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">Analytics</button>
          </li>
          <li class="nav-item" role="presentation">
            <button class="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false">About</button>
          </li>
          <li class="nav-item" role="presentation">
            <button class="nav-link" id="contact-tab" data-bs-toggle="tab" data-bs-target="#contact" type="button" role="tab" aria-controls="contact" aria-selected="false">Reviews</button>
          </li>
          <li class="nav-item" role="presentation">
            <button class="nav-link" id="pricing-tab" data-bs-toggle="tab" data-bs-target="#pricing" type="button" role="tab" aria-controls="pricing" aria-selected="false">pricing strategy</button>
          </li>
        </ul>
        <div class="tab-content" id="myTabContent">
          <!-- tab 1 -->
         <Tabcontent1 />
         <Tabcontent2 />
         <Tabcontent3 />
         <Tabcontent4 />
        </div>
 </div>
      </div>
</template>

<script>
  // import Chart from 'chart.js/auto';
   import Tabcontent1 from '@/components/details/Tabcontent1.vue'
   import Tabcontent2 from '@/components/details/Tabcontent2.vue'
   import Tabcontent3 from '@/components/details/Tabcontent3.vue'
   import Tabcontent4 from '@/components/details/Tabcontent4.vue'
   import { useRoute } from 'vue-router'
   import {mapState,mapActions} from 'vuex'

   export default {
     name : 'AboutDetails',
      data() {      
      return {
            params:{
              appid: '',  
            },
            isloaded:false ,
            isfollowdataparams: {
                user_id: '',
                app_id: '',
                action:'',
            },    
         };
     }, 
     components: {
                Tabcontent1,
                Tabcontent2,
                Tabcontent3,
                Tabcontent4,
            },
       
     mounted(){
            const route=useRoute();
            this.params.appid=route.params.appid;
            //alert(this.params.appid)
            this.GetApphistorybyappid(this.params);
            this.GetAppchartdatabyappid(this.params);
            this.GetAppprichingfeture(this.params);
            this.GetrelatedAppprichingplane(this.params);
            
           // this.isloaded=true;   
        },
      computed:{
        ...mapState({
            user: (state) => state.authModule.user,
            userfollowapps: (state) => state.authModule.userfollowapps,
            //apphistory: (state) => state.AppListModule.apphistory,
            //userfollowapps: (state) => state.authModule.userfollowapps,
            apphistory: (state) => state.AppListModule.apphistory,
            
            
        }),
    },

  methods:{
    int(){          
      this.istopcardload=true;     
    }, 
     addFollow(event){
              this.isfollowdataparams.user_id=this.user.data.userid;
              this.isfollowdataparams.app_id=event;
              this.isfollowdataparams.action='ADD';
              this.userfollowaps(this.isfollowdataparams)
           },
     unflowapps(event){
        this.isfollowdataparams.user_id=this.user.data.userid;
        this.isfollowdataparams.app_id=event;
        this.isfollowdataparams.action='Delete';
        this.userfollowaps(this.isfollowdataparams)
    },

    ...mapActions({         
         GetApphistorybyappid: 'AppListModule/GetApphistorybyappid', 
         GetAppchartdatabyappid: 'AppListModule/GetAppchartdatabyappid', 
         userfollowaps: 'authModule/userfollowaps',   
         GetAppprichingfeture: 'AppListModule/GetAppprichingfeture',  
         GetrelatedAppprichingplane: 'AppListModule/Getreletedapspricingtire', 
         
     }),
    
  },

  watch: {
         apphistory(newval,oldval) { 
         this.isloaded=true;
       },
        '$route.params'(newId, oldId) {
            this.isloaded=false;
            this.params.appid=newId.appid;
            this.GetApphistorybyappid(this.params);
            this.GetAppchartdatabyappid(this.params);
            this.GetAppprichingfeture(this.params);
            this.GetAppprichingfeture(this.params)
            document.getElementById('home-tab').click()
         }    
   }
   }  
</script>

<style scoped>
*{
    font-family: 'Poppins', sans-serif;
    transition: all .3s;
}



input.form-control{
    outline: none;
    box-shadow: none !important;
}
ul{
    list-style: none;
    padding: 0;
    margin: 0;
}
input:focus,
a:focus,
button:focus{
box-shadow: none !important;
outline: none;
}

body  {
    overflow-x: hidden;
}


.main_right-pn{
    background-color: #191927;
    margin-left: 398px;
    padding: 0 92px 0 84px;
}



/* header section start here  */
.main_right-pn header{
    padding-top: 40px;
    row-gap: 10px;
}
.main_right-pn header,
.main_right-pn header .ite_sm:first-of-type,
.main_right-pn header .ite_sm:last-child{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}
.main_right-pn header .navbar-collapse{
    max-width: 30px;
    height: 30px;
    background: transparent;
    position: relative;
    border: none;
}
.main_right-pn header .navbar-collapse::after,
.main_right-pn header .navbar-collapse::before{
    content: '';
    position: absolute;
    height: 2px;
    background-color: #fff;
}
.main_right-pn header .navbar-collapse::after{
    top: 0;
    width: 16px;
    right: 2px;
}
.main_right-pn header .navbar-collapse::before{
    bottom: 50%;
    width: 28px;
    left: 0;
    box-shadow: 0 11px #fff;
}
.main_right-pn header .ite_sm:first-of-type h5{
    font-weight: 300;
    font-size: 19px;
    color: #FFFFFF;
    line-height: 1;
    margin: 0;
}
.main_right-pn header .ite_sm:first-of-type{
    gap: 16px;
    margin-left: 56px;
}
.main_right-pn header .ite_sm:first-of-type h5 strong {
    font-weight: 500;
}
.main_right-pn header .ite_sm:first-of-type .btn{
    font-weight: 500;
    font-size: 15px;
    letter-spacing: -0.01em;
    color: #000000;
    background: #FFC42E;
    border-radius: 50px;
    padding: 8px 13px;
    line-height: 1;
}
.main_right-pn header .ite_sm:last-child{
    margin-left: auto;
    gap: 35px;
}
.main_right-pn header .ite_sm:last-child .form-group{
    position: relative;
}
.main_right-pn header .ite_sm:last-child .form-group .form-control{
    background: #323243;
    border: 1px solid #49495F;
    border-radius: 4px;
    width: 412px;
    height: 48px;
    font-weight: 400;
    font-size: 17px;
    letter-spacing: 0.02em;
    color: #ffffffb8;
}
.main_right-pn header .ite_sm:last-child .form-group [type="submit"] {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    padding: 0;
    background: transparent;
    border: 0;
    color: #fff;
    right: 18px;
}
.main_right-pn header .ite_sm:last-child .notify{
    color: #FFFFFF;
    position: relative;
}
.main_right-pn header .ite_sm:last-child .notify .badge-nofy{
    position: absolute;
    inset: -7px -2px auto auto;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background: #FF630B;
    border: 2px solid #191927;
    font-family: 'Rubik';
    font-weight: 500;
    font-size: 12px;
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
}
.main_right-pn header .ite_sm:last-child .prof li .nav-link{
    padding: 0 0 0 6px;
    font-family: 'Rubik';
    font-weight: 400;
    font-size: 19px;
    color: #FFFFFF;
}
.main_right-pn header .ite_sm:last-child .prof li .nav-link img{
    border-radius: 50%;
    width: 52px;
    margin-right: 12px;
}
/* header section end  */

/* tab one Section Start Here  */


.main_right-pn .tab_boxe  {
    padding-top: 110px;
}
.main_right-pn .tab_boxe .tab_tittle_border a  {
    text-decoration: none;
    border: 1.8px solid #3CAEA3;
    border-radius: 4px;
    padding: 6px 38px;
    line-height: 0px !important;
    font-weight: 400;
    font-size: 26px;
    text-align: center;
    letter-spacing: 0.03em;
    text-transform: capitalize;
    color: #FFFFFF;
}
.main_right-pn .tab_boxe h2  {
    font-weight: 300;
    font-size: 40px;
    line-height: 60px;
    text-transform: capitalize;
    color: #FFFFFF;
    padding-bottom: 45px;
    margin: 0;
}
.main_right-pn .tab_boxe .nav-tabs  {
    padding-top: 27px;
    border-bottom: none !important;
}
.main_right-pn .tab_boxe .nav-tabs .nav-item  {
    margin-right: 7px;
}
.main_right-pn .tab_boxe .nav-tabs .nav-item .nav-link  {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-transform: capitalize;
    color: #FFFFFF;
    background: rgba(48, 48, 64, 0.73);
    border: 0.5px solid rgba(155, 155, 155, 0.49);
    border-radius: 3px;
    padding: 10px 46px;
    transition: all ease-in-out 300ms;
}
.main_right-pn .tab_boxe .nav-tabs .nav-item .nav-link:hover  {
    background: #00C1EC;
}
.main_right-pn .tab_boxe .nav-tabs .nav-item .nav-link.active {
    color: #fff !important;
    background-color: #00C1EC !important;
    border-color: #00C1EC !important;
}
.main_right-pn .tab_boxe .tab-content  {
    padding-top: 74px;
}
.main_right-pn .tab_boxe .tab-content .tab_One_Box .tab_One_Content .tab_One_Image  {
    max-width: 110px;
}
.main_right-pn .tab_boxe .tab-content .tab_One_Box .tab_One_Content .tab_One_Image img  {
    width: 100%;
    display: block;
}
.main_right-pn .tab_boxe .tab-content .tab_One_Box .tab_One_Content .tab_One_Text  {
    padding-left: 35px;
}
.main_right-pn .tab_boxe .tab-content .tab_One_Box .tab_One_Content .tab_One_Text h2  {
    font-family: 'Rubik';
    font-weight: 400;
    font-size: 25px;
    line-height: 32px;
    text-transform: capitalize; 
    color: #FFFFFF;
    padding-bottom: 8px;
}
.main_right-pn .tab_boxe .tab-content .tab_One_Box .tab_One_Content .tab_One_Text h3  {
    font-family: 'Rubik';
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-transform: capitalize;
    color: #FFFFFF;
    padding-bottom: 13px;
    margin: 0;
}
.main_right-pn .tab_boxe .tab-content .tab_One_Box .tab_One_Content .tab_One_Text h4  {
    font-family: 'Rubik';
    font-weight: 400;
    font-size: 17px;
    line-height: 23px;
    text-transform: capitalize;
    color: rgba(255, 255, 255, 0.83);
    padding-bottom: 0;
}
.main_right-pn .tab_boxe .tab-content .tab_One_Box .tab_One_Content .tab_One_Text h5 span  {
    font-family: 'Rubik';
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-transform: capitalize;
    color: #FFFFFF;
    padding-left: 5px;
}
.main_right-pn .tab_boxe .tab-content .tab_One_Box .tab_One_Content .tab_One_Option  {
    padding-left: 60px;
}
.main_right-pn .tab_boxe .tab-content .tab_One_Box .tab_One_Content .tab_One_Option ul li:not(:last-child)  {
    border-bottom: 1px dotted #53536A;
    padding-bottom: 20px;
    margin-bottom: 20px;
}
.main_right-pn .tab_boxe .tab-content .tab_One_Box .tab_One_Content .tab_One_Option a  {
    text-decoration: none;
    display: block;
}
.main_right-pn .tab_boxe .tab-content .tab_One_Box .tab_One_Content .tab_One_Option span  {
    font-family: 'Rubik';
    font-weight: 400;
    font-size: 17px;
    line-height: 23px;
    color: rgba(255, 255, 255, 0.83);
    padding-left: 15px;
}

.main_right-pn .table-section table.blue-table thead th  {
    border-color: #2C88D4;
    padding: 15px;
    text-align: left;
    white-space: nowrap;
}
.main_right-pn .table-section table.blue-table thead  {
    border-left: 1px solid #2571B0;
    border-right: 1px solid #2571B0;
    background-color:#2571B0;
}

.main_right-pn .table-section table.skygren-table thead th,
.tab_Two_Box .skygren-table thead  {
    border-color: #46C2B6;
}
.main_right-pn .table-section table.skygren-table thead,
.tab_Two_Box .skygren-table thead  {
    border-left: 1px solid #3CAEA3;
    border-right: 1px solid #3CAEA3;
    background-color:#3CAEA3;
}






.main_right-pn .table-section table,
.tab_Two_Box .skygren-table thead table  {
    width: 100%;
    border-radius: 3px;
}
.main_right-pn .table-section table thead th,
.tab_Two_Box .skygren-table thead th{
    font-family: 'Rubik';
    font-weight: 400;
    font-size: 17px;
    line-height: 1;
    letter-spacing: 0.02em;
    text-transform: capitalize;
    color: #FFFFFF;
    padding: 20px;
    text-align: center;
    border-style: solid;
    border-left: 1px solid #adadad99;
}
.main_right-pn .table-section table thead th:last-child,
.tab_Two_Box .skygren-table thead th:last-child{
    border-right: none;
}
.main_right-pn .table-section table tbody td,
.tab_Two_Box .skygren-table tbody td {
    font-family: 'Rubik';
    font-weight: 400;
    font-size: 16px;
    color: #E0E0E0;
    border-left: 1px solid #3f3f5299;
    text-align: left;
    line-height: 1;
    border-bottom: 1px solid #3f3f5299;
    padding: 15px;
}
.main_right-pn .table-section table thead tr > :nth-child(3),
.main_right-pn .table-section table tbody tr > :nth-child(3) {
    text-align: left;
    padding-left: 18px;
}
.main_right-pn .table-section table tbody > :nth-child(odd),
.tab_Two_Box .skygren-table tbody > :nth-child(odd){
    background-color: #292938;
}
.main_right-pn .table-section table tbody > :nth-child(even),
.tab_Two_Box .skygren-table tbody > :nth-child(even){
    background-color: #262635;
}
.main_right-pn .table-section table tbody tr td:last-child,
.tab_Two_Box .skygren-table tbody tr td:last-child {
    border-right: 1px solid #3f3f5299;
}
.main_right-pn .table-section table tbody td img{
    max-width: 100%;
    display: block;
    height: auto;
    margin: auto;
}











.main_right-pn .tab_boxe .tab-content .tab_Three_ChartOne  {
    width: 100%;
    height: 100%;
}
.main_right-pn .tab_boxe .tab-content .tab_Three_ChartOne  label  {
    color: #fff;
}
.main_right-pn .tab_boxe .tab-content .tab_Three_Box canvas  {
    width: 100% !important;
    height: auto !important;
    background: #282838;
    padding: 25px 35px;
    border: 0.5px solid rgba(0, 0, 0, 0.31);
}



.tab_Two_Box .skygren-table  {
    width: 100%;
    border-radius: 3px;
}
.tab_Two_Box .skygren-table  tbody tr td span  {
    padding-left: 12px;
}
.tab_Two_Box  {
    padding-top: 84px;
}
:deep(.sky_Green_Body)  {
    width: 100%;
    height: 100%;
    background: #ffc42e !important;
    border: 0.5px solid rgba(116, 116, 116, 0.16);
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 50px;
}
:deep(.sky_Green_Body h3)  {
    font-family: 'Rubik';
    font-weight: 400;
    font-size: 25px;
    line-height: 38px;
    text-align: center;
    color: #000 !important;
    opacity: 1 !important;
    margin: 0;
}



.tabs_Head h2 {
    font-weight: 300;
    font-size: 35px;
    text-transform: capitalize;
    color: #FFFFFF;
    padding-top: 100px;
}
.tabs_Head h3 {
    font-weight: 300;
    font-size: 35px;
    text-transform: capitalize;
    color: #FFFFFF;
}

.tab_Box_footer  {
    padding: 100px 0 60px 0; 
}
.tab_Box_footer h3  {
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    letter-spacing: 0.01em;
    color: rgba(255, 255, 255, 0.66);
    text-align: center;
}


/* tab one Section end  */

/* tab two Section Start Here  */

/* .main_right-pn .tab_boxe .tab-content article h4,
.main_right-pn .tab_boxe .tab-content .media-assets h4{
    font-weight: 300;
    font-size: 40px;
    text-transform: capitalize;
    color: #FFFFFF;
    margin: 0 0 30px 0;
    line-height: 1;
} */
/* .main_right-pn .tab_boxe .tab-content article p:first-of-type{
    margin: 0 0 30px 0;
} */
.main_right-pn .tab_boxe .tab-content article p{
    font-family: 'Rubik';
    font-weight: 400;
    font-size: 16px;
    color: #BBBBBB;
    margin: 0;
    line-height: 22px;
    padding-bottom: 30px;
}
.main_right-pn .tab_boxe .tab-content article{
    padding-bottom: 90px;
}
.main_right-pn .tab_boxe .tab-content .media-assets .img-gly img,
.main_right-pn .tab_boxe .tab-content .media-assets video{
    width: 100%;
}
.main_right-pn .tab_boxe .tab-content .media-assets .page__loop{
    font-weight: 400;
    font-size: 15px;
    letter-spacing: 0.01em;
    color: #FFFFFF;
    text-decoration: none;
    margin-top: 30px;
    display: inline-block;
}

/* tab two Section Start end  */


/* tab four Section Start Here  */

.main_right-pn .tab_boxe .tab-content .pricing-card .item .hrad{
    padding: 14px 0 14px 30px;
    margin-bottom: 32px;
}
.main_right-pn .tab_boxe .tab-content .pricing-card .item .hrad h5{
    margin: 0;
    font-family: 'Rubik';
    font-weight: 500;
    font-size: 28px;
    letter-spacing: 0.02em;
    color: #FFFFFF;
}
.main_right-pn .tab_boxe .tab-content .pricing-card .item{
    background: #303040b3;
    border: 0.5px solid #74747429;
    border-radius: 3px;
    height: 100%;
}
.main_right-pn .tab_boxe .tab-content .pricing-card .item:hover{
    background: #303040d1;
    box-shadow: 0px 4px 67px #00000061;
}
.main_right-pn .tab_boxe .tab-content .pricing-card .item .tile{
    margin-bottom: 8px;
    padding-left: 30px;
}
.main_right-pn .tab_boxe .tab-content .pricing-card .item .tile h4{
    font-family: 'Rubik';
    font-weight: 500;
    font-size: 34px;
    text-transform: capitalize;
    color: #9286FF;
    margin: 0;
}
.main_right-pn .tab_boxe .tab-content .pricing-card .item .tile h4 span{
    font-size: 17px;
    color: #FFF;
}
.main_right-pn .tab_boxe .tab-content .pricing-card .item ul{
    padding: 0 30px 34px;
}
.main_right-pn .tab_boxe .tab-content .pricing-card .item ul li{
    font-family: 'Rubik';
    font-weight: 400;
    font-size: 17px;
    padding: 15px 0;
    color: #ffffffbd;
    border-bottom: 1px solid #5151634f;
    display: flex;
    align-items: baseline;
    column-gap: 7px;
}
.main_right-pn .tab_boxe .tab-content .pricing-card .item ul li:last-child{
    border-bottom: none;
}
.main_right-pn .tab_boxe .tab-content .pricing-card .item ul li svg{
    position: relative;
    top: 2px;
}
.main_right-pn .tab_boxe .tab-content .pricing-card .cr-blur .hrad{
    background: #2571B0;
}
.main_right-pn .tab_boxe .tab-content .pricing-card .cr-teal .hrad{
    background: #3CAEA3;
}
.main_right-pn .tab_boxe .tab-content .pricing-card .cr-voil .hrad{
    background: #685DC9;
}
.main_right-pn .tab_boxe .tab-content .pricing-card .cr-yelo .hrad{
    background: #D6993D;
}
.main_right-pn .tab_boxe .tab-content .pricing-card .cr-blur ul li svg,
.main_right-pn .tab_boxe .tab-content .pricing-card .cr-blur .tile h4{
    color: #5AB4FF;
}
.main_right-pn .tab_boxe .tab-content .pricing-card .cr-teal ul li svg,
.main_right-pn .tab_boxe .tab-content .pricing-card .cr-teal .tile h4{
    color: #55E0D3;
}
.main_right-pn .tab_boxe .tab-content .pricing-card .cr-voil ul li svg,
.main_right-pn .tab_boxe .tab-content .pricing-card .cr-voil .tile h4{
    color: #9286FF;
}
.main_right-pn .tab_boxe .tab-content .pricing-card .cr-yelo ul li svg,
.main_right-pn .tab_boxe .tab-content .pricing-card .cr-yelo .tile h4{
    color: #E8A645;
}
.main_right-pn .tab_boxe .tab-content .competitor-table .heading{
    margin: 146px 0 34px;
}
.main_right-pn .tab_boxe .tab-content .competitor-table .heading h4{
    font-weight: 300;
    font-size: 40px;
    text-align: center;
    text-transform: capitalize;
    color: #FFFFFF;
    margin: 0;
}
.main_right-pn .table-section.other-table-section table thead tr > :nth-child(3),
.main_right-pn .table-section.other-table-section table tbody tr > :nth-child(3){
    text-align: center;
    padding-left: 0;
}
.main_right-pn .table-section.other-table-section table thead tr > :nth-child(1),
.main_right-pn .table-section.other-table-section table tbody tr > :nth-child(1){
    text-align: left;
    padding-left: 38px;
}
.main_right-pn .table-section.other-table-section table tbody td img{
    display: inline-block;
    margin: 0 15px 0 0;
}
.main_right-pn .table-section.other-table-section table{
    min-width: 480px;
}

/* tab four Section Start end  */





/* main lg chat bar start Here  */
.main_right-pn .chat-bar .chat-title{
    margin-top: 90px;
}
.main_right-pn .chat-bar .chat-title,
.main_right-pn .chat-bar .chat-title > div{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.main_right-pn .chat-bar .chat-title h5{
    font-weight: 300;
    font-size: 35px;
    text-transform: capitalize;
    color: #FFFFFF;
    margin: 0;
}
.main_right-pn .chat-bar .chat-title > div span:first-child{
    font-family: 'Rubik';
    font-weight: 400;
    font-size: 18px;
    text-transform: capitalize;
    color: #FFFFFF;
    flex-shrink: 0;
    margin-right: 23px;
    display: flex;
    align-items: center;
}
.main_right-pn .chat-bar .chat-title > div span:first-child::before{
    content: '';
    width: 12.97px;
    height: 12.97px;
    background: #2CC174;
    display: block;
    border-radius: 50%;
    margin-right: 9px;
}
.main_right-pn .chat-bar .chat-title > div .select-ar:last-child{
    background: #323243;
    border: 1px solid #49495F;
    border-radius: 4px;
    height: 40.13px;
    display: flex;
    color: #FFFFFF;
    align-items: center;
    padding: 0 14px;
}
.main_right-pn .chat-bar .chat-title > div select{
    font-family: 'Rubik';
    font-weight: 400;
    font-size: 15px;
    letter-spacing: 0.056em;
    color: #FFFFFF;
    border: none;
    background-color: #191927;
    background: transparent;
}
.main_right-pn .chat-bar .chat-title > div input[type="date"]{
    font-family: 'Rubik';
    font-weight: 400;
    font-size: 15px;
    letter-spacing: 0.056em;
    color: #FFFFFF;
    border: none;
    background-color: #191927;
    background: transparent;
}

.main_right-pn .growth-chh{
    background-color: #282838;
    padding: 30px;
    margin: 30px 0 115px;
}



/* main lg chat bar end */



/* table row section start Here  */
.main_right-pn .table-section .ciel h6{
    font-family: 'Rubik';
    font-weight: 400;
    font-size: 34px;
    letter-spacing: 0.01em;
    color: #FFFFFF;
}
.main_right-pn .table-section ul.nav.nav-tabs{
    gap: 10px;
    border-bottom-color: #424255;
}
.main_right-pn .table-section .tab-pane,
.main_right-pn .table-section .tab-content{
    height: 100%;
}
.main_right-pn .table-section .ciel{
    width: 100%;
}
.main_right-pn .table-section .ciel canvas{
    max-width: 600px;
    aspect-ratio: 3/3;
}
.main_right-pn .table-section .tab-pane .dvh-2{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}
.main_right-pn .table-section ul.nav.nav-tabs .nav-item .nav-link{
    font-weight: 600;
    font-size: 16px;
    text-transform: uppercase;
    color: #000000;
    background-color: #fff;
    border: unset !important;
}
.main_right-pn .table-section ul.nav.nav-tabs .nav-item .nav-link.active{
    color: #fff;
    background-color: #ED553B;
}

.main_right-pn .table-section .resp-table::-webkit-scrollbar {
    width: 2px;
}
.main_right-pn .table-section .resp-table::-webkit-scrollbar-track {
    border-radius: 0;
}
.main_right-pn .table-section .resp-table::-webkit-scrollbar-thumb {
    background: transparent; 
    border-radius: 0;
}
.main_right-pn .table-section table.blue-table thead th{
    border-color: #2C88D4;
}.main_right-pn .table-section table.blue-table thead{
    border-left: 1px solid #2571B0;
    border-right: 1px solid #2571B0;
    background-color:#2571B0;
}
.main_right-pn .table-section table.skygren-table thead th{
    border-color: #46C2B6;
}.main_right-pn .table-section table.skygren-table thead{
    border-left: 1px solid #3CAEA3;
    border-right: 1px solid #3CAEA3;
    background-color:#3CAEA3;
}

.main_right-pn .table-section table.purpl-table thead th{
    border-color: #796DE8;
}.main_right-pn .table-section table.purpl-table thead{
    border-left: 1px solid #685DC9;
    border-right: 1px solid #685DC9;
    background-color:#685DC9;
}

.main_right-pn .table-section table.whitgren-table thead th{
    border-color: #3ACA74;
}.main_right-pn .table-section table.whitgren-table thead{
    border-left: 1px solid #49AE72;
    border-right: 1px solid #49AE72;
    background-color:#49AE72;
}

.main_right-pn .table-section table.orgn-table thead th{
    border-color: #EFA83C;
}.main_right-pn .table-section table.orgn-table thead{
    border-left: 1px solid #D6993D;
    border-right: 1px solid #D6993D;
    background-color:#D6993D;
}



.main_right-pn .table-section .tbl-head{
    margin-bottom: 26px;
}
.main_right-pn .table-section .tbl-head h6{
    font-weight: 300;
    font-size: 30px;
    text-transform: capitalize;
    color: #FFFFFF;
    margin: 0;
}
.main_right-pn .table-section .tbl-head p{
    font-weight: 500;
    font-size: 23px;
    margin-bottom: 0px;
}
.main_right-pn .table-section .tbl-head p[green]{
    color: #3ACA74;
}
.main_right-pn .table-section .tbl-head p[organic]{
    color: #EAA337;
}
.main_right-pn .table-section .tbl-head p[blu]{
    color: #00D1FF;
}
.main_right-pn .table-section table{
    width: 100%;
    border-radius: 3px;
}
.main_right-pn .table-section table thead th{
    font-family: 'Rubik';
    font-weight: 400;
    font-size: 17px;
    line-height: 1;
    letter-spacing: 0.02em;
    text-transform: capitalize;
    color: #FFFFFF;
    padding: 20px;
    text-align: center;
    border-style: solid;
    border-right-width: 1px;
    white-space: nowrap;
}
.main_right-pn .table-section table thead th:last-child{
    border-right: none;
}
.main_right-pn .table-section table tbody td{
    font-family: 'Rubik';
    font-weight: 400;
    font-size: 16px;
    color: #E0E0E0;
    border-left: 1px solid #3f3f5299;
    line-height: 1;
    border-bottom: 1px solid #3f3f5299;
    padding: 15px;
    white-space: nowrap;
}
.main_right-pn .table-section table thead tr > :nth-child(3),
.main_right-pn .table-section table tbody tr > :nth-child(3) {
    text-align: left;
    padding-left: 18px;
}
.main_right-pn .table-section table tbody > :nth-child(odd){
    background-color: #292938;
}
.main_right-pn .table-section table tbody > :nth-child(even){
    background-color: #262635;
}
.main_right-pn .table-section table tbody tr td:last-child{
    border-right: 1px solid #3f3f5299;
}
.main_right-pn .table-section table tbody td img{
    max-width: 100%;
    display: block;
    height: auto;
    margin: auto;
}
.main_right-pn .table-section table.last-table tr > :nth-child(1){
    text-align: left;
    padding-left: 30px;
}
.main_right-pn .table-section table.last-table thead th{
    border-color: #00CAF6;
}
.main_right-pn .table-section table.last-table thead{
    border-left: 1px solid #00ADD2;
    border-right: 1px solid #00ADD2;
    background-color:#00ADD2;
}      
.main_right-pn .table-section table.last-table tr td:last-child,
.main_right-pn .table-section table.last-table tr th:last-child{
    text-align: center;
    padding-left: 0;
}
.main_right-pn .copy-rrt{
    margin-top: 148px;
    padding-bottom: 45px;
}
.main_right-pn .copy-rrt p{
    text-align: center;
    margin: 0;
    font-weight: 400;
    font-size: 15px;
    letter-spacing: 0.01em;
    color: #ffffffa8;
}
/* table row section end */


/* main right pana section end  */




/* / .light-theme-np{} / */

.light-theme-np .main_left-pn {
    box-shadow: 0px 0px 20px #d7d7d7;
    background-color: #fff;
}

:deep(.light-theme-np .bodycontent ul li.bullet){ color:#000;}
.light-theme-np .main_left-pn .logo-sect h4,
.light-theme-np .main_left-pn .accd-sect .accordion-button,
.light-theme-np .main_left-pn .accd-sect .accordion-item .accordion-body li,
.light-theme-np .main_left-pn .dl-mode-btn span,
.light-theme-np .main_right-pn header .ite_sm:first-of-type h5,
.light-theme-np .main_right-pn header .ite_sm:last-child .prof li .nav-link,
.light-theme-np .main_right-pn .card-section [class^="col"]:nth-child(odd) .item .ttx h6,
.light-theme-np .main_right-pn .card-section [class^="col"]:nth-child(odd) .item .ttx h5,
.light-theme-np .main_right-pn .chat-bar .chat-title h5,
.light-theme-np .main_right-pn .chat-bar .chat-title > div span:first-child,
.light-theme-np .main_right-pn .table-section .ciel h6,
.light-theme-np .main_right-pn .chat-bar .chat-title > div input[type="date"],
.light-theme-np .main_right-pn .table-section .tbl-head h6,
.light-theme-np .main_right-pn .table-section table tbody td,
.light-theme-np .main_right-pn .copy-rrt p,
.light-theme-np .main_right-pn .tab_boxe h2,
.light-theme-np .main_right-pn .tab-content .tab_One_Box .tab_One_Content .tab_One_Text h2,
.light-theme-np .main_right-pn .tab_boxe .tab-content .tab_One_Box .tab_One_Content .tab_One_Text h4,
.light-theme-np .main_right-pn .tab_boxe .tab-content .tab_One_Box .tab_One_Content .tab_One_Text h5 span,
.light-theme-np .main_right-pn .tab_boxe .tab-content .tab_One_Box .tab_One_Content .tab_One_Option span,
.light-theme-np .main_right-pn .tab_boxe h2,
.light-theme-np .main_right-pn .tab_boxe h3,
.light-theme-np .tab_Box_footer h3,
.light-theme-np .main_right-pn .tab_boxe .tab-content article h4,
.light-theme-np .main_right-pn .tab_boxe .tab-content .media-assets h4,
.light-theme-np .main_right-pn .tab_boxe .tab-content article p,
.light-theme-np .main_right-pn .tab_boxe .tab-content .media-assets .page__loop,
.light-theme-np .main_right-pn .tab_boxe .tab-content .pricing-card .item ul li,
.light-theme-np .main_right-pn .tab_boxe .tab-content .pricing-card ul li svg,
.light-theme-np .main_right-pn .tab_boxe .tab-content .pricing-card .tile h4,
.light-theme-np .main_right-pn .tab_boxe .tab-content .pricing-card .item .tile h4 span,
.light-theme-np .main_right-pn .tab_boxe .tab-content .competitor-table .heading h4,
.light-theme-np .main_right-pn .tab_boxe .nav-tabs .nav-item .nav-link,
.light-theme-np .main_right-pn .tab_tittle_border a,
.light-theme-np .main_right-pn .tab_boxe .changeLogsBox .changeLogsStatus .changeLogsText h3,
.light-theme-np .main_right-pn .tab_boxe .changeLogsBox .changeLogsStatus .changeLogsText span, 
.light-theme-np .main_right-pn .tab_boxe .changeLogsBox .changeLogsSub ul li .changeDiv span,
.light-theme-np .main_right-pn .tab_boxe .changeLogosVersion .chVerLeft p,
.light-theme-np .main_right-pn .tab_boxe .changeLogosVersion .chVerRight p,
.light-theme-np .main_right-pn .tab_boxe .logsFillterBtn a,
.light-theme-np .main_right-pn .tab_boxe .logsMainBox .logsMainLeft .logsMainLeftSub h4,
.light-theme-np .main_right-pn .tab_boxe .logsMainBox .logsMainRight .logsMainLeftSub h4,
.light-theme-np .main_right-pn .tab_boxe .logsMainBox .logsMainLeft .logsMainLeftSub a,
.light-theme-np .main_right-pn .tab_boxe .logsMainBox .logsMainRight .logsMainLeftSub a,
.light-theme-np .main_right-pn .tab_boxe .logsMainBox .logsMainLeft .logsMainLeftSub h5,
.light-theme-np .main_right-pn .tab_boxe .logsMainBox .logsMainRight .logsMainLeftSub h5,
.light-theme-np .keyWordFirstLeft p,
.light-theme-np .tab_boxe .sidePagePara,
.light-theme-np .appsBox .appsSub .appSubProfile .proText h4,
.light-theme-np .appsBox .appsSub .appSubProfile .proText p,
.light-theme-np .appsBox .appsSub .appSubProfile .proText h4 span,
.light-theme-np .appsBox .appsSub .appSubProfile .proText h5,
.light-theme-np .appsBox .appsSub .appSubOption p,
.light-theme-np .tab_Three_Box .comparingLink .comparingOption ul li a,
.light-theme-np .comparfningHeadUl ul li a,
.light-theme-np .adsKeyWordsp p,
.light-theme-np .tab_boxe .adsKeyDetaildsRe .tittle p,
.light-theme-np .keyZeroStateBtns .keyZeroStatePara p,
.light-theme-np .adsByAppReportProfile .adsByAppReportProfileImage .Textbox h4 span,
.light-theme-np .adsByAppReportProfile .adsByAppReportProfileImage .Textbox p,
.light-theme-np .adsByAppReportProfile .adsByAppReportProfileImage .Textbox h5,
.light-theme-np .adsReportTableTop table tbody tr td a,
.light-theme-np .adsByAppBox .accordion-body .accBodyChekBox ul li span,
.light-theme-np .adsByAppBox .accordion-body p  {
    color: #212529 !important;
}

.light-theme-np .main_right-pn .tab_boxe .logsFillterBtn a svg  {
    color: #212529 !important;
    fill: #212529;
}


.light-theme-np .main_right-pn .tab_boxe .nav-tabs .nav-item .nav-link,
.light-theme-np .main_right-pn .tab_boxe .changeLogsBox .changeLogsSub ul li .changeDiv {
    background-color: #f2f4f2e6;
    border: 1px solid #e7e7e757;
}

.light-theme-np .main_right-pn .tab_boxe .changeLogsBox .changeLogsSub ul li .changeDiv::after  {
    background: #f2f4f2e6;
}

.light-theme-np .main_right-pn .tab_boxe .changeLogsBox .changeLogsSub ul li .changeRound span  {
    background: #fff;
}

.light-theme-np .main_right-pn .tab_boxe .tab-content .pricing-card .item{
    background: #EAECEE;
}

.light-theme-np .main_left-pn .logo-sect .close-nav button svg   {
    fill: #212529;
}
.light-theme-np .main_left-pn .accd-sect .accordion-button img,
.light-theme-np .main_left-pn .accd-sect .accordion-button::after {
    filter: sepia(1) saturate(1) brightness(0.1) hue-rotate(135deg);
}
.light-theme-np .accordion-collapse {
    border-left: 1px solid #cad0d6;
    box-shadow: 7px 0px 12px -8px #a9a9a9;
}
.light-theme-np .main_right-pn,
.light-theme-np .accordion-collapse,
.light-theme-np .main_right-pn .growth-chh{
    background-color: #fff;
}
.light-theme-np .slider:before,
.light-theme-np .main_right-pn header .navbar-collapse::after,
.light-theme-np .main_right-pn header .navbar-collapse::before {
    background-color: #191927;
}
.light-theme-np .main_right-pn header .navbar-collapse::before {
    box-shadow: 0 11px #191927;
}
.light-theme-np .main_right-pn header .ite_sm:last-child .form-group .form-control,
.light-theme-np  .main_right-pn .chat-bar .chat-title > div .select-ar:last-child{
    background-color: #f2f4f2e6;
    border: 1px solid #e7e7e757;
}
.light-theme-np .main_right-pn header .ite_sm:last-child .form-group [type="submit"] svg,
.light-theme-np .main_right-pn header .ite_sm:last-child .notify{
    fill: #5e5e6d;
    color: #5e5e6d;
}
.light-theme-np .main_right-pn header .ite_sm:last-child .notify .badge-nofy{
    border-color: #fff;
}
.light-theme-np .main_right-pn .card-section [class^="col"]:nth-child(odd) .item {
    background-color: #dadada;
}
.light-theme-np .main_right-pn .table-section table tbody > :nth-child(odd) {
    background-color: #eaecee;
}
.light-theme-np .main_right-pn .tab_Two_Box .skygren-table tbody > :nth-child(odd) {
    background-color: #eaecee;
}
.light-theme-np .main_right-pn .tab_Two_Box .skygren-table tbody > :nth-child(even) {
    background-color: #e1e1e1;
}
.light-theme-np .main_right-pn .table-section table tbody td, .light-theme-np .tab_Two_Box .skygren-table tbody td {
    color: #212529;
    border-left: 1px solid #d6d6d6;
    border-bottom: 1px solid #d6d6d6;
}
.light-theme-np .main_right-pn .table-section table tbody > :nth-child(even) {
    background-color: #e1e1e1;
}
.light-theme-np .main_right-pn .table-section table tbody td,
.light-theme-np .main_right-pn .table-section table tbody tr td:last-child{
    border-color: #d6d6d6;
}
.light-theme-np .main_right-pn .table-section ul.nav.nav-tabs .nav-item .nav-link {
    font-weight: 600;
    font-size: 16px;
    text-transform: uppercase;
    color: #000000;
    background-color: #eaecee;
    border: unset !important;
}
.light-theme-np .main_right-pn .table-section ul.nav.nav-tabs {
    gap: 10px;
    border-bottom-color: #d6d6d6;
}
.light-theme-np .main_right-pn .table-section ul.nav.nav-tabs .nav-item .nav-link.active {
    color: #fff;
    background-color: #ED553B;
}
.light-theme-np .main_left-pn::-webkit-scrollbar-thumb {
    background: #d6d6d6; 
}
.light-theme-np .main_right-pn .tab_boxe .tab-content .tab_Three_Box canvas,
.light-theme-np .main_right-pn .tab_boxe .tab_Three_Box canvas {
    background: #fff;
    border-color: #d6d6d6;
}
.light-theme-np .sky_Green_Body {
    background: #d6d6d6;
    border-color: #d6d6d6 !important;
}
.light-theme-np .sky_Green_Body h3 {
    color: #212529;
    opacity: 1;
}
.light-theme-np .main_right-pn header .ite_sm:last-child .form-group .form-control  {
    color: #212529;
}
.light-theme-np .app_InputBox .app_InputBoxSub label {
    color: #212529;
}
.light-theme-np .app_InputBox .app_InputBoxSub select,
.light-theme-np .keyWordFirstLeft .keyWordInput {
    background: #f2f4f2e6;
    border: 1px solid #e7e7e757;
    color: rgba(48, 48, 64, 0.73);
}

.light-theme-np .main_right-pn .logsMainBox .logsMainLeft > :nth-child(odd),
.light-theme-np .main_right-pn .logsMainBox .logsMainRight > :nth-child(odd) {
    background-color: #eaecee;
}
.light-theme-np .main_right-pn .logsMainBox .logsMainLeft > :nth-child(even),
.light-theme-np .main_right-pn .logsMainBox .logsMainRight > :nth-child(even) {
    background-color: #e1e1e1;
}
.light-theme-np .main_right-pn .logsMainBox .logsMainLeft .logsMainLeftSub:not(:last-child),
.light-theme-np .main_right-pn .logsMainBox .logsMainRight .logsMainLeftSub:not(:last-child) {
    border-bottom: 1px solid #d6d6d6;
}

.light-theme-np .keyWordFirstRight .heading .btn a {
    color: #212529;
    border: 1.2px solid #212529;
}
.light-theme-np .modal-dialog.cuts-modal .modal-content .modal-header .btn-close {
    background: #d6d6d6;
    border: 5px solid #d6d6d6;
}
.light-theme-np .main_right-pn .appsBox .appsSub .appSubHead h2  {
    color: #fff !important;
}
.light-theme-np .main_right-pn .appsBox .appsSub {
    background: #eaecee;
}
.light-theme-np .appsBox .appSubClose a {
    border: 5px solid #eaecee;
}
.light-theme-np .tab_Three_Box .comparingLink .comparingSelect select {
    background: #d6d6d6;
    border: 5px solid #d6d6d6;
    color: #212529;
}
.light-theme-np .tab_boxe .comparingThree .comThreeBox {
    background: #ececec7a;
    border: 0.5px solid #74747414;
    box-shadow: 0px 4px 4px #8f8f8f70;
}
.light-theme-np .tab_boxe .comparingThree .comThreeBox a .threeDiv {
    background: #d6d6d6;
    border: 1px solid #d6d6d6;
}
.light-theme-np .tab_boxe .comparingThree .comThreeBox a .threeDiv .btn {
    background: #e1e1e1;
    border: 1px solid #cacaca;
}
.light-theme-np .form-check-input {
    background-color: #b8b8b8;
    border: 1px solid #c7c7c7;
}
.light-theme-np .form-check-input:checked {
    background-color: #999999;
    border-color: #7D7D95;
}
.light-theme-np .keyZeroStateBtns .keyZeroStatePara  {
    background-color: #f2f4f2e6;
    border: 1px solid #e7e7e757;
}
.light-theme-np .keyZeroStateBtns .btnn {
    border: 1px solid #e7e7e757;
    background: #fff;
}
.light-theme-np .keyZeroStateBtns .btnn::after,
.light-theme-np .keyZeroStateBtns .btnn::before  {
    background: #fff;
}
.light-theme-np .adsByAppBox .accordion-items {
    background: #e1e1e1;
    border: 1px solid #cacaca;
}
.light-theme-np .adsByAppBox .accordion-button:not(.collapsed) {
    color: #212529 !important;
    background: #e1e1e1 !important;
    box-shadow: none !important;
}
.light-theme-np .adsByAppBox .accordion-button {
    color: #212529 !important;
    background-color: #e1e1e1 !important;
}
.light-theme-np .adsByAppBox .accordion-collapse  {
    box-shadow: none !important;
    border: none !important;
}
.light-theme-np .adsByAppBox .accordion-body  {
    background-color: #e1e1e1 !important;
    box-shadow: none !important;
}
.light-theme-np .adsByAppBox .accordion-body .accBodyInputBox .accBin {
    background: #f4f4f4;
    border: 1px solid #c9c9c9;
}
.light-theme-np .adsByAppBox .accordion-button::after {
    background-image: url(@/assets/images/icon/nextBlack.png) !important;
    z-index: 1;
}
.light-theme-np .adsByAppBox .accordion-button:not(.collapsed)::after {
    background-image: url(@/assets/images/icon/nextBlack.png) !important;
}


/* / main right pana section end  / */








/*about details page responsive start*/




@media (min-width:1400px) and (max-width:1799px)  {
    .main_right-pn .tab_boxe {
        padding-top: 30px;
    }
    .main_right-pn .tab_boxe h2,
    .main_right-pn .tab_tittle_border {
        padding-bottom: 0px;
        margin: 0;
    }
    .main_right-pn .tab_boxe .tab-content {
        padding-top: 50px;
    }
    .main_right-pn .tab_boxe .tab-content .tab_One_Box .tab_One_Content .tab_One_Text {
        padding-left: 15px !important;
    }
    .main_right-pn .tab_boxe .tab-content .tab_One_Box .tab_One_Content .tab_One_Option {
        padding-left: 15px !important;
    }
    .main_right-pn .table-section table thead th, .tab_Two_Box .skygren-table thead th {
        font-size: 16px;
    }
    .main_right-pn .table-section table tbody td, .tab_Two_Box .skygren-table tbody td {
        font-size: 14px;
    }
    .tab_Two_Box {
        padding-top: 50px;
    }
    .tabs_Head h2 {
        padding-top: 50px;
    }
    .main_right-pn .tab_boxe h2 {
        font-size: 30px;
    }
    .tab_Box_footer {
        padding: 50px 0 30px 0;
    }
    .main_right-pn .keyWordSecBox .table-section  {
        margin-bottom: 0px !important;
    }
    /* .keyWordFirstLeft .keyWordsubmit {
        width: 180.77px;
        height: 44.48px;
        font-size: 17px;
        margin-top: 20px;
    } */
}

@media (min-width:1280px) and (max-width:1400px)  {
    /* .main_right-pn .tab_boxe {
        padding-top: 30px;
    }    */
    .main_right-pn .tab_boxe h2,
    .main_right-pn .tab_tittle_border {
        padding-bottom: 0px;
    }
    .main_right-pn .tab_boxe .tab-content {
        padding-top: 50px;
    }
    .main_right-pn .tab_boxe .tab-content .tab_One_Box .tab_One_Content .tab_One_Text {
        padding-left: 15px !important;
    }
    .main_right-pn .tab_boxe .tab-content .tab_One_Box .tab_One_Content .tab_One_Option {
        padding-left: 15px !important;
    }
    .main_right-pn .table-section table thead th, .tab_Two_Box .skygren-table thead th {
        font-size: 16px;
    }
    .main_right-pn .table-section table tbody td, .tab_Two_Box .skygren-table tbody td {
        font-size: 14px;
    }
    .tab_Two_Box {
        padding-top: 50px;
    }
    .tabs_Head h2    {
        padding-top: 50px;
    }
    .main_right-pn .tab_boxe h2 {
        font-size: 30px;
    }
    .main_right-pn .tab_boxe .nav-tabs .nav-item .nav-link {
        font-size: 14px;
        padding: 7px 30px;
    }
    .main_right-pn .tab_boxe .tab-content .tab_One_Box .tab_One_Content .tab_One_Text h2 {
        font-size: 20px !important;
    }
    .main_right-pn .tab_boxe .tab-content .tab_One_Box .tab_One_Content .tab_One_Text h4 {
        font-size: 14px;
        padding-bottom: 5px;
    }
    .main_right-pn .tab_boxe .tab-content .tab_One_Box .tab_One_Content .tab_One_Option span {
        font-size: 14px;
        padding-left: 10px;
    }
    .main_right-pn .tab_boxe .tab-content .tab_One_Box .tab_One_Content .tab_One_Option ul li:not(:last-child) {
        padding-bottom: 15px;
        margin-bottom: 15px;
    }
    .main_right-pn .table-section table tbody td, .tab_Two_Box .skygren-table tbody td {
        font-size: 13px;
        padding: 12px;
    }
    .main_right-pn .tab_boxe h2 {
        font-size: 26px;
    }
    .tab_Box_footer {
        padding: 50px 0 30px 0;
    }
    .tab_Box_footer h3 {
        font-size: 13px;
    }
    .appsBox .appsSub .appSubProfile .proImg {
        width: 90px;
    }
    .appsBox .appsSub .appSubProfile .proText {
        width: calc(100% - 90px);
    }
}

@media (min-width:992px) {
    .oilPiechart #myChart2{
        width: 555px !important;
        height: 555px !important;
    }
}

@media (max-width:1366px)  {
    .keyWordFirstRight tbody tr td .keyBoxDiv h2, .modal-body .table-section .resp-table tr td .keyBoxDiv h2 {
        font-size: 13px;
    }
    .keyWordFirstRight tbody tr td .keyBoxDiv a, .modal-body .table-section .resp-table tr td .keyBoxDiv a {
        font-size: 10px;
        color: #FFFFFF;
        padding: 3px 6px;
    }
    .appsBox .appsSub .appSubProfile .proText h3 {
        font-size: 18px;
    }
    .appsBox .appsSub .appSubProfile {
        padding: 46px 15px;
    }
    .appsBox .appsSub .appSubOption {
        padding: 0 30px 20px 30px;
    }
}

@media (max-width:992px) {
    .oilPiechart #myChart2{
        width: 100% !important;
        height: 100% !important;
    }
}

@media (max-width:991px)  {
    .side-collapse .dl-mode-btn > span {
        display: block;
    }
    .main_right-pn .tab_boxe {
        padding-top: 50px;
    }
    .main_right-pn .tab_boxe h2,
    .tab_boxe .adsKeyDetaildsRe .tittle h2  {
        padding-bottom: 10px;
        line-height: 30px;
    }
    .main_right-pn .tab_boxe .nav-tabs {
        padding-top: 15px;
    }
    .main_right-pn .tab_boxe .nav-tabs .nav-item .nav-link {
        font-size: 13px;
        padding: 4px 15px;
        margin: 3px 0;
    }
    .main_right-pn .tab_boxe .tab-content {
        padding-top: 50px;
    }
    .main_right-pn .tab_boxe .tab-content .tab_One_Box .tab_One_Content .tab_One_Text {
        padding-left: 15px !important;
    }
    .main_right-pn .tab_boxe .tab-content .tab_One_Box .tab_One_Content .tab_One_Option {
        padding: 30px  15px !important;
    }
    .main_right-pn .tab_boxe .tab-content .tab_One_Box .tab_One_Content .tab_One_Text h2 {
        font-size: 19px;
        padding-bottom: 5px;
    }
    .main_right-pn .tab_boxe .tab-content .tab_One_Box .tab_One_Content .tab_One_Text h3 {
        font-size: 13px;
        padding-bottom: 8px;
    }
    .main_right-pn .tab_boxe .tab-content .tab_One_Box .tab_One_Content .tab_One_Text h4 {
        font-size: 14px;
        line-height: 20px;
        padding-bottom: 12px;
    }
    .main_right-pn .table-section table tbody td, .tab_Two_Box .skygren-table tbody td {
        font-size: 14px;
        padding: 12px;
    }
    .main_right-pn .table-section table thead th, .tab_Two_Box .skygren-table thead th {
        font-size: 16px;
    }
    .tablePaddTop  {
        padding-top: 20px;
    }
    .tab_Two_Box {
        padding-top: 50px;
    }
    .main_right-pn .tab_boxe h2,
    .tab_boxe .adsKeyDetaildsRe .tittle h2 {
        font-size: 24px;
    }
    .tabs_Head h2 {
        padding-top: 40px;
    }
    .tab_Box_footer {
        padding: 35px 0 30px 0;
    }
    .tab_Box_footer h3 {
        font-size: 13px;
    }
    .sky_Green_Body {
        margin-top: 20px;
    }
    .sky_Green_Body h3 {
        padding: 50px 5px;
    }
    .changeRound {
        display: none;
    }
    .main_right-pn .tab_boxe .changeLogsBox .changeLogsSub ul li   {
        width: 100%;
        overflow-y: hidden;
        overflow-x: scroll;
        white-space: nowrap;
    }
    .main_right-pn .tab_boxe .changeLogsBox .changeLogsSub ul li .changeDiv   {
        width: 250% !important;
    }
    .main_right-pn .tab_boxe .changeLogsBox .changeLogsSub ul li .changeDiv::after  {
        display: none;
    }
    .main_right-pn .tab_boxe .changeLogsBox .changeLogsSub ul li .changeDiv span {
        font-size: 14px;
    }
    .main_right-pn .tab_boxe .changeLogsBox .changeLogsSub ul li .changeDiv .changeDivBtn a {
        font-size: 13px;
        padding: 2px 10px;
    }
    .main_right-pn .tab_boxe .changeLogsBox {
        padding-top: 30px;
    }
    .main_right-pn .tab_boxe .changeLogsBox .changeLogsSub ul li:not(:last-child) {
        margin-bottom: 25px;
    }
    .main_right-pn .tab_boxe .changeLogsBox .changeLogsStatus .changeLogsText h3 {
        font-size: 21px;
    }
    .main_right-pn .tab_boxe .changeLogsBox .changeLogsStatus .changeLogsText span {
        font-size: 12px;
    }
    .main_right-pn .tab_boxe .changeLogsBox .changeLogsStatus .changeLogsText span::after {
        top: 5px;
    }
    .main_right-pn .tab_boxe .changeLogsBox .changeLogsSub ul li .changeDiv span  {
        margin-right: 15px;
        padding-right: 15px
    }
    .logsFillterBtn {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 40px 0 30px 0;
    }
    .main_right-pn .tab_boxe .changeLogsBox .changeLogsStatus .changeLogsText span {
        padding-right: 6px;
        margin-right: 8px;
    }
    .logsMainBox .logsMainLeft, .logsMainBox .logsMainRight {
        padding: 15px 0;
    }
    .logsMainBox .logsMainLeft .logsMainLeftSub, .logsMainBox .logsMainRight .logsMainLeftSub {
        padding: 20px 20px 24px 20px;
    }
    .keyWordFirstLeft .kwflOInput  {
        flex-direction: column;
    }
    .keyWordFirstLeft .kwflOInput .keyWordsubmit {
        width: 180.77px;
        height: 44.48px;
        font-size: 17px;
        margin-top: 20px;
        margin-left: 0px;
    }
    .keyWordFirstLeft p {
        font-size: 19px;
        line-height: 31px;
        padding-top: 20px;
        padding-bottom: 30px;
    }
    .keyWordFirstRight .heading h3 {
        font-size: 21px;
    }
    .keyWordSecBox .heading {
        padding-top: 30px;
        padding-bottom: 20px;
    }
    .keyWordSecBox .heading h3 {
        font-size: 21px;
    }
    .keyWordSecBox .heading a {
        font-size: 15px;
        padding: 4px 24px;
    }
    .tab_boxe .sidePagePara {
        font-size: 18px;
    }
    .tab_boxe .appsBox {
        padding-top: 15px;
    }
    .tab_boxe .appsBox .comGap  {
        padding: 15px;
    }
    .tab_boxe .comparingThree {
        padding-top: 5px;
    }
    .tab_boxe .comparingThree .compds  {
        padding: 15px;
    }
    .tab_boxe .comparingThree .comThreeBox {
        padding: 50px 40px;
    }
    .tab_boxe .comparingThree .comThreeBox h3 {
        font-size: 20px;
        padding-top: 35px;
    }
    .adsKeyWordsp p {
        font-size: 18px;
        padding-top: 20px;
        margin-bottom: 0;
    }
    .adsKeyTableTopP  {
        padding-top: 0px !important;
    }
    .main_right-pn .tab_Two_Box .borderOff tbody .borderBtn a {
        font-size: 12px;
        padding: 5px 16px;
    }
    .tab_boxe .adsKeyDetaildsRe {
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    .tab_boxe .adsKeyDetaildsRe .tittle  {
        text-align: center;
    }
    .tab_boxe .adsKeyDetaildsRe .tittle p {
        font-size: 18px;
        line-height: 27px;
        margin-bottom: 20px;
    }
    .tab_boxe .adsKeyDetaildsRe .btn a {
        font-size: 15px;
        padding: 9px 22px;
    }
    .adsReportTableCenter table thead th {
        padding: 20px !important;
    }
    .keyZeroStateBtns .btnn {
        padding: 12px 9px;
    }
    .keyZeroStateBtns .btnn a {
        font-size: 18px;
        padding: 6.5px 20.3px;
    }
    .keyZeroStateBtns .keyZeroStatePara {
        padding: 50px 60px 40px 60px;
    }
    .keyZeroStateBtns .keyZeroStatePara p {
        font-size: 20px;
        line-height: 30px;
    }
    .adsKeyFeedBack a {
        font-size: 15px;
        padding: 12px 23px;
    }
    .adsReportTableTop table tbody tr td a  {
        font-size: 16px !important;
    }
    .adsByAppReportProfile  {
        flex-direction: column;
    }
    .adsByAppReportProfileBtn {
        padding-top: 20px;
    }
    .adsByAppReportProfile .adsByAppReportProfileBtn a {
        font-size: 14px;
        padding: 9px 18px;
    }
    .adsByAppReportHead h2 {
        padding: 50px 0 20px 0 !important;
    }
    .adsReportHours h3 {
        font-size: 12px;
    }
    .adsKeyWordsp p {
        font-size: 17px;
        padding-bottom: 15px;
    }

 .oilPiechart #myChart2{
        width: 100% !important;
        height: 100% !important;
    }

}

@media (max-width:667px)   {
    .row {
        margin: 0 !important;
    }
    .main_right-pn .tab_boxe {
        padding-top: 30px;
    }
    .main_right-pn .tab_boxe .nav-tabs {
        padding-top: 5px;
        margin-top:10px;
    }
    .main_right-pn .tab_boxe .nav-tabs .nav-item .nav-link {
        font-size: 12px;
        padding: 4px 8px;
    }
    .main_right-pn .tab_boxe .tab-content {
        padding-top: 30px;
    }
    .main_right-pn .tab_boxe .tab-content .tab_One_Box .tab_One_Content .tab_One_Image {
        padding-bottom: 15px;
    }
    .main_right-pn .tab_boxe .tab-content .tab_One_Box .tab_One_Content .tab_One_Image  {
    max-width: auto !important;
    }

    .main_right-pn .tab_boxe .tab-content .tab_One_Box .tab_One_Content .tab_One_Image img{
     display: table !important; margin: 0 auto !important; text-align: center !important; width: auto !important;
    }

    .main_right-pn .table-section table thead th, .tab_Two_Box .skygren-table thead th {
        font-size: 14px;
    }
    .main_right-pn .table-section table tbody td, .tab_Two_Box .skygren-table tbody td {
        font-size: 12px;
        padding: 10px;
    }
    .tab_Two_Box {
        padding-top: 30px;
    }
    .sky_Green_Body h3 {
        font-size: 18px;
    }
    .sky_Green_Body {
        height: auto;
    }
    .main_right-pn .tab_boxe h2 {
        font-size: 20px;
    }
    .tabs_Head h2 {
        padding-top: 30px;
    }
    .keyWordFirstRight .heading {
        flex-direction: column;
    }
    .keyWordSecBox .heading {
        flex-direction: column;
    }
    .comparfningHeadUl,
    .tab_Three_Box .comparingLink {
        flex-direction: column;
        padding-top: 20px;
        padding-bottom: 30px;
    }
    .comparingOption {
        padding-top: 10px;
    }
    .tab_Three_Box .comparingLink .comparingSelect {
        max-width: 360px;
    }
    .appsBox .appsSub .appSubProfile {
        padding: 30px 20px;
        flex-direction: column;
        justify-content: center;
        text-align: center;
    }
    .appsBox .appsSub .appSubOption {
        padding: 0 50px 15px 50px;
    }
    .appsBox .appsSub .appSubProfile .proImg {
        width: 100%;
        padding-bottom: 10px;
    }
    .appsBox .appsSub .appSubProfile .proText {
        width: 100%;
    }
    .appsBox .appsSub .appSubProfile .proText h3 {
        font-size: 18px;
        line-height: 28px;
    }
    .appsBox .appsSub .appSubProfile .proText h4 {
        font-size: 14px;
    }
    .appsBox .appsSub .appSubProfile .proText p {
        font-size: 14px;
    }
    .appsBox .appsSub .appSubOption p {
        font-size: 14px;
        text-align: center;
        line-height: 40px;
    }
    .compareupper  {
        text-align: center;
    }
    .compareupper h2 {
        padding-bottom: 0 !important;
    }
    .appsBox .appsSub .appSubHead h2 {
        font-size: 22px;
        line-height: 12px;
    }
    .tab_Three_Box .comparingLink .comparingOption ul li a, .comparfningHeadUl ul li a {
        font-size: 12px;;
    }
    .tab_boxe .comparingThree .comThreeBox {
        padding: 40px 30px;
    }
    .tab_boxe .comparingThree .comThreeBox h3 {
        font-size: 18px;
        padding-top: 25px;
    }
    .tab_boxe .sidePagePara {
        font-size: 16px;
        line-height: 26px;
    }
    .tab_boxe .comparingThree .comThreeBox a .threeDiv {
        width: 150.25px;
        height: 150.25px;
    }
    .tab_boxe .adsKeyDetaildsRe .tittle p {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 15px;
    }
    .tab_boxe .adsKeyDetaildsRe .btn a {
        font-size: 13px;
        padding: 8px 20px;
    }
    .keyZeroStateBtns .btnn {
        padding: 10px 8px;
    }
    .keyZeroStateBtns .btnn a {
        font-size: 16px;
        padding: 5.5px 18.3px;
    }
    .keyZeroStateBtns .keyZeroStatePara {
        padding: 30px 40px 20px 40px;
    }
    .keyZeroStateBtns .keyZeroStatePara p {
        font-size: 18px;
        line-height: 26px;
    }
    .adsReportTableTop table tbody tr td a  {
        font-size: 14px !important;
    }
    .adsByAppReportProfile .adsByAppReportProfileImage  {
        flex-direction: column;
    }
    .adsByAppReportProfile .adsByAppReportProfileImage .Textbox  {
        padding-top: 20px;
    }
    .adsKeyWordsp p {
        font-size: 17px;
        padding-bottom: 15px;
    }
    .adsByAppBox .accordion-items {
        padding: 10px 10px;
        margin-bottom: 15px;
        overflow-x: scroll;
        overflow-y: hidden;
    }
    .adsByAppBox .accordion-button {
        padding: 10px 5px;
    }
    .adsByAppBox .accItemBox .accItemChekDiv .accItemChekProfile .btn img {
        padding-right: 5px;
    }
    .adsByAppBox .accItemBox .accItemChekDiv .accItemChekProfile .btn span {
        padding-right: 10px;
        margin-right: 10px;
        font-size: 13px;
    }
    .adsByAppBox .accItemBox .accItemChekBtn a {
        font-size: 12px;
        padding: 4px 10px;
    }
    .adsByAppBox .accordion-button:not(.collapsed)::after {
        width: 6px;
    }
    .adsByAppBox .accordion-button::after {
        width: 6px;
        height: 10px;
    }
    .adsByAppBox .accordion-body .accBodyInputBox .accbBbtn {
        margin-top: 10px;
    }
    .main_right-pn .tab_boxe .tab_tittle_border a{padding: 6px 31px; font-size:15px;}
} 

/*about details page responsive end*/


/*debraj responsive start left panel*/

@media (max-width:1799px){
    .main_left-pn {
        width: 270px;
        padding: 42px 0 0 0;
    }
    .main_right-pn {
        margin-left: 270px;
        padding: 0 35px;
    }
    .main_left-pn .accd-sect .accordion-button {
        padding: 0 20px;
        gap: 15px;
        line-height: 1.2;
        font-weight: 300;
    }
    .main_left-pn .logo-sect img {
        width: 35px;
    }
    .main_left-pn .logo-sect a{
        gap: 15px;
        padding-left: 20px;
        margin-bottom: 80px;
    }
    .main_left-pn .logo-sect h4 {
        font-size: 24px;
    }
    .main_right-pn .table-section .tbl-head p,
    .main_left-pn .dl-mode-btn span {
        font-weight: 300;
    }
    .main_right-pn .table-section table thead th {
        padding: 16px 3px;
    }
    .main_right-pn .table-section table tbody td,
    .main_right-pn .table-section table thead th,
    .main_right-pn header .ite_sm:last-child .form-group .form-control,
    .main_right-pn .card-section .item .ttx h6,
    .main_left-pn .accd-sect .accordion-button,
    .main_left-pn .accd-sect .accordion-item .accordion-body li,
    .main_right-pn header .ite_sm:first-of-type .btn {
        font-size: 14px;
    }
    .main_right-pn .table-section {
        margin-bottom: 0px;
    }
    .main_right-pn header .ite_sm:first-of-type {
        gap: 15px;
        margin-left: 20px;
    }
    .main_right-pn header .ite_sm:first-of-type h5 {
        font-size: 18px;
    }
    .main_right-pn header .ite_sm:first-of-type .btn {
        padding: 6px 10px;
    }
  
    .main_right-pn header .ite_sm:last-child {
        gap: 20px;
    }
    .main_right-pn header .ite_sm:last-child .prof li .nav-link,
    .main_left-pn .dl-mode-btn span {
        font-size: 16px;
    }
    .main_right-pn .card-section {
        margin-top: 90px;
    }
    .main_right-pn .card-section .item .ttx h5 {
        font-size: 26px;
    }
    .main_right-pn .card-section .item img{
        width: 70px;
    }
    .main_right-pn .card-section .item {
        padding: 15px 20px;
    }
    .main_right-pn .chat-bar .chat-title h5,
    .main_right-pn .table-section .ciel h6 {
        font-size: 30px;
    }
    .main_right-pn .growth-chh {
        margin: 30px 0 90px;
    }
    .main_right-pn .table-section .tbl-head h6 {
        font-size: 24px;
    }
    .main_right-pn .table-section .tbl-head p {
        font-size: 18px;
    }
    .main_left-pn .accd-sect .accordion-item {
        margin-bottom: 30px;
    }
    .main_left-pn .accd-sect .accordion-item .accordion-body li {
        margin: 0 0 11px 0;
        font-weight: 300;
    }
   
    .main_left-pn .accd-sect .accordion-button img{
        width: 24px;
    }

}

@media (max-width:1440px) {
    .main_right-pn .tab_boxe .tab-content .pricing-card .item .hrad h5 {
        font-size: 20px;
    }
    .main_right-pn .tab_boxe .tab-content .pricing-card .item .tile h4 {
        font-size: 25px;
    }
    .main_right-pn .tab_boxe .tab-content .pricing-card .item ul li {
        font-size: 14px;
        padding: 10px 0;
    }
    .main_right-pn .tab_boxe .tab-content .pricing-card .item ul {
        padding: 0 25px 20px;
    }
    .main_right-pn .tab_boxe .tab-content .competitor-table .heading h4 {
        font-size: 35px;
        
    }
}

@media (max-width:1280px) {
    .main_right-pn header .ite_sm:last-child .prof li .nav-link span:last-child{
        display: none;
    }
    .main_right-pn header .ite_sm:last-child .form-group .form-control {
        height: 44px;
    }
    .main_right-pn header .ite_sm:last-child {
        gap: 12px;
    }
    .main_right-pn header .ite_sm:last-child .prof li .nav-link img {
        width: 48px;
        margin-right: 0px;
        max-width: 100%;
    }
    .main_right-pn .tab_boxe .tab-content .competitor-table .heading {
        margin: 80px 0 30px;
    }

}

@media (max-width:1199px) { 
    .main_right-pn {
        margin-left: 270px;
        padding: 0px 20px;
    }
    .main_right-pn header .ite_sm:first-of-type {
        gap: 15px;
        margin-left: 15px;
    }
    .main_right-pn header .ite_sm:last-child .form-group .form-control {
        min-width: auto;
    }
    .main_right-pn .table-section {
        margin-bottom: 0px;
    }
    .main_right-pn .chat-bar .chat-title {
        margin-top: 50px;
    }
    .main_right-pn .growth-chh {
        margin: 30px 0 ;
    }
    .main_right-pn .chat-bar .chat-title h5,
    .main_right-pn .table-section .ciel h6 {
        font-size: 25px;
    }
    .main_right-pn .keyWordSecBox .table-section  {
        margin-bottom: 0px !important;
    }
}

@media (max-width:1023px) {
    .main_left-pn .logo-sect .close-nav {
       display: block;
    }
    .main_left-pn{
        left: -270px;
    }
    .main_right-pn {
        margin-left: 0;
    }
    .main_right-pn .copy-rrt {
        margin-top: 50px;
        padding-bottom: 20px;
    }
    .side-collapse .main_right-pn {
        margin-left: 0;
    }
    .side-collapse .main_left-pn {
        left: 0;
        width: 80%;
        max-width: 430px;
    }
    .side-collapse .main_left-pn .accd-sect .accordion-button .mini__menu,
    .side-collapse .main_left-pn .accordion-button::after,
    .side-collapse .main_left-pn .logo-sect h4 {
        display: inline-block;
    }
    .side-collapse .accordion-collapse{
        position: static;
    }
    .side-collapse .main_left-pn .accd-sect .accordion-item .accordion-body {
        padding-left: 59px;
    }
}

@media (max-width:991px) {
    .main_right-pn .tab_boxe .tab-content article h4,
    .main_right-pn .tab_boxe .tab-content .media-assets h4 {
        font-size: 30px;
        margin: 0 0 20px 0;
    }
    .main_right-pn .tab_boxe .tab-content article p,
    .adsKeypFont .tab-content article p   {
        font-size: 14px !important;
        line-height: 20px !important;
    }
    .main_right-pn .tab_boxe .tab-content article p:first-of-type {
        margin: 0 0 20px 0;
    }
    .main_right-pn .tab_boxe .tab-content article {
        padding-bottom: 50px;
    }
    .main_right-pn .tab_boxe .tab-content .competitor-table .heading h4 {
        font-size: 25px;
    }
}

@media (max-width:767px) {
    .main_right-pn .card-section .item img {
        width: 50px;
    }
    .main_right-pn .card-section .item .ttx h5 {
        font-size: 20px;
    }
    .main_right-pn .chat-bar .chat-title > div span:first-child {
        font-size: 16px;
        margin-right: 20px;
    }
    .main_right-pn .table-section .tbl-head h6,
    .main_right-pn .chat-bar .chat-title h5,
    .main_right-pn .table-section .ciel h6 {
        font-size: 22px;
    }
    .main_right-pn .table-section ul.nav.nav-tabs .nav-item .nav-link {
        font-weight: 400;
        font-size: 15px;
    }
    .main_right-pn .table-section .tbl-head p{
        margin-bottom: 4px;
    }
    .main_right-pn header .ite_sm:first-of-type{
        order: 1;
        width: 100%;
        justify-content: center;
    }
    .main_right-pn header {
        padding-top: 20px;
    }
    .main_right-pn header .ite_sm:last-child{
        width: calc(100% - 40px);
    }
    .main_right-pn header .ite_sm form{
        flex-grow: 1;
    }
    .main_right-pn .chat-bar .chat-title,
    .main_right-pn .chat-bar .chat-title > div{
         flex-wrap: wrap;
    }
    .main_right-pn .chat-bar .chat-title > div{
        margin-left: auto;
    }
    .main_right-pn .growth-chh {
        margin: 10px 0;
    }
    .main_right-pn .tab_boxe .tab-content .competitor-table .heading {
        margin: 40px 0 25px;
    }
    .main_right-pn .table-section table.last-table tr td {
        padding: 20px 6px;
    }
    .main_right-pn .table-section.other-table-section table thead tr > :nth-child(1), .main_right-pn .table-section.other-table-section table tbody tr > :nth-child(1) {
        text-align: left;
        padding: 0 15px;
    }
    .main_right-pn .tab_boxe .tab-content{ text-align: center;}
    .main_right-pn .tab_boxe .tab-content .tab_One_Box .tab_One_Content .tab_One_Image{ display: table; margin: 0 auto !important; }
}

@media (max-width:576px){
    .main_right-pn .table-section table tbody td{
        font-size: 12px;
    }
    .main_right-pn .table-section .tbl-head h6,
    .main_right-pn .chat-bar .chat-title h5,
    .main_right-pn .table-section .ciel h6 {
        font-size: 18px;
    }
    .main_right-pn .table-section .tbl-head p,
    .main_right-pn header .ite_sm:first-of-type h5 {
        font-size: 14px;
    }
    .main_right-pn header .ite_sm:first-of-type .btn {
        font-size: 12px;
    }
    .main_right-pn .copy-rrt p {
        font-weight: 300;
        font-size: 12px;
    }
    .main_right-pn .card-section {
        margin-top: 50px;
    }
    .main_right-pn header .ite_sm:last-child {
        gap: 8px;
        width: 100%;
    }
    .main_right-pn header .ite_sm:last-child .prof li .nav-link img {
        width: 40px;
    }
    .main_right-pn {
        padding: 0px 10px;
    }
    .main_right-pn header .ite_sm:first-of-type {
        margin-left: 0px;
    }
    .main_right-pn .growth-chh {
        padding: 10px;
    }
    .main_right-pn header .ite_sm:last-child{
        order: 1;
    }
    .main_right-pn header .navbar-collapse{
        order: 2;
    }
    
  .bullet__icon{display:none !important;}

}

/*debraj responsive end*/
</style>