import axios from 'axios'

const url = process.env.VUE_APP_API_BASE_URL;

const actions = {
    GetAllBlog(store, params) {
        axios.get(`${url}wp/v2/posts/`, { params }).then((resp) => {
           
            store.commit('SET_BLOGS', resp.data);
            store.commit('SET_TOTALBLOG', resp.headers['x-wp-total']);
            store.commit('SET_TOTALPAGE', resp.headers['x-wp-totalpages']);
        }).catch((err) => {
            // Toastr.handleServerError(err);
            console.log(err)
        });
    },

    UpdateViewCounter(store, params) {
        axios.get(`${url}wp/v2/view_counter/`, { params }).then((resp) => {
        }).catch((err) => {
            // Toastr.handleServerError(err);
            console.log(err)
        });
    },

    GetBlockDetails(store, params) {
        axios.get(`${url}wp/v2/posts/`, { params }).then((resp) => {
            store.commit('SET_BLOGDETAILS', resp.data);
        }).catch((err) => {
            // Toastr.handleServerError(err);
            console.log(err)
        });
    },

    GetPopularPost(store, params) {
        axios.get(`${url}wp/v2/popularpost/`, { params }).then((resp) => {
            store.commit('SET_POPULARPOST', resp.data);
            console.log('popularpost',resp.data)
        }).catch((err) => {
            // Toastr.handleServerError(err);
            console.log(err)
        });
    },
};

export default actions;