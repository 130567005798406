<template>
 <div class="ful-wdth">
   <div class="tab_boxe">
        <h2 class="sidePageTittle">App Comparison side by side</h2>
        <p class="sidePagePara">Track your competitor's App and Analyze their App Performance</p>

        <div class="comparingThree">
            <div class="row">
                <div class="col-lg-4 col-md-6 compds">
                    <div class="comThreeBox">
                        <a href="javascript:void(0)"  @click="showModal">
                            <div class="threeDiv">
                                <button class="btn" type="button" data-bs-toggle="modal" data-bs-target="#exampleModal2">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-plus-lg" viewBox="0 0 16 16">
                                      <path fill-rule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z"></path>
                                    </svg>
                                </button>
                            </div>
                        </a>
                        <h3>Add your App</h3>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 compds">
                    <div class="comThreeBox">
                        <a href="#">
                            <div class="threeDiv">
                                <button class="btn" type="button" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-plus-lg" viewBox="0 0 16 16">
                                      <path fill-rule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z"></path>
                                    </svg>
                                </button>
                            </div>
                        </a>
                        <h3>Add your competitor's App</h3>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 compds">
                    <div class="comThreeBox">
                        <a href="#">
                            <div class="threeDiv">
                                <button class="btn" type="button" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-plus-lg" viewBox="0 0 16 16">
                                      <path fill-rule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z"></path>
                                    </svg>
                                </button>
                            </div>
                        </a>
                        <h3>Add your competitor's app</h3>
                    </div>
                </div>
            </div>
        </div>
      </div>
   <div>
</div>

<div  id="exampleModal2" class="modal-backdrop1 modal fade" tabindex="-1" aria-labelledby="exampleModal2" aria-modal="true" role="dialog" 
>
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        
        <button type="button" ref="Close" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
          <div class="ful-wdth">
       <div class="serach-result-part">
         <vue3-simple-typeahead
                id="compayarpps"
                v-model="searchkey21"
                :value="searchkey21"
                placeholder="Search app Bye Keyword"
                :items="searchItem"
                class="headersearch form-control"
                :minInputLength="3"
                :itemProjection="
                (item) => {
                      var url1=item.app_logo;
                      url=url1.replace('%3', '');
                      var html=`<div class='compayarpps'><div><img src='${url}'></div>`;
                      html=html+'<div><h2>'+item.app_title+'</h2><p>'+item.app_brief_description+'</p>';
                      html=html+'<p>'+item.app_rating+' of 5 stars ('+item.app_num_of_reviews+')</p></div><div>';
                      return html;
                }"
                    @selectItem="compayarppslist"
                    @onInput="onInputcompayarpp"
                    @onFocus="onFocusompayarApps"
                    @onBlur="onBlurompayarApps"
                >  

                  <template #list-item-text="slot">
                       <span v-html="slot.itemProjection(slot.item)" class="material-icons"></span>
                  </template>      
                </vue3-simple-typeahead>

        </div> 
       
    </div>
      </div>
      <div class="modal-footer">
        <button type="button" ref="Close" class="btn-close" data-bs-dismiss="modal" aria-label="Close" >Close</button>
      </div>
    </div>
  </div>
</div>
 </div>
</template>

<script>

import {mapState,mapActions} from 'vuex'

export default {
   name:'KeyWordTracking',
   props: {
    visible: Boolean,
    variant:String,
  },
   components: {
  },
  data() {
        return {
           selectedaps:{
               appid:[]
           },
          searchItem:[],
            searchkey : null,
            searchkey21:'',
            filterdata: {
            input: '',
            selection: null,
            userid: null,
          },    
        }
  },
   mounted() {  
        //this.GetApsforComparison(this.selectedaps)   
  },
  computed: {
      ...mapState({
            comparisonappsearch: (state) => state.AppListModule.comparisonappsearch,
            user: (state) => state.authModule.user,
            userfollowapps: (state) => state.authModule.userfollowapps,
            searchAppresult: (state) => state.AppListModule.comparisonappsearch,
       }),
   },
  methods:{
        ...mapActions({
            logoutUser: 'authModule/logout',
            GetApsforComparison: 'AppListModule/GetApsforComparison',
        }),
        OpenCloseFun(){
          this.OpenClose =false;
        }, 

         onInputcompayarpp(event){
          this.filterdata.selection = null;
          this.filterdata.input = event.input;
         // this.filterdata.userid = this.user.data.userid;
          if(this.filterdata.input.length>2){
            this.GetApsforComparison(this.filterdata);
            this.searchItem=this.searchAppresult;
          } 
      },
      compayarppslist(item){
         this.searchkey21=item.app_title;
         return item.app_title;
      }, 
        

    
  },
  watch: {

       
         
  }
} 
 
</script>



<style scoped>
 .tab_boxe .sidePageTittle  {
    font-weight: 300;
    font-size: 44px;
    line-height: 66px;
    text-align: center;
    text-transform: capitalize;
    color: #FFFFFF;
}
.tab_boxe .sidePagePara  {
    font-weight: 300;
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    color: #FFFFFF;
}
.tab_boxe .appsBox {
    padding-top: 70px;
}
.appsBox .appsSub  {
    position: relative;
    background: rgba(48, 48, 64, 0.82);
    border: 0.5px solid rgba(116, 116, 116, 0.16);
    box-shadow: 0px 4px 67px rgba(0, 0, 0, 0.38);
    border-radius: 11px 11px 4px 4px;
    height: 100%;
}
.appsBox .appsSub .appSubHead h2  {
    font-family: 'Rubik';
    font-weight: 500;
    font-size: 28px;
    line-height: 33px;
    letter-spacing: 0.02em;
    text-transform: capitalize;
    color: #FFFFFF;
    background: #2571B0;
    border: 0.5px solid rgba(116, 116, 116, 0.16);
    border-radius: 9px 9px 0px 0px;
    padding: 20px 0px 20px 36px;
}
.appsBox .appsSub .appSubGreen h2  {
    background: #3CAEA3;
}
.appsBox .appsSub .appSubViolet h2  {
    background: #685DC9;
}
.appsBox .appsSub .appSubProfile  {
    padding: 46px 35px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}
.appsBox .appsSub .appSubProfile .proImg  {
    width: 110px;
}
.appsBox .appsSub .appSubProfile .proText  {
    width: calc(100% - 110px);
}
.appsBox .appsSub .appSubProfile .proText h3  {
    font-family: 'Rubik';
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    text-transform: capitalize;
    color: #FFFFFF;
}
.appsBox .appsSub .appSubProfile .proText h4  {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px; 
    text-transform: capitalize;  
    color: #C0C0C0;
}
.appsBox .appsSub .appSubProfile .proText h4 span  {
    color: #fff;
    padding-right: 5px;
}
.appsBox .appsSub .appSubProfile .proText p  {
    font-family: 'Rubik';
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    text-transform: capitalize;
    color: rgba(255, 255, 255, 0.83);
    
}
.appsBox .appsSub .appSubProfile .proText h5  {
    font-family: 'Rubik';
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    color: #FFFFFF;
}
.appsBox .appsSub .appSubProfile .proText h5 span  {
    vertical-align: middle;
    padding-right: 5px;
}
.appsBox .appsSub .appSubProfile .proText a  {
    text-decoration: none;
    font-family: 'Rubik';
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    text-transform: capitalize;
    color: #1BF197;
}
.appsBox .appsSub .appSubOption  {
    padding: 0 76px 20px 76px;
}
.appsBox .appsSub .appSubOption p  {
    font-family: 'Rubik';
     font-weight: 400;
    font-size: 17px;
    line-height: 55px;
    text-transform: capitalize;
    color: rgba(255, 255, 255, 0.74);
}
.appsBox .appsSub .appSubOption p span  {
    padding-right: 10px;
}
.appsBox .appsSub .appSubOption p:not(:last-child)  {
    border-bottom: 1px solid rgba(81, 81, 99, 0.31);
}
.appsBox .appSubClose  {
    position: absolute;
    top: -12px;
    right: -17px;
}
.appsBox .appSubClose a {
    background: #62B8FF;
    border: 5px solid #181826;
    border-radius: 50px;
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.appsBox .appSubCloseGreen a {
    background: #3CAEA3;
}
.appsBox .appSubCloseViolet a {
    background: #685DC9;
}
.comparingTableLeft table thead tr th  {
    text-align: center !important;
}
.comparingTableLeft table thead tr > :nth-child(1)  {
    text-align: left !important;
}
.comparingTableLeft table tbody tr td  {
    text-align: center !important;
}
.comparingTableLeft table tbody tr > :nth-child(1)  {
    text-align: left !important;
}
.main_right-pn .tab_boxe .tab_Three_Box canvas {
    width: 100% !important;
    max-height: 641px !important;
    background: #282838;
    padding: 25px 35px;
    border: 0.5px solid rgba(0, 0, 0, 0.31);
}
.tab_Three_Box .comparingLink  {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-bottom: 40px;
}
.tab_Three_Box .comparingLink .comparingSelect  {
    max-width: 243px;
    width: 100%;
}
.tab_Three_Box .comparingLink .comparingSelect select  {
    border: 1px solid #737389;
    border-radius: 4px;
    width: 100%;
    height: 40px;
    font-family: 'Rubik';
    font-weight: 400;
    font-size: 16px;
    line-height: 35px;
    letter-spacing: 0.02em;
    color: #FFFFFF;
    padding-left: 10px;
    outline: none;
    background: #191927;
}
.tab_Three_Box .comparingLink .comparingOption ul,
.comparfningHeadUl ul  {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.tab_Three_Box .comparingLink .comparingOption ul li:not(:last-child),
.comparfningHeadUl ul li:not(:last-child)  {
    padding-right: 30px;
}
.tab_Three_Box .comparingLink .comparingOption ul li a,
.comparfningHeadUl ul li a  {
    text-decoration: none;
    font-weight: 400;
    font-size: 17px;
    line-height: 26px;
    text-align: center;
    text-transform: capitalize;
    color: #FFFFFF;
}
.tab_Three_Box .comparingLink .comparingOption ul li a span  {
    padding-right: 6px;
}
.comparfningHeadUl {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 100px;
    padding-bottom: 42px;
}
.comparfningHeadUl h2  {
    padding: 0 !important;
}

.keyWordSecBox .heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-top: 120px;
  padding-bottom: 36px;
  position: relative;
}

.keyWordSecBox .heading h3 {
  font-weight: 300;
  font-size: 34px;
  text-transform: capitalize;
  color: #FFFFFF;
}

.keyWordSecBox .table-section .skyBackground thead {
  background: #00ADD2 !important;
}

.comparingTableLeft table thead tr > :nth-child(1) {
  text-align: left !important;
}

.light-theme-np .main_right-pn .appsBox .appsSub {
  background: #eaecee;
}

.light-theme-np .main_right-pn .tab_boxe h3, .light-theme-np .appsBox .appsSub .appSubProfile .proText p, .light-theme-np .appsBox .appsSub .appSubOption p, .light-theme-np .appsBox .appsSub .appSubProfile .proText h5, .light-theme-np .appsBox .appsSub .appSubProfile .proText h4, .light-theme-np .appsBox .appsSub .appSubProfile .proText h4 span
{color: #212529 !important;}
.appsBox .appsSub .appSubOption p:not(:last-child) {
  border-bottom: 1px solid rgba(81, 81, 99, 0.31);
}

.main_right-pn .tab_boxe {
  padding-top: 110px;
}

.main_right-pn .tab_boxe h2 {
  font-weight: 300;
  font-size: 40px;
  line-height: 60px;
  text-transform: capitalize;
  color: #FFFFFF;
  padding-bottom: 45px;
  margin: 0;
}

.light-theme-np .tab_boxe .sidePagePara, .light-theme-np .tab_Three_Box .comparingLink .comparingOption ul li a, .light-theme-np .comparfningHeadUl ul li a{color: #212529 !important;}

 .light-theme-np .main_right-pn .tab_boxe h2{ color:#fff;}
 .light-theme-np .main_right-pn .tab_boxe h2 {color: #212529;}
 .light-theme-np .main_right-pn .tab_boxe .appSubHead h2{color:#fff;}

 .tab_boxe .comparingThree .comThreeBox {
  background: #232335;
  border: 0.5px solid rgba(116, 116, 116, 0.16);
  box-shadow: 0px 4px 67px rgba(0, 0, 0, 0.38);
  border-radius: 4px;
  padding: 72px 80px;
  height: 100%;
}

.tab_boxe .comparingThree .comThreeBox a .threeDiv {
  width: 173.25px;
  height: 173.25px;
  background: rgba(61, 61, 79, 0.73);
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.tab_boxe .comparingThree .comThreeBox a .threeDiv .btn {
  width: 66px;
  height: 66px;
  background: #4C4C5C;
  border-radius: 50%;
  background: #4C4C5C;
  border: 1px solid #5A5A6B;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tab_boxe .comparingThree .comThreeBox h3 {
  font-weight: 300;
  font-size: 28px;
  line-height: 42px;
  text-align: center;
  color: #FFFFFF;
  padding-top: 55px;
}

.tab_boxe .comparingThree {
  padding-top: 44px;
}

.light-theme-np .tab_boxe .comparingThree .comThreeBox {
  background: #ececec7a;
  border: 0.5px solid #74747414;
  box-shadow: 0px 4px 4px #8f8f8f70;
}

.light-theme-np .tab_boxe .comparingThree .comThreeBox a .threeDiv {
  background: #d6d6d6;
  border: 1px solid #d6d6d6;
}

.light-theme-np .tab_boxe .comparingThree .comThreeBox a .threeDiv .btn {
  background: #e1e1e1;
  border: 1px solid #cacaca;
}

  @media (max-width: 1799px){
.main_right-pn .table-section table thead th {
  padding: 16px 3px;
}
  }

  @media (min-width: 1280px) and (max-width: 1400px) {
.appsBox .appsSub .appSubProfile .proText {
  width: calc(100% - 90px);
}
.main_right-pn .table-section table tbody td, .tab_Two_Box .skygren-table tbody td {
  
  padding: 12px;
}

.main_right-pn .tab_boxe h2 {
  font-size: 26px;
}
.main_right-pn .tab_boxe h2, .main_right-pn .tab_tittle_border {
  padding-bottom: 0px;
}
  }

  @media (max-width: 1366px) {
.appsBox .appsSub .appSubProfile {
  padding: 46px 15px;
}
.appsBox .appsSub .appSubProfile .proText h3 {
  font-size: 18px;
}
.appsBox .appsSub .appSubOption {
  padding: 0 30px 20px 30px;
}
  }
  @media (max-width: 767px) {
 .tab_boxe .comparingThree .comThreeBox{ padding:40px 20px;}
 .tab_boxe .comparingThree .comThreeBox h3{ font-size: 24px;}

  }
</style>