<template>
<div class="inner-header">
 <HeaderHome/>
 </div>
 <div class="ful-wdth grey-bg pt-4">
<div class="container blog-container">

 <div class="row">
   <div class="col-lg-12">
    <div class="blog_list-details" v-if="ispageload">
      <div class="pb-3" v-if="blogdetails[0]._embedded['wp:featuredmedia']['0'].source_url">
        <img   :src="blogdetails[0]._embedded['wp:featuredmedia']['0'].source_url">
       </div> 
        <h1>{{blogdetails[0].title.rendered}}</h1>
         <span class="sidebar-posts--date-topic">{{moment(blogdetails[0].date).format("MMMM DD,YYYY") }}</span>
         <div  v-html="blogdetails[0].content.rendered"></div>  
            
    </div>
   </div>


  <!-- <Blogleftpanel/> -->


 </div>
</div>
 </div>
 <div class="inner-home">
<FooterHome/>
 </div>
</template>
<style scoped>
 .blog_list-details {
  max-width: 80%;
  margin: 50px auto;
  box-shadow: 0 0 6px #999;
  padding: 25px;
}
.blog_list-details h1 {
  font-size: 25px;
  padding-bottom: 10px;
}

@media (max-width:740px){
 .blog_list-details{ max-width: 100%; }
}

</style>
<script>
import HeaderHome from '@/components/Mainpage/HeaderHome.vue'
import FooterHome from '@/components/Mainpage/FooterHome.vue'
// import Blogleftpanel from '@/components/Blogleftpanel.vue'
import { mapState, mapActions } from 'vuex'
import moment from "moment";
import { useRoute } from 'vue-router'
export default {
      name: 'BlogPage',
      data() {
          return {
              ispageload:false,
              params:{
              per_page: 10,
              page:1,
              _embed:true,
           } ,
           page: 1,
           moment: moment,
           postid:{
             post_id:null,
           },
           query:{
             slug:'',
             _embed:true,
           }
           
         };
      },
      components: {
          HeaderHome,
          FooterHome,
          // Blogleftpanel
      },
      computed:{
          ...mapState({
              bloglist: state => state.BlogDetails.bloglist,
              totalblog: state => state.BlogDetails.totalblog,
              blogdetails: state => state.BlogDetails.blogdetails,
          }),
      },
      methods: {
          ...mapActions({
               GetAllBlog: 'BlogDetails/GetAllBlog',
               UpdateViewCounter: 'BlogDetails/UpdateViewCounter',
               GetBlockDetails: 'BlogDetails/GetBlockDetails',
               GetPopularPost: 'BlogDetails/GetPopularPost',
          }),
      },
      mounted() { 
          const route=useRoute();
          this.query.slug=route.params.slug;
          this.GetAllBlog(this.params);
          this.GetBlockDetails(this.query);
          this.GetPopularPost();
      },
    watch: {
        blogdetails(newval,oldval) { 
          if(newval){
             if(this.blogdetails[0])
              this.ispageload=true;
              {
                this.postid.post_id=this.blogdetails[0].id;
                document.title = this.blogdetails[0].title.rendered;
                this.UpdateViewCounter(this.postid)
              }
          }
          
       },
       '$route.params'(newId, oldId) {
            this.query.slug=newId.slug;
            this.GetBlockDetails(this.query);
         }  
    }
}
</script>



