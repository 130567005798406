<template>
      <div>
        <TopCard/>
        <LineChart/>
        <div class="table-section">
          <div class="row gy-4">
            <TableSection/>
            <ReviewTable/>
            <StoreTable/>
            <RankingTable/>
            <TabPanel/>
            <OrganicTable/>
            <CategoryTable/>
          </div>
        </div>
      </div>
</template>

<script>
import TopCard from '@/components/TopCard.vue'
import LineChart from '@/components/LineChart.vue'
import TableSection from '@/components/TableSection.vue'
import ReviewTable from '@/components/ReviewTable.vue'
import StoreTable from '@/components/StoreTable.vue'
import TabPanel from '@/components/TabPanel.vue'
import RankingTable from '@/components/RankingTable.vue'
import OrganicTable from '@/components/OrganicTable.vue'
import CategoryTable from '@/components/CategoryTable.vue'
import {mapState,mapActions} from 'vuex'
export default {
  name: 'App',
  data() {
    return {
        isdashboard:false,
        isfollowdataparams: {
                user_id: '',
                app_id: '',
                action:'',
            },
        params:{
              user_id: '',  
            }    

    };
  },
  components: {
      TopCard,
      LineChart,
      TableSection,
      ReviewTable,
      StoreTable,
      TabPanel,
      RankingTable,
      OrganicTable,
      CategoryTable,
    },
   
   mounted() {  
      const vm = this;
      this.isload=true;
      vm.isfollowdataparams.user_id=vm.user.data.userid;
      vm.params.user_id=vm.user.data.userid;
      vm.get30daysappsbycategorywise(); 
      vm.PaidandFreeData();
      vm.latestedapp();
      vm.dashboardmostinstallsaps()
      vm.dashboardrelevantaps()
      vm.dashboarduserFollowAps(vm.isfollowdataparams)
      vm.dashboardmostreviewaddAps()
      vm.userfollowaps(vm.isfollowdataparams);
      vm.userfollowapsdetails(vm.params);
      vm.CategoryWiseapsinventory();
  },

   computed:{
        ...mapState({
            user: (state) => state.authModule.user,
            userfollowapps: (state) => state.authModule.userfollowapps,
        }),
         
    },

  methods:{
    int(){          
      this.istopcardload=true;     
    },  
    
    //dashboardmostreviewsapp: 'AppreviewModule/getTopreviewsApp',
    //latestedapp: 'AppListModule/getLatestedApp',
    ...mapActions({ 
         //totalapp: 'AppListModule/getTotalAps', 
         get30daysappsbycategorywise: 'AppListModule/getLast30daysapsbycategory',
         PaidandFreeData: 'AppListModule/PaidandFreeData',       
         latestedapp: 'AppListModule/getDashboardnewestapplist', 
         dashboardmostinstallsaps: 'AppListModule/getDashboardmostinstallsaps', 
         dashboardrelevantaps: 'AppListModule/getDashboardRelevantAps', 
         dashboarduserFollowAps: 'AppListModule/getDashboarduserFollowAps', 
         dashboardmostreviewaddAps: 'AppListModule/getDashboarMostreviewaddAps',
         userfollowaps: 'authModule/userfollowaps',
         userfollowapsdetails: 'AppListModule/UserFollowApsDetails',
         CategoryWiseapsinventory: 'AppListModule/CategoryWiseapsinventory',
     }),
    
  },

  watch: {
        isload(newval,oldval) { 
        this.int();
      
    },
  } 
}
</script>