<template>
  <div class="ful-wdth blk-bg login-page">
   
    <div class="signup-box">
       <div class="logo-login"><router-link to="/"><img src="@/assets/images/logo/Sas-rader-logo.png"></router-link></div>
      <h2>Sign In</h2>
     <Form @submit="handleLogin" :validation-schema="schema" v-slot="{ errors }">
      <div class="row">
                
        <div class="col-lg-12 col-md-12"><label>Email ID</label>  
          <Field name="email" type="email" placeholder="example@onboardme.io" :class="{ 'has-error': errors.email }" />
          <ErrorMessage name="email" class="text-danger" />
        </div> 

        <div class="col-lg-12 col-md-12"><label> Password</label> 
          <Field name="password" type="password" placeholder="Type Password" :class="{ 'has-error': errors.password }" />
          <ErrorMessage name="password" class="text-danger" />
        </div>
      </div>  
       <input type="submit" value="SIGN IN">
     </Form>  
      <p class="pt-3"> <router-link to="/forgot">Forgot Password?</router-link>  <router-link to="/sign-up" class="sn-in">SIGN UP</router-link></p>
   
      <router-link to="/" class="bck-btn">Back to home</router-link>
      <div class="or"><span>or</span></div>
      
      <div class="googl-register">
        

          <GoogleLogin :callback="callback" style="cursor: pointer;">
             <img src="@/assets/images/Google.png" alt=""> Login With Google
           </GoogleLogin>
       </div>
    </div> 
    
    
  </div>
  
</template>



<script>
    import { Field, Form, ErrorMessage } from 'vee-validate'
    import * as yup from 'yup'
    import { mapState, mapActions } from 'vuex'
    //import router from '@/routes'
    import { googleOneTap } from "vue3-google-login"
    import { decodeCredential } from 'vue3-google-login'
    export default {
        name: "userLogin",
        data () {
            const schema = yup.object({
                email: yup.string().required().email().label('The email'),
                password: yup.string().required().min(6).label('The password'),
            });
            return {
                schema,
                googlingdata:{
                fname:'',
                lname:'',
                email:'',
                role:'developer',
                password:'',
                googleid:'',
                profileimage:''
                }
            };
        },

        components: {
            Field,
            Form,
            ErrorMessage,
        },
        computed:{
           ...mapState({
             formLoader: state => state.authModule.formLoader,
            }),
        },

          mounted() {  
          const vm = this;
          // vm.totalapp();
          // vm.get30daysappsbycategorywise(); 
          // vm.PaidandFreeData();
          // vm.totalreviews();
          // vm.totaldeveloper();
          //  vm.allapsanddevloper();
          //  vm.allappsdevlopers();
          },

          methods: {
            ...mapActions({
                login: 'authModule/login',
                // totalapp: 'AppListModule/getTotalAps',
                // // totalreviews: 'AppListModule/getTotalReviews',
                // // totaldeveloper: 'AppListModule/getTotalDevelopers',
                // get30daysappsbycategorywise: 'AppListModule/getLast30daysapsbycategory',
                // PaidandFreeData: 'AppListModule/PaidandFreeData',
              GoogleSignup: 'authModule/GoogleSignup',  
                
            }),

            handleLogin (params, { setFieldError }) {
                const vm = this;
                params.setFieldError = setFieldError;
                vm.login(params);
               
            },
           callback(response){
           const vm = this;
            googleOneTap()
                .then((response) => {
              const responsePayload = decodeCredential(response.credential);
              vm.googlingdata.fname=responsePayload.given_name;
              vm.googlingdata.lname=responsePayload.family_name;
              vm.googlingdata.email=responsePayload.email;
              vm.googlingdata.password=responsePayload.sub;
              vm.googlingdata.profileimage=responsePayload.picture;
              vm.googlingdata.googleid=responsePayload.sub;
              vm.GoogleSignup(vm.googlingdata);
            }).catch((error) => {
                console.log("invalid Login Error", error)
             })
        }
        },
    }
</script>