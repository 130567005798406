<template>
   <div class="ful-wdth blk-bg">
   
    
      <div class="signup-box">
        <Form @submit="handlesignup" :validation-schema="schema" v-slot="{ errors }">
      <h2>Sign Up</h2>
      
      <div class="row">
      
       <div class="col-lg-6 col-md-6"><label>First Name</label> 
       <Field name="fname" type="text" placeholder="Type First Name" :class="{ 'has-error': errors.fname }" />
          <ErrorMessage name="fname" class="text-danger" />
       </div> 

       <div class="col-lg-6 col-md-6"><label>Last Name</label> 
         <Field name="lname" type="text" placeholder="Type Last Name" :class="{ 'has-error': errors.lname }" />
          <ErrorMessage name="lname" class="text-danger" />
       </div>

       <div class="col-lg-12 col-md-12"><label>Email Addess</label> 
          <Field name="email" type="email" placeholder="example@onboardme.io" :class="{ 'has-error': errors.email }" />
          <ErrorMessage name="email" class="text-danger" />
        </div>

        <div class="col-lg-12 col-md-12"><label>Role</label> 
        <Field name="selected_type" v-model="selected_type" :class="{ 'has-error': errors.selected_type }" as="select">
        <option v-bind:key="options.slug" v-for="options in typeoptions" :value="options.slug">{{options.name}}</option>
       </Field>
         <ErrorMessage name="selected_type" class="text-danger" />
        </div>

        <div class="col-lg-6 col-md-6"><label>Password</label> 
          <Field name="password" type="password" placeholder="Type Password" :class="{ 'has-error': errors.password }" />
          <ErrorMessage name="password" class="text-danger" />
        </div>

        <div class="col-lg-6 col-md-6"><label>Confirm Password</label> 
          <Field name="cpassword" type="password" placeholder="Type Password" :class="{ 'has-error': errors.cpassword }" />
          <ErrorMessage name="cpassword" class="text-danger" />
        </div>

        
      </div>  
       <input type="submit" value="SIGN UP">

       <div class="or"><span>or</span></div>
       <div class="googl-register">
        

          <GoogleLogin :callback="callback"  style="cursor: pointer;">
             <img src="@/assets/images/Google.png" alt=""> Register With Google
           </GoogleLogin>
       </div>
        </Form>
      </div>  
       
  </div>  
</template>

<script>
import moment from "moment";
import { Field, Form, ErrorMessage } from 'vee-validate'
import * as yup from 'yup'
import { mapState, mapActions } from 'vuex'
import { onMounted } from "vue"
import { googleOneTap } from "vue3-google-login"
import { decodeCredential } from 'vue3-google-login'

/*onMounted(() => {
  googleOneTap()
    .then((response) => {
      // This promise is resolved when user selects an account from the the One Tap prompt
      console.log("Handle the response", response)
    })
    .catch((error) => {
      console.log("Handle the error", error)
    })
})*/

export default {
  name: 'SignUp',  
    data() {
          const schema = yup.object({
                email: yup.string().trim().required().email().label('The email'),
                fname: yup.string().trim().required().label('The First Name'),
                lname: yup.string().trim().required().label('The Last Name'),
                selected_type: yup.string().required().label('Setect Role'),
                password: yup.string().required().matches(
                  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
                  "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
                ),
                //cpassword: yup.string().required().confirmed('password').min(6).label('The password'),
                cpassword: yup.string().required('Confirm password is a required field').oneOf([yup.ref('password'), null], 'Passwords must match')
            });
        return { 
            schema,          
            moment: moment,
            resultdata:[],
            url:process.env.VUE_APP_API_BASE_URL,
            typeoptions: [{'slug':'','name':'Please Select Type'},
                         {'slug':'developer','name':'Developer'},
                         {'slug':'investor','name':'Investor'},
                         {'slug':'marketer','name':'Marketer'},
                        ],
            selected_type: '',
            googlingdata:{
              fname:'',
              lname:'',
              email:'',
              role:'developer',
              password:'',
              googleid:'',
              profileimage:''
            }

        }
    }, 
    components: {
        Field,
        Form,
        ErrorMessage,
    },

   /*setup() {
        onMounted(() => {
          googleOneTap()
          .then((response) => {
          // This promise is resolved when user selects an account from the the One Tap prompt
          console.log("Handle the response3", response);
          const userData2 = decodeCredential(response.credential)
          console.log("Handle the userDat2", userData2)
          })
          .catch((error) => {
          console.log("Handle the error4", error)
          })
        })
  },*/
   
    computed:{
        ...mapState({
          formLoader: state => state.authModule.formLoader,
          //user: state => state.authModule.user,
      }),
    } ,
    methods: {
        ...mapActions({
            UserSignup: 'authModule/UserSignup',
            GoogleSignup: 'authModule/GoogleSignup',
           // getapplist: 'AppListModule/getAppslist',
           // getappcatlist: 'AppListModule/getAppCategory',
           // getAppsTotalReviews: 'AppreviewModule/getAppsTotalReviews',
        }),

        handlesignup (params, { setFieldError }) {
            const vm = this;
            params.setFieldError = setFieldError;
            vm.UserSignup(params);
           // vm.getapplist();
          //  vm.getappcatlist();
           // vm.getAppsTotalReviews();
        },

        callback(response){
           const vm = this;
            googleOneTap()
                .then((response) => {
              const responsePayload = decodeCredential(response.credential);
              vm.googlingdata.fname=responsePayload.given_name;
              vm.googlingdata.lname=responsePayload.family_name;
              vm.googlingdata.email=responsePayload.email;
              vm.googlingdata.password=responsePayload.sub;
              vm.googlingdata.profileimage=responsePayload.picture;
              vm.googlingdata.googleid=responsePayload.sub;
              //this.GoogleSignup(this.googlingdata);
              /*console.log("ID: " + responsePayload.sub);
              console.log('Full Name: ' + responsePayload.name);
              console.log('Given Name: ' + responsePayload.given_name);
              console.log('Family Name: ' + responsePayload.family_name);
              console.log("Image URL: " + responsePayload.picture);
              console.log("Email: " + vm.googlingdata.fname);*/
              vm.GoogleSignup(vm.googlingdata);
              //console.log("Email: " + vm.googlingdata.fname);
             // console.log("Handle the response", vm.googlingdata)
            }).catch((error) => {
                console.log("invalid Login Error", error)
             })
        }
    },

    
    
}
</script>

