<template>
 <div :class="modelValue ? 'active' : ''"  v-if="todoLisloginflug">
        <div class="logo-sect">
               <router-link to="/"><img src="@/assets/images/logo/Sas-rader-logo.png" alt=""></router-link>
               <router-link to="/"> <h4><strong>SAS</strong> Radar</h4></router-link>
                <div class="close-nav" id="closeNav" @click="$emit('update:modelValue', false)">
                  <button class="btn">
                   
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="#fff" class="bi bi-x-lg" viewBox="0 0 16 16">
                      <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
                    </svg>
                  </button>
                </div>
        </div>

      


<div class="rich-select custom-select">
                    <div class="rich-select-dropdown">
                            <div class="rich-select-option">
                                <input type="radio" name="game" id="game-174430-268248" checked="">
                                <div class="rich-select-option-body">
                                    <label tabindex="-1">
                                        <img src="@/assets/images/usa-flag.jpg" alt="">USA
                                    </label>
                                </div>
                            </div>
                            <div class="rich-select-option">
                                <input type="radio" name="game" id="game-174430-331119">
                                <div class="rich-select-option-body">
                                    <label tabindex="-1">
                                        <img src="@/assets/images/uk-flag.jpg" alt="">Coming Soon
                                    </label>
                                </div>
                            </div>
                            <div class="rich-select-option">
                                <input type="radio" name="game" id="game-174430-331120">
                                <div class="rich-select-option-body">
                                    <label tabindex="-1">
                                        <img src="@/assets/images/aus-flag.jpg" alt="">Coming Soon
                                    </label>
                                </div>
                            </div>
                            <div class="rich-select-option">
                                <input type="radio" name="game" id="game-174430-351462">
                                <div class="rich-select-option-body">
                                    <label tabindex="-1">
                                        <img src="@/assets/images/canada-flag.jpg" alt="">Coming Soon
                                    </label>
                                </div>
                            </div>
                           <div class="rich-select-option">
                                <input type="radio" name="game" id="game-174430-351463">
                                <div class="rich-select-option-body">
                                    <label tabindex="-1">
                                        <img src="@/assets/images/germany-flag.jpg" alt="">Coming Soon
                                    </label>
                                </div>
                            </div>

                    </div>
                    <input type="checkbox" id="rich-select-dropdown-button" class="rich-select-dropdown-button">
                    <label for="rich-select-dropdown-button"></label>
                    <div class="rich-select-options">
                            <div class="rich-select-option">
                                <div class="rich-select-option-body">
                                    <label for="game-174430-268248" tabindex="-1" onclick="document.getElementById('rich-select-dropdown-button').click();">
                                        <img src="@/assets/images/usa-flag.jpg" alt="">USA
                                    </label>
                                </div>
                            </div>
                            <div class="rich-select-option">
                                <div class="rich-select-option-body">
                                    <label for="game-174430-331119" tabindex="-1" onclick="document.getElementById('rich-select-dropdown-button').click();">
                                         <img src="@/assets/images/uk-flag.jpg" alt="">Coming Soon
                                    </label>
                                </div>
                            </div>
                            <div class="rich-select-option">
                                <div class="rich-select-option-body">
                                    <label for="game-174430-331120" tabindex="-1" onclick="document.getElementById('rich-select-dropdown-button').click();">
                                       <img src="@/assets/images/aus-flag.jpg" alt="">Coming Soon
                                    </label>
                                </div>
                            </div>
                            <div class="rich-select-option">
                                <div class="rich-select-option-body">
                                    <label for="game-174430-351462" tabindex="-1" onclick="document.getElementById('rich-select-dropdown-button').click();">
                                         <img src="@/assets/images/canada-flag.jpg" alt="">Coming Soon
                                    </label>
                                </div>
                            </div>

                            <div class="rich-select-option">
                                <div class="rich-select-option-body">
                                    <label for="game-174430-351463" tabindex="-1" onclick="document.getElementById('rich-select-dropdown-button').click();">
                                         <img src="@/assets/images/germany-flag.jpg" alt="">Coming Soon
                                    </label>
                                </div>
                            </div>
                          
                        
                    </div>
                </div>



     
          <div class="accd-sect">            
            <div class="accordion" id="accordionExample">

                    <div class="accordion-item drop-none">
                       <router-link to="/dashboard"  @click="$emit('update:modelValue', false)"> <button class="accordion-button" type="button">
                         <img src="@/assets/images/icon/meter-left.svg" alt=""> <span class="mini__menu"> Dashboard</span></button>
                       </router-link>
                      
                    </div>

                    <div class="accordion-item">
                       <router-link to=""  @click="collapSidebar">
                        <button class="accordion-button collapsed" :class="{'router-link-active': subIsActive(['/AppStoreRanking','/mostinstalled','/MostReviewsAdded','/MostReviews','/NewestApp','/BigGainer'])}"  type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                            <img src="@/assets/images/icon/app-ranking.svg" alt=""/> <span class="mini__menu"> App Ranking</span></button> </router-link>
                      <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                          <ul>
                              <li><router-link to="/AppStoreRanking"  @click="$emit('update:modelValue', false)">App Store Ranking</router-link></li>
                              <li><router-link to="/mostinstalled"  @click="$emit('update:modelValue', false)">Most Installed</router-link></li>
                              <li><router-link to="/MostReviewsAdded"  @click="$emit('update:modelValue', false)">Most Reviews Added</router-link></li>
                              <li><router-link to="/MostReviews"  @click="$emit('update:modelValue', false)">Most Reviewed</router-link></li>
                              <li><router-link to="/NewestApp"  @click="$emit('update:modelValue', false)">Newest Apps</router-link></li>
                              <li><router-link to="/BigGainer"  @click="$emit('update:modelValue', false)">Big Gainers</router-link></li>
                          </ul>
                        </div>
                      </div>
                    </div>

                    <div class="accordion-item">
                          <router-link to=""  @click="collapSidebar">
                        <button class="accordion-button collapsed" :class="{'router-link-active': subIsActive(['/categories-page','/developers-team-directory','/app-tracking','/keyword-tracking','/changelog-applisting'])}" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                            <img src="@/assets/images/icon/app-left.svg" alt=""> <span class="mini__menu"> App Store Intelligence</span></button>
                          </router-link>
                      <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                          <ul>
                              <li><router-link to="/categories-page"  @click="$emit('update:modelValue', false)">Categories</router-link></li>
                              <li><router-link to="/developers-team-directory"  @click="$emit('update:modelValue', false)">Developers</router-link></li>
                              <li><router-link to="/app-tracking"  @click="$emit('update:modelValue', false)">App Tracking</router-link></li>
                              <li><router-link to="/keyword-tracking"  @click="$emit('update:modelValue', false)">Keyword Tracking</router-link></li>
                              <li><router-link to="/changelog-applisting"  @click="$emit('update:modelValue', false)">Change Logs <span class="beta">Beta</span></router-link></li>
                          </ul>
                        </div>
                      </div>
                    </div>

                    <div class="accordion-item drop-none">
                      <router-link to="/side-by-side-comparison"  @click="$emit('update:modelValue', false)">
                        <button class="accordion-button collapsed" type="button">
                            <img src="@/assets/images/icon/side-left.svg" alt=""> <span class="mini__menu">  Side by Side Comparison</span></button>
                        </router-link>
                    </div>

                    <div class="accordion-item">
                          <router-link to=""  @click="collapSidebar">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                         <img src="@/assets/images/icon/Investors-left.svg" alt=""> <span class="mini__menu">Investors Intelligence</span></button>
                          </router-link>
                      <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                          <ul>
                              <li>Marketplace Intelligence</li>
                              <li>App Marketplace</li>
                           </ul>
                        </div>
                      </div>
                    </div>

                    <div class="accordion-item">
                          <router-link to="" @click="collapSidebar">
                        <button class="accordion-button collapsed" :class="{'router-link-active': subIsActive(['/track-ads-by-keyword','/monitor-competitors-ads'])}" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                            <img src="@/assets/images/icon/ad-store-left.svg" alt=""> <span class="mini__menu"> Ad Store Inspector <span>Beta</span> </span></button>
                          </router-link>
                      <div id="collapseFive" class="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                          <ul>
                              <li><router-link to="/track-ads-by-keyword"  @click="$emit('update:modelValue', false)"> Ads by keyword</router-link></li>
                              <li><router-link to="/monitor-competitors-ads"  @click="$emit('update:modelValue', false)"> Competitors Ads</router-link></li>
                              <!--<li><router-link to="/ad-raddar-active-scan"  @click="$emit('update:modelValue', false)">Ad Radar - Active Scan</router-link></li>-->
                          </ul>
                        </div>
                      </div>
                    </div>

                    <div class="accordion-item">
                        <router-link to=""  @click="collapSidebar">
                        <button class="accordion-button collapsed" :class="{'router-link-active': subIsActive(['/shopify-store-poro'])}" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                            <img src="@/assets/images/icon/Shopify-left.svg" alt=""> <span class="mini__menu"> Shopify Store Prospector</span> </button>
                        </router-link>
                      <div id="collapseSix" class="accordion-collapse collapse" aria-labelledby="headingSix" data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                          <ul>
                              <li><router-link to="/shopify-store-poro"  @click="$emit('update:modelValue', false)"> Store Listing </router-link></li>
                          </ul>
                        </div>
                      </div>
                    </div>

                     <div class="accordion-item">
                         <router-link to=""  @click="collapSidebar">
                        <button class="accordion-button collapsed" :class="{'router-link-active': subIsActive(['/coming-soon'])}" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                            <img src="@/assets/images/icon/partnership.svg" class="prtner-icon" alt=""> <span class="mini__menu"> Partnership</span> </button>
                         </router-link>
                      <div id="collapseSeven" class="accordion-collapse collapse" aria-labelledby="headingSix" data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                          <ul>
                              <li><router-link to="/coming-soon"  @click="$emit('update:modelValue', false)"> Partners </router-link></li>
                          </ul>
                        </div>
                      </div>
                    </div>

                  </div>
            </div>


            <div class="dl-mode-btn">
            <span> Dark Mode</span>
        <span class="darkOff">Off</span>
        <span class="darkOn">On</span>
        <label class="switch">
          <input type="checkbox" id="thme-chgs" @click="x">
          <span class="slider"></span>
        </label> 
      </div>

 </div> 
</template>

<script>
import {mapState,mapActions} from 'vuex'
import { useRoute } from 'vue-router';
// import CountryFlag from 'vue-country-flag-next'
// import VueCountryDropdown from 'vue-country-dropdown'
export default {
  name: 'LeftSidebar',
  props: ['islogin', 'modelValue', 'x'],
  emits: ['update:modelValue'],
  
  data() {
    return {
      todoLisloginflug : this.islogin,
      pageload:false,
     
    };
  },

 

  // components: {
  //      VueCountryDropdown
  //   },

  computed: {
      ...mapState({
          user: state => state.authModule.user,
          //accessed: state => state.authModule.accessed,
          //companyLocations: state => state.authModule.companyLocations,
      }),
  },

  mounted() {  
       /*this.todoLisloginflug=true;*/
       //console.log('user',this.user)
       //this.todoLisloginflug=true
       const route = useRoute();        
       this.routename=this.$route.name 
       this.pageload=true 
       if(this.user.data)  {
          this.todoLisloginflug=true
        }
       else{
           this.todoLisloginflug=false
        } 

      
  },



  methods:{
    int(){  
        if(this.user)  {
          this.todoLisloginflug=true
          console.log('user',this.user) 
        }else{
           this.todoLisloginflug=false
        }     
          
    },
   subIsActive(input) {
        const paths = Array.isArray(input) ? input : [input]
        return paths.some(path => {
        return this.$route.path.indexOf(path) === 0 // current path starts with this path string
        })
    },

    collapSidebar(){
        let screenWidth = window.innerWidth;
        // alert(this.modelValue)
        
       
        if(screenWidth > 1199){
          this.$emit('update:modelValue', false)
        }else{
           this.$emit('update:modelValue', true)
        }
        console.log(this.modelValue)
    },
    ...mapActions({
        logoutUser: 'authModule/logout',
    }),
  },  
  watch: {
        user() { 
        this.int();
      
    },
  }
  
 
}

</script>

<style scoped>



.rich-select {
    height: auto; /* 75px + 6 */
    position: relative;
    border: none;
    margin: 5px 0;
    padding: 0;
    background: none;
}

.rich-select-dropdown {
    border: solid 1px #DDD;
    border-radius: .25rem;
    margin: 20px 20px 0;
}
    .rich-select-dropdown:focus-within {
        border-color: #80bdff;
        outline: 0;
        box-shadow: 0 0 0 0.2rem rgba(0,123,255,.25);
    }

.rich-select-dropdown-button {
    opacity: 0;
    width: 0;
    height: 0;
    margin: 0;
    padding: 0;
}
    .rich-select-dropdown-button + label,
    .rich-select-option > input[type=radio] {
        position: absolute;
        display: block;
        right: .5rem;
        width: 2rem;
        height: 1rem;
        outline: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
    }

    .rich-select-dropdown-button + label {
        top: .5rem;
        /* padding: 25px 10px; */
		width:100%;
    }
    .rich-select-dropdown-button + label::before {
        position: absolute;
        top: -3px;
		right:21px;
        width: 1rem;
        height: 1rem;
        content: "";
        pointer-events: none;
        background-repeat: no-repeat;
        background-position: center center;
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 5L0 3h4z'/%3e%3c/svg%3e");
    }

.rich-select-dropdown > .rich-select-option > .rich-select-option-body {
    display: none;
}
    .rich-select-dropdown > .rich-select-option input[type=radio]:checked + .rich-select-option-body {
        display: inline-block;
        width: 100%;
    }

.rich-select-options {
    display: none;
    position: absolute;
    background-color: #191927;
    /* border: solid 1px #DDD;
    border-top: none; */
    border-radius: .25rem;
    width: calc(100% - 40px);
    max-height: 75vh;
    overflow-y: auto;
    z-index: 1000;
    box-shadow: rgba(0,0,0,0.4) 5px 8px 15px;
    margin:0 20px;
}

.active .rich-select-option-body label{ font-size:0;}
.active .rich-select-dropdown{ margin:20px 10px;}
.light-theme-np .rich-select-option-body label{ color:#636363;}
.light-theme-np .rich-select-options{ background: #fff;;}
.active .rich-select-dropdown-button{ display:none;}
.active .rich-select-options{ width:100%; margin:0; top:100%;}
.active .rich-select-option-body img{ margin:0 auto;}
.active .rich-select-dropdown-button + label::before { top:10px; right:18px;}

.rich-select-option {
    padding: 0 8px;
}
.rich-select-options > .rich-select-option:hover {
    background-color: #313144;
}

.light-theme-np .rich-select-options > .rich-select-option:hover {
    background-color: #00c1ec;
}

.rich-select-option-body label {
    width: 100%;
    padding: 2px;
    margin-bottom: 0;
	display:flex;
	align-items:center;
  color:#fff;
}
.rich-select-option-body img {
    height: 18px;
    width: 30px;
    min-height: 18px;
    min-width: 30px;
    object-fit: contain;
    margin-right: .5rem;
}
.rich-select-option-body > button {
    width: 100%;
    text-align: left;
    padding: 2px;
    border: none;
    outline: none;
    background: none;
} 

.rich-select-dropdown-button:checked ~ .rich-select-options {
    display: inline-block;
}


.language {
width: 96%;
display: block;
padding: 3px 20px;
margin: 30px 2%;
border: none;
border-radius: 5px;
cursor: pointer;
background: #313144;
color: #fff;
}
.light-theme-np .language {
background: #eaeaea;
color: #313144;
}
.darkOff  {
    display: none;
}
.light-theme-np .darkOff  {
    display: block;
}
.light-theme-np .darkOn  {
    display: none;
}.darkOff  {
    display: none;
}
.light-theme-np .darkOff  {
    display: block;
}
.light-theme-np .darkOn  {
    display: none;
}
.main_left-pn .accd-sect .accordion-button img.prtner-icon{ width:24px;}
.beta{font-weight: 500;
font-size: 12px;
color: #242424;
background: #FEC33E;
border-radius: 19px;
padding: 0 5px;display: inline-block;
padding: 4px 10px;
margin-left: 30px;}
.main_left-pn .dropdown .dropdown-toggle{display: table; background: none; border: solid 1px #fff;  color: #fff; border-radius: 0; margin: 10px auto 25px;}
.flag-country{
  display: table;
  margin: 0 auto;
}

/* .active .rich-select-options {
  display: none !important;
} */

@media (max-width:1199px) { 
.rich-select{ display: none;}
.active .rich-select{ display: block;}
.active .rich-select-option-body img{ margin:0 10px 0 0 !important;}
.active .rich-select-option-body label{ font-size: 15px !important;}
.active .rich-select-dropdown-button + label::before { top:-3px; right:18px;}
}

@media (max-width:991px){
.main_left-pn > div.active{ overflow-y: scroll;}
.main_left-pn > div.active .dl-mode-btn{ top:inherit; position: absolute;}
.main_left-pn > div.active .dl-mode-btn span {
  font-size: 16px;
}
.main_left-pn .dl-mode-btn{ display: none;}
.language{ display:none;}
.active .language{ display:block;}
.rich-select{ display: none;}
.active .rich-select{ display: block;}
.active .rich-select-option-body img{ margin:0 10px 0 0 !important;}
.active .rich-select-option-body label{ font-size: 15px !important;}
.active .rich-select-dropdown-button + label::before { top:-3px; right:18px;}
}

@media (max-width:767px){
.main_left-pn .dl-mode-btn{ display: none;}
}



</style>