<template>
 <div class="ful-wdth">
  <div class="tab_boxe">
        <h2 class="tab_Boxe_Tittle">Change Logs - App Store Listing Page</h2>

        <div class="row paywallChangeLogsBox">
            <div class="col-lg-6 paywallChangeLogsLeft">
                <h3>Don't be left in the dust. Stay informed when competitors update their app listing pole.
                </h3>
                <div class="logsLeftSub d-flex">
                    <div class="logsSymbol">
                        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="#4CDBCD" class="bi bi-check-lg" viewBox="0 0 16 16">
                            <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z"/>
                          </svg>
                    </div>
                    <div class="logsText">
                        <h3>Start Following your competitor's listing page and learn what they're optimizing to become a more Effective marketer.</h3>
                    </div>
                </div>
                <div class="logsLeftSub d-flex">
                    <div class="logsSymbol">
                        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="#4CDBCD" class="bi bi-check-lg" viewBox="0 0 16 16">
                            <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z"/>
                          </svg>
                    </div>
                    <div class="logsText">
                        <h3>Get notified about competitor's app listing changes</h3>
                    </div>
                </div>
                <div class="logsLeftSub d-flex">
                    <div class="logsSymbol">
                        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="#4CDBCD" class="bi bi-check-lg" viewBox="0 0 16 16">
                            <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z"/>
                          </svg>
                    </div>
                    <div class="logsText">
                        <h3>Elevate your offensive and defensive strategies by learning what's working for your competition.</h3>
                    </div>
                </div>
                <div class="logsLeftSub d-flex">
                    <div class="logsSymbol">
                        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="#4CDBCD" class="bi bi-check-lg" viewBox="0 0 16 16">
                            <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z"/>
                          </svg>
                    </div>
                    <div class="logsText">
                        <h3>See the history of everyone's app listing changes</h3>
                    </div>
                </div>
                <div class="logsLeftBtn">
                    <a href="#">Upgrade</a>
                </div>
            </div>
            <div class="col-lg-6 paywallChangeLogsRight">
                <img src="@/assets/images/logs/logImg.jpg" alt="">
            </div>
        </div>

      </div>
 </div>
</template>