<template>
 <div class="ful-wdth">
   <div class="tab_boxe tab_box_margin">
          <div class="tab-content">
            <div class="keyZeroStateBtns">
                <div class="btnn">
                    <a href="#">Date:  Feb 5 2022 14.20</a>
                </div>
                <div class="keyZeroStatePara">
                    <p>This search term hasn't been crawled yet, Please check back in a few hours to see your report. this report has been expedited.</p>
                </div>
            </div>
          </div>
        </div>
 </div>
</template>