<template>
 <div class="ful-wdth">
    <div class="tab_boxe tab_box_margin adsKeypFont">
          <div class="tab-content">
            <article>
              <h4>Monitor Shopify Search Ads by Keyword</h4>
              <p>Track competitors' ad movements on your keywords and optimize your bidding strategy.  Add a keyword and you can monitor all of the ads that appear how they’re positioned throughout the day.</p>
            </article>
          </div>
        </div>

        <div class="app_InputBox d-flex adskeyWidth">
          <div class="app_InputBoxSub">
            <input type="text" placeholder="Add a keyword">
          </div>
          <div class="app_InputBoxBtn">
            <button type="submit" class="app_In_Btn">Follow</button>
          </div>
        </div>

        <div class="adsKeyWordsp">
            <p>Here are keywords you’re following.  Some keywords might take 24 hours to process. </p>
        </div>

        <div class="changeLogosBtn adsByKlogoBtn">
          <ul>
            <li class="changeLogosyellowBtn"><a href="#">delete</a></li>
          </ul>
        </div>

        <div class="tab_Two_Box table-section tab_TwoP_top adsKeyTableTopP">
          <div class="resp-table">
            <table class="skygren-table borderOff">
              <tbody>
                <tr>
                  <td>
                    <div class="form-check tableChek">
                      <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                    </div>
                  </td>
                  <td>
                    Keyword 1
                  </td>
                  <td>
                    <div class="borderBtn">
                      <router-link to="/ads-keyword-details">  View </router-link>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="form-check tableChek">
                      <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                    </div>
                  </td>
                  <td>
                    Keyword 2
                  </td>
                  <td>
                    <div class="borderBtn">
                     <router-link to="/ads-keyword-details">  View </router-link>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="form-check tableChek">
                      <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                    </div>
                  </td>
                  <td>
                    Keyword 3
                  </td>
                  <td>
                    <div class="borderBtn">
                     <router-link to="/ads-keyword-details">  View </router-link>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td style="text-align: center;">
                    <div class="form-check tableChek">
                      <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                    </div>
                  </td>
                  <td>
                    Keyword 4
                  </td>
                  <td>
                    <div class="borderBtn">
                      <router-link to="/ads-keyword-details">  View </router-link>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="form-check tableChek">
                      <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                    </div>
                  </td>
                  <td>
                    Keyword 5
                  </td>
                  <td>
                    <div class="borderBtn">
                  <router-link to="/ads-keyword-details">  View </router-link>
                    </div>
                  </td>
                </tr>        
              </tbody>
            </table>
          </div>
        </div>

 </div>
</template>

<style scoped>
 .adsKeypFont .tab-content article p  {
    font-size: 21px !important;
    line-height: 29px !important;
}

.main_right-pn .tab_boxe .tab-content article h4{ color: #fff; font-size: 30px;
font-weight: 400;
padding-bottom: 20px;}
.adskeyWidth  {
    max-width: 629px;
    width: 100%;
}
.adskeyWidth .app_InputBoxSub  {
    max-width: 476px;
    width: 100%;
}
.adskeyWidth .app_InputBoxBtn  {
    max-width: 143px;
}
.adsKeyWordsp p  {
    font-family: 'Rubik';
    font-weight: 300;
    font-size: 19px;
    line-height: 25px;
    color: #EDEDED;
    padding-top: 82px;
}
.adsKeyTableTopP  {
    padding-top: 0px;
}
.adsByKlogoBtn ul  {
    padding: 34px 0 15px 0 !important;
}
.adsKeyTableTopP table tbody tr > :nth-child(3)  {
    text-align: right !important;
}
.adsKeyFeedBack {
    position: fixed;
    top: 48%;
    right: -50px;
    transform: rotate(-90deg);
}
.adsKeyFeedBack a {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #FFFFFF;
    background: #ED553B;
    border-radius: 6px;
    padding: 17px 24px;
    text-decoration: none;
}
.adsKeypFont .tab-content article p {
  font-size: 21px !important;
  line-height: 29px !important;
}
.app_InputBox .app_InputBoxBtn .app_In_Btn {
  background: #00C1EC;
  border-radius: 4px;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  text-transform: uppercase;
  color: #FFFFFF;
  text-shadow: 0px 0.5px 1px rgba(0, 0, 0, 0.25);
  padding: 8px 43px;
  border: none;
}


.app_InputBox .app_InputBoxSub {
  margin-right: 10px;
  width: 100%;
  max-width: 341px;
}
.adskeyWidth .app_InputBoxSub {
  max-width: 476px;
  width: 100%;
}
.adskeyWidth {
  max-width: 629px;
  width: 100%;
}
.adsKeyWordsp p {
  font-family: 'Rubik';
  font-weight: 300;
  font-size: 19px;
  line-height: 25px;
  color: #EDEDED;
  padding-top: 82px;
}
.main_right-pn .changeLogosBtn ul .changeLogosyellowBtn {
  background: #FF7301;
  border-radius: 4px;
 
}
.main_right-pn .changeLogosBtn ul a {
  text-decoration: none;
  font-weight: 500;
  font-size: 19px;
  line-height: 28px;
  text-align: center;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  color: #FFFFFF;
   padding: 7px 33px;
   display: block;
}
.main_right-pn .changeLogosBtn ul {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;}
  .adsByKlogoBtn ul {
  padding: 34px 0 15px 0 !important;
}
.main_right-pn .tab_Two_Box .borderOff tbody .borderBtn a {
  text-decoration: none;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #FFFFFF;
  background: #4BCCC0;
  border-radius: 4px;
  padding: 7px 30px;
}
.main_right-pn .tab_Two_Box .borderOff tbody td {
  border-left: none !important;
}
.main_right-pn .table-section table tbody td, .tab_Two_Box .skygren-table tbody td{ text-align: left;}
.app_InputBoxSub input[type="text"] { width: 100%; border: 1px solid #5D5D76;border-radius: 4px; background: #323243;height: 43px; line-height: 43px; color: #EDEDED; text-indent: 10px;}
.main_right-pn .table-section table tbody td, .tab_Two_Box .skygren-table tbody td {
 
  padding: 12px;}
  .light-theme-np .main_right-pn .tab_boxe .tab-content article h4, .light-theme-np .main_right-pn .tab_boxe .tab-content article p, .light-theme-np .adsActiveandScanUlP p, .light-theme-np .adsKeyWordsp p{color: #212529 !important;}
.light-theme-np .app_InputBox .app_InputBoxSub input[type="text"]{background: #f2f4f2e6;
border: 1px solid #e7e7e757;
color: rgba(48, 48, 64, 0.73); font-weight:500;}

@media (max-width:767px){
.adsKeypFont .tab-content article p  {
    font-size: 16px !important;
    line-height: 23px !important;
}

.app_InputBox .app_InputBoxBtn .app_In_Btn{
  background: #00C1EC;
  border-radius: 4px;
  font-weight: 600;
  font-size: 15px;
  line-height: 27px;
  text-align: center;
  text-transform: uppercase;
  color: #FFFFFF;
  text-shadow: 0px 0.5px 1px rgba(0, 0, 0, 0.25);
  padding: 8px 17px;}
  .adsKeyWordsp p{ padding-top: 20px;}
}
@media (max-width:767px){
  .adsByKlogoBtn ul[data-v-e95eb1ca]{ padding: 0 0 15px 0 !important;}
}
</style>