<template>
<div class="chat-bar">
        <div class="chat-title">
          <h5>App Store Growth</h5>
          <div>
            <span>Total Apps</span>
            <div class="select-ar">
               <Datepicker minDate="2022-09-31"   v-model="year"  yearPicker :clearable="false"   :yearRange="[startyear, endyear]" autoApply  :maxDate="new Date()" />
            </div>
          </div>
        </div>

        <!-- <div class="growth-chh psrl" >
         
        </div> -->

        <div class="growth-chh psrl" >

            <div class="loader" v-if="showline">
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="margin:auto;display:block;" width="200px" height="200px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
<circle cx="75" cy="50" fill="#00d1ff" r="5">
  <animate attributeName="r" values="3;3;5;3;3" times="0;0.1;0.2;0.3;1" dur="1s" repeatCount="indefinite" begin="-0.9166666666666666s"></animate>
  <animate attributeName="fill" values="#00d1ff;#00d1ff;#00d1ff;#00d1ff;#00d1ff" repeatCount="indefinite" times="0;0.1;0.2;0.3;1" dur="1s" begin="-0.9166666666666666s"></animate>
</circle><circle cx="71.65063509461098" cy="62.5" fill="#00d1ff" r="5">
  <animate attributeName="r" values="3;3;5;3;3" times="0;0.1;0.2;0.3;1" dur="1s" repeatCount="indefinite" begin="-0.8333333333333334s"></animate>
  <animate attributeName="fill" values="#00d1ff;#00d1ff;#00d1ff;#00d1ff;#00d1ff" repeatCount="indefinite" times="0;0.1;0.2;0.3;1" dur="1s" begin="-0.8333333333333334s"></animate>
</circle><circle cx="62.5" cy="71.65063509461096" fill="#00d1ff" r="5">
  <animate attributeName="r" values="3;3;5;3;3" times="0;0.1;0.2;0.3;1" dur="1s" repeatCount="indefinite" begin="-0.75s"></animate>
  <animate attributeName="fill" values="#00d1ff;#00d1ff;#00d1ff;#00d1ff;#00d1ff" repeatCount="indefinite" times="0;0.1;0.2;0.3;1" dur="1s" begin="-0.75s"></animate>
</circle><circle cx="50" cy="75" fill="#00d1ff" r="5">
  <animate attributeName="r" values="3;3;5;3;3" times="0;0.1;0.2;0.3;1" dur="1s" repeatCount="indefinite" begin="-0.6666666666666666s"></animate>
  <animate attributeName="fill" values="#00d1ff;#00d1ff;#00d1ff;#00d1ff;#00d1ff" repeatCount="indefinite" times="0;0.1;0.2;0.3;1" dur="1s" begin="-0.6666666666666666s"></animate>
</circle><circle cx="37.50000000000001" cy="71.65063509461098" fill="#00d1ff" r="5">
  <animate attributeName="r" values="3;3;5;3;3" times="0;0.1;0.2;0.3;1" dur="1s" repeatCount="indefinite" begin="-0.5833333333333334s"></animate>
  <animate attributeName="fill" values="#00d1ff;#00d1ff;#00d1ff;#00d1ff;#00d1ff" repeatCount="indefinite" times="0;0.1;0.2;0.3;1" dur="1s" begin="-0.5833333333333334s"></animate>
</circle><circle cx="28.34936490538903" cy="62.5" fill="#00d1ff" r="5">
  <animate attributeName="r" values="3;3;5;3;3" times="0;0.1;0.2;0.3;1" dur="1s" repeatCount="indefinite" begin="-0.5s"></animate>
  <animate attributeName="fill" values="#00d1ff;#00d1ff;#00d1ff;#00d1ff;#00d1ff" repeatCount="indefinite" times="0;0.1;0.2;0.3;1" dur="1s" begin="-0.5s"></animate>
</circle><circle cx="25" cy="50" fill="#00d1ff" r="5">
  <animate attributeName="r" values="3;3;5;3;3" times="0;0.1;0.2;0.3;1" dur="1s" repeatCount="indefinite" begin="-0.4166666666666667s"></animate>
  <animate attributeName="fill" values="#00d1ff;#00d1ff;#00d1ff;#00d1ff;#00d1ff" repeatCount="indefinite" times="0;0.1;0.2;0.3;1" dur="1s" begin="-0.4166666666666667s"></animate>
</circle><circle cx="28.34936490538903" cy="37.50000000000001" fill="#00d1ff" r="5">
  <animate attributeName="r" values="3;3;5;3;3" times="0;0.1;0.2;0.3;1" dur="1s" repeatCount="indefinite" begin="-0.3333333333333333s"></animate>
  <animate attributeName="fill" values="#00d1ff;#00d1ff;#00d1ff;#00d1ff;#00d1ff" repeatCount="indefinite" times="0;0.1;0.2;0.3;1" dur="1s" begin="-0.3333333333333333s"></animate>
</circle><circle cx="37.499999999999986" cy="28.349364905389038" fill="#00d1ff" r="5">
  <animate attributeName="r" values="3;3;5;3;3" times="0;0.1;0.2;0.3;1" dur="1s" repeatCount="indefinite" begin="-0.25s"></animate>
  <animate attributeName="fill" values="#00d1ff;#00d1ff;#00d1ff;#00d1ff;#00d1ff" repeatCount="indefinite" times="0;0.1;0.2;0.3;1" dur="1s" begin="-0.25s"></animate>
</circle><circle cx="49.99999999999999" cy="25" fill="#00d1ff" r="5">
  <animate attributeName="r" values="3;3;5;3;3" times="0;0.1;0.2;0.3;1" dur="1s" repeatCount="indefinite" begin="-0.16666666666666666s"></animate>
  <animate attributeName="fill" values="#00d1ff;#00d1ff;#00d1ff;#00d1ff;#00d1ff" repeatCount="indefinite" times="0;0.1;0.2;0.3;1" dur="1s" begin="-0.16666666666666666s"></animate>
</circle><circle cx="62.5" cy="28.349364905389034" fill="#00d1ff" r="5">
  <animate attributeName="r" values="3;3;5;3;3" times="0;0.1;0.2;0.3;1" dur="1s" repeatCount="indefinite" begin="-0.08333333333333333s"></animate>
  <animate attributeName="fill" values="#00d1ff;#00d1ff;#00d1ff;#00d1ff;#00d1ff" repeatCount="indefinite" times="0;0.1;0.2;0.3;1" dur="1s" begin="-0.08333333333333333s"></animate>
</circle><circle cx="71.65063509461096" cy="37.499999999999986" fill="#00d1ff" r="5">
  <animate attributeName="r" values="3;3;5;3;3" times="0;0.1;0.2;0.3;1" dur="1s" repeatCount="indefinite" begin="0s"></animate>
  <animate attributeName="fill" values="#00d1ff;#00d1ff;#00d1ff;#00d1ff;#00d1ff" repeatCount="indefinite" times="0;0.1;0.2;0.3;1" dur="1s" begin="0s"></animate>
</circle>
            </svg>
           </div>

          <canvas id="myChart3"></canvas>
        </div>
      </div>
</template>

<script>
import Chart from 'chart.js/auto';
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import {mapState,mapActions } from 'vuex'
import { ref } from 'vue'


export default {
  name: 'LineChart',
  //startyear:new Date().getFullYear()-12,  
  data(){
    //document.querySelectorAll('main_right-pn')
  //  addEventListener('wheel', function(event){
  //        return closeMenu()
  //   });
  return {
          datasets:{
              data:[],
              labels:[],
             },
          params:{
                 year:new Date().getFullYear(),
          },
          montharray:[],   
          isload:false ,
          year:new Date().getFullYear(),
          startyear:new Date().getFullYear()-12,
          endyear:new Date().getFullYear(),
          cruntyeardate:new Date(),
          monthlength:12,
          showline:true,
          myChart6:null,

  }
},
components: {
   Datepicker
},
  computed: {
      ...mapState({
          appchartlinedata: state => state.AppListModule.appchartlinedata,
      }),
  }, 

 mounted(){ 
          
          this.AppsLinechartappData();
          this.isload=true;

         // var currentdate=new Date();
          // var cyear=currentdate.getFullYear(); 
          // if(this.year==cyear)
          // var cmonth=currentdate.getMonth()+1;
          // else 
          // var cmonth=12;
          // for(let ii=1;ii<=cmonth;ii++){ 
          // this.montharray[ii]=0;
          // }   
          // this.appchartlinedata.forEach((element, index) => { 
          //    this.montharray[parseInt(element.month)] = element.total;
          // });      
       },
  methods:{
            int(){                   
                let chartStatus = Chart.getChart("myChart3"); // <canvas> id
                if (chartStatus != undefined) {
                    chartStatus.destroy();
                }
                this.datasets.data=[];
                this.datasets.labels=[];
                var ctx5 = document.getElementById("myChart3");

                // this.montharray.forEach((element, index) => { 
                //      this.datasets.data.push(element);  
                // });

                this.appchartlinedata.forEach((element, index) => { 
                     this.datasets.labels.push(element.month);  
                     this.datasets.data.push(element.total);  
                 });     
               
                this.myChart6 = new Chart(ctx5, {
                        type: "line",
                        data: {
                                labels: this.datasets.labels,
                                datasets: [{
                                      label: 'Total Apps',
                                      data: this.datasets.data,
                                      backgroundColor: [
                                      "rgb(15, 243, 125)"
                                      ],
                                      borderColor: [
                                      "rgb(15, 243, 125)"
                                      ],
                                      borderWidth: 1
                                  }]
                              },
                  });
                 this.myChart6;
                 this.showline=false;  
                },

        ...mapActions({
            AppsLinechartappData: 'AppListModule/AppsLinechartData',
         }),

     },
  
   watch:{
    isload(val){       
      if(val){
         //this.int();
         //this.myChart6;
        
      }
    },
    year(newval,oldval){      
        this.params.year=newval;
        this.AppsLinechartappData(this.params);       
    },
    appchartlinedata(){     
       if(this.year==this.cruntyeardate.getFullYear())
          this.monthlength=this.cruntyeardate.getMonth()+1;
          else 
           this.monthlength=12;

        this.montharray=[];  
          for(let j=1;j<=this.monthlength;j++){ 
            this.montharray[j]=0;
          } 
        this.appchartlinedata.forEach((element, index) => { 
              let monthindex=parseInt(element.month) 
              this.montharray[monthindex] = element.total;
          });
        
         if(this.myChart6!=null) 
          { 
                this.myChart6.destroy();
                this.myChart6=null;
          } 
         if(this.appchartlinedata.length>0)
            setTimeout(() =>  this.int(), 300); 
        //this.int();
    }

  }
}
</script>
<style scoped>
/* .psrl{height: 250px;} */
.growth-chh.psrl::before{ display: none;}
</style>