<template>
  <HeaderHome/>
    <!-- banner section start here -->
    <BannerHome/>
    <!-- banner section end here -->
    <!-- platfrom section start here -->
    <PlatfromHome/>
    <!-- platfrom section end here -->  
    <!-- app better section start here -->
    <AppbetterHome/>
    <!-- app better section end here -->
    <!-- gain section start here -->
    <GainHome/>
    <!-- gain section end here -->
    <!-- free sign section start here -->
    <FreesignHome/>
    <!-- free sign end here -->
    <!-- news section start here -->
    <NewsHome/>
    <!-- news section end here -->

  <FooterHome/>
</template>

<script>
import HeaderHome from './Mainpage/HeaderHome.vue'
import FooterHome from './Mainpage/FooterHome.vue'
import BannerHome from './Mainpage/BannerHome.vue'
import PlatfromHome from './Mainpage/PlatfromHome.vue'
import AppbetterHome from './Mainpage/AppbetterHome.vue'
import GainHome from './Mainpage/GainHome.vue'
import FreesignHome from './Mainpage/FreesignHome.vue'
import NewsHome from './Mainpage/NewsHome.vue'
export default {
    name: 'HomePage',
    components: {
    HeaderHome,
    FooterHome,
    BannerHome,
    PlatfromHome,
    AppbetterHome,
    GainHome,
    FreesignHome,
    NewsHome
},
  computed:{
    shouldShowSidebar(){
        return this.$route.meta.LeftSidebar!==false;
    }
},

}
</script>


<style scoped>
*{
    font-family: 'Poppins', sans-serif;
    transition: all .3s;
}

input.form-control{
    outline: none;
    box-shadow: none !important;
}
ul{
    list-style: none;
    padding: 0;
    margin: 0;
}
input:focus,
a:focus,
button:focus{
box-shadow: none !important;
outline: none;
}

body  {
    overflow-x: hidden;
}

h1, h2, h3, h4, h5, h6  {
    margin-bottom: 0;
}

.container  {
    width: 100%;
    max-width: 1543px !important;
}



/* banner section start here */

.homeBanner  {
    background: #191927;
    width: 100%;
    position: relative;
    min-height: 1074px;
}
.homeBanner .homeShape  {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
}
.homeBanner .homeShape img  {
    width: 100%;
    display: block;
}
.homeBanner .homeBanText  {
    position: relative;
    text-align: center;
    color: #FFFFFF;
    padding-top: 224px;
    z-index: 999;
}
.homeBanner .homeBanText h1  {
    font-weight: 500;
    font-size: 81px;
    line-height: 80px;
    letter-spacing: -0.03em;
    color: #FFFFFF;
    text-shadow: 0px 4px 4px #000000;
}
.homeBanner .homeBanText h2  {
    font-weight: 275;
    font-size: 40px;
    line-height: 60px;
    text-transform: capitalize;
    color: #FFFFFF;
}
.homeBanner .homeBanText p  {
    max-width: 689px;
    margin: 0 auto;
    font-weight: 300;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    letter-spacing: 0.02em;
    color: #FFFFFF;
    padding-bottom: 36px;
}
.homeBanner .homeBanText a  {
    text-decoration: none;
    display: inline-block;
    font-weight: 500;
    font-size: 19px;
    line-height: 28px;
    letter-spacing: 0.02em;
    color: #FFFFFF;
    background: linear-gradient(270deg, #3798FF 0%, #007BFF 102.52%);
    border-radius: 50px;
    padding: 13.6px 34px;
    transition: all ease-in-out 300ms;
}
.homeBanner .homeBanText a:hover  {
    background: #FF8A00;
}
.homeBanner .homeBanText a span  {
    padding-left: 36px;
}
.homeBanImage  {
    position: relative;
    z-index: 1;
}
.homeBanImage .homeBanImg img  {
    display: block;
    margin: -430px auto 0 auto;
    box-shadow: 0px 4px 57px rgba(0, 0, 0, 0.15);
}
.homeBanLeft img,
.homeBanRight img  {
    display: block;
}
.homeBanLeft {
    position: absolute;
    top: 310px;
    left: 26px;
    content: "";
    background: url(@/assets/images/homePage-1/bannerLeft.png);
    width: 460px;
    height: 547px;
    background-repeat: no-repeat;
    z-index: 9;
}
.homeBanRight  {
    position: absolute;
    top: 264px;
    right: 56px;
    content: "";
    background: url(@/assets/images/homePage-1/bannerRight.png);
    width: 287px;
    height: 986px;
    background-repeat: no-repeat;
    z-index: 9;
}

/* banner section end here */


/* platfrom section start here */

.platfrom  {
    margin-top: 120px;
    position: relative;
}
.platfrom .platfromHead h2  {
    font-weight: 500;
    font-size: 61px;
    line-height: 38px;
    text-transform: capitalize;
    color: #000000;
    text-align: center;
    padding-bottom: 74px;
}
.platfrom .platfromBox  {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    border-right: 1px dashed #B3B3B3;
    padding: 0 33px;
}
.platfrom [class^="col"]:last-child .platfromBox {
    border-right: none;
}
.platfrom .platfromBox .platFromBoxImage  {
    width: 120px;
}
.platfrom .platfromBox .platFromBoxImage img  {
    display: block;
}
.platfrom .platfromBox .platFromBoxText  {
    width: calc(100% - 120px);
}
.platfrom .platfromBox .platFromBoxText h3  {
    font-family: 'Rubik';
    font-weight: 300;
    font-size: 26px;
    line-height: 39px;
    text-transform: capitalize;
    color: #000000;
}
.platfrombg {
    position: absolute;
    top: 0px;
    left: 110px;
    content: "";
    background: url(@/assets/images/homePage-1/platformbg.png);
    width: 153px;
    height: 295px;
    background-repeat: no-repeat;
}
.platfrombgOne  {
    position: absolute;
    top: 0px;
    right: 80px;
    content: "";
    background: url(@/assets/images/homePage-1/platformbg1.png);
    width: 160px;
    height: 412px;
    background-repeat: no-repeat;
}

/* platfrom section end here */

/* app better section start here */
img{ max-width: 100%;}
.appBetter  {
    position: relative;
    margin-top: 160px;
}
.appBetter .appBetterCenter  {
    align-items: center;
    z-index: 1;
}
.appBetter .appBetterCenter .appBetterLeft img  {
    display: block;
}
.appBetterbg {
    position: absolute;
    top: 0px;
    left: 110px;
    content: "";
    background: url(@/assets/images/homePage-1/appbetter2.png);
    width: 787px;
    height: 767px;
    background-repeat: no-repeat;
    z-index: -1;
}
.appBetter .appBetterCenter .appBetterRight h2  {
    font-weight: 500;
    font-size: 63px;
    line-height: 76px;
    color: #222222;
}
.appBetter .appBetterCenter .appBetterRight h2 span  {
    color: #FF792E;
}
.appBetter .appBetterCenter .appBetterRight p  {
    font-weight: 300;
    font-size: 19px;
    line-height: 35px;  
    color: #515151;
    padding: 26px 0 46px 0;
}
.appBetter .appBetterCenter .appBetterRight a  {
    text-decoration: none;
    display: inline-block;
    font-weight: 500;
    font-size: 19px;
    line-height: 28px;
    background: #FFCF1F;
    border-radius: 50px;
    letter-spacing: 0.02em;
    color: #1C202B;
    padding: 13.6px 34px;
    transition: all ease-in-out 300ms;
}
.appBetter .appBetterCenter .appBetterRight a:hover  {
    background: #FF792E;
    color: #FFFFFF;
}
.appBetter .appBetterCenter .appBetterRight a span  {
    padding-left: 15px;
}
.appBetter .appBetterCenter .appBetterRight a span svg  {
    transition: all ease-in-out 300ms;
}

.footerLogo img {
  max-width: 100%;
}

/* app better section end here */

/* gain section start here */

.gain  {
    width: 100%;
    background: url(@/assets/images/homePage-1/allIcon.png) #191927;
    position: relative;
    margin-top: 300px;
    z-index: 0;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}
.gain .zindex  {
    position: relative;
    z-index: 1;
}
.gain::after  {
    position: absolute;
    top: 99%;
    left: 0px;
    content: "";
    background: url(@/assets/images/homePage-1/gainbackgroundBottom.png);
    width: 100%;
    height: 155px;
    background-repeat: repeat;
}
.gain::before  {
    position: absolute;
    top: -1%;
    left: 0px;
    content: "";
    background: url(@/assets/images/homePage-1/gainbackgroundTop.png);
    width: 100%;
    height: 155px;
    background-repeat: repeat;
}
.gain .gainHead  {
    text-align: center;
    padding-top: 60px;
}
.gain .gainHead h3  {
    font-weight: 300;
    font-size: 48px;
    line-height: 78px;
    text-align: center;
    color: #FFFFFF;
    padding-top: 15px;
}
.gain .gainHead h2  {
    font-weight: 600;
    font-size: 74px;
    line-height: 78px;
    text-align: center;
    color: #FFFFFF;
}
.gain .gainMainBox  {
    padding-top: 179px;
}
.gain .gainMainBox .gainMainBoxFlex  {
    align-items: center;
}
.gain .gainBox h4  {
    font-weight: 500;
    font-size: 54px;
    line-height: 55px;
    color: #FFFFFF;
    padding-bottom: 57px;
}
.gain .gainBox .gainOption  {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding-bottom: 42px;
}
.gain .gainBox .gainOption .optionImg  {
    width: 77px;
}
.gain .gainBox .gainOption .optionImg img  {
    display: block;
}
.gain .gainBox .gainOption .optionText  {
    width: calc(100% - 77px);
}
.gain .gainBox .gainOption .optionText p  {
    font-weight: 300;
    font-size: 20px;
    line-height: 29px;
    letter-spacing: 0.02em;
    color: #FFFFFF;
}
.gainBox a  {
    text-decoration: none;
    display: inline-block;
    background: #FEC33E;
    border-radius: 50px;
    font-weight: 400;
    font-size: 17px;
    line-height: 26px;
    letter-spacing: 0.02em;
    color: #000000;
    padding: 10px 35px;
    transition: all ease-in-out 300ms;
}
.gainBox a:hover  {
    background: #FF792E;
    color: #FFFFFF;
}
.gainImageBox .gainMainBoxFlex img  {
    display: block;
    position: relative;
}
.gainImageBox .gainMainBoxFlex .gainPadd  {
    padding-bottom: 30px;
}
.gainbp  {
    padding-bottom: 255px;
}
.gainMainBox .gainMainBoxFlex .gainImageBox  {
    position: relative;
}
.gainMainBox .gainMainBoxFlex .gainImageBox img  {
    width: 100%;
    display: block;
    z-index: 1;
    position: relative;
}
.gainMainBox .gainMainBoxFlex .gainImageBox .gaintimg  {
    padding-top: 90px;
}
.gainMainBox .gainMainBoxFlex .gainImageBox .gaintbimg  {
    padding-top: 310px;
}
.gainImageBox .gainMainBoxFlex .gainAfterYellow,
.gainMainBoxFlex .gainAfterblue,
.gainMainBoxFlex .gainAftergreen  {
    position: relative;
}
.gainImageBox .gainMainBoxFlex .gainAfterYellow::after {
    content: "";
    width: 211px;
    height: 211px;
    top: -82px;
    right: -70px;
    background: #FFAF2E;
    position: absolute;
    border-radius: 100px;
}
.gainMainBoxFlex .gainAfterblue::after {
    content: "";
    width: 211px;
    height: 211px;
    top: 215px;
    left: -88px;
    background: #007BFF;
    position: absolute;
    border-radius: 100px;
}
.gainMainBoxFlex .gainAftergreen::after {
    content: "";
    width: 211px;
    height: 211px;
    top: 215px;
    left: -88px;
    background: #26CBB3;
    position: absolute;
    border-radius: 100px;
}
.gainMainBox .leftArrow,
.gainMainBox .rightArrow  {
    position: relative;
}
.gainMainBox .leftArrow::before {
    position: absolute;
    bottom: -199px;
    left: -205px;
    content: "";
    background: url(@/assets/images/homePage-1/arrow1.png);
    width: 197px;
    height: 274px;
    background-repeat: no-repeat;
}
.gainMainBox .rightArrow::before {
    position: absolute;
    bottom: -226px;
    right: -169px;
    content: "";
    background: url(@/assets/images/homePage-1/arrow2.png);
    width: 215px;
    height: 309px;
    background-repeat: no-repeat;
}
.gainMainBoxFlex .gainNoneImage  {
    display: none;
    padding: 30px 10px 20px 10px;
}
.gainMainBoxFlex .gainNoneImage img  {
    width: 100%;
    display: block;
    margin: 0 auto;
}

/* gain section end here */

/* free sign section start here */

.freeSign  {
    position: relative !important;
}
.freeSign .freeSignBox  {
    max-width: 1324px !important;
    width: 100% !important;
    background-image: url(@/assets/images/homePage-1/signup.png);
    background-position: center !important;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    position: relative !important;
    margin: 0 auto 0 auto !important;
    padding: 65px 185px !important;
    border-radius: 16px !important;
}
.freeSign .freeSignBox h2  {
    font-weight: 300;
    font-size: 40px;
    line-height: 47px;
    text-align: center;
    color: #FFFFFF;
    margin: 0;
}
.freeSign .freeSignBox span  {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.freeSign .freeSignBox span a  {
    display: inline-block;
    font-weight: 500;
    font-size: 22px;
    line-height: 33px;
    letter-spacing: 0.02em;
    background: #FF8A00;
    border-radius: 50px;
    color: #FFFFFF;
    text-decoration: none;
    padding: 16px 59px;
    transition: all ease-in-out 300ms;
}
.freeSign .freeSignBox span a:hover  {
    background: #FEC33E;
    color: #FFFFFF;
}

/* free sign section end here */

/* news section start here */

.news  {
    padding-top: 123px;
}
.news .neWSbox  {
    text-align: center;
}
.news .neWSbox h2  {
    font-weight: 500;
    font-size: 42px;
    line-height: 63px;
    color: #191927;
    padding-bottom: 24px;
}
.news .neWSbox .newsInput  {
    max-width: 808px;
    margin: 0 auto;
    position: relative;
}
.news .neWSbox .newsInput .nInput  {
    width: 100%;
    height: 67px;
    background: #FFFFFF;
    border: 1px solid #C9C9C9;
    box-shadow: 0px 4px 28px rgba(0, 0, 0, 0.06);
    border-radius: 15px;
    font-weight: 300;
    font-size: 17px;
    line-height: 26px;
    letter-spacing: 0.02em;
    color: #717171;
    padding-left: 37px;
    position: relative;
}
.news .neWSbox .newsInput .nBtn  {
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    letter-spacing: 0.11em;
    text-transform: uppercase;   
    color: #FFFFFF;
    width: 159.28px;
    height: 50.17px;
    position: absolute;
    right: 9px;
    top: 9px;
    background: #0165FE;
    border-radius: 10px;
    border: none;
    outline: none;
}
.news .neWSbox .newsInput .nBtn:hover  {
    background: #FEC33E;
}

/* news section end here */

@media (max-width:1700px)  {
    .gainImageBox .gainMainBoxFlex .gainAfterYellow::after {
        top: -55px;
        right: -30px;
    }
}
@media (max-width:1600px)  {
    .gainImageBox .gainMainBoxFlex .gainAfterYellow::after {
        top: -55px;
        right: 10px;
    }
}
@media (max-width:1400px)  {
    header nav .headerRight .nav-link {
        padding: 0px 30px !important;
    }
    header nav .headerRight .navber-btn ul li a {
        padding: 7px 30px;
    }
    .homeBanner .homeBanText h1 {
        font-size: 56px;
    }
    .homeBanner .homeBanText h2 {
        font-size: 34px;
    }
    .homeBanner .homeBanText p {
        max-width: 650px;
        font-size: 18px;
        line-height: 27px;
        padding-bottom: 30px;
    }
    .homeBanner .homeBanText a {
        font-size: 17px;
        line-height: 28px;
        padding: 13.6px 34px;
    }
    .homeBanLeft {
        top: 248px;
        left: 10px;
    }
    .homeBanRight  {
        top: 248px;
        right: 10px;
    }

    .platfrom .platfromBox {
        padding: 0 20px;
    }
    .platfrom .platfromHead h2 {
        font-size: 52px;
    }
    .platfrom .platfromBox .platFromBoxText h3 {
       font-size: 20px;
       line-height: 28px;
    }

    .appBetter {
        position: relative;
        margin-top: 140px;
    }
    .appBetterbg   {
        left: 10px;
    }
    .appBetter .appBetterCenter .appBetterRight h2 {
        font-size: 56px;
        line-height: 73px;
    }
    .appBetter .appBetterCenter .appBetterRight p {
        font-size: 18px;
        line-height: 33px;
        padding: 20px 0 36px 0;
    }
    .appBetter .appBetterCenter .appBetterRight a {
        font-size: 18px;
        padding: 10.6px 32px;
    }

    .gain {
        margin-top: 360px;
    }
    .gain .gainBox h4 {
        font-size: 50px;
        padding-bottom: 50px;
    }
    .gain .gainBox .gainOption .optionText p {
        font-size: 18px;
        line-height: 27px;
    }
    .gainImageBox .gainMainBoxFlex .gainAfterYellow::after {
        right: 5px;
    }
    .gain {
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }
    .gain .gainHead h2 {
        font-size: 50px;
        line-height: 36px;
    }
    .gain .gainHead h3  {
        font-size: 40px;
    }
    .gain .gainMainBox {
        padding-top: 110px;
    }

    .freeSign .freeSignBox h2 {
        font-size: 36px;
        line-height: 44px;
    }
    .freeSign .freeSignBox span a {
        font-size: 20px;
        padding: 15px 50px;
    }

    .gainbp {
        padding-bottom: 130px;
    }

    
}

@media (max-width:1200px)  {
    .appBetter .appBetterCenter .appBetterLeft img {
        width: 100%;
    }
    
}

@media (max-width:1100px)  {
    .homeBanImage .homeBanImg  {
        padding: 0 50px;
    }
    .homeBanImage .homeBanImg img {
        width: 100%;
    }
    footer .footerLogo img {
        width: 100%;
    }

    .appBetterbg{ width:100%; height:100%; display: none;}
    .gain{margin-top:100px;}
   
}



@media (max-width:991px)  {
    .navToogle  {
        position: fixed;
        top: 0;
        right: -100%;
        background: #0B0B0B;
        width: 90%;
        bottom: 0;
        padding: 30px 0;
        transition: all 500ms ease-in-out ;
    }
    .navToogle.show{
        right: 0;
        transition: all 500ms ease-in-out ;
    }
    .toggleC {
        position: absolute;
        top: 20px;
        right: 50px;
        border: none;
        outline: none;
        background: none;
        color: #fff;
        font-size: 20px;
        color: #fff;
        display: block;
    }
    .navbar-light .navbar-toggler {
        color: rgb(255 255 255);
        border-color: none;
    }
    .navbar-toggler {
        line-height: 1;
        background-color: transparent;
        border: none;
        border-radius: 0rem;
    }
    .navbar-text  {
        display: none;
    }
    .navbar-light .navbar-toggler-icon {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(255, 255, 255, 1)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
    }
    .navbar {
        padding: .5rem 0rem;
    }
    .navbar-nav {
        padding: 0 10px;
    }
    .nav-item {
        border-bottom: 1px solid #2D2D2D;
        padding: 5px 0;
    }
    header {
        position: absolute;
        width: 100%;
        z-index: 1;
        z-index: 9999;
    }
    .navbar-nav {
        padding-bottom: 50px;
    }
    header nav .headerRight .nav-link {
        padding: 10px 30px !important;
    }
    .navToogle.show{
        right: 0;
        transition: all 500ms ease-in-out ;
    }

    .homeBanRight,
    .homeBanLeft,
    .platfrombgOne,
    .platfrombg,
    .appBetterbg,
    .gainMainBox .rightArrow::before,
    .gainMainBox .leftArrow::before,
    .gainMainBoxFlex .gainAfterblue::after,
    .gainMainBoxFlex .gainAftergreen::after,
    .gainImageBox .gainMainBoxFlex .gainAfterYellow::after,
    .gainDisNone {
        display: none;
    }
    .gain {
        background: #191927;
    }
    .flexGainReverse  {
        flex-direction: column-reverse;
    }

    .homeBanner .homeBanText {
        padding-top: 150px;
    }
    .homeBanner .homeBanText h1 {
        font-size: 40px;
        margin: 0;
    }
    .homeBanner .homeBanText h2 {
        font-size: 30px;
        margin: 0;
    }
    .homeBanner .homeBanText p {
        font-size: 16px;
        padding-bottom: 26px;
    }
    .homeBanner .homeBanText a {
        font-size: 16px;
        line-height: 23px;
        padding: 12.6px 30px;
    }
    .homeBanner {
        min-height: 881px;
    }

    .homeBanImage .homeBanImg img {
        margin: -382px auto 0 auto;
    }

    .platfrom {
        margin-top: 70px;
    }
    .platfrom .platfromHead h2 {
        font-size: 40px;
        padding-bottom: 36px;
    }

    footer .footerList ul li {
      width:auto;
      padding: 0 10px;
    }

    .footerLogo{ text-align: center;}

    .platfrom .platfromBox {
        padding: 15px 0 10px 15px;
    }
    .platfrom .platfromBox {
        border-right: none;
        border-top: 1px dashed #B3B3B3;
    }

    .appBetter {
        margin-top: 70px;
    }

    .appBetter .appBetterCenter .appBetterLeft img {
        width: unset;
        padding-bottom: 30px;
    }
    .appBetter .appBetterCenter .appBetterRight h2 {
        font-size: 36px;
        line-height: 30px;
    }
    .appBetter .appBetterCenter .appBetterRight p {
        font-size: 17px;
        line-height: 33px;
        padding: 10px 0 20px 0;
    }
    .appBetter .appBetterCenter .appBetterRight a {
        font-size: 16px;
        padding: 9.6px 30px;
    }

    .gain {
        margin-top: 120px;
    }
    .gain .gainHead {
        padding-top: 30px;
    }
    .gain .gainHead h3 {
        font-size: 34px;
        padding-top: 0px;
    }
    .gain .gainHead h2 {
        font-size: 40px;
        line-height: 10px;
    }
    .gain .gainMainBox {
        padding-top: 60px;
    }
    .gain .gainBox h4 {
        font-size: 36px;
        padding-bottom: 30px;
    }
    .gain .gainBox .gainOption {
        padding-bottom: 20px;
    }
    .gain .gainBox .gainOption .optionText p {
        font-size: 16px;
    }
    .gainBox a {
        margin: 20px 0 30px 0;
    }
    .gainMainBox .gainMainBoxFlex .gainImageBox .gaintimg {
        padding-top: 0px;
    }
    .gainMainBox .gainMainBoxFlex .gainImageBox .gaintbimg {
        padding-top: 0px;
    }
    .gainMainBoxFlex .gainNoneImage  {
        display: block;
    }

    .freeSign {
        margin: -43px 20px 0;
    }
    .freeSign .freeSignBox {
        padding: 50px 76px;
    }
    .freeSign .freeSignBox h2 {
        font-size: 26px;
        line-height: 37px;
    }

    .news {
        padding-top: 80px;
    }
    .news .neWSbox h2 {
        font-size: 36px;
        line-height: 46px;
    }

    footer {
        padding-top: 70px;
    }
    footer .footerIcon {
        text-align: left;
    }
    footer .footerIcon ul {
        padding-bottom: 20px;
        justify-content: flex-start;
    }
    footer .footerLogo img {
        width: 70%;
        margin: 0 auto;
        text-align: center;
    }

    footer .footerList ul {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
}



     
    
}

@media (max-width:667px)  {
    a.navbar-brand img {
        width: 70%;
    }
    .homeBanner .homeBanText {
        padding-top: 100px;
    }
    .homeBanner .homeBanText h1 {
        font-size: 22px;
        line-height: 26px;
        padding-bottom: 0;
    }
    .homeBanner .homeBanText h2 {
        font-size: 22px;
        line-height: 44px;
    }
    .homeBanner .homeBanText p {
        font-size: 15px;
        padding-bottom: 16px;
    }
    .homeBanner .homeBanText a {
        font-size: 15px;
        line-height: 13px;
        padding: 10.6px 14px;
    }
    .homeBanner .homeBanText a span {
        padding-left: 20px;
    }
    .homeBanner .homeBanText a span svg  {
        width: 16px;
        height: 16px;
    }
    .homeBanner {
        min-height: 740px;
    }
    .homeBanner {
        min-height: 600px;
    }
    .homeBanImage .homeBanImg img {
        margin: -235px auto 0 auto;
    }
    .platfrom {
        margin-top: 60px;
    }
    .platfrom .platfromHead h2 {
        font-size: 34px;
        padding-bottom: 20px;
    }
    /* .platfrom .platfromBox {
        padding: 0 0 0px 15px;
    } */
    .appBetter .appBetterCenter .appBetterLeft img {
        width: 100%;
    }
    .appBetter {
        margin-top: 40px;
    }
    .appBetter .appBetterCenter .appBetterRight h2 {
        font-size: 30px;
    }
    .appBetter .appBetterCenter .appBetterRight p {
        font-size: 14px;
        line-height: 30px;
        padding: 10px 0 15px 0;
    }
    .appBetter .appBetterCenter .appBetterRight a {
        font-size: 15px;
        padding: 7.6px 20px;
    }
    .appBetter .appBetterCenter .appBetterRight a span svg  {
        width: 16px;
        height: 16px;
    }
    .gain {
        margin-top: 80px;
    }
    .gain .gainHead h3 {
        font-size: 24px;
    }
    .gain .gainHead h2 {
        font-size: 30px;
        line-height: 37px;
    }
    .gain .gainMainBox {
        padding-top: 36px;
        padding-bottom: 15px;
    }
    .gain .gainBox h4 {
        font-size: 30px;
        padding-bottom: 20px;
    }
    .gainBox a {
        font-size: 15px;
        padding: 6px 26px;
    }
    .freeSign .freeSignBox h2 {
        font-size: 22px;
        line-height: 32px;
    }
    .freeSign .freeSignBox {
        padding: 40px 20px;
    }
    .freeSign .freeSignBox span a {
        font-size: 16px;
        padding: 9px 32px;
    }
    .freeSign {
        margin: 30px 20px 0;
    }
    .news {
        padding-top: 58px;
    }
    .news .neWSbox h2 {
        font-size: 20px;
        line-height: 30px;
    }
    .news .neWSbox .newsInput .nInput {
        height: 57px;
    }
    .news .neWSbox .newsInput .nBtn {
        font-size: 14px;
        width: 110.28px;
        height: 44.17px;
        right: 5px;
        top: 6px;
    }
    .news .neWSbox .newsInput .nInput {
        font-size: 14px;
        padding-left: 15px;
    }
    footer {
        padding-top: 40px;
    }
    footer .footerLogo img {
        width: 70%;
    }
    footer .footerList ul li {
        padding-bottom: 17px;
        width: 50%;
    }
    footer .footerList ul li a {
        font-size: 15px;
    }
    footer .footerIcon p {
        font-size: 12px;
    }
    footer .footerIcon ul {
        padding-bottom: 15px;
    }
    footer .footerIcon ul li {
        padding-left: 0px;
        padding-right: 10px;
    }
    .gainBox {
        padding-left: 20px;
    }
    footer .footerIcon {
        padding-left: 20px;
    }
    footer .footerList {
        padding-left: 20px;
    }

    .freeSign .freeSignBox{padding: 45px 45px 65px !important;}
    .freeSign .freeSignBox span{ width: 175px;}
}



@media (max-width:540px) {
    .homeBanner {
        min-height: 525px;
    }
    .homeBanImage .homeBanImg img {
        margin: -193px auto 0 auto;
    }
    .platfrom .platfromHead h2 {
        font-size: 28px;
        padding-bottom: 15px;
    }
    .platfrom .platfromBox .platFromBoxText h3 {
        font-size: 22px;
    }
    .appBetter .appBetterCenter .appBetterRight h2 {
        font-size: 26px;
    }
    .appBetter .appBetterCenter .appBetterRight p {
        font-size: 12px;
        line-height: 24px;
        padding: 8px 0 12px 0;
    }
    .appBetter .appBetterCenter .appBetterRight a {
        font-size: 14px;
        padding: 4.6px 16px;
    }
    .gain .gainHead h3 {
        font-size: 20px;
    }
    .gain .gainHead h2 {
        font-size: 26px;
        line-height: 10px;
        line-height: 33px;
    }
    .gain .gainBox h4 {
        font-size: 22px;
        padding-bottom: 10px;
    }
    .gain .gainBox .gainOption .optionText p {
        font-size: 14px;
    }
    .gainBox a {
        font-size: 14px;
        padding: 5px 25px;
    }
    .freeSign .freeSignBox h2 {
        font-size: 20px;
        line-height: 29px;
    }
    .freeSign .freeSignBox {
        padding: 40px 5px;
    }
    .freeSign .freeSignBox span a {
        font-size: 14px;
        padding: 6px 21px;
    }
   
}

@media (max-width:400px)  {
    .homeBanImage .homeBanImg img {
        margin: -125px auto 0 auto;
    }
}




</style>