const mutations = {
  
    SET_REVIEWS: (state, reviews) => {
        state.reviews = reviews;
      },

      SET_TOPAPPREVIEWS: (state, topappreviews) => {
        state.topappreviews = topappreviews;
      }, 

   
};

export default mutations;