<template>
 <div class="ful-wdth">
   <div class="tab_boxe">
        <h2>Ad: keyword</h2>

        <div class="adsByAppReportProfile">
            <div class="adsByAppReportProfileImage">
                <div class="imgbox">
                    <img src="@/assets/images/profile/proimg.jpg">
                </div>
                <div class="Textbox">
                    <h3>Foridev - Quick Shopping List</h3>
                    <h4><span>by</span> Foridev</h4>
                    <p>How to bundle products, Chained Products, Grouped Products</p>
                    <h5>
                        <span><svg xmlns="http://www.w3.org/2000/svg" width="15" height="14" fill="#FEC33E" class="bi bi-star-fill" viewBox="0 0 16 16">
                            <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path>
                          </svg></span>
                        4.9 of 5 Stars (1275)</h5>
                    <a href="#">10-day free trial</a>    
                </div>
            </div>
            <div class="adsByAppReportProfileBtn">
                <a href="#">Download CSV</a>
            </div>
        </div>

        <div class="adsReportZeroBox">
            <p>This request hasn't process yet, 
                Please check back in a few hours to see your report.</p>
        </div>

       
      </div>
 </div>
</template>