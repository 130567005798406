import axios from 'axios'
import Toastr from 'toastr'
import 'toastr/build/toastr.css'

const url = process.env.VUE_APP_API_BASE_URL;
const actions = {

    getTotalAps (store, params) {
        return new Promise(function(resolve, reject) { 
            axios.get(`${url}wp/v2/getapptotal`, { params }).then((resp) => { 
                store.commit('SET_TOTALAPPS', resp.data.totalaps);
                store.commit('SET_TOTALDEVELOPER', resp.data.totaldeveloper);
                store.commit('SET_TOTALREVIEWS', resp.data.totalreviews);

            }).catch((err) => {
                // Toastr.handleServerError(err);
                console.log(err)
            });
        });

    },


    getLatestedApp (store, params) {
        return new Promise(function(resolve, reject) { 
            axios.get(`${url}wp/v2/getlatestedapp`, { params }).then((resp) => { 
                store.commit('SET_LASTADDED10APS', resp.data);
            }).catch((err) => {
                // Toastr.handleServerError(err);
                console.log(err)
            });
        });
    },

    getAppslist (store, params) {
        return new Promise(function(resolve, reject) { 
            axios.get(`${url}wp/v2/applist`, { params }).then((resp) => { 
                store.commit('SET_LATESTAPS', resp.data);
            }).catch((err) => {
                // Toastr.handleServerError(err);
                console.log(err)
            });
        });

    },

    getDashboardnewestapplist (store, params) {
        return new Promise(function(resolve, reject) { 
            axios.get(`${url}wp/v2/dashboardnewestapplist`, { params }).then((resp) => { 
                store.commit('SET_DASHBOARDNEWESTAPP', resp.data);
            }).catch((err) => {
                // Toastr.handleServerError(err);
                console.log(err)
            });
        });
    },

    getDashboardmostinstallsaps (store, params) {
        return new Promise(function(resolve, reject) { 
            axios.get(`${url}wp/v2/getDashboardmostinstallsaps`, { params }).then((resp) => { 
                store.commit('SET_DASHBOARDMOSTINSTALLAPP', resp.data);
            }).catch((err) => {
                // Toastr.handleServerError(err);
                console.log(err)
            });
        });
    },

    getDashboardRelevantAps (store, params) {
        return new Promise(function(resolve, reject) { 
            axios.get(`${url}wp/v2/getDashboardRelevantAps`, { params }).then((resp) => { 
                store.commit('SET_DASHBOARDRELEVENTAPP', resp.data);
            }).catch((err) => {
                // Toastr.handleServerError(err);
                console.log(err)
            });
        });
    },

    getDashboarduserFollowAps(store, params) {
        return new Promise(function(resolve, reject) { 
            axios.get(`${url}wp/v2/getDashboarduserFollowAps`, { params }).then((resp) => { 
                store.commit('SET_DASHBOARDUSERFOLLOWAPP', resp.data);
            }).catch((err) => {
                // Toastr.handleServerError(err);
                console.log(err)
            });
        });
    },

    getDashboarMostreviewaddAps (store, params) {
        return new Promise(function(resolve, reject) { 
            axios.get(`${url}wp/v2/getDashboarMostreviewaddAps`, { params }).then((resp) => { 
                store.commit('SET_DASHBOARDMOSTREVIEWAPP', resp.data);
            }).catch((err) => {
                // Toastr.handleServerError(err);
                console.log(err)
            });
        });
    },





    getAppCategory (store, params) {
        var categorylist=[];
        categorylist.push({'category_id':'','category_title':'Select Category','category_description':''})
        return new Promise(function(resolve, reject) { 
            axios.get(`${url}wp/v2/appcat`, { params }).then((resp) => {
                for(var i=0;i<resp.data.length;i++)
                categorylist.push(resp.data[i]);
                store.commit('SET_CATEGORY', categorylist);
            }).catch((err) => {
                console.log(err)
            });

        });
    },

    getAppSubCategory (store, params) {
        var subcategorylist=[];
        subcategorylist.push({'category_id':'','category_title':'Select Subcategory','category_description':''})
        return new Promise(function(resolve, reject) { 
            axios.get(`${url}wp/v2/appsubcategory`, { params }).then((resp) => {
                for(var i=0;i<resp.data.length;i++)
                subcategorylist.push(resp.data[i]);
                store.commit('SET_SUBCATEGORY', subcategorylist);
            }).catch((err) => {
                console.log(err)
            });

        });
    },

    getLast30daysapsbycategory(store, params){
        return new Promise(function(resolve, reject) { 
            axios.get(`${url}wp/v2/30daysapsbycategory`, { params }).then((resp) => {
                //console.log('30daysapsbycategory',resp)
                store.commit('SET_LAST30DAYSAPPBYCAT', resp.data);
            }).catch((err) => {
                // Toastr.handleServerError(err);
                console.log(err)
            });
        });
    },



    UserDashboardData (store, params) {
        return new Promise(function(resolve, reject) { 
            axios.get(`${url}wp/v2/dashboard`, { params }).then((resp) => {
                //console.log('listData2',resp.data.addedappspercat)
                store.commit('SET_LAST30DAYSAPPBYCAT', resp.data.addedappspercat);
                store.commit('SET_LASTADDED10APS', resp.data.lastadded10app);
                store.commit('SET_TOPAPPREVIEWS', resp.data.topratedreviews);
            }).catch((err) => {
                // Toastr.handleServerError(err);
                console.log(err)
            });
        });

    },

    PaidandFreeData (store, params) {
        return new Promise(function(resolve, reject) { 
            axios.get(`${url}wp/v2/freeandpaidapps`, { params }).then((resp) => {
                console.log('listData2',resp.data) 
                store.commit('SET_PAIDANDFREEDATA', resp.data);
            }).catch((err) => {
                // Toastr.handleServerError(err);
                console.log(err)
            });
        });

    },

    AppsLinechartData (store, params) {
        return new Promise(function(resolve, reject) { 
            axios.get(`${url}wp/v2/applinechartdata`, { params }).then((resp) => {
                store.commit('SET_APPSLINECHARTDATA', resp.data);
            }).catch((err) => {
                // Toastr.handleServerError(err);
                console.log(err)
            });
        });

    },

    getapplistByReviews(store, params){
        return new Promise(function(resolve, reject) { 
            axios.get(`${url}wp/v2/applistbyreviews`, { params }).then((resp) => {                 
                console.log('mostviewdata',resp)        
                store.commit('SET_APPBYREVIEWS', resp.data);
            }).catch((err) => {
                // Toastr.handleServerError(err);
                console.log(err)
            });
        });   
    },

    getapplistByStoreRank(store, params){
        return new Promise(function(resolve, reject) { 
            axios.get(`${url}wp/v2/appliststoreranking`, { params }).then((resp) => { 
                store.commit('SET_APPLISTBYSTORERANK', resp.data);
                //store.commit('SET_TOTALAPPSTOREAPP', resp.data.datatotal);
            }).catch((err) => {
                // Toastr.handleServerError(err);
                console.log(err)
            });
        });   
    },

   


    getapplistBymostinstallrank(store, params){
        return new Promise(function(resolve, reject) { 
            axios.get(`${url}wp/v2/applistmostinstallrank`, { params }).then((resp) => {                 
                store.commit('SET_MOSTINSTALLAPPRANKS', resp.data);
            }).catch((err) => {
                // Toastr.handleServerError(err);
                console.log(err)
            });
        });   
    },


    applistbymostreviewadded(store, params){
        return new Promise(function(resolve, reject) { 
            axios.get(`${url}wp/v2/applistbymostreviewadded`, { params }).then((resp) => {  
                store.commit('SET_APPBYMOSTADDEDREVIEW', resp.data);
            }).catch((err) => {
                // Toastr.handleServerError(err);
                console.log(err)
            });
        });   
    },


    BigGainersrankapps(store, params){
        return new Promise(function(resolve, reject) { 
            axios.get(`${url}wp/v2/BigGainersrankapps`, { params }).then((resp) => { 
                store.commit('SET_BIGGANNERSAPPLIST', resp.data);
            }).catch((err) => {
                // Toastr.handleServerError(err);
                console.log(err)
            });
        });   
    },

    UserFollowApsDetails(store, params){
        return new Promise(function(resolve, reject) { 
            axios.get(`${url}wp/v2/userfollowingappslist`, { params }).then((resp) => {  
                //console.log('Following',resp)
                store.commit('SET_USERFOLLOWAPPSLIST', resp.data.list);
                store.commit('SET_USERFOLLOWAPSTOTAL', resp.data.datatotal);
            }).catch((err) => {
                // Toastr.handleServerError(err);
                console.log(err)
            });
        });   
    },

    CategoryWiseapsinventory(store, params){
        return new Promise(function(resolve, reject) { 
            axios.get(`${url}wp/v2/categorywiseapstotal`, { params }).then((resp)=>{
               // console.log('CATEGORYINVENTORY',resp)         
                store.commit('SET_TOTALAPSCOUNTBYCAT', resp.data.totalaps);
                store.commit('SET_CATEGORYWISEAPSTOTAL', resp.data.result);
            }).catch((err) => {
                // Toastr.handleServerError(err);
                console.log(err)
            });
        });   
    },

    PopoularAndResentApp(store, params){
        return new Promise(function(resolve, reject) { 
            axios.get(`${url}wp/v2/popoularandresentaps`, { params }).then((resp)=>{
                store.commit('SET_RESENTAPS', resp.data.resentaps);
                store.commit('SET_POPULARAPS', resp.data.popoularaps);
            }).catch((err) => {
                // Toastr.handleServerError(err);
                console.log(err)
            });
        });   
    },


    GetApphistorybyappid(store, params){
        return new Promise(function(resolve, reject) { 
            axios.get(`${url}wp/v2/apphistorybyappid`, { params }).then((resp)=>{
                console.log('appcategory',resp.data.appcategoryhistory)
                store.commit('SET_APPHISTORY', resp.data.details);
                store.commit('SET_DEVLOPERHISTORY', resp.data.developers);
                store.commit('SET_RELATEDAPSLIST', resp.data.relatedaps);
                store.commit('SET_APPCATEGORYHISTORY', resp.data.appcategoryhistory);
                store.commit('SET_4WEEKREVIEWTOTALSINGLEAPP', resp.data.apps4weekreview);
                store.commit('SET_4WEEKRATTINGINGLEAPP', resp.data.apps4weekratting);
                store.commit('SET_4WEEKAPPMOSTANDOVERALLDATA', resp.data.apprankmostoverall);
            }).catch((err) => {
                // Toastr.handleServerError(err);
                console.log(err)
            });
        });   
    },

    Getreletedapspricingtire(store, params){
        return new Promise(function(resolve, reject) { 
            axios.get(`${url}wp/v2/getreletedapspricingtire`, { params }).then((resp)=>{
                store.commit('SET_RELATEDAPSPRICETIRE', resp.data);
                console.log('relatedpricingapsfeture',resp.data)
            }).catch((err) => {
                // Toastr.handleServerError(err);
                console.log(err)
            });
        });   
    },


  
    
    GetAppchartdatabyappid(store, params){
        return new Promise(function(resolve, reject) { 
            axios.get(`${url}wp/v2/appgraphandchartdata`, { params }).then((resp)=>{
                store.commit('SET_PICHARTDATA', resp.data.pichartdata);
                store.commit('SET_LINEHARTDATA1', resp.data.linechartdata1);
                store.commit('SET_CHARTRATTINGDATA', resp.data.rattingchart);                
            }).catch((err) => {
                // Toastr.handleServerError(err);
                console.log(err)
            });
        });   
    },

    GetAppprichingfeture(store, params){
        return new Promise(function(resolve, reject) { 
            axios.get(`${url}wp/v2/apppricingfeature`, { params }).then((resp)=>{
                store.commit('SET_PRICEFETUREDATA', resp.data);             
            }).catch((err) => {
                console.log(err)
            });
        });   
    },

    GetAllAppkeyWords(store, params){
        return new Promise(function(resolve, reject) { 
            axios.get(`${url}wp/v2/getappskeywords`, { params }).then((resp)=>{
                store.commit('SET_ALLKEYWORDS', resp.data);          
            }).catch((err) => {
                // Toastr.handleServerError(err);
                console.log(err)
            });
        });   
    },

    GetAllkeywordByuser(store, params){
        return new Promise(function(resolve, reject) { 
            axios.get(`${url}wp/v2/getuserfollowkeywords`, { params }).then((resp)=>{
                store.commit('SET_ALLKEYWORDSBYUSERID', resp.data);          
            }).catch((err) => {
                // Toastr.handleServerError(err);
                console.log(err)
            });
        });   
    },
    
    Insertuserfollowkeywords(store, params){
       // store.commit('SET_FORM_LOADER_STATUS', true);
        axios.post(`${url}wp/v2/insertuserkeword`, params).then((resp) => {
            if(resp.data.code==400)
               Toastr.success(resp.data.message);
            else 
               Toastr.error(resp.data.message);  

         }).catch((err) => {
            console.log(err)
        });
    },


    Removeuserfollowkeywords(store, params){
        return new Promise(function(resolve, reject) { 
            axios.get(`${url}wp/v2/removeuseraddedkeword`, { params }).then((resp)=>{
                //store.commit('SET_ALLKEYWORDS', resp.data);
                Toastr.success(resp.data.message);
            }).catch((err) => {
                // Toastr.handleServerError(err);
                console.log(err)
            });
        });   
    },

    SearchAppbykeyword(store, params){
        return new Promise(function(resolve, reject) { 
         axios.get(`${url}wp/v2/applistwithoutuserfollow`, {params}).then((resp) => {
            store.commit('SET_SEARCHAPPSRESULT', resp.data); 
          }).catch((err) => {
             console.log(err)
         });
        });  
     },

     SearchresultBykeyword(store, params){
        return new Promise(function(resolve, reject) { 
         axios.get(`${url}wp/v2/searchresult`, {params}).then((resp) => {
            
            store.commit('SET_KEYWORDSEARCHRESULT', resp.data.data); 
            store.commit('SET_KEYWORDSEARCHTOTAL', resp.data.searchtotal); 
            store.commit('SET_SEARCHRESULTTYPE', resp.data.type); 
          }).catch((err) => {
             console.log(err)
         });
        });  
     },

     GetAppDeveloperList(store, params){
        return new Promise(function(resolve, reject) { 
         axios.get(`${url}wp/v2/devloperlist`, {params}).then((resp) => {
            store.commit('SET_DEVELOPERLIST', resp.data); 
          }).catch((err) => {
             console.log(err)
         });
        });  
     },


     CategoryWithChildcategory(store, params){
        return new Promise(function(resolve, reject) { 
         axios.get(`${url}wp/v2/categorywithchild`, {params}).then((resp) => {
            store.commit('SET_CATEGORYWWITHCHILDCATEGORY', resp.data); 
            console.log('categorydetails',resp.data)
          }).catch((err) => {
             console.log(err)
         });
        });  
     },


     GetAppListByDeveloperId(store, params){
        return new Promise(function(resolve, reject) { 
         axios.get(`${url}wp/v2/applistbydevloperid`, {params}).then((resp) => {
            store.commit('SET_DEVELOPERSAPPAS', resp.data.results); 
            store.commit('SET_DEVELOPERSTOTALAPPAS', resp.data.totaldata); 
          }).catch((err) => {
             console.log(err)
         });
        });  
     },

    Getdeveloperdetails(store, params){
        return new Promise(function(resolve, reject) { 
         axios.get(`${url}wp/v2/getdeveloperdetails`, {params}).then((resp) => {
           store.commit('SET_DEVELOPERDETAILS', resp.data); 
          }).catch((err) => {
             console.log(err)
         });
        });  
     },
    
     GetApsListbyekeywordsearch(store, params){
        return new Promise(function(resolve, reject) { 
         axios.get(`${url}wp/v2/getapplistbyekeyword`, {params}).then((resp) => {
            store.commit('SET_APPLISTBYKEYWORDS', resp.data.result); 
            store.commit('SET_KEYWORDMAPDATA', resp.data.mapchartdata); 
          }).catch((err) => {
             console.log(err)
         });
        });  
     },

     GetApsforComparison(store, params){
        return new Promise(function(resolve, reject) { 
         axios.get(`${url}wp/v2/getapplistforcomparison`, {params}).then((resp) => {
            store.commit('SET_COMPARISONAPPSLIST', resp.data);
          }).catch((err) => {
             console.log(err)
         });
        });  
     },

     GetComparisonAppsdata(store, params){
        console.log('params',params);  
        return new Promise(function(resolve, reject) { 
         axios.get(`${url}wp/v2/getcomparisonappsdata`, {params}).then((resp) => {
            console.log('COMPEAR APP HISTORYCATDATA',resp.data.comparisonchratdataoption);  
            store.commit('SET_COMPARISONAPPSDATA', resp.data.resultdata);
            store.commit('SET_COMPARISONAPPSDATAPLANE', resp.data.planedetails);
            store.commit('SET_COMPARISONCHARTDATAOPTION', resp.data.comparisonchratdataoption);
            store.commit('SET_COMPARISONCHARTDATALABEL', resp.data.chartdatalabels);
            store.commit('SET_CATEGORYSBYAPPIDS', resp.data.appcategorylist);
          }).catch((err) => {
             console.log(err)
         });
        });  
     },



     GetComparisonchartOptioncatdataByAppid(store, params){
        return new Promise(function(resolve, reject) { 
         axios.get(`${url}wp/v2/getcomparisonchartoptioncatdata`, {params}).then((resp) => {
            //console.log('COMPEAR APP HISTORY LENGTH',resp.data.length);  
            //console.log('COMPEAR APP HISTORYCATDATA',resp.data);  
            store.commit('SET_COMPARISONCHARTCATOPTIONS', resp.data);
          }).catch((err) => {
             console.log(err)
         });
        });  
     },

     GetAllAppsWithoutpagination(store, params){
        return new Promise(function(resolve, reject) { 
         axios.get(`${url}wp/v2/GetAllAppsWithoutpagination`, {params}).then((resp) => {
            store.commit('SET_APPSWITHOUTPAGINATION', resp.data.applist);
            store.commit('SET_DEVELOPERSWITHOUTPAGINATION', resp.data.devloperlist);
          }).catch((err) => {
             console.log(err)
         });
        });  
     },

     ChangeDefultCss(store, params){
        store.commit('SET_DEFULTMODECSSFILE', params);
     },


     GetAppChangelogHistory(store, params){
        return new Promise(function(resolve, reject) { 
            axios.get(`${url}wp/v2/GetAppChangelogHistory`, { params }).then((resp)=>{
                console.log('COMPEAR APP HISTORYCATDATA',resp.data);  
                store.commit('SET_APPCHANGELOGDATA', resp.data.changehistory);
                store.commit('SET_APPRANKRELATEDWITHDATE', resp.data.rankchange);
                store.commit('SET_KEYBENIFITDETAILS', resp.data.keybenifitdetails);
            }).catch((err) => {
                // Toastr.handleServerError(err);
                console.log(err)
            });
        });   
    },

    GetAppCurrentdetails(store, params){
        return new Promise(function(resolve, reject) { 
            axios.get(`${url}wp/v2/GetAppCurrentdetails`, { params }).then((resp)=>{
                console.log('COMPEAR APP HISTORYCATDATA',resp.data);  
                store.commit('SET_APPCURRENTDATA', resp.data);
            }).catch((err) => {
                // Toastr.handleServerError(err);
                console.log(err)
            });
        });   
    },

};

export default actions;