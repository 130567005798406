const state = {
    defultmode:false,
    latestaps: [],
    dashboardlatestaps: [],
    dashboardmostinstallaps: [],
    getdashboardrelevantaps:[],
    getdashboarduserfollowaps:[],
    dashboardmostreviewaddaps:[],
    categorywithchildcategory:[],
    appcategoryhistory:[],
    totalapps: 0,
    apppricingfeture:[],
    totalappstoreapps: 0,
    totalmostinstallapps: 0,
    totalmostrivewapps: 0,
    totalbigginerapps: 0,
    totalrivewaddedapps: 0,
    datatotal: 0,
    categorylist: [],
    current30daysapps:[],
    lastadded10app:[],
    appbymostaddedreviews:[],
    statusTabsLoader: false,
    totaldevloper: 0,
    subcategorylist: [],   
    paidfreedata: [],
    appchartlinedata:[],
    getapplistbyreview:[],
    getapplistbystorerank:[],
    mostinstallrankingaps:[],
    totaldatacount:0,
    totalreviews:0,
    getbiggainersapplist:[],
    getuserfollowapplist:[],
    userfollowapptotal:0,
    totalapscountbycat:0,
    apscountcategorywise:[],
    popoularaps:[],
    resentapps:[],
    apprankmostoverall:[],
    allapslistwithoutpagination:[],
    alldeveloperwithoutpagination:[],

    apphistory:[],
    devloperhistory:[],
    relatedaps:[],
    relatedapspricetire:[],
    last4weekreview:[],
    last4weekratting:[],
    picchartdata:[],
    linechartdata1:[],
    rattingdata:[],
    allkeywords:[],
    alluserfollowkeywords:[],
    searchAppresult:[],
    keywordsearchresult:[],
    searchtotaldata:0,
    searchresulttype:'',

    developerlist:[],
    totaldeveloper:0,
    developerapplist:[],
    totaldeveloperapp:0,
    developerdetails:'',
    applistbykeywords:[],
    keywordmapdata:[],
    comparisonappsearch:[],
    comparisonappsdata:[],
    comparisonappsdataplane:[],
    comparisonchratdataoption:[],
    comparisonchratdatacategoryoption:[],
    comparisonchratdatalabels:[],
    categorylistrelatedwithappids:[],

    appchangelogdata:'[]',
    appcurrentdata:[],
    appranddatewise:[],
    keybenifitdetails:[],
    


    
} 
export default state;