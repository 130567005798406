<template>
 <div class="col-xl-6 col-lg-12">
           <div class="tbl-head">
             <p green >Biggest Gainers </p>
             <h6><router-link :to="'/BigGainer/'">most installs ranking In The Last 7 Days</router-link></h6>
          </div>
          
          <div class="resp-table">
            <table class="whitgren-table">
              <thead>
                <tr>
                  <th>Rank</th>
                  <th>App Logo</th>
                  <th>App Name</th>
                  <th>Rating</th>
                  <th>Change</th>
                </tr>
              </thead>
              <tbody v-if="appslistbymostinstallrank.length>0">
                <tr v-bind:key="result.app_id" v-for="(result) in appslistbymostinstallrank.slice(0,5)">
                  <td>{{result.mostinstallrank}}</td>
                  <td style="text-align: center;">
                    <router-link :to="'/about-details/' + result.app_id"><img :src=result.app_logo></router-link>
                  </td>
                  <td><router-link :to="'/about-details/' + result.app_id">{{result.app_title}}</router-link> </td>

                  <td> {{result.app_rating}}</td>
                  <td>
                    {{parseInt(result.rankchange)>0 ?'+':''}} {{result.rankchange}} 
                     <svg v-if="parseInt(result.rankchange)>0" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#44D880" class="bi bi-arrow-up" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z"/>
                  </svg>
                  
                  <svg v-if="parseInt(result.rankchange)==0" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#3CAEA3" class="bi blue bi-arrow-down-up" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M11.5 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L11 2.707V14.5a.5.5 0 0 0 .5.5zm-7-14a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L4 13.293V1.5a.5.5 0 0 1 .5-.5z">
                  </path></svg>


                  <svg v-if="parseInt(result.rankchange)<0" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#44D880" class="bi bi-arrow-down" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z"/>
                  </svg>

                  
                  
                  </td>
                  </tr>
                
              </tbody>
               <tbody v-else>
                <tr><td colspan="5">Sorry! no data found.</td></tr>
              </tbody>
            </table>
          </div>
        </div>
</template>


<script>
import {mapState} from 'vuex'
import moment from "moment";
export default {
  name: 'RankingTable',
  data() {
    return {
        istopcardload:false,
        moment: moment,
        startindex:1
    };
  },
 // lastadded10apps: state => state.AppListModule.lastadded10app,
   computed: {
      ...mapState({
           appslistbymostinstallrank: (state) => state.AppListModule.dashboardmostinstallaps,
      }),
  },
   mounted() { 
     this.istopcardload=true; 
  },

   methods:{
     int(){          
      this.istopcardload=true;     
    }    
  }, 
  watch: {
        istopcardload(newval,oldval) {
        this.int();      
       },
  }  
}
</script>

