<template>
  <div class="hello" v-if="todoLisloginflug" >
     <header>
        <button class="navbar-collapse" @click="toggle" type="button" id="navCollapseNav"></button>

        <div class="ite_sm">
          <h5>Membership Plan: <strong>{{user.data.membershiptype}}</strong></h5>
            <router-link :to="'/accountmanageprofilem/?tab=upgrade'" class="btn">Upgrade</router-link>
        </div>

        <div class="ite_sm">
          <!-- <form action="#">
            <div class="form-group">
              <input type="search" class="form-control" placeholder="Search app name or developer">
              <button type="submit"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-search" viewBox="0 0 16 16">
                <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
              </svg></button>
            </div>
          </form> -->

          <div class="form-group">
             <Form @submit="submitClick" > 
              <Field autocomplete="off" @input="searchappkeuword" v-on:keydown="returnval" type="text" v-model="searchTerm" id="myInput" name="searchTerm"  class="simple-typeahead-input headersearch form-control" placeholder="Search app name or developer" />
         


              <ul :class="(searcappslist.length>0 || searcdeveloperslist.length>0)?'serchresult':''" id="autocomplete-list">

              <li v-for="appdata in searcappslist" :key="appdata.app_title" data-type="apps" :data-id="appdata.app_id"  @click="selectappsforsearch(appdata.app_id,'apps',appdata.app_title)">
                <div class="compayarpps header-searching"><div class="img-sec"><img :src="appdata.app_logo"></div><div class="text-part"><h3>{{ appdata.app_title }}</h3>
<p>{{appdata.app_rating}} of 5 stars ({{appdata.app_num_of_reviews}})</p></div></div>
                   
              </li> 

                <li v-for="appdata in searcdeveloperslist" :key="appdata.dev_name" data-type="devloper" :data-id="appdata.dev_id"  @click="selectappsforsearch(appdata.dev_id,'devloper',appdata.dev_name)">
                <div class="compayarpps header-searching"><div class="text-part"><h3>{{ appdata.dev_name }}</h3>
<p>{{appdata.no_of_developer_app}}</p></div></div>
                   
              </li> 


              </ul>
              <button type="submit" id="globalsearch"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-search" viewBox="0 0 16 16">
              <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
              </svg></button>
             <!-- </form> -->
              </Form>        
          </div>



          <div class="notify">
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-bell" viewBox="0 0 16 16">
              <path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zM8 1.918l-.797.161A4.002 4.002 0 0 0 4 6c0 .628-.134 2.197-.459 3.742-.16.767-.376 1.566-.663 2.258h10.244c-.287-.692-.502-1.49-.663-2.258C12.134 8.197 12 6.628 12 6a4.002 4.002 0 0 0-3.203-3.92L8 1.917zM14.22 12c.223.447.481.801.78 1H1c.299-.199.557-.553.78-1C2.68 10.2 3 6.88 3 6c0-2.42 1.72-4.44 4.005-4.901a1 1 0 1 1 1.99 0A5.002 5.002 0 0 1 13 6c0 .88.32 4.2 1.22 6z"/>
            </svg>
            <span class="badge-nofy">5</span>
          </div>
          <div class="prof">
            <div class="dropdown">
     <button type="button" class="dropdown-toggle" data-bs-toggle="dropdown">
      <img class="profileimg_cer" v-if="user.data.avatar_url!=''" :src="user.data.avatar_url" alt=""> 
      <img v-else src="@/assets/images/profile/Group123.png" alt="">
      <span>{{user.data.display_name}}</span>   
    </button>
      <ul class="dropdown-menu">
        <router-link to="/accountmanageprofilem" class="dropdown-item">My Account</router-link>
       <div class="dropdown-divider"></div>
      <a class="dropdown-item" @click="logoutLogedUser" style="cursor: pointer;">Logout</a>
      </ul>
</div>
          </div>
        </div>
      </header> 
      <div class="feedback-side">
          <button type="button" data-bs-toggle="modal" data-bs-target="#writereviewfrom">
            <a href="#" class="dn-mob">FEEDBACK</a>
            </button>
        <!-- <router-link to="/leaveproductfeed"> FEEDBACK</router-link> -->
     </div>
   <div class="rt-btn">
    <button type="button" data-bs-toggle="modal" data-bs-target="#writereviewfrom">
         <img class="db-mob" src="@/assets/images/feed-back.png" alt="#">
    </button>
    </div>                                                                                                                                    
  </div>
    <div class="modal fade feedback" id="writereviewfrom" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog cuts-modal">
          <div class="modal-content">
            <div class="modal-header">
              <button type="button" ref="Close3" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
                  <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
                </svg>
              </button>
            </div>
            <div class="modal-body">
      <div class="feedback-content">
           
        <div class="row">
            
            <div class="col-md-12 col-lg-12 col-xl-12">
               
                
                <div class="text-side">
                    <article>  
                        <h3><span>Hi</span>, feel free to leave any feedback or new features you want to see or improve.</h3>
                    </article>
                    <Form class="UserReviewForm" @submit="contactfromsend" :validation-schema="schema" v-slot="{ errors }">
                        <div class="form-group" style="wi">
                            <label for="exampleFormControlTextarea1">Message</label>
                            <Field name="message" v-model="message"  placeholder="Message" :class="'form-control '+{'has-error': errors.message }" as="textarea">
                             </Field>
                             <ErrorMessage name="message" class="text-danger" /> 
                          </div>
                         <p class="mt-4"> <button type="submit" class="btn">Submit</button></p>
                    </Form>
                </div>
            </div>
        </div>
     
      
     
       </div>
      </div>
        </div>
      </div>
       </div>
</template>

<script>
import {computed} from "vue"
import { ref } from "vue"
import { Field, Form, ErrorMessage } from 'vee-validate'
import * as yup from 'yup'
import {mapState,mapActions} from 'vuex'
import { useRoute } from 'vue-router';
// import VueClientRecaptcha from "vue-client-recaptcha";
//import "vue-client-recaptcha/dist/style.css";
export default {
  name: 'PageHeader',
  props: ['toggle','islogin'],
  data() {
       const schema = yup.object({
           //inputValue: yup.string().trim().required().label('Captcha Code'),
           message: yup.string().trim().required().label('Message'),
        });

        
        
    return {
      schema,
      todoLisloginflug : this.islogin,
      pageload:false,
      searchItem:[],
      searchkey : null,
      searchkey2:'',
      filterdata: {
                    input: '',
                    selection: null,
                    userid: null,
				 }, 
        form: {
            robot: false
        }, 
      isvalidecaptcha:false,  
      showerror:false,
      searchTerm:'',
      searcappslist:[],
      selectedapps:[],
      searcdeveloperslist:[],
      curentindex:0,
      redirectrout:'',
      arraylist:true,
      searchkeworddata:{
        app_or_dev_id:'',
        searchtype:'',
        keyword:''
      }
      
    };
  },

  components: {
            // VueClientRecaptcha,
            Field,
            Form,
            ErrorMessage,
       },

  computed: {
      ...mapState({
          user: state => state.authModule.user,  
          allappsbysearch: (state) => state.AppListModule.allapslistwithoutpagination,
          allappsdevelopers: (state) => state.AppListModule.alldeveloperwithoutpagination,
          searchAppresult: (state) => state.AppListModule.searchAppresult,
      }),
  },

  mounted() {  
       /*this.todoLisloginflug=true;
       console.log('user',this.user)*/
       //this.todoLisloginflug=true
       const route = useRoute();
       this.searchkey=route.query.key;
       this.pageload=true 
       if(this.user.data)  {
          this.todoLisloginflug=true
        }
       else{
           this.todoLisloginflug=false
        }  

      
           
  },

  methods:{
      ...mapActions({
           logoutUser: 'authModule/logout',
           //SearchAppbykeyword: 'AppListModule/SearchAppbykeyword',
           SendUserReview: 'authModule/SendUserReview',
           allapsanddevloper: 'AppListModule/GetAllAppsWithoutpagination',  
        }),

    int(){  
        if(this.user.data)  {
          this.todoLisloginflug=true
        }else{
           this.todoLisloginflug=false
        }     
          
    },
    logoutLogedUser(){
      //alert('logout test')
      this.logoutUser()
    },

    selectItemEventHandlerApps(item){
      document.querySelector('input[id="keyword2"]').value=item.app_title; 
      //alert(document.getElementById('keyword2').value)
      this.searchkey2=item.app_title;
      return item.app_title;  
    },

   

    onInputEventHandlerApps(event){
        this.filterdata.selection = null;
        this.filterdata.input = event.input;
        var x = event.keyCode;
        //alert(x)
        this.filterdata.userid = this.user.data.userid;
        if(this.filterdata.input.length>2){
        // alert(this.data.input.length)
        this.SearchAppbykeyword(this.filterdata);
        this.searchItem=this.searchAppresult;
        } 
    },

    selectappsforsearch(id,type,title){
         this.searchkeworddata.app_or_dev_id=id;
         this.searchkeworddata.searchtype=type;
         document.getElementById('globalsearch').click()
       
    },

    submitClick(){         
          if(this.searchkeworddata.searchtype!=''){
            if(this.searchkeworddata.searchtype=='apps'){
              this.redirectrout='/about-details/'+this.searchkeworddata.app_or_dev_id;
            }
            else if(this.searchkeworddata.searchtype=='devloper'){
               this.redirectrout='/developers-team-name/'+this.searchkeworddata.app_or_dev_id;
            }
            document.getElementById('myInput').value=''
            this.searchkeworddata.searchtype='';
            this.searchkeworddata.app_or_dev_id='';
            document.getElementById('myInput').focus();
            this.searcappslist=[];
            this.searcdeveloperslist=[];
            this.curentindex=0;
            this.$router.push({path: this.redirectrout})
          }else{
             var keywordval=document.getElementById('myInput').value;
             this.searcappslist=[];
             this.searcdeveloperslist=[];
             this.curentindex=0;
             if(keywordval.trim().length>=1)
                this.$router.push({path: '/search-result', query:{key: keywordval}})
          }
    } , 

    checkValidCaptcha(value){         
        this.isvalidecaptcha=value;
    },

    contactfromsend: function(params, { setFieldError }) {
      params.setFieldError = setFieldError;  
      
       //if (this.isvalidecaptcha) {
          // this.showerror=false;

        const form = new FormData();
        for (const field in params) {
            form.append(field, params[field]);
        }
        
        form.append('userid',this.user.data.userid);
        form.append('firstname', this.user.data.first_name);
        form.append('lastname',this.user.data.last_name);
        form.append('email',this.user.data.email);
        this.SendUserReview(form);
        document.querySelector('.UserReviewForm').reset();
        this.$refs.Close3.click();        
         for (const field in params) {
                 params[field]='';
           }
        

      //  }else{
      //    this.showerror=true;
      //  }
     },  

     returnval(event){
             let len = document.querySelectorAll("ul.serchresult li").length

             if (event.keyCode === 40 && this.curentindex<len)
               {
                  this.curentindex=this.curentindex+1;
               }
             else if(event.keyCode === 38 && this.curentindex>0){
                  this.curentindex=this.curentindex-1;
              } 
            if(event.keyCode===40 || event.keyCode==38)
              { 
                document.querySelectorAll("ul.serchresult li").forEach((el,index) => {
                   el.classList.remove('active');
                    if(((this.curentindex)-1)==index){
                       el.classList.add('active');
                       this.searchkeworddata.app_or_dev_id=el.getAttribute('data-id');
                       this.searchkeworddata.searchtype=el.getAttribute('data-type');
                    }
                });
               if(this.curentindex==0){
                  this.searchkeworddata.app_or_dev_id='';
                  this.searchkeworddata.searchtype='';
               }
              }

     }, 

     searchappkeuword(event){
      this.searchTerm=document.getElementById('myInput').value;
       if(this.searchTerm.length>=10){
           this.allapsanddevloper({keyvalue:this.searchTerm})
       }else{
        this.searcappslist=[];
        this.searcdeveloperslist=[];
       }
      
     }      
           
  },  
  watch: {
       user() { 
           this.int();
       },
       allappsbysearch(){
          this.searcappslist=this.allappsbysearch;
          this.searcdeveloperslist=this.allappsdevelopers;
       },

       '$route.query'(newId, oldId) { 
        if(newId.key==undefined || newId.key=='')
          this.searchkey2='';
        }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.vue_client_recaptcha_icon {
  float: right !important;
  margin-top: 10px;
}


.light-theme-np .radio-star{ color: #000;}
.light-theme-np .radio-star-total{ color: #000;}

.radio-stars {
  display: table;
  position: relative;
  unicode-bidi: bidi-override;
  direction: rtl;
  counter-reset: star-rating;
  font-size: 0;
  margin: 0 auto;
}
.radio-stars:hover .radio-star::before {
  content: "☆";
}
.radio-stars:hover .radio-star:hover::before,
.radio-stars:hover .radio-star:hover ~ .radio-star::before {
  content: "★";
}

.radio-star {
  display: inline-block;
  overflow: hidden;
  cursor: pointer;
  padding: 0 2px;
  width: 0.9em;
  direction: ltr;
  color: rgba(255, 255, 255, 0.87);
  font-size: 40px;
  white-space: nowrap;
}
.radio-star::before {
  content: "☆";
}
.radio-star:hover, .radio-star:hover ~ .radio-star, input:checked ~ .radio-star {
  color: orange;
}
input:checked ~ .radio-star {
  counter-increment: star-rating;
}
input:checked ~ .radio-star::before {
  content: "★";
}

.radio-star-total {
  pointer-events: none;
  direction: ltr;
  unicode-bidi: bidi-override;
  position: absolute;
  right: -2em;
  bottom: 0.5em;
  color: gray;
  color: white;
  font-size: 20px;
}
.radio-star-total::before {
  content: counter(star-rating) "/5";
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  overflow: hidden;
  margin: -1px;
  padding: 0;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
.main_right-pn .feedback-side button {
  background: none;
  border: none;
}

.feedback-content{ position: relative;}
.appSubClose{
  position: absolute;
  top: -12px;
  right: -17px;
}
.appSubClose a{
  background: #62b8ff;
  border: 5px solid #181826;
  border-radius: 50px;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.main_right-pn .feedback-content .text-side h3{ margin: 0;}
.feedback .modal-dialog.cuts-modal .modal-content .modal-body {
  padding: 0 !important;
  margin: 0;
}
.feedback .modal-dialog.cuts-modal {
  max-width: 850px;
}
.text-side form {
  width: 100%;
  float: left;
  padding-bottom: 37px;
}
.rate:not(:checked) > input {
    position:absolute;
    top:-9999px;
}
.rate:not(:checked) > label {
    float:right;
    width:1em;
    overflow:hidden;
    white-space:nowrap;
    cursor:pointer;
    font-size:30px;
    color:#ccc;
}
.rate:not(:checked) > label:before {
    content: '★ ';
}
.rate > input:checked ~ label {
    color: #ffc700;    
}
.rate:not(:checked) > label:hover,
.rate:not(:checked) > label:hover ~ label {
    color: #deb217;  
}
.rate > input:checked + label:hover,
.rate > input:checked + label:hover ~ label,
.rate > input:checked ~ label:hover,
.rate > input:checked ~ label:hover ~ label,
.rate > label:hover ~ input:checked ~ label {
    color: #c59b08;
}
.main_right-pn .feedback-content{
    background-color: #2C2C3B;
    margin-top: 0;
}
.main_right-pn .feedback-content .row{
    margin: 0;
    row-gap: 20px;
}
.main_right-pn .feedback-content .rating-sct .rating-list ul {
  width: auto;
}


.light-theme-np .main_right-pn .feedback-content .text-side .verification .fild [type="text"]{ color:#000;}
.light-theme-np .main_right-pn .feedback-content .text-side .form-group textarea{ color: #000;}
.main_right-pn .feedback-content .row>*{
    padding: 0;
}
.main_right-pn .feedback-content .rating-sct {
     background-image: url('@/assets/images/verification/ve-bg.png');
    height: 100%;
    background-size: cover;
    background-position: center; 
    background: none !important;
}
.main_right-pn .feedback-content .rating-sct .rating-list{
    display: flex;
    justify-content: flex-end;
    padding: 80px 20px 0 0;
}
.main_right-pn .feedback-content .rating-sct .rating-list li{
    display: flex;
    align-items: center;
    column-gap: 10px;
    margin-bottom: 20px;
}
.main_right-pn .feedback-content .rating-sct .rating-list li:last-child{
    margin-bottom: 0;
}
.main_right-pn .feedback-content .rating-sct .rating-list li input[type=radio] {
    padding: 0.6em;
    -webkit-appearance: none;
    outline: 0.1em solid black;
    outline-offset: 0.1em;
}
.main_right-pn .feedback-content .rating-sct .rating-list li input[type=radio]:checked {
    display: inline-block;
    background-image: url('@/assets/images/icon/check-green.svg');
    background-size: cover;
    background-position: center;
}
.main_right-pn .feedback-content .rating-sct .rating-list li label svg{
    fill: #D69909;
    color: #D69909;
    flex-shrink: 0;
    position: relative;
    top: -1px;
}
.main_right-pn .feedback-content .text-side{
    padding: 80px;
}
.main_right-pn .feedback-content .text-side h3{
    font-weight: 300;
    font-size: 35px;
    line-height: 47px;
    color: #FFFFFF;
    margin-bottom: 30px;
}
.main_right-pn .feedback-content .text-side h3 span{
    font-weight: 500;
    font-size: 36px;
}
.main_right-pn .feedback-content .text-side .form-group label{
    font-family: 'Rubik';
    font-weight: 400;
    font-size: 18px;
    text-transform: capitalize;
    color: #FFFFFF;
    margin-bottom: 8px;
}
.main_right-pn .feedback-content .text-side .form-group textarea{
    background: #414155;
    border: 1px solid #686882;
    border-radius: 2px;
    font-family: 'Rubik';
    font-weight: 400;
    font-size: 14px;
    color: #FFFFFF;
    min-height: 174px;
}
.main_right-pn .feedback-content .text-side .verification{
    display: flex;
    gap: 19px;
    flex-wrap: wrap;
    margin: 32px 0 30px;
}
.main_right-pn .feedback-content .text-side .verification .item{
    display: flex;
}
.main_right-pn .feedback-content .text-side .verification .item .verif-img{
    background-color: #414155;
    width: 180px;
    display: flex;
    justify-content: center;
}
.main_right-pn .feedback-content .text-side .verification .item .verif-btn{
    background: transparent;
    border-radius: 0;
    border-left: 1px solid #686882;
}
.main_right-pn .feedback-content .text-side .verification .item,
.main_right-pn .feedback-content .text-side .verification .fild [type="text"]{
    background: #414155;
    border: 1px solid #686882;
    border-radius: 2px;
    height: 100%;
    text-indent: 10px;
}
.main_right-pn .feedback-content .text-side .verification .fild{
    flex-grow: 1; width: 50%;
}
.main_right-pn .feedback-content .text-side .verification .fild [type="text"]{
    width: 100%;
    color: #fff;
}
.main_right-pn .feedback-content .text-side [type="submit"]{
    background: #3CAEA3;
    border-radius: 4px;
    font-weight: 600;
    font-size: 20px;
    text-align: center;
    text-transform: uppercase;
    color: #FFFFFF;
    padding: 10px 48px;
}

.light-theme-np .modal-dialog.cuts-modal .modal-content .modal-header .btn-close {
  position: absolute;
  right: -7px;
  top: -10px;
  background: #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #fff;}

.feedback .modal-dialog.cuts-modal .modal-content .modal-header .btn-close{ z-index: 999;}
.feedback .modal-dialog.cuts-modal .modal-content .modal-header {
  padding: 0 !important;
}

.feedback .modal-content {
  padding: 0;
}

.feedback .modal-dialog.cuts-modal .modal-content .modal-header{ border-bottom: none;}
/* Feedback section content end */

/* light theme start here */

.light-theme-np .main_left-pn {
    box-shadow: 0px 0px 20px #d7d7d7;
    background-color: #fff;
}
.light-theme-np .main_left-pn .logo-sect h4,
.light-theme-np .main_left-pn .accd-sect .accordion-button,
.light-theme-np .main_left-pn .accd-sect .accordion-item .accordion-body li,
.light-theme-np .main_left-pn .dl-mode-btn span,
.light-theme-np .main_right-pn header .ite_sm:first-of-type h5,
.light-theme-np .main_right-pn header .ite_sm:last-child .prof li .nav-link,
.light-theme-np .main_right-pn .card-section [class^="col"]:nth-child(odd) .item .ttx h6,
.light-theme-np .main_right-pn .card-section [class^="col"]:nth-child(odd) .item .ttx h5,
.light-theme-np .main_right-pn .chat-bar .chat-title h5,
.light-theme-np .main_right-pn .chat-bar .chat-title > div span:first-child,
.light-theme-np .main_right-pn .table-section .ciel h6,
.light-theme-np .main_right-pn .chat-bar .chat-title > div input[type="date"],
.light-theme-np .main_right-pn .table-section .tbl-head h6,
.light-theme-np .main_right-pn .table-section table tbody td,
.light-theme-np .main_right-pn .copy-rrt p,
.light-theme-np .main_right-pn .section__head h4,
.light-theme-np .main_right-pn .drag-opt article h5,
.light-theme-np .main_right-pn .drag-opt article p,
.light-theme-np .main_right-pn .table-section.spg-table .tbl-head p,
.light-theme-np .main_right-pn header .ite_sm:last-child .form-group .form-control,
.light-theme-np .main_right-pn nav[aria-label="navigation-cuts"] ul .page-link,
.light-theme-np .main_right-pn .drag-opt .up-drag .btn svg,
.light-theme-np .main_right-pn .filter-mt-sect .item label,
.light-theme-np .main_right-pn .filter-mt-sect .item select,
.light-theme-np .main_right-pn .feedback-content .text-side h3,
.light-theme-np .main_right-pn .feedback-content .text-side .form-group label,
.light-theme-np .main_right-pn .chat-bar .chat-title h5,
.light-theme-np .main_right-pn .grap-item h5,
.light-theme-np .main_right-pn .grap-item .head-flex li,
.light-theme-np .tab-ppr p, .light-theme-np .main_right-pn .table-section .tbl-head p.min-para{
    color: #212529;
}

.light-theme-np .main_right-pn .feedback-content .text-side .verification .item,
.light-theme-np .main_right-pn .feedback-content .text-side .verification .fild [type="text"],
.light-theme-np .main_right-pn .feedback-content .text-side .form-group textarea {
    background: #ebebeb;
    border: 1px solid #d4d4d5;
    border-radius: 2px;
}
.light-theme-np .main_right-pn .feedback-content .text-side .verification .item .verif-img{
    background: #ebebeb;
}
.light-theme-np .main_right-pn .feedback-content .text-side .verification .item .verif-btn{
    border-left: 1px solid #d4d4d5;
}

.light-theme-np .main_left-pn .accd-sect .accordion-button img,
.light-theme-np .main_left-pn .accd-sect .accordion-button::after,
.light-theme-np .main_right-pn .feedback-content .text-side .verification .item .verif-btn img{
    filter: sepia(1) saturate(1) brightness(0.1) hue-rotate(135deg);
}
.light-theme-np .accordion-collapse {
    border-left: 1px solid #cad0d6;
    box-shadow: 7px 0px 12px -8px #a9a9a9;
}
.light-theme-np .main_right-pn .feedback-content,
.light-theme-np .main_right-pn .growth-chh {
    background-color: #eaeaea;
}
.light-theme-np .main_right-pn,
.light-theme-np .accordion-collapse{
    background-color: #fff;
}
.light-theme-np .slider:before,
.light-theme-np .main_right-pn header .navbar-collapse::after,
.light-theme-np .main_right-pn header .navbar-collapse::before {
    background-color: #191927;
}
.light-theme-np .main_right-pn header .navbar-collapse::before {
    box-shadow: 0 11px #191927;
}
.light-theme-np .main_right-pn header .ite_sm:last-child .form-group .form-control,
.light-theme-np .main_right-pn .chat-bar .chat-title > div .select-ar:last-child,
.light-theme-np .main_right-pn .filter-mt-sect .item select{
    background-color: #e8e8e8e6;
    border: 1px solid #e7e7e757;
}
.light-theme-np .main_right-pn header .ite_sm:last-child .form-group [type="submit"] svg,
.light-theme-np .main_right-pn header .ite_sm:last-child .notify{
    fill: #5e5e6d;
    color: #5e5e6d;
}
.light-theme-np .main_right-pn header .ite_sm:last-child .notify .badge-nofy{
    border-color: #fff;
}
.light-theme-np .main_right-pn .card-section [class^="col"]:nth-child(odd) .item,
.light-theme-np .main_right-pn nav[aria-label="navigation-cuts"] ul .page-link {
    background-color: #dadada;
}
.light-theme-np .main_left-pn .logo-sect .close-nav button svg {
    fill: #000000 !important;
}
.light-theme-np .main_right-pn .table-section table tbody > :nth-child(odd) {
    background-color: #eaecee;
}
.light-theme-np .main_right-pn .table-section table tbody > :nth-child(even) {
    background-color: #e1e1e1;
}
.light-theme-np .main_right-pn .table-section table tbody td,
.light-theme-np .main_right-pn .table-section table tbody tr td:last-child{
    border-color: #d6d6d6;
}
.light-theme-np .main_right-pn .table-section ul.nav.nav-tabs .nav-item .nav-link {
    font-weight: 600;
    font-size: 16px;
    text-transform: uppercase;
    color: #000000;
    background-color: #eaecee;
    border: unset !important;
}
.light-theme-np .main_right-pn .table-section ul.nav.nav-tabs {
    gap: 10px;
    border-bottom-color: #d6d6d6;
}
.light-theme-np .main_right-pn .table-section ul.nav.nav-tabs .nav-item .nav-link.active {
    color: #fff;
    background-color: #ED553B;
}
.light-theme-np .main_left-pn::-webkit-scrollbar-thumb {
    background: #d6d6d6; 
}
.light-theme-np .main_right-pn .drag-opt .up-drag {
    background: #ececec7a;
    border: 0.5px solid #74747414;
    box-shadow: 0px 4px 4px #8f8f8f70;
}
.light-theme-np .main_right-pn .drag-opt .up-drag .btn {
    background: #e1e1e1;
    border: 1px solid #cacaca;
}
/* light theme start here */
.main_right-pn .feedback-content .rating-sct {
    background-image: url('@/assets/images/verification/ve-bg.png');
    height: 100%;
    background-size: cover;
    background-position: center;
}

.serchresult{
  width: 100%;
  background: #fff;
  z-index: 9999;
  position: absolute;
  top:100%;
  left:0;
  height: 250px;
  overflow-y: scroll;
}
@media (max-width:1440px){
 
    /* Feedback section content start */
    .main_right-pn .feedback-content .text-side {
        padding: 40px;
    }
    .main_right-pn .feedback-content .text-side h3 {
        font-size: 30px;
        line-height: 40px;
        margin-bottom: 20px;
    }
    .main_right-pn .feedback-content .text-side .form-group label {
        font-size: 16px;
    }
    .main_right-pn .feedback-content .text-side .form-group textarea {
        min-height: 140px;
    }
    .main_right-pn .feedback-content .text-side [type="submit"] {
        font-size: 18px;
        padding: 7px 37px;
    }
    .main_right-pn .feedback-content .text-side h3 span {
        font-size: 25px;
    }
    /* Feedback section content end */
}
@media (max-width:991px)  {

    /* Feedback section content start */
    .main_right-pn .feedback-content .text-side h3 {
        font-size: 22px;
        line-height: 30px;
        margin-bottom: 10px;
    }
    .main_right-pn .feedback-content .text-side h3 span {
        font-size: 23px;
    }
    .main_right-pn .feedback-content .text-side .form-group label {
        font-size: 14px;
        margin-bottom: 6px;
    }
    .main_right-pn .feedback-content {
        margin-top: 70px;
    }
    /* Feedback section content end */
}
@media (max-width:767px) {


    /* Feedback section content start */
    .main_right-pn .feedback-content .rating-sct .rating-list {
        padding: 80px 10px;
    }
    .main_right-pn .feedback-content {
        margin-top: 40px;
    }


    /* Feedback section content end */
}
@media (max-width:576px){
    /* Feedback section content start */
    .main_right-pn .feedback-content .text-side {
        padding: 20px;
    }
    .main_right-pn .feedback-content .text-side h3 {
        font-size: 20px;
    }
    .main_right-pn .feedback-content .text-side h3 span {
        font-size: 21px;
    }
    .main_right-pn .feedback-content .rating-sct .rating-list {
        padding: 20px 10px 150px;
    }
    /* Feedback section content end */
}
</style>