<template>
  <div :class="membershipcontentclass" id="contact" role="tabpanel" aria-labelledby="contact-tab">

            <div class="magageFree">
                <div class="magageFreeTop">
                  <div class="tittle">
                    <h2>Manage my Subscription</h2>
                  </div>
                  <div class="free">
                    <h5>Membership Plan: <span>Beta</span></h5>
                    <h5><span>$0.00 USD / month</span></h5>
                    <h5></h5>
                  </div>
                  <div class="demo">
                    <h6>Membership Status: <span>Free</span></h6>
                  </div>
                </div>
                <div class="magageFreeBottom">
                    <div class="headline">
                      <h3>Recommended</h3>
                    </div>
                  <div class="row freeWrap gx-0">
                    <div class="col-lg-4 freeWrapMargin" v-bind:key="result.id" v-for="(result,index) in levels.level">
                      <div class="Starter" :id="index">
                        <h2 :class="bgarraay[index]">{{result.name}}</h2>
                          <div class="box">
                            <!-- <h5>$12.99 <span>/Mo</span></h5> -->
                          <div class="StarterSub" v-if="levels[result.id]['no_of_app_changelog']!=''">
                            <h4>App Listing pages</h4>
                              <div class="StarterFlex">
                                <div class="left">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#5AB4FF" class="bi bi-check-lg" viewBox="0 0 16 16">
                                    <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z"/>
                                  </svg>
                                </div>
                                <div class="right">
                                  <h4>Change Logs</h4>
                                  <p>Monitor up to {{levels[result.id]['no_of_app_changelog']}} apps</p>
                                </div>
                              </div>
                          </div>
                          <div class="StarterSub" v-if="levels[result.id]['no_of_intelligence_ads']!=''">
                            <h4>Ad Store Inspector</h4>
                              <div class="StarterFlex">
                                <div class="left">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#5AB4FF" class="bi bi-check-lg" viewBox="0 0 16 16">
                                    <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z"/>
                                  </svg>
                                </div>
                                <div class="right">
                                  <h4>Track  Ads by Keywords</h4>
                                  <p>Up to {{levels[result.id]['no_of_intelligence_ads']}} keywords</p>
                                </div>
                              </div>
                          </div>
                          <div class="StarterSub border-0" v-if="levels[result.id]['no_of_keyword_per_aps']!=''">
                              <div class="StarterFlex">
                                <div class="left">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#5AB4FF" class="bi bi-check-lg" viewBox="0 0 16 16">
                                    <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z"/>
                                  </svg>
                                </div>
                                <div class="right">
                                  <h4>Track Competitor’s Ads</h4>
                                  <h5>Up to {{levels[result.id]['no_of_keyword_per_aps']}} apps</h5>
                                  <p v-if="levels[result.id]['no_of_track_ads']!=''">Monitor up to  {{levels[result.id]['no_of_track_ads']}} keywords per app</p>
                                </div>
                              </div>
                          </div>


                           <div class="StarterSub border-0" v-if="levels[result.id]['no_of_track_competitor_ads']!=''">
                              <div class="StarterFlex">
                                <div class="left">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#4CDBCD" class="bi bi-check-lg" viewBox="0 0 16 16">
                                    <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z"/>
                                  </svg>
                                </div>
                                <div class="right">
                                  <h4>Data export of Ad store Intelligence</h4>
                                </div>
                              </div>
                          </div>


                          <div :class="'startBtn '+bgarraay[index]">
                            <a href="#">Select Plan</a>
                          </div>
                          </div>
                      </div>
                    </div>
                  
                  </div>
                </div>
            </div>

          
          </div>
</template>

<script>
import {mapState,mapActions} from 'vuex'
export default {
     props:['membershipcontentclass'],
     name: 'PackageSubscription',
      data() {
            return {
              isload:false,
              bgarraay:['','hGreen','hviolet','','hGreen','hviolet','','hGreen','hviolet','','hGreen','hviolet','','hGreen','hviolet']
            };
           
        },
    components: {
    },
    computed: {
        ...mapState({
             levels: state => state.authModule.userselectedpackagedetails,
        }),
    },

    mounted() { 
      this.isload=true;
      console.log('levels',this.levels)
    },
    methods:{          
    },  
    watch: {
       
    }
}
</script>

<style scoped>
.magageFreeTop .tittle h2  {
    font-weight: 500;
    font-size: 34px;
    line-height: 30px;
    text-transform: uppercase;
    color: #FFFFFF;
    padding-top: 39px;
    padding-bottom: 26px;
}
.magageFreeTop .free  {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}
.magageFreeTop .free h5,
.magageFreeTop .demo h6  {
    font-weight: 300;
    font-size: 20px;
    line-height: 24px;
    color: rgba(255, 255, 255, 0.86);
    padding-bottom: 15px;
    display: inline-block;
}
.magageFreeTop .demo p  {
    font-weight: 300;
    font-size: 20px;
    line-height: 24px;
    color: rgba(255, 255, 255, 0.86);
    padding-bottom: 15px;
    width: calc(100% - 320px);
}
.magageFreeTop .demo {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}
.magageFreeTop .demo h6  {
    width: 320px;
}
.magageFreeTop .free h5 span,
.magageFreeTop .demo h6 span  {
    color: #FFFFFF;
    font-weight: 500;
}
.magageFreeBottom  {
    padding-top: 45px;
}
.magageFreeBottom .headline  {
    display: flex;
    justify-content: center;
    align-items: center;
}
.magageFreeBottom .headline h3  {
    font-weight: 400;
    font-size: 21px;
    line-height: 32px;
    letter-spacing: 0.64em;
    text-transform: uppercase;
    color: #FFFFFF;
    border: 1.4px solid #3CAEA3;
    padding: 10px 66px;
    margin: 0;
}
.magageFreeBottom .freeWrap .Starter  {
    background: rgba(48, 48, 64, 0.82);
    border: 0.5px solid rgba(116, 116, 116, 0.16);
    border-radius: 11px 11px 11px 11px;
    height: 100%;
    position: relative;
    transition: all ease-in-out 300ms;
}
.magageFreeBottom .freeWrap .Starter:hover  {
    box-shadow: 0px 4px 67px rgba(0, 0, 0, 0.38);
    position: relative;
    z-index: 1;
}
.magageFreeBottom .freeWrap .Starter h2  {
    background: #2571B0;
    border: 0.5px solid rgba(116, 116, 116, 0.16);
    border-radius: 9px 9px 0px 0px;
    font-family: 'Rubik';
    font-weight: 500 !important;
    font-size: 28px;
    line-height: 33px;
    letter-spacing: 0.02em;
    text-transform: capitalize;
    color: #FFFFFF;
    padding: 16px 0 !important;
    display: flex;
    justify-content: center;
    align-items: center;
}
.magageFreeBottom .freeWrap .Starter .hGreen  {
     background: #3CAEA3;
    border: 0.5px solid rgba(116, 116, 116, 0.16);
}
.magageFreeBottom .freeWrap .Starter .hviolet  {
    background: #685DC9;
    border: 0.5px solid rgba(116, 116, 116, 0.16);
}
.magageFreeBottom .freeWrap .Starter .box  {
    text-align: center;
    padding: 47px 56px 216px 56px;
}
.magageFreeBottom .freeWrap .Starter .box h5  {
    font-family: 'Rubik';
    font-weight: 500;
    font-size: 46px;
    line-height: 23px;
    text-transform: capitalize;
    color: #60B7FF;
    padding-bottom: 35px;
}
.magageFreeBottom .freeWrap .Starter .box .fGreen  {
    color: #3CAEA3;
}
.magageFreeBottom .freeWrap .Starter .box .fviolet  {
    color: #685DC9;
}
.magageFreeBottom .freeWrap .Starter .box h5 span  {
    font-family: 'Rubik';
    font-weight: 400;
    font-size: 25px;
    line-height: 23px;
    letter-spacing: 0.17em;
    text-transform: capitalize;
    color: #FFFFFF;
}
.magageFreeBottom .freeWrap .Starter .box .StarterSub  {
    padding-top: 35px;
  }
.magageFreeBottom .freeWrap .Starter .box .StarterSub:not(:last-child)  {
    border-bottom: 1px dashed rgba(98, 98, 122, 0.8);
}
.magageFreeBottom .freeWrap .Starter .box .StarterSub h4  {
    font-family: 'Rubik';
    font-weight: 500;
    font-size: 28px;
    line-height: 33px;
    letter-spacing: 0.02em;
    text-transform: capitalize;
    color: #FFFFFF;
    padding-bottom: 20px;
    text-align: left;
}
.magageFreeBottom .freeWrap .Starter .box .StarterSub .StarterFlex  {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
}
.magageFreeBottom .freeWrap .Starter .box .StarterSub .StarterFlex .left  {
    width: 32px;
}
.magageFreeBottom .freeWrap .Starter .box .StarterSub .StarterFlex .right  {
    width: calc(100% - 32px);
}
.magageFreeBottom .freeWrap .Starter .box .StarterSub .StarterFlex .right h4  {
    font-family: 'Rubik';
    font-weight: 400;
    font-size: 22px;
    line-height: 26px;
    text-transform: capitalize;
    color: #FFFFFF;
    padding-bottom: 20px;
    text-align: left;
    margin: 0;
}
.magageFreeBottom .freeWrap .Starter .box .StarterSub .StarterFlex .right h5  {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    letter-spacing: 0.02em;
    color: #FFFFFF;
    padding-bottom: 10px;
    text-align: left;
    margin: 0;
}
.magageFreeBottom .freeWrap .Starter .box .StarterSub .StarterFlex .right p  {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: 0.02em;
    color: #C8C8C8;
    text-align: left;
    margin: 0;
    padding-bottom: 40px;
}
.magageFreeBottom .freeWrap .Starter .startBtn {
    position: absolute;
    bottom: 80px;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
}
.magageFreeBottom .freeWrap .Starter .startBtn a  {
    background: #2571B0;
    border-radius: 3px;
    font-family: 'Rubik';
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
    text-transform: capitalize;
    color: #FFFFFF;
    text-decoration: none;
    width: 100%;
    height: 51px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.magageFreeBottom .freeWrap .Starter .bgreen a  {
    background: #3CAEA3;
}
.magageFreeBottom .freeWrap .Starter .bviolet a  {
    background: #685DC9;
}
.magageFreeTop .cancelBtn ul  {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-top: 60px;
}
.magageFreeTop .cancelBtn ul li  {
    font-weight: 300;
    font-size: 20px;
    line-height: 24px;
    color: rgba(255, 255, 255, 0.86);
}
.magageFreeTop .cancelBtn ul li a   {
    text-decoration: none;
    font-weight: 600;
    font-size: 15px;
    line-height: 22px;
    text-align: center;
    text-transform: uppercase;
    color: #FFFFFF;
    text-shadow: 0px 0.5px 1px rgba(0, 0, 0, 0.25);
    border: 1px solid #B3B3B3;
    border-radius: 4px;
    padding: 11px 39px;
    margin-left: 20px;
}

@media (max-width:767px){
.magageFreeBottom .freeWrap .Starter .box .StarterSub h4{ font-size: 22px; line-height:30px;}
.magageFreeBottom .freeWrap .Starter .box .StarterSub .StarterFlex .right p {
  padding-bottom: 17px !important;
  font-size: 16px !important;}
  .magageFreeBottom .freeWrap .Starter .box .StarterSub .StarterFlex .right h4{padding-bottom: 10px;}
}

</style>