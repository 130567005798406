import axios from 'axios'

const url = process.env.VUE_APP_API_BASE_URL;

const actions = {

    getTopreviewsApp(store, params) {
        axios.get(`${url}wp/v2/getdashboardmostreviewsapp`, { params }).then((resp) => {
            store.commit('SET_TOPAPPREVIEWS', resp.data);
        }).catch((err) => {
            // Toastr.handleServerError(err);
            console.log(err)
        });
    },

    getAppsreviews(store, params) {
        axios.get(`${url}wp/v2/getreviews`, { params }).then((resp) => {
            store.commit('SET_LATESTAPS', resp.data);
        }).catch((err) => {
            // Toastr.handleServerError(err);
            console.log(err)
        });
    },

   /* getAppsTotalReviews(store, params) {
        axios.get(`${url}wp/v2/getreviewstotal`, { params }).then((resp) => {
            store.commit('SET_TOTALREVIEWS', resp.data);
        }).catch((err) => {
            // Toastr.handleServerError(err);
            console.log(err)
        });
    },*/   
};

export default actions;