<template>
  <div class="ful-wdth">
    <div class="tab_boxe">
        <div class="contactUs">
            <div class="row gx-0">
                <div class="col-md-6 col-lg-6 col-xl-5">
                    <div class="contactUsImage">
                       <img src="@/assets/images/contact/contact1.jpg" alt="">
                    </div>
                </div>
                <div class="col-md-6 col-lg-6 col-xl-7">
                    <div class="contactUsText">
                        <h3>Hi, how can we help?</h3>
                        <p>Our team is excited to help in anyway.</p>
                        <form action="#">
                            <div class="contactInput">
                                <label for="">Name</label>
                                <input class="conIn" type="text" placeholder="">
                            </div>
                            <div class="contactInput">
                                <label for="">Email</label>
                                <input class="conIn" type="text" placeholder="">
                            </div>
                            <div class="contactInput">
                                <label for="">Message</label>
                                <textarea class="conText" placeholder=""></textarea>
                            </div>
                            <div class="verification">
                                <div class="item">
                                    <div class="verifImg">
                                        <img src="@/assets/images/verification/verification.png" alt="">
                                    </div>
                                    <button class="btn verifBtn">
                                        <img src="@/assets/images/verification/reload.png" alt="">
                                    </button>
                                </div>
                                <div class="fild">
                                    <input type="text" name="" id="">
                                </div>
                            </div>
                            <button type="submit" class="contactBtn">Submit</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>       
    </div>
  </div>
</template>