<template>
  <div class="ful-wdth">
     <div class="comeing_Soon_Box">
          <div class="text_box">
            <img src="@/assets/images/sascomeingtext.png" alt="">
            <p>Get ready! Something really cool is coming!</p>
          </div>
        </div>
  </div>
</template>


<style scoped>
 .comeing_box {
    height: 100vh;
}
.comeing_box .tab_boxe {
    padding-top: 50px;
}
.comeing_box .copy-rrt  {
    margin-top: 50px;
}
.comeing_Soon_Box {
    background-image: url(@/assets/images/sascomeingsoon_v01.png);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.light-theme-np .comeing_Soon_Box {
    background-image: url(@/assets/images/sascomeingsoon_drk.png);}

.comeing_Soon_Box .text_box p  {
    font-weight: 300;
    font-size: 21px;
    line-height: 32px;
    text-align: center;
    letter-spacing: 0.04em;
    color: #FFFFFF;
}
.light-theme-np .comeing_Soon_Box .text_box p  {
    color: #212529 !important;
}

.light-theme-np .comeing_Soon_Box .text_box img  {
    filter: brightness(0.3);
}
</style>