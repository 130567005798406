<template>
  <div class="ful-wdth">

 <div class="section__head">
        <h4>Follow Your Competitors</h4>
      </div>
      <div class="drag-opt mb-0">        
        <article>
          <h5>Add an app to follow</h5>
          <p>If your app is struggling to take off in the Shopify App Store, you're not alone. In fact, many great apps are falling through the cracks and missing out on installs due to poor optimization.  Follow your competitors, learn from them and beat them in the app store.  We'll keep track of your competitor's app status & rankings.</p>
        </article>
      </div>

       <div class="serach-result-part  key-wrd">
           <vue3-simple-typeahead
                id="compayarpps"
                v-model="searchkey"
                :value="searchkey"
                placeholder="Search app name"
                :items="searchAppresult"
                class="searching form-control txt-bx"
                :minInputLength="3"
                :itemProjection="
                (item) => {
                      var url1=item.app_logo;
                      url=url1.replace('%3', '');
                      var html=`<div class='compayarpps header-searching'><div class='img-sec'><img src='${url}'></div>`;
                      html=html+`<div class='text-part'><h3>`+item.app_title+`</h3>`;
                      html=html+'<p>'+item.app_rating+' of 5 stars ('+item.app_num_of_reviews+')</p></div><div>';
                      return html;
                }"
                @selectItem="selectItemAppsFlowing"
                @onInput="onInputEventHandlerApps"
                @onFocus="onFocusEventHandlerApps"
                @onBlur="onBlurEventHandlerApps"
                >  

                <template #list-item-text="slot">
                    <span v-html="slot.itemProjection(slot.item)" class="material-icons"></span>
                </template>      
            </vue3-simple-typeahead>
            <button class="keyWordsubmit" type="button" @click="addapsinuserlist">App to Follow</button>

       </div> 

    <!-- Modal start -->
    
   
   
    <!-- Modal end -->


    <!-- 1 table row section start Here  -->

      <div class="table-section spg-table app-trck-tbl" v-if="isload">
            <div class="tbl-head">
              <h6>Apps You're Following</h6>
              <!-- <p><span>Zero state:</span> Add an app to follow</p> -->
            </div>
            <div class="resp-table">
              <table class="blue-table" v-if="userfollowappslist.length>0">
                <thead>
                  <tr>
                    <th>Following</th>
                    <th>App Name</th>
                    <th>Description</th>
                    <th>Ranking </th>
                    <th>Rating </th>
                    <th>Reviews</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-bind:key="result.app_id" v-for="result in userfollowappslist">
                    <td>
                      <a href="javascript:void(0)" @click="unflowapps(result.app_id)" ><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-square" viewBox="0 0 16 16">
                      <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
                      <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                    </svg></a>
                    </td>
                   

                    <td><router-link :to="'/about-details/' + result.app_id" class="td_link"><img :src=result.app_logo alt=""> <span>{{result.app_title}}</span></router-link></td>
                    <td><router-link :to="'/about-details/' + result.app_id">{{result.app_brief_description}}</router-link></td>
                    <td>{{result.cat_current_rank}}</td>

                    <td><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
                      <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
                    </svg> {{result.app_rating==NULL?'0':result.app_rating}}</td>
                    <td>{{result.app_num_of_reviews}}</td>
                  </tr>
                  

                </tbody>
              </table>
              <div class="data-nt" v-else>Sorry no data found.</div>
            </div>
      </div>

      <nav aria-label="navigation-cuts">
        <pagination 
        v-model="page" 
        :records="userfollowapptotal" 
        :per-page="10" 
        :options={chunk:5} 
        @paginate="nextprevious($event)"
        />          
      </nav>
    </div>
</template>


<script>

/*import SwiperCore, { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/swiper-bundle.min.css";*/
import {mapState,mapActions} from 'vuex'
import { useRoute } from 'vue-router'
//SwiperCore.use([Navigation]);


export default {
   name:'AppTracking',
   components: {
    //Swiper,
    //SwiperSlide,
  },
  data() {
        return {
          swiperOptions: {
            loop: true,
            observer: true,
            observeParents: true,

          },
          isload:false,
          startindex:1,
          popoularapsload:false,
          popoularaps:false,
          page: 1,
          params: {
                  page: 1,
                  per_page: 10,
                  user_id: '',
              },
          isfollowdataparams: {
                user_id: '',
                app_id: '',
                action:'',
            },
           searchappslist:[],
           filterdata: {
                input: '',
                selection: null,
                userid: null,
				}, 
          searchkey : null, 
          appfollowingid:false,           
        }
  },
   mounted() {  
        const vm = this;
        this.isload=true; 
        
        const route=useRoute();
        vm.isfollowdataparams.user_id=vm.user.data.userid;
        vm.filterdata.userid = this.user.data.userid; 
        vm.params.user_id=vm.user.data.userid;
          if(route.query.page){
          if(parseInt(route.query.page)>1){
              this.page=route.query.page;
              this.params.page=route.query.page;
              }else{
                this.page=1;
                this.params.page=1;
                vm.userfollowapsdetails( vm.isfollowdataparams);
              }
          }else{
              vm.userfollowapsdetails( vm.isfollowdataparams);
          }

       
        vm.SearchAppbykeyword(this.filterdata);
        vm.userfollowaps(vm.isfollowdataparams);
  },
  computed: {
      ...mapState({
          userfollowappslist: (state) => state.AppListModule.getuserfollowapplist,
          user: (state) => state.authModule.user,
          userfollowapps: (state) => state.authModule.userfollowapps,
          userfollowapptotal: (state) => state.AppListModule.userfollowapptotal,
          //popoularaps: (state) => state.AppListModule.popoularaps,
          //resentapps: (state) => state.AppListModule.resentapps,
          searchAppresult: (state) => state.AppListModule.searchAppresult,
          appfolowinglimitover: (state) => state.authModule.appfolowinglimitover,
      }),
  },
  methods:{
        ...mapActions({
                userfollowaps: 'authModule/userfollowaps',
                userfollowapsdetails: 'AppListModule/UserFollowApsDetails',
                SearchAppbykeyword: 'AppListModule/SearchAppbykeyword',

          }),
         nextprevious($data){ 
                this.$router.replace({ name: "AppTracking", query: {page: $data} })
                const vm = this; 
                vm.params.page=$data;
                vm.startindex=(($data-1)*10)+1;
                vm.userfollowapsdetails(this.params)
         },

        addFollow(event){
            this.isload=false;
            this.params.page=1;
            this.startindex=1;
            this.isfollowdataparams.user_id=this.user.data.userid;
            this.isfollowdataparams.app_id=event;
            this.page=1;
            //if(this.appfolowinglimitover){
            // event.checked = false 
            //  }
            this.isfollowdataparams.action='ADD';
            this.userfollowaps(this.isfollowdataparams);
        },
        unflowapps(event){
            this.params.page=1;
            this.startindex=1;
            this.page=1;
            this.isload=false;
            this.isfollowdataparams.user_id=this.user.data.userid;
            this.isfollowdataparams.app_id=event;
            this.isfollowdataparams.action='Delete';
            this.userfollowaps(this.isfollowdataparams);
            
        },
        onInputEventHandlerApps(event){
            //this.filterdata.selection = null;
            this.filterdata.input = event.input;
            // this.filterdata.userid = this.user.data.userid;            
            // if(this.filterdata.input.length>2){
            // // alert(this.data.input.length)
            //   this.SearchAppbykeyword(this.filterdata);
            //   this.searchappslist=this.searchAppresult;
            // } 
        },
        submitClick(){
            //const mdbackdrop=document.querySelector('.modal-backdrop1');
           // mdbackdrop.classList.remove('modal-backdrop show');
            this.$refs.Close.click();
            this.$router.push({path: '/search-result', query:{key: this.searchkey}})
        },         
        selectItemAppsFlowing(item){
            //document.querySelector('input[id="keyword2"]').value=item.app_title; 
            //alert(document.getElementById('keyword2').value)
            this.searchkey=item.app_title;
            this.searchkey=item.app_title;
            this.appfollowingid=item.app_id;
            return item.app_title;  
        },
       addapsinuserlist(){
           if(this.appfollowingid)
            { 
                this.addFollow(this.appfollowingid)
                this.appfollowingid=false;
                this.searchkey='';
                this. searchappslist=[];
            }
       } 

    },
     watch: {
        isload(){ 
         //this.isload=true;
          this.userfollowapsdetails(this.params)
         },
         userfollowapps(){ 
          this.userfollowapsdetails(this.params)
         },
         userfollowappslist(){ 
          this.isload=true;
         },
         popoularaps(){
          this.popoularapsload =true;
         }
     }
} 
 
</script>


<style>
.cuts-modal .modal-header {
  margin: 0 -9px;
}
 .light-theme-np .modal-dialog.cuts-modal .modal-content .modal-body .slider-title h4{
  color:#323232;
 }
 .modal-header form {
  display: flex;
  flex-wrap: wrap;
}
.swiper {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.modal-content{
  padding: 0 10px 10px;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: none;
  height: auto;
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
   width: 33.333% !important; 
  
}
.team-name-sect,
.swiper-slide .item{
  height: 100%;
}
.team-name-sect article{
  text-align: left;
}
.team-name-sect .item img{
  height: auto !important;
}
.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper-wrapper {
  align-items: stretch;
  column-gap: 10px;
}


/*  */
.main_right-pn .drag-opt{
    display: flex;
    gap: 30px 50px;
    margin-bottom: 185px;
}
.main_right-pn .drag-opt .up-drag{
    background: #303040ba;
    border: 0.5px solid #7474747d;
    box-shadow: 0px 4px 4px #00000040;
    border-radius: 3px;
    width: 178px;
    height: 178px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
}
.main_right-pn .drag-opt .up-drag .btn{
    width: 67.36px;
    height: 67.36px;
    background: #4C4C5C;
    border: 1px solid #5A5A6B;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.main_right-pn .drag-opt .up-drag .btn svg{
    color: #fff;
}

.main_right-pn .drag-opt article h5{
    font-weight: 300;
    font-size: 27px;
    color: #ffffffdb;
}
.main_right-pn .drag-opt article p:first-of-type,
.main_right-pn .drag-opt article h5{
    margin-bottom: 30px;
}
.main_right-pn .drag-opt article p{
    font-weight: 400;
    font-size: 15px;
    line-height: 26px;
    color: #d9d9d9e6;
    margin-bottom: 0;
}

.light-theme-np .key-wrd .txt-bx{ color:#000 !important}


/* Modal start  */

.modal-dialog.cuts-modal{   
    max-width: 1360px; 
    box-shadow: 0px 4px 63px #0000008f;
    border-radius: 6px;
}
.modal-dialog.cuts-modal .modal-content .modal-header{
    background: #20202A;
    padding: 54px 57px;
    border-bottom: 1px solid #3f3f4a70;
    position: relative;
    flex-wrap: wrap;
    gap: 20px;
}
.modal-dialog.cuts-modal .modal-content .modal-header .btn-close{
    position: absolute;
    right: -10px;
    top: -10px;
    background: #469ae0;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 5px solid #0D0D14;
    opacity: 1;
    padding: 5px;
}
.modal-dialog.cuts-modal .modal-content .modal-header .btn-close svg{
    width: 14px;
    height: 14px;
}
.modal-dialog.cuts-modal .modal-content .modal-body{
    background: #1C1C25;
    padding: 50px 60px;
}
.modal-dialog.cuts-modal .modal-content .modal-body hr{
    border: 1px solid rgba(64, 64, 82, 0.61);
    margin-bottom: 60px;
}
.modal-dialog.cuts-modal .modal-content .modal-body .slider-title h4{
    font-weight: 300;
    font-size: 36px;
    color: #FFFFFF;
    margin-bottom: 0;
}
.modal-dialog.cuts-modal .modal-content .modal-body .slider-title{
    margin-bottom: 30px;
}
.modal-dialog.cuts-modal .modal-content .modal-header h5{
    font-weight: 600;
    font-size: 48px;
    text-transform: uppercase;
    color: #FFFFFF;
    line-height: 1;
}
.modal-dialog.cuts-modal .modal-content .modal-header [type="search"], .modal-dialog.cuts-modal .modal-content .modal-header [type="text"]{
    height: 49.48px;
    background: #404053;
    border: 1px solid #67677C;
    border-radius: 4px;
    font-family: 'Rubik';
    font-weight: 400;
    font-size: 17px;
    min-width: 422px;
    padding: 0 25px;
    color: #ffffffb8;
}
.modal-dialog.cuts-modal .modal-content .modal-header [type="search"]::placeholder{
    font-family: 'Rubik';
    font-weight: 400;
    font-size: 17px;
    color: #ffffffb8;
}
.modal-dialog.cuts-modal .modal-content .modal-header [type="submit"]{
    background: #00D1FF;
    border: 1px solid #00D1FF;
    border-radius: 4px;
    font-weight: 600;
    font-size: 18px;
    text-align: center;
    text-transform: uppercase;
    color: #FFFFFF;
    height: 49.48px;
    margin-left: 3px;
    text-shadow: 0px 0.5px 1px #00000040;
}
.modal-dialog.cuts-modal .modal-content .owl-theme .owl-nav{
    text-align: right;
    margin-top: 20px;
}
.modal-dialog.cuts-modal .modal-content .owl-theme .owl-nav button.owl-next{
    background-image: url('@/assets/images/icon/chevron-right.svg') !important;
}
.modal-dialog.cuts-modal .modal-content .owl-theme .owl-nav button.owl-prev{
    background-image: url('@/assets/images/icon/chevron-left.svg') !important;
}
.modal-dialog.cuts-modal .modal-content .owl-theme .owl-nav button.owl-next,
.modal-dialog.cuts-modal .modal-content .owl-theme .owl-nav button.owl-prev{
    background-size: cover;
    background-repeat: no-repeat;
    width: 15px;
    height: 16px;
    background-color: #cccccc00;
}
.modal-dialog.cuts-modal .modal-content .owl-theme .owl-nav button.owl-next span,
.modal-dialog.cuts-modal .modal-content .owl-theme .owl-nav button.owl-prev span{
    display: none;

}
/* Modal end */


/* Modal slider section start here  */

.team-name-sect .item{
    background: #2A2A38;
    border: 0.5px solid #74747429;
    border-radius: 3px;
    display: flex;
    padding: 30px;
    align-items: flex-start;
    gap: 30px;
    min-height: 235px;
}
.team-name-sect .item img{
    max-width: 100%;
    display: block;
    width: 72px !important;
}
.team-name-sect .item article,
.team-name-sect .item article h4,
.team-name-sect .item article p{
    font-family: 'Rubik';
    font-weight: 400;
    margin-bottom: 0;
}
.team-name-sect .item article h4{
    font-size: 24px;
    color: #FFFFFF;
}
.team-name-sect .item article > :nth-child(2){
    font-size: 16px;
    color: #C0C0C0;
    margin: 7px 0 8px; 
}
.team-name-sect .item article > :nth-child(2) span{
    font-weight: 500;
    color: #fff;
}
.team-name-sect .item article > :nth-child(3){
    font-size: 15px;
    color: #FFFFFF;
    margin-bottom: 20px;
}
.team-name-sect .item article > :nth-child(3) svg{
    color: #FEC33E;
    fill: #FEC33E;
    width: 14px;
    height: 14px;
    position: relative;
    top: -2px;
    margin-right: 3px;
}
.team-name-sect .item article a{
    background: #00D1FF;
    border: 1px solid #00D1FF;
    border-radius: 4px;
    text-decoration: none;
    font-family: 'Rubik';
    font-weight: 500;
    font-size: 13px;
    color: #FFFFFF;
    display: inline-block;
    padding: 5.5px 20px;
}
.team-name-sect .item article a[green] {
    background: #2ACF85;
    border: 1px solid #2ACF85;
}



/* Modal slider section end  */




.main_right-pn .section__head{
    margin: 118px 0 55px;
}
.main_right-pn .section__head h4{
    font-weight: 300;
    font-size: 40px;
    color: #FFFFFF;
    margin-bottom: 0;
}
.main_right-pn .table-section.spg-table table thead tr th{
    padding-left: 28px;
    padding-right: 28px;
}
.main_right-pn .table-section.spg-table table thead tr > :nth-child(1),
.main_right-pn .table-section.spg-table table thead tr > :nth-child(2),
.main_right-pn .table-section.spg-table table tbody tr > :nth-child(1),
.main_right-pn .table-section.spg-table table tbody tr > :nth-child(2){
    text-align: left;
}
.main_right-pn .table-section.spg-table table thead tr > :nth-child(1),
.main_right-pn .table-section.spg-table table tbody tr > :nth-child(1){
    padding-left: 28px;
}
.main_right-pn .table-section.spg-table table tbody tr > :nth-child(1){ padding-left: 0;}
.main_right-pn .table-section.spg-table table thead tr > :nth-child(1) {
  text-align: center;
}

.main_right-pn .table-section.spg-table table tbody td svg.bi-x-square {
  color: #ff0000;
  fill: #ff0000;
  width: 69px;
  height: 18px;
  position: relative;
  top: -2px;
  margin-right: 5px;
}

.main_right-pn .table-section.spg-table table tbody td svg.bi-star-fill
{
color:#FEC33E; fill: #FEC33E;
}
/* .main_right-pn .table-section.spg-table table tbody tr > :nth-child(1){
    display: inline-flex;
    align-items: center;
} */
.main_right-pn .table-section.spg-table table thead tr > :nth-child(2),
.main_right-pn .table-section.spg-table table tbody tr > :nth-child(2){
    padding-left: 45px;
    padding-right: 15px;
}
.main_right-pn .table-section.spg-table table tbody tr td:last-child svg{
    color: #7D7D95;
    fill: #7D7D95;
}
.main_right-pn .table-section.spg-table table tbody tr > :nth-child(1) img{
    margin: 0 20px 0 0;
    display: inline-block;
}
.main_right-pn .table-section.spg-table table tbody td{
    white-space: unset;
}
.main_right-pn .table-section.spg-table .tbl-head p{
    font-family: 'Rubik';
    font-weight: 400;
    font-size: 18px;
    color: #D3D3D3;
    margin-top: 10px;
}
.main_right-pn .table-section.spg-table .tbl-head p span{
    color: #FFAF2E;
}

.main_right-pn  table tbody td svg{
    color: #FEC33E;
    fill: #FEC33E;
    width: 14px;
    height: 14px;
    position: relative;
    top: -2px;margin-right: 5px;
}
.main_right-pn nav[aria-label="navigation-cuts"] ul{
    justify-content: flex-end;
    column-gap: 10px;
}
.main_right-pn nav[aria-label="navigation-cuts"] ul .page-link{
    padding: 0;
    line-height: 1;
    font-family: 'Rubik';
    font-weight: 400;
    font-size: 15px;
    color: #FFFFFF;
    background: #444457b3;
    border: 0.5px solid #85858529;
    border-radius: 3px;
    display: flex;
    padding: 0 16px;
    height: 35px;
    align-items: center;
    justify-content: center;
    transition: all ease-in-out 300ms;
}
.main_right-pn nav[aria-label="navigation-cuts"] ul .active.page-link,
.main_right-pn nav[aria-label="navigation-cuts"] ul .page-link:hover {
    background: rgba(0, 209, 255, 0.7) !important;
    color: #fff !important;
}

/*  */


.swiper-button-next,
.swiper-button-prev{
  bottom: 0;
  top: unset !important;
  width: 16px;
  height: 16px;
}
.swiper-button-next::after,
.swiper-button-prev::after{
  font-size: unset !important;
  color: #00d1ff !important;
}
.swiper-button-prev, .swiper-container-rtl .swiper-button-next{
  left: auto !important;
  right: 40px !important;
}
.swiper-container{
  padding-bottom: 40px;
}

.modal-content {

    background-color: #1c1c25;

}

/* table row section start Here  */
.main_right-pn .table-section .ciel h6{
    font-family: 'Rubik';
    font-weight: 400;
    font-size: 34px;
    letter-spacing: 0.01em;
    color: #FFFFFF;
}
.main_right-pn .table-section ul.nav.nav-tabs{
    gap: 10px;
    border-bottom-color: #424255;
}
.main_right-pn .table-section .resp-table{
    width: 100%;
  
}
.main_right-pn .table-section .resp-table::-webkit-scrollbar {
    width: 2px;
}
.main_right-pn .table-section .resp-table::-webkit-scrollbar-track {
    border-radius: 0;
}
.main_right-pn .table-section .resp-table::-webkit-scrollbar-thumb {
    background: transparent; 
    border-radius: 0;
}
.main_right-pn .table-section table.blue-table thead th{
    border-color: #2C88D4;
}.main_right-pn .table-section table.blue-table thead{
    border-left: 1px solid #2571B0;
    border-right: 1px solid #2571B0;
    background-color:#2571B0;
}
.main_right-pn .table-section table.skygren-table thead th{
    border-color: #46C2B6;
}.main_right-pn .table-section table.skygren-table thead{
    border-left: 1px solid #3CAEA3;
    border-right: 1px solid #3CAEA3;
    background-color:#3CAEA3;
}
.main_right-pn .table-section table.purpl-table thead th{
    border-color: #796DE8;
}.main_right-pn .table-section table.purpl-table thead{
    border-left: 1px solid #685DC9;
    border-right: 1px solid #685DC9;
    background-color:#685DC9;
}
.main_right-pn .table-section table.whitgren-table thead th{
    border-color: #3ACA74;
}.main_right-pn .table-section table.whitgren-table thead{
    border-left: 1px solid #49AE72;
    border-right: 1px solid #49AE72;
    background-color:#49AE72;
}
.main_right-pn .table-section table.orgn-table thead th{
    border-color: #EFA83C;
}.main_right-pn .table-section table.orgn-table thead{
    border-left: 1px solid #D6993D;
    border-right: 1px solid #D6993D;
    background-color:#D6993D;
}
.main_right-pn .table-section{
    margin-bottom: 115px;
}
.main_right-pn .table-section .tbl-head{
    margin-bottom: 26px;
}
.main_right-pn .table-section .tbl-head h6{
    font-weight: 300;
    font-size: 30px;
    text-transform: capitalize;
    color: #FFFFFF;
    margin: 0;
}
.main_right-pn .table-section .tbl-head p{
    font-weight: 500;
    font-size: 23px;
    margin-bottom: 0px;
}
.main_right-pn .table-section .tbl-head p[green]{
    color: #3ACA74;
}
.main_right-pn .table-section .tbl-head p[organic]{
    color: #EAA337;
}
.main_right-pn .table-section .tbl-head p[blu]{
    color: #00D1FF;
}
.main_right-pn .table-section table{
    width: 100%;
    border-radius: 3px;
    /*max-width: 830px;*/
}
.main_right-pn .table-section table thead th{
    font-family: 'Rubik';
    font-weight: 400;
    font-size: 17px;
    line-height: 1;
    letter-spacing: 0.02em;
    text-transform: capitalize;
    color: #FFFFFF;
    padding: 20px 0;
    text-align: center;
    border-style: solid;
    border-right-width: 1px;
    white-space: nowrap;
}
.main_right-pn .table-section table thead th:last-child{
    border-right: none;
}
.main_right-pn .table-section table tbody td{
    font-family: 'Rubik';
    font-weight: 400;
    font-size: 16px;
    color: #E0E0E0;
    border-left: 1px solid #3f3f5299;
    text-align: center;
    line-height: 1;
    border-bottom: 1px solid #3f3f5299;
    padding: 13px 0;
    white-space: nowrap;
}
.main_right-pn .table-section table tbody > :nth-child(odd){
    background-color: #292938;
}
.main_right-pn .table-section table tbody > :nth-child(even){
    background-color: #262635;
}
.main_right-pn .table-section table tbody tr td:last-child{
    border-right: 1px solid #3f3f5299;
}
.main_right-pn .table-section table tbody td img{
    max-width: 100%;
    display: block;
    height: auto;
    margin: auto;
}
.main_right-pn .table-section table.last-table tr > :nth-child(1){
    text-align: left;
    padding-left: 30px;
}
.main_right-pn .table-section table.last-table thead th{
    border-color: #00CAF6;
}
.main_right-pn .table-section table.last-table thead{
    border-left: 1px solid #00ADD2;
    border-right: 1px solid #00ADD2;
    background-color:#00ADD2;
}      
.main_right-pn .table-section table.last-table tr td:last-child,
.main_right-pn .table-section table.last-table tr th:last-child{
    text-align: center;
    padding-left: 0;
}
.main_right-pn .table-section table.last-table tr td{
    padding: 20px 0;
}
.main_right-pn .copy-rrt{
    margin-top: 148px;
    padding-bottom: 45px;
}
.main_right-pn .copy-rrt p{
    text-align: center;
    margin: 0;
    font-weight: 400;
    font-size: 15px;
    letter-spacing: 0.01em;
    color: #ffffffa8;
}



.main_right-pn .table-section.spg-table.app-trck-tbl table tbody tr > :nth-child(2) {
  padding-left: 25px;
  padding-right: 15px;
}

.main_right-pn .table-section.spg-table.app-trck-tbl table tbody td img {
  max-width: 100%;
  display: block;
  height: auto;
  margin: 0 10px 0 0;
  float:left;
}

 .main_right-pn .table-section.spg-table table tbody tr > :nth-child(1){ text-align: center;}



/* table row section end */




/* light theme start here */

.light-theme-np .main_left-pn {
    box-shadow: 0px 0px 20px #d7d7d7;
    background-color: #fff;
}
.light-theme-np .main_left-pn .logo-sect h4,
.light-theme-np .main_left-pn .accd-sect .accordion-button,
.light-theme-np .main_left-pn .accd-sect .accordion-item .accordion-body li,
.light-theme-np .main_left-pn .dl-mode-btn span,
.light-theme-np .main_right-pn header .ite_sm:first-of-type h5,
.light-theme-np .main_right-pn header .ite_sm:last-child .prof li .nav-link,
.light-theme-np .main_right-pn .card-section [class^="col"]:nth-child(odd) .item .ttx h6,
.light-theme-np .main_right-pn .card-section [class^="col"]:nth-child(odd) .item .ttx h5,
.light-theme-np .main_right-pn .chat-bar .chat-title h5,
.light-theme-np .main_right-pn .chat-bar .chat-title > div span:first-child,
.light-theme-np .main_right-pn .table-section .ciel h6,
.light-theme-np .main_right-pn .chat-bar .chat-title > div input[type="date"],
.light-theme-np .main_right-pn .table-section .tbl-head h6,
.light-theme-np .main_right-pn .table-section table tbody td,
.light-theme-np .main_right-pn .copy-rrt p,
.light-theme-np .main_right-pn .section__head h4,
.light-theme-np .main_right-pn .drag-opt article h5,
.light-theme-np .main_right-pn .drag-opt article p,
.light-theme-np .main_right-pn .table-section.spg-table .tbl-head p,
.light-theme-np .main_right-pn header .ite_sm:last-child .form-group .form-control,
.light-theme-np .main_right-pn nav[aria-label="navigation-cuts"] ul .page-link,
.light-theme-np .main_right-pn .drag-opt .up-drag .btn svg,
.light-theme-np .modal-dialog.cuts-modal .modal-content .modal-header h5,
.light-theme-np .modal-dialog.cuts-modal .modal-content .modal-header [type="search"],
.light-theme-np .team-name-sect .item article h4,
.light-theme-np .team-name-sect .item article > :nth-child(2){
    color: #212529;
}
.light-theme-np .swiper-button-next::after,
.light-theme-np .swiper-button-prev::after{
  color: white !important;
}
.light-theme-np .modal-dialog.cuts-modal .modal-content .modal-header [type="search"]::placeholder{
    color: #919496;
}


.light-theme-np .main_left-pn .accd-sect .accordion-button img,
.light-theme-np .main_left-pn .accd-sect .accordion-button::after {
    filter: sepia(1) saturate(1) brightness(0.1) hue-rotate(135deg);
}
.light-theme-np .accordion-collapse {
    border-left: 1px solid #cad0d6;
    box-shadow: 7px 0px 12px -8px #a9a9a9;
}
.light-theme-np .modal-content,
.light-theme-np .main_right-pn,
.light-theme-np .accordion-collapse,
.light-theme-np .main_right-pn .growth-chh{
    background-color: #fff;
}
.light-theme-np .slider:before,
.light-theme-np .main_right-pn header .navbar-collapse::after,
.light-theme-np .main_right-pn header .navbar-collapse::before {
    background-color: #191927;
}
.light-theme-np .main_right-pn header .navbar-collapse::before {
    box-shadow: 0 11px #191927;
}
.light-theme-np .main_right-pn header .ite_sm:last-child .form-group .form-control,
.light-theme-np .main_right-pn .chat-bar .chat-title > div .select-ar:last-child,
.light-theme-np .modal-dialog.cuts-modal .modal-content .modal-header [type="search"]{
    background-color: #efefefe6;
    border: 1px solid #d4d4d457;
}
.light-theme-np .modal-dialog.cuts-modal .modal-content .owl-theme .owl-nav button.owl-prev {
    background-image: url('@/assets/images/icon/chevron-left-black.svg') !important;
}
.light-theme-np .modal-dialog.cuts-modal .modal-content .owl-theme .owl-nav button.owl-next {
    background-image: url('@/assets/images/icon/chevron-right-black.svg') !important;
}
.light-theme-np .modal-dialog.cuts-modal .modal-content .modal-body hr{
    border-color: #000000;
}
.light-theme-np .main_left-pn .logo-sect .close-nav button svg{
    fill: #000000 !important;
}
.light-theme-np .main_right-pn header .ite_sm:last-child .form-group [type="submit"] svg,
.light-theme-np .main_right-pn header .ite_sm:last-child .notify{
    fill: #5e5e6d;
    color: #5e5e6d;
}
.light-theme-np .main_right-pn header .ite_sm:last-child .notify .badge-nofy{
    border-color: #fff;
}
.light-theme-np .main_right-pn .card-section [class^="col"]:nth-child(odd) .item,
.light-theme-np .main_right-pn nav[aria-label="navigation-cuts"] ul .page-link {
    background-color: #dadada;
}
.light-theme-np .main_right-pn .table-section table tbody > :nth-child(odd) {
    background-color: #eaecee;
}
.light-theme-np .main_right-pn .table-section table tbody > :nth-child(even) {
    background-color: #e1e1e1;
}
.light-theme-np .main_right-pn .table-section table tbody td,
.light-theme-np .main_right-pn .table-section table tbody tr td:last-child{
    border-color: #d6d6d6;
}
.light-theme-np .main_right-pn .table-section ul.nav.nav-tabs .nav-item .nav-link {
    font-weight: 600;
    font-size: 16px;
    text-transform: uppercase;
    color: #000000;
    background-color: #eaecee;
    border: unset !important;
}
.light-theme-np .main_right-pn .table-section ul.nav.nav-tabs {
    gap: 10px;
    border-bottom-color: #d6d6d6;
}
.light-theme-np .main_right-pn .table-section ul.nav.nav-tabs .nav-item .nav-link.active {
    color: #fff;
    background-color: #ED553B;
}
.light-theme-np .team-name-sect .item article > :nth-child(2) span,
.light-theme-np .team-name-sect .item article > :nth-child(3){
    color: #000000;
}
.light-theme-np .main_left-pn::-webkit-scrollbar-thumb {
    background: #d6d6d6; 
}
.light-theme-np .main_right-pn .drag-opt .up-drag {
    background: #ececec7a;
    border: 0.5px solid #74747414;
    box-shadow: 0px 4px 4px #8f8f8f70;
}
.light-theme-np .main_right-pn .drag-opt .up-drag .btn {
    background: #e1e1e1;
    border: 1px solid #cacaca;
}
.light-theme-np .modal-dialog.cuts-modal .modal-content .modal-header {
    background: #f3f3f3;
}
.light-theme-np .modal-dialog.cuts-modal .modal-content .modal-body{
    background-color: #fff;
}
.light-theme-np .team-name-sect .item{
    background-color: #efefefe6;
    border: 1px solid #cacaca;
}

#keyword_wrapper{ position:relative !important;}
.key-wrd .txt-bx{background: #323243;
border: 1px solid #49495F !important;
border-radius: 4px;

height: 48px;
font-weight: 400;
font-size: 17px;
letter-spacing: 0.02em;
color: #ffffffb8;}
.key-wrd .simple-typeahead{float:left; width:60% !important;}
.serach-result-part.key-wrd {
  padding-top: 10px;
  padding-bottom: 30px;
}
.key-wrd .keyWordsubmit{
 width: 190.77px;
height: 46.48px;
background: #00c1ec;
border-radius: 4px;
font-weight: 600;
font-size: 15px;
text-align: center;
text-transform: uppercase;
color: #fff;
line-height: 27px;
border: none;
outline: none;
margin-left: 5px;
}
/* light theme start here */

/* main right pana section end  */



/*/   Responsive start here  /*/

@media (max-width:1799px){
    
    .main_right-pn .table-section .tbl-head p,
    .main_left-pn .dl-mode-btn span {
        font-weight: 300;
    }
    .main_right-pn .table-section table thead th {
        padding: 16px 3px;
    }
    .main_right-pn .table-section table tbody td,
    .main_right-pn .table-section table thead th,
    .main_right-pn header .ite_sm:last-child .form-group .form-control,
    .main_right-pn .card-section .item .ttx h6,
    .main_left-pn .accd-sect .accordion-button,
    .main_left-pn .accd-sect .accordion-item .accordion-body li,
    .main_right-pn header .ite_sm:first-of-type .btn {
        font-size: 14px;
    }
    .main_right-pn .table-section {
        margin-bottom: 90px;
    }
    .main_right-pn header .ite_sm:first-of-type {
        gap: 15px;
        margin-left: 20px;
    }
    .main_right-pn header .ite_sm:first-of-type h5 {
        font-size: 18px;
    }
    .main_right-pn header .ite_sm:first-of-type .btn {
        padding: 6px 10px;
    }
  
    .main_right-pn header .ite_sm:last-child {
        gap: 20px;
    }
    .main_right-pn header .ite_sm:last-child .prof li .nav-link,
    .main_left-pn .dl-mode-btn span {
        font-size: 16px;
    }
    .main_right-pn .chat-bar .chat-title h5,
    .main_right-pn .table-section .ciel h6 {
        font-size: 30px;
    }
    .main_right-pn .growth-chh {
        margin: 30px 0 90px;
    }
    .main_right-pn .table-section .tbl-head h6 {
        font-size: 24px;
    }
    .main_right-pn .table-section .tbl-head p {
        font-size: 18px;
    }
    .main_left-pn .accd-sect .accordion-item {
        margin-bottom: 30px;
    }
    .main_left-pn .accd-sect .accordion-item .accordion-body li {
        font-weight: 300;
    }
    .main_left-pn .accd-sect .accordion-item .accordion-body {
        padding: 10px 0 0 60px;
    }
    .main_left-pn .accd-sect .accordion-button img{
        width: 24px;
    }

}

@media (max-width:1440px){
    .modal-dialog.cuts-modal {
        max-width: 1099px;
    }
    .modal-dialog.cuts-modal .modal-content .modal-header {
        padding: 22px 30px;
    }
    .modal-dialog.cuts-modal .modal-content .modal-header h5 {
        font-size: 28px;
    }
    .modal-dialog.cuts-modal .modal-content .modal-header [type="search"] {
        height: 42.48px;
        font-size: 15px;
        min-width: 400px;
        padding: 0 20px;
    }
    .modal-dialog.cuts-modal .modal-content .modal-header [type="submit"] {
        font-size: 15px;
        
    }
    .modal-dialog.cuts-modal .modal-content .modal-body .slider-title h4 {
        font-size: 22px;
    }
    .modal-dialog.cuts-modal .modal-content .modal-body {
        padding: 30px 30px;
    }
    .modal-dialog.cuts-modal .modal-content .modal-body .slider-title {
        margin-bottom: 20px;
    }

    .team-name-sect .item {
        padding: 20px !important;
        gap: 18px !important;
        min-height: 190px;
    }
    .team-name-sect .item img {
        width: 64px !important;
    }
    .team-name-sect .item article h4 {
        font-size: 18px;
    }
    .team-name-sect .item article > :nth-child(2),
    .team-name-sect .item article > :nth-child(3){
        font-size: 13px;
    }
    .team-name-sect .item article > :nth-child(3) {
        margin: 16px 0;
    }
    .team-name-sect .item article a {
        padding: 3.5px 15px;
    }
    .modal-dialog.cuts-modal .modal-content .modal-body hr {
        margin-bottom: 40px;
    }

}
@media (max-width:1280px) {
    .main_right-pn header .ite_sm:last-child .prof li .nav-link span:last-child{
        display: none;
    }
    .main_right-pn header .ite_sm:last-child .form-group .form-control {
        height: 44px;
    }
    .main_right-pn header .ite_sm:last-child {
        gap: 12px;
    }
    .main_right-pn header .ite_sm:last-child .prof li .nav-link img {
        width: 48px;
        margin-right: 0px;
        max-width: 100%;
    }
    .main_right-pn .drag-opt {
        margin-bottom: 100px;
    }
}

@media (max-width:1199px) { 
   
    
    .main_right-pn header .ite_sm:last-child .form-group .form-control {
        min-width: auto;
    }
    .main_right-pn .table-section {
        margin-bottom: 30px;
    }
    .main_right-pn .chat-bar .chat-title {
        margin-top: 50px;
    }
    .main_right-pn .growth-chh {
        margin: 30px 0 ;
    }
    .main_right-pn .chat-bar .chat-title h5,
    .main_right-pn .table-section .ciel h6 {
        font-size: 25px;
    }


    .main_right-pn .section__head {
        margin: 45px 0 30px;
    }
    .main_right-pn .section__head h4 {
        font-size: 30px;
    }

    .modal-dialog.cuts-modal {
        width: calc(100% - 50px);
    }

    .modal-dialog.cuts-modal .modal-content .modal-header {
        padding: 18px 20px;
    }
}

@media (max-width:1023px) {
    .main_left-pn .logo-sect .close-nav {
       display: block;
    }
   
    .main_right-pn {
        margin-left: 0;
    }
    .main_right-pn .copy-rrt {
        margin-top: 50px;
        padding-bottom: 20px;
    }
    .side-collapse .main_right-pn {
        margin-left: 0;
    }
    .side-collapse .main_left-pn {
        left: 0;
        width: 80%;
        max-width: 430px;
    }
    .side-collapse .main_left-pn .accd-sect .accordion-button .mini__menu,
    .side-collapse .main_left-pn .accordion-button::after,
    .side-collapse .main_left-pn .logo-sect h4 {
        display: inline-block;
    }
    .side-collapse .accordion-collapse{
        position: static;
    }
    .side-collapse .main_left-pn .accd-sect .accordion-item .accordion-body {
        padding-left: 59px;
    }

    .main_right-pn .drag-opt {
        margin-bottom: 70px;
    }
}

@media (max-width:991px)  {
    .side-collapse .dl-mode-btn > span {
        display: block;
    }
}

@media (max-width:767px) {
    .main_right-pn .card-section .item img {
        width: 50px;
    }
    .main_right-pn .card-section .item .ttx h5 {
        font-size: 20px;
    }
    .main_right-pn .chat-bar .chat-title > div span:first-child {
        font-size: 16px;
        margin-right: 20px;
    }
    .main_right-pn .table-section .tbl-head h6,
    .main_right-pn .chat-bar .chat-title h5,
    .main_right-pn .table-section .ciel h6 {
        font-size: 22px;
    }
    .main_right-pn .table-section ul.nav.nav-tabs .nav-item .nav-link {
        font-weight: 400;
        font-size: 15px;
    }
    .main_right-pn .table-section .tbl-head p{
        margin-bottom: 4px;
    }
    .main_right-pn header .ite_sm:first-of-type{
        order: 1;
        width: 100%;
        justify-content: center;
    }
    .main_right-pn header {
        padding-top: 20px;
    }
    .main_right-pn header .ite_sm:last-child{
        width: calc(100% - 40px);
    }
    .main_right-pn header .ite_sm form{
        flex-grow: 1;
    }
    .main_right-pn .chat-bar .chat-title,
    .main_right-pn .chat-bar .chat-title > div{
         flex-wrap: wrap;
    }
    .main_right-pn .chat-bar .chat-title > div{
        margin-left: auto;
    }
    .main_right-pn .growth-chh {
        margin: 10px 0;
    }



    .main_right-pn .table-section.reper-table table tbody tr > :nth-child(2),
    .main_right-pn .table-section.reper-table table thead tr > :nth-child(2) {
        text-align: left;
        padding-left: 20px;
    }

    .main_right-pn .drag-opt {
        flex-wrap: wrap;
    }
    .main_right-pn .table-section.spg-table table thead tr th {
        padding-left: 16px;
        padding-right: 16px;
    }
    .main_right-pn .drag-opt .up-drag {
        width: 140px;
        height: 140px;
    }
    .main_right-pn .drag-opt .up-drag .btn {
        width: 50px;
        height: 50px;
    }
    .main_right-pn .drag-opt article h5 {
        font-size: 22px;
    }
    .main_right-pn .drag-opt article p {
        font-size: 14px;
        line-height: 22px;
    }
    .main_right-pn .drag-opt article p:first-of-type,
    .main_right-pn .drag-opt article h5 {
        margin-bottom: 14px;
    }
    .swiper-slide { width:100% !important; display: block;}
    .modal-dialog.cuts-modal .modal-content .modal-header [type="search"], .modal-dialog.cuts-modal .modal-content .modal-header [type="text"]{ min-width:100%;}
  .modal-header form {display: flex;flex-wrap: initial;}
  .main_right-pn .table-section.spg-table.app-trck-tbl table tbody tr > :nth-child(2) {
  padding-left: 15px;
  padding-right: 15px;
  word-break: break-all;
}
    .team-name-sect .item {
        flex-wrap: wrap;
        justify-content: center;
    }
    .team-name-sect .item{ display: block;}
    .team-name-sect .item > a{ display: table; margin: 0 auto 20px;}
    .modal-dialog.cuts-modal .modal-content .modal-header [type="search"], .modal-dialog.cuts-modal .modal-content .modal-header [type="text"]{width: 219px; font-size: 13px; padding: 0 9px;}
    .key-wrd .simple-typeahead{ width:100% !important}
    .key-wrd .keyWordsubmit{ margin-left: 0; margin-top:10px;}
}
@media (max-width:599px){
    .modal-dialog.cuts-modal .modal-content .modal-header form{
        width: 100%;
    }
    .modal-dialog.cuts-modal .modal-content .modal-header [type="search"] {
        min-width: 100%;
        margin-bottom: 20px;
    }
}
@media (max-width:576px){
    .main_right-pn .table-section table tbody td{
        font-size: 12px;
    }
    .main_right-pn .table-section .tbl-head h6,
    .main_right-pn .chat-bar .chat-title h5,
    .main_right-pn .table-section .ciel h6 {
        font-size: 18px;
    }
    .main_right-pn .table-section .tbl-head p,
    .main_right-pn header .ite_sm:first-of-type h5 {
        font-size: 14px;
    }
    .main_right-pn header .ite_sm:first-of-type .btn {
        font-size: 12px;
    }
    .main_right-pn .copy-rrt p {
        font-weight: 300;
        font-size: 12px;
    }
    .main_right-pn .card-section {
        margin-top: 50px;
    }
    .main_right-pn header .ite_sm:last-child {
        gap: 8px;
        width: 100%;
    }
    .main_right-pn header .ite_sm:last-child .prof li .nav-link img {
        width: 40px;
    }
    .main_right-pn {
        padding: 0px 10px;
    }
    .main_right-pn header .ite_sm:first-of-type {
        margin-left: 0px;
    }
    .main_right-pn .growth-chh {
        padding: 10px;
    }
    .main_right-pn header .ite_sm:last-child{
        order: 1;
    }
    .main_right-pn header .navbar-collapse{
        order: 2;
    }
    .main_right-pn header .ite_sm:first-of-type{
        order: 3;
        width: auto;
        margin: auto;
    }


    .main_right-pn .section__head {
        margin: 35px 0 25px;
    }
    .main_right-pn .section__head h4 {
        font-size: 24px;
    }

    .modal-dialog.cuts-modal {
        width: calc(100% - 22px);
    }

    .modal-dialog.cuts-modal .modal-content .modal-header .btn-close {
        right: -4px;
        top: -3px;
    }
    
}

@media (max-width:375px){
    .team-name-sect .item {
        flex-wrap: wrap;
        justify-content: center;
    }
    .team-name-sect .item article,
    .team-name-sect .item article h4,
    .team-name-sect .item article p {
        font-family: 'Rubik';
        font-weight: 400;
        margin-bottom: 0;
        text-align: center;
    }
}
</style>