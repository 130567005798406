<template>
  <div class="ful-wdth">
    <div class="tab_boxe">
        <h2>Keyword Ranking</h2>
            <div class="keyWordFirstBox">
                <div class="row">
                    <div class="col-lg-6 keyWordFirstLeft">
                    <div class="oone">

                    <Form @submit="addkewwordinuserlistbyefrom" id="appkeywordsearchfrm" :validation-schema="schema" v-slot="{ errors }"> 
                    <div id="Keyword_id_wrapper" class="simple-typeahead">
                     <Field v-if="user.data" name="user_id"  type="hidden" :value="user.data.userid"/> 
                     <Field name="appkeyword" class="simple-typeahead-input headersearch form-control"  type="text" placeholder="Start writing..." :class="{ 'has-error': errors.email }" />
                     <ErrorMessage name="appkeyword" class="text-danger" />
                    </div>

                    <!-- <vue3-simple-typeahead
                    id="Keyword_id"
                    placeholder="Start writing..."
                    v-model="keywordsearchval"
                    :items="autoloadkeyword"
                    :value="keywordsearchval"
                    class="form-control kywrd-txt"
                    :minInputLength="3"
                    :itemProjection="
                    (item) => {
                      return item.title;
                    }
                  "
                    @selectItem="selectItemEventHandler"
                    @onInput="onInputEventHandler"
                    @onFocus="onFocusEventHandler"
                    @onBlur="onBlurEventHandler"
                    >
        
                   </vue3-simple-typeahead> -->
                        
                    <button class="keyWordsubmit" type="submit">Add Keywords</button>
                    </Form>  
                   </div>
                        <p>See if you outrank the competition for your keywords. Easily monitor your app store ranking and chart your progress. Add your keywords and competitors you wish to follow. Reports will usually take 24 hours to process. </p>
                    </div>
                    <div class="col-lg-6 keyWordFirstRight" v-if="userfollowkewwords.length>0">
                        <div class="heading">
                            <h3>Keywords You're Following</h3>
                            <button class="btn" type="button">
                              <a data-bs-toggle="modal" data-bs-target="#exampleModal"> View All </a>
                            </button>
                        </div>
                        <div class="table-section">
                            <div class="resp-table">
                              <table class="blue-table trck-tbl">
                                <thead>
                                  <tr>
                                    <th>#</th>
                                    <th>Keywords</th>
                                    <th style="text-align: center;">Following</th>
                                  </tr>
                                </thead>
                                <tbody>
                                    <tr v-bind:key="result.ID" v-for="(result,index) in userfollowkewwords.slice(0,5)">
                                        <td>{{index+1}}</td>
                                        <td>
                                            <div class="keyBoxDiv">
                                                <h2>{{result.title}}  </h2>
            
                                                <router-link :to="'/key-tracking-details-page/'+result.title.replace(/\s/g, '_')"> View Report </router-link>
                                            </div>
                                        </td>
                                        <td>
                                            <a href="javascript:void(0)" @click="removekeyword(result.ID)"> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-square" viewBox="0 0 16 16"><path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"></path><path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"></path></svg></a>
                                            <!-- <div class="form-check tableChek">
                                                <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                                              </div> -->
                                        </td>
                                    </tr>
                                    
                                </tbody>
                              </table>
                            </div>
                                
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-12 keyWordSecBox">


                <div class="heading">
                  <h3>Apps You're Following</h3>
                   <div class="serach-result-part key-wrd">
           <vue3-simple-typeahead
                id="compayarpps"
                v-model="searchkey"
                :value="searchkey"
                placeholder="Search by app name"
                :items="searchAppresult"
                class="searching form-control txt-bx"
                :minInputLength="3"
                :itemProjection="
                (item) => {
                      let url1=item.app_logo;
                      url=url1.replace('%3', '');
                      let html=`<div class='compayarpps header-searching'><div class='img-sec'><img src='${url}'></div>`;
                      html=html+`<div class='text-part'><h3>`+item.app_title+`</h3>`;
                      html=html+'<p>'+item.app_rating+' of 5 stars ('+item.app_num_of_reviews+')</p></div><div>';
                      return html;
                }"
                @selectItem="selectItemAppsFlowing"
                @onInput="onInputEventHandlerApps"
                @onFocus="onFocusEventHandlerApps"
                @onBlur="onBlurEventHandlerApps"
                >  

                <template #list-item-text="slot">
                    <span v-html="slot.itemProjection(slot.item)" class="material-icons"></span>
                </template>      
            </vue3-simple-typeahead>
            <button class="keyWordsubmit" type="button" @click="addapsinuserlist">App to Follow</button>

       </div> 
                </div>



                <div class="table-section">
                    <div class="resp-table">
                      <table class="skyBackground trck-tbl" v-if="userfollowappslist.length>0">
                        <thead>
                          <tr>
                            <th style="text-align: center;">Logo</th>
                            <th style="text-align: left;">App Name</th>
                            <th>Description</th>
                            <th style="text-align: center;">Rating</th>
                            <th style="text-align: center;">Reviews</th>
                            <th style="text-align: center;">Follow</th>
                          </tr>
                        </thead>
                        <tbody>
                            <tr v-bind:key="result.app_id" v-for="result in userfollowappslist">
                                <td><router-link :to="'/about-details/' + result.app_id"><img :src=result.app_logo alt=""> </router-link></td>
                                <td style="text-align: left;"> <router-link :to="'/about-details/' + result.app_id">{{result.app_title}}</router-link></td>
                                <td> <router-link :to="'/about-details/' + result.app_id">{{result.app_brief_description}}</router-link></td>
                                <td>{{result.app_rating==NULL?'0':result.app_rating}}</td>
                                <td>{{result.app_num_of_reviews}}</td>
                                <td>
                                     <a href="javascript:void(0)" @click="unflowapps(result.app_id)" ><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-square" viewBox="0 0 16 16">
                      <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
                      <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                    </svg></a>
                                </td>
                            </tr>
                           
                        </tbody>
                      </table>
                      <div class="data-nt"  v-else>Sorry no data found.</div>
                    </div>
                        
                </div>
            <nav aria-label="navigation-cuts">
                    <pagination 
                    v-model="page" 
                    :records="userfollowapptotal" 
                    :per-page="10" 
                    :options={chunk:5} 
                    @paginate="nextprevious($event)"
                    />          
            </nav>
            </div>

    <!-- Modal start -->
      <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" v-if="userfollowkewwords.length>0">
        <div class="modal-dialog cuts-modal">
          <div class="modal-content">
            <div class="modal-header">
              <button type="button" ref="Close2" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
                  <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
                </svg>
              </button>
            </div>
            <div class="modal-body">
                <div class="table-section">
                    <div class="resp-table">
                      <table class="blue-table trck-tbl">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Keywords</th>
                            <th style="text-align: center;">Following</th>
                          </tr>
                        </thead>
                        <tbody>
                            <tr v-bind:key="result.ID" v-for="(result,index) in userfollowkewwords">
                                <td>{{index+1}}</td>
                                <td>
                                    <div class="keyBoxDiv">
                                        <h2>{{result.title}}</h2>
                                           <a href="javascript:void(0)" @click="changerout(result.title.replace(/\s/g, '_'))">View Report</a>
                                       
                                        <!--<router-link :to="'/key-tracking-details-page/'+result.title.replace(/\s/g, '_')"> View Report </router-link>-->
                                    </div>
                                </td>
                                <td>
                                  <a href="javascript:void(0)" @click="removekeyword(result.ID)">  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-square" viewBox="0 0 16 16"><path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"></path><path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"></path></svg></a>
                                </td>
                            </tr>
                        </tbody>
                      </table>
                      <div class="pseduo-track"></div>
                    </div>
                        
                </div>
            </div>
          </div>
        </div>
      </div>
    <!-- Modal end -->

     
      </div>
  </div>
</template>

<script>

/*mport SwiperCore, { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/swiper-bundle.min.css";*/
import { Field, Form, ErrorMessage } from 'vee-validate'
import * as yup from 'yup'
import {mapState,mapActions} from 'vuex'
import Toastr from 'toastr'
import { useRoute } from 'vue-router'
import 'toastr/build/toastr.css'
//SwiperCore.use([Navigation]);


export default {
   name:'KeyWordTracking',
   
  data() {
       const schema = yup.object({
            appkeyword: yup.string().required().label('The keyword'),
        });
        return {
          schema,
          swiperOptions: {
            loop: true,
            observer: true,
            observeParents: true,
          },
          isload:false,
          startindex:1,
          popoularapsload:false,
          page: 1,
          popoularaps:'',
          params: {
                  page: 1,
                  per_page: 10,
                  user_id: '',
              },
          isfollowdataparams: {
                user_id: '',
                app_id: '',
                action:'',
            },
         userkeydata: {
                keywordid: '',
                keywordtitle: '',
                user_id: '',
            },
        removeuserkeydata: {
            keywordid: '',
            user_id: '',
        }, 

         autoloadkeyword:[] ,
         searchappslist:[],
         listFiltered: [],
		   data: {
					input: '',
					selection: null,
           userid: null,
				},  
        filterdata: {
					input: '',
					selection: null,
          userid: null,
				},  
         isloadfollowing:false,  
         searchkey : null,          
         keywordsearchval:'', 
         appfollowingid:false,       

        }
  },
  components: {
            Field,
            Form,
            ErrorMessage,
        },
   mounted() {  
        const vm = this;
        this.isload=true; 
        const route=useRoute();     
        vm.isfollowdataparams.user_id=vm.user.data.userid;
        vm.params.user_id=vm.user.data.userid;
        vm.userkeydata.user_id=vm.user.data.userid;
        vm.filterdata.userid = this.user.data.userid;
        if(route.query.page){
          if(parseInt(route.query.page)>1){
              this.page=route.query.page;
              this.params.page=route.query.page;
              }else{
                this.page=1;
                this.params.page=1;
                vm.userfollowapsdetails( vm.isfollowdataparams);
              }
          }else{
              vm.userfollowapsdetails( vm.isfollowdataparams);
          }
        // vm.userfollowapsdetails( vm.isfollowdataparams);
        vm.userfollowaps(vm.isfollowdataparams);
        vm.getAllkeywordsbyuser(vm.isfollowdataparams);
        vm.SearchAppbykeyword(this.filterdata);
  },
  computed: {
      ...mapState({
          userfollowappslist: (state) => state.AppListModule.getuserfollowapplist,
          user: (state) => state.authModule.user,
          userfollowapps: (state) => state.authModule.userfollowapps,
          userfollowapptotal: (state) => state.AppListModule.userfollowapptotal,
        //  popoularaps: (state) => state.AppListModule.popoularaps,
         // resentapps: (state) => state.AppListModule.resentapps,
          allkeywords: (state) => state.AppListModule.allkeywords,
          userfollowkewwords: (state) => state.AppListModule.alluserfollowkeywords,
          searchAppresult: (state) => state.AppListModule.searchAppresult,
      }),

        /*userfollowkeywordlist() {
            return this.allkeywords.filter( (items) => this.userfollowkewwords.some((items2) => items2.keywords_id === items.ID));
        },*/

       /* allkeywordstitle() {
            let filterkeyword=this.allkeywords.filter( (items) => !this.userfollowkewwords.some((items2) => items2.keywords_id === items.ID));
            return [...new Set(filterkeyword.map(item => item.title))];
        
        },*/
            
  },
  methods:{
        ...mapActions({
                userfollowaps: 'authModule/userfollowaps',
                userfollowapsdetails: 'AppListModule/UserFollowApsDetails',
                getAllAppkeywords: 'AppListModule/GetAllAppkeyWords',
                getAllkeywordsbyuser: 'AppListModule/GetAllkeywordByuser',
                Insertuserfollowkeywords: 'AppListModule/Insertuserfollowkeywords',
                Removeuserfollowkeywords: 'AppListModule/Removeuserfollowkeywords',
                SearchAppbykeyword: 'AppListModule/SearchAppbykeyword',
          }),
         nextprevious($data){ 
                this.$router.replace({ name: "KeyWordTracking", query: {page: $data} })
                const vm = this; 
                vm.params.page=$data;
                vm.startindex=(($data-1)*10)+1;
                vm.userfollowapsdetails(this.params)
         },

        addFollow(event){
            this.isload=false;
            this.params.page=1;
            this.startindex=1;
            this.isfollowdataparams.user_id=this.user.data.userid;
            this.isfollowdataparams.app_id=event;
            this.isfollowdataparams.action='ADD';
            this.userfollowaps(this.isfollowdataparams);
            this.searchkey='';
            this.searchappslist=[]
            this.page=1;
        },
        unflowapps(event){
            this.params.page=1;
            this.startindex=1;
            this.isload=false;
            this.isfollowdataparams.user_id=this.user.data.userid;
            this.isfollowdataparams.app_id=event;
            this.isfollowdataparams.action='Delete';
            this.userfollowaps(this.isfollowdataparams);
            this.page=1;
            
        },
        selectItemEventHandler($item){
            if($item){
               //let keytitleid = this.allkeywords.filter(keytitleid => keytitleid.title===$item);
              // this.keyselectkeyword=keytitleid[0].ID;
               this.userkeydata.keywordid=$item.ID;
               this.userkeydata.keywordtitle=$item.title;
               this.userkeydata.user_id=this.user.data.userid;
               this.keywordsearchval=$item.title;
               this.autoloadkeyword=[];
               
            }
        
        },
        addkewwordinuserlist(){
          //alert(document.querySelector('.kywrd-txt').value)
          if(this.userkeydata.keywordtitle==''){
            this.userkeydata.keywordid='0';
            this.userkeydata.keywordtitle=document.querySelector('.kywrd-txt').value;
          }
        
           if(this.userkeydata.keywordid!='' && this.userkeydata.keywordtitle!='' &&  this.userkeydata.keywordtitle.trim().length>0) { 
              //alert(this.userkeydata.keywordtitle.trim().length)
              this.Insertuserfollowkeywords(this.userkeydata);
              document.getElementById("Keyword_id").value = "";
              this.userkeydata.keywordid='';
              this.userkeydata.keywordtitle='';
               setTimeout(() =>  this.getAllkeywordsbyuser(this.isfollowdataparams), 3000);
              this.keywordsearchval='';
             // this.getAllkeywordsbyuser(this.isfollowdataparams);
           } else{
              this.userkeydata.keywordtitle='';
              Toastr.error('Please enter valid keyword'); 
              
           }
            
        },

         onInputEventHandler(event){
            this.data.selection = null;
            this.data.input = event.input;
            this.data.userid = this.user.data.userid;
            if(this.data.input.length>2){
               // alert(this.data.input.length)
                this.getAllAppkeywords(this.data);
                this.autoloadkeyword=this.allkeywords;
            } 
         },

         removekeyword(keywordid){
            this.removeuserkeydata.keywordid=keywordid;
            this.removeuserkeydata.user_id=this.user.data.userid;
            if(this.removeuserkeydata.keywordid!=''){
            this.Removeuserfollowkeywords(this.removeuserkeydata);
            this.removeuserkeydata.keywordid='';    
               setTimeout(() =>  this.getAllkeywordsbyuser(this.isfollowdataparams), 2000);
            }
         },
         onInputEventHandlerApps(event){
            //alert('dfdsfsdf')
            // this.filterdata.selection = null;
             this.filterdata.input = event.input;
            // this.filterdata.userid = this.user.data.userid;
            // if(this.filterdata.input.length>2){
            // // alert(this.data.input.length)
            // this.SearchAppbykeyword(this.filterdata);
            // this.searchappslist=this.searchAppresult;
            // } 

         },
        submitClick(){
            //const mdbackdrop=document.querySelector('.modal-backdrop1');
           // mdbackdrop.classList.remove('modal-backdrop show');
          // alert(this.searchkey)
            this.$refs.Close.click();
            this.$router.push({path: '/search-result', query:{key: this.searchkey}})
        },

        changerout(slug){
           this.$refs.Close2.click();
           this.$router.push({path: '/key-tracking-details-page/'+slug, query:{}})
        },
         selectItemAppsFlowing(item){
            //document.querySelector('input[id="keyword2"]').value=item.app_title; 
            //alert(document.getElementById('keyword2').value)
            this.searchkey=item.app_title;
            this.searchkey=item.app_title;
            this.appfollowingid=item.app_id;
            return item.app_title;  
        },
       addapsinuserlist(){
           if(this.appfollowingid)
            { 
                this.addFollow(this.appfollowingid)
                this.appfollowingid=false;
                this.searchkey='';
            }
       },

       addkewwordinuserlistbyefrom (params, { setFieldError }) {
              const vm = this;
              params.setFieldError = setFieldError;
              vm.Insertuserfollowkeywords(params);
              //vm.getAllkeywordsbyuser(vm.isfollowdataparams);
              setTimeout(() =>  vm.getAllkeywordsbyuser(vm.isfollowdataparams), 2000);
              document.getElementById("appkeywordsearchfrm").reset();
              
          },
      
    },
     watch: {

        userfollowkewwords(){
           this.isloadfollowing=true;
        },
        isload(){
          this.userfollowapsdetails(this.params)
         },
         userfollowapps(){ 
          this.userfollowapsdetails(this.params)
          this.SearchAppbykeyword(this.filterdata);
         },
         userfollowappslist(){ 
          this.isload=true;
         },
         popoularaps(){
          this.popoularapsload =true;
         },
         
     }
} 
 
</script>


<style scoped>


.main_right-pn .table-section table.trck-tbl tbody tr td:last-child{ text-align: center;}

.main_right-pn .table-section.spg-table table tbody td svg.bi-x-square{
  color: #ff0000;
  fill: #ff0000;
  width: 69px;
  height: 18px;
  position: relative;
  top: -2px;
  margin-right: 5px;
}

.main_right-pn table tbody td svg.bi-x-square{
  color: #ff0000;
  fill: #ff0000;
  width: 69px;
  height: 18px;
  position: relative;
  top: -2px;
  margin-right: 5px;
}

.light-theme-np .main_right-pn table.trck-tbl tbody td svg{ color: #ff0000;fill: #ff0000;}
  .keyWordFirstLeft .kwflOInput  {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.main_right-pn .table-section table.blue-table thead th:first-child{text-align: center;}
.keyWordFirstLeft .kwflOInput{background: #323243 !important;border-radius: 5px;}
.form-control {

    max-width: 453.92px;
    width: 100%;
    height: 49.48px;
    padding-left: 15px;
    font-weight: 400;
    font-size: 14px;
    color: #C4C4C4;
    background: #323243 !important;
    opacity: 0.65;
    border: 1px solid #5D5D76;
    border-radius: 4px;
    outline: none;
}
.keyWordFirstLeft .kwflOInput .keyWordsubmit  {
    width: 190.77px;
    height: 49.48px;
    background: #00C1EC;
    border-radius: 4px;
    font-weight: 600;
    font-size: 15px;
    text-align: center;
    text-transform: uppercase;
    color: #FFFFFF;
    line-height: 27px;
    border: none;
    outline: none;
    margin-left: 5px;
}
.keyWordFirstLeft p  {
    font-weight: 300;
    font-size: 23px;
    line-height: 37px;
    color: rgba(255, 255, 255, 0.86);
    padding-top: 40px;
}
.keyWordFirstRight tbody tr td .keyBoxDiv,
.modal-body .table-section .resp-table tr td .keyBoxDiv    {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.keyWordFirstRight tbody tr td .keyBoxDiv h2,
.modal-body .table-section .resp-table tr td .keyBoxDiv h2  {
    font-family: 'Rubik';
    font-weight: 400;
    font-size: 16px !important;
    line-height: 19px;
    color: #E0E0E0;
    text-align: left !important;
    padding-bottom: 0 !important;
}
.keyWordFirstRight tbody tr td .keyBoxDiv a,
.modal-body .table-section .resp-table tr td .keyBoxDiv a  {
    background: #05AA60;
    /* opacity: 0.75; */
    border: 1px solid #05AA60;
    border-radius: 4px;
    font-family: 'Rubik';
    font-weight: 500;
    font-size: 12px;
    color: #FFFFFF;
    padding: 5px 7px;
    text-decoration: none;
    display: inline-block;
}
.keyWordFirstRight .heading  {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-bottom: 20px;
}
.keyWordSecBox .heading  {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-top: 120px;
    padding-bottom: 36px;
    position: relative;
}
.keyWordFirstRight .heading h3  {
    font-weight: 300;
    font-size: 27px;
    color: #FFFFFF;
}
.keyWordFirstRight .heading .btn a  {
    display: inline-block;
    font-weight: 500;
    font-size: 12px;
    text-align: center;
    letter-spacing: 0.06em;
    text-transform: uppercase;
    color: #FFFFFF;
    border: 1.2px solid #FFFFFF;
    border-radius: 3px;
    padding: 3px 11px;
    text-decoration: none;
}
.keyWordSecBox .heading h3  {
    font-weight: 300;
    font-size: 34px;
    text-transform: capitalize;
    color: #FFFFFF;
}
.keyWordSecBox .heading a  {
    display: inline-block;
    font-family: 'Rubik';
    font-weight: 400;
    font-size: 17px;
    letter-spacing: 0.02em;
    color: #FFFFFF;
    padding: 8px 36px;
    background: #685DC9;
    border-radius: 4px;
    text-decoration: none;
    position: relative;
}

.simple-typeahead-input .keyWordInput{ color:#fff !important; padding-left: 10px;}
#Keyword_id {
  width: 100%;
  color: #fff !important;
  padding-left: 10px;
}

.serach-result-part.key-wrd{ width:600px;}

input#Keyword_id{ width:100% !important}
/* .keyWordSecBox .table-section table tbody tr td  {
    text-align: center;
} */
.modal-dialog.cuts-modal{   
    /* max-width: 783px;  */
    box-shadow: 0px 4px 63px #0000008f;
    border-radius: 6px;
}
.modal-dialog.cuts-modal .modal-content .modal-header{
    border-bottom: none !important;
}
.modal-dialog.cuts-modal .modal-content .modal-header .btn-close{
    position: absolute;
    right: -10px;
    top: -10px;
    background: #469ae0;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 5px solid #0D0D14;
    opacity: 1;
    padding: 5px;
    z-index: 1;
}
.modal-dialog.cuts-modal .modal-content .modal-header .btn-close svg{
    width: 14px;
    height: 14px;
}
.modal-header,
.modal-body  {
    padding: 0rem !important;
}
.modal-body .table-section .resp-table {
    width: 100%;
    overflow-y: scroll;
    overflow-x: unset;
    height: 90vh;
    scrollbar-color: #FE833E grey;
    scrollbar-width: thin;
    -ms-overflow-style: none;
}
.modal-body .table-section .resp-table::-webkit-scrollbar {
    width: 7px;
}
.modal-body .table-section .resp-table::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey; 
    border-radius: 50px;
}
.modal-body .table-section .resp-table::-webkit-scrollbar-thumb {
    background: #FE833E; 
    border-radius: 50px;
}

.main_right-pn .tab_boxe {
  padding-top: 110px;
}

.keyWordSecBox .table-section .skyBackground thead {
  background: #00ADD2 !important;
}

.main_right-pn .tab_boxe h2 {
  font-weight: 300;
  font-size: 40px;
  line-height: 60px;
  text-transform: capitalize;
  color: #FFFFFF;
  padding-bottom: 45px;
  margin: 0;
}
.main_right-pn .table-section table.blue-table thead th {
  border-color: #2C88D4;
  padding: 15px;
  text-align: left;
  white-space: nowrap;
}

.main_right-pn .table-section {
  margin-bottom: 0;
}

 .light-theme-np .main_right-pn .tab_boxe h2, .light-theme-np .main_right-pn .tab_boxe h3{ color: #212529 !important;}
 .light-theme-np .keyWordFirstLeft p {
  color: #212529 !important;
}

.light-theme-np .keyWordFirstRight .heading .btn a {
  color: #212529;
  border: 1.2px solid #212529;
}

.light-theme-np .keyWordFirstLeft .kwflOInput .keyWordInput{ background: #e1e1e1; border-color: #e1e1e1;color:#212529}
.main_right-pn .table-section table tbody td{ padding:12px}
.simple-typeahead-input.keyWordInput {
  background: #fff !important;
  line-height: 50px;
  width: 100%;
  margin: 0 !important;
}
/* .oone {
  display: flex;
} */
form#appkeywordsearchfrm {
    width: 100%;
    display: flex;
}
div#Keyword_id_wrapper {
    width: 79%;
}
.oone .simple-typeahead > input{ width: 100% !important;  background: none !important;opacity: 1 !important; }
.black .keyWordFirstLeft input.headersearch { color:#fff !important;}
.light-theme-np .keyWordFirstLeft input.headersearch { color:#000 !important;}

/* :deep(.form-control.kywrd-txt){ height: 51px !important;background: #323243; color:#fff;}
:deep(.light-theme-np .form-control.kywrd-txt){ background: #eaeaea;} */

.keyWordsubmit{
    width: 185.77px;
    height: 49.48px;
    background: #00c1ec;
    border-radius: 4px;
    font-weight: 600;
    font-size: 15px;
    text-align: center;
    text-transform: uppercase;
    color: #fff;
    line-height: 27px;
    border: none;
    outline: none;
    margin-left: 5px;
}


  @media (min-width: 1280px) and (max-width: 1400px) {
.main_right-pn .table-section table tbody td, .tab_Two_Box .skygren-table tbody td {
  font-size: 13px;
  padding: 12px;
}
.keyWordFirstRight tbody tr td .keyBoxDiv h2 {
  font-size: 13px !important;
  overflow: hidden;
  width:60%;
}
  }
 @media (max-width: 740px) {
  .keyWordsubmit{ font-size: 13px;}
  .keyWordFirstLeft p {
  font-weight: 300;
  font-size: 16px;
  line-height: 26px;
 }
 .keyWordSecBox .heading{ padding-bottom: 0;}
.col-lg-6.keyWordFirstLeft {
  padding: 0;
}
 .serach-result-part.key-wrd{ width:100%}
 .main_right-pn .table-section.spg-table.app-trck-tbl table tbody tr > :nth-child(2){ word-break: break-all;}
 }

 .modal-dialog.cuts-modal .modal-content .modal-header [type="search"], .modal-dialog.cuts-modal .modal-content .modal-header [type="text"]{
    height: 49.48px !important;
    background: #404053;
    border: 1px solid #67677C;
    border-radius: 4px;
    font-family: 'Rubik';
    font-weight: 400;
    font-size: 17px;
    min-width: 422px;
    padding: 0 25px;
    color: #ffffffb8;
}
</style>