<template>
<div class="inner-header">
 <HeaderHome/>
 </div>
 <div class="ful-wdth grey-bg pt-4">
<div class="container blog-container">

 <div class="row">
   <div class="col-lg-12">
    <div class="blog_list" v-if="ispageload">
            <div class="blog-list" v-bind:key="result.ID" v-for="(result,index) in bloglist">
            <div class="img-lft" :id="'featureimage_'+index">
                 <img v-if="result._embedded['wp:featuredmedia']['0'].source_url"  :src="result._embedded['wp:featuredmedia']['0'].source_url">

                <img v-else src="@/assets/images/blog/strategies-to-increase-sales.jpg">
              </div>
            <div class="blog-text">
                <h3 class="post-item--title">
                    <router-link :to="'/blog/' + result.slug"> {{ result.title.rendered.substring(0,40)+"..." }}</router-link>
                  </h3>
                 <ul class="post-item--tags">
                    <li>
                    <!-- <router-link class="topic-link" :to="'/blog/'+ result.slug"> -->
                    <div v-html="result.excerpt.rendered"></div> 
                     <!-- </router-link>  -->
                    </li>
                  </ul> 
              <div class="post-item--date">
                      {{moment(result.date).format("MMMM DD,YYYY") }}
                  </div>
            </div>
            </div>

        <nav aria-label="navigation-cuts">               
          <pagination 
          v-model="page" 
          :records="totalblog" 
          :per-page="10" 
          :options={chunk:5} 
          @paginate="nextprevious($event)"
          />
        </nav>
            
    </div>
   </div>


  <!-- <Blogleftpanel/> -->


 </div>
</div>
 </div>
 <div class="inner-home">
<FooterHome/>
 </div>
</template>
<script>
import HeaderHome from '@/components/Mainpage/HeaderHome.vue'
import FooterHome from '@/components/Mainpage/FooterHome.vue'
// import Blogleftpanel from '@/components/Blogleftpanel.vue'
import { mapState, mapActions } from 'vuex'
import moment from "moment";
export default {
      name: 'BlogPage',
      data() {
          return {
              ispageload:true,
              params:{
              per_page: 10,
              page:1,
               max: 36,
              _embed:true  
           } ,
           page: 1,
           moment: moment,
         };
      },
      components: {
          HeaderHome,
          FooterHome,
          // Blogleftpanel
      },
      computed:{
          ...mapState({
              bloglist: state => state.BlogDetails.bloglist,
              totalblog: state => state.BlogDetails.totalblog,
              totalpage: state => state.BlogDetails.totalpage,
              
          }),
      },
      methods: {
          ...mapActions({
               GetAllBlog: 'BlogDetails/GetAllBlog',
               GetPopularPost: 'BlogDetails/GetPopularPost',
          }),
          nextprevious($data){ 
              const vm = this; 
              vm.params.page=$data;
              vm.GetAllBlog(vm.params);
              
         },
      },
      mounted() { 
        this.GetAllBlog(this.params);
      },
    watch: {
        bloglist(newval,oldval) { 
          this.ispageload=true;
       },
    }
}
</script>

