import axios from 'axios'
import router from '@/routes'
import Toastr from 'toastr'
import 'toastr/build/toastr.css'
const url = process.env.VUE_APP_API_BASE_URL;

const actions = {
    login (store, params) {          
        const promise= new Promise(function(resolve, reject) { 
        store.commit('SET_FORM_LOADER_STATUS', true);
        axios.post(`${url}wp/v2/login`, params).then((resp) => {
            store.commit('SET_FORM_LOADER_STATUS', false);
            store.commit('SET_IS_USER_LOGGED_IN_STATUS', true);
            store.commit('SET_AUTH_TOKEN', resp.data);
            store.commit('SET_AUTH', resp.data.user);
            
            //console.log('params',resp.data.nonce)
            /*axios.defaults.headers.common['Authorization'] = 'Sasradar '+resp.data.nonce;
            axios.defaults.headers.common['accessed-location-id'] = resp.data.accessed_location_id;
            axios.defaults.headers.common['user-id'] = resp.data.user.data.userid;
            axios.defaults.headers.common["Access-Control-Allow-Origin"]= "*";
            axios.defaults.headers.common["Access-Control-Allow-Credentials"]= "true";
            axios.defaults.headers.common["Access-Control-Allow-Methods"]="GET,HEAD,OPTIONS,POST,PUT";
            axios.defaults.headers.common["Access-Control-Allow-Headers"]= "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers";
            */
            resolve(true);
            router.push({ name: 'HomePage' });
        }).catch((err) => {
            console.log(err); // Network Error
            console.log(err.status); // undefined
            console.log(err.code); // undefined
           // Toastr.handleServerError(err, params.setFieldError);
           // console.log('Login Erroer',err.response.data.message)
            Toastr.error(err.response.data.message);
            store.commit('SET_FORM_LOADER_STATUS', false);
            resolve(false);
        });

     });
        promise.then((successMessage) => {
           if(successMessage)
               router.push({ name: 'Dashboard' })
        }, error => {
            console.log('Promise rejected.');
            console.log(error.message);
        });


    },

    logout (store,params) {
        const promise= new Promise(function(resolve, reject) { 
        axios.get(`${url}wp/v2/logout`, { params }).then((resp) => {
            if (resp.data.status) {
                store.commit('LOGOUT_USER');
                store.commit('RESET_STATE', false, { root: true });
            }
            resolve(true);
        }).catch((err) => {
            Toastr.error('Some internal error');
            resolve(false);
        });
       }); 
    },

    UserSignup (store, params) {
        const promise= new Promise(function(resolve, reject) {
            store.commit('SET_USER_SAVE_LOADER_STATUS', true);
            axios.post(`${url}wp/v2/users/register`, params).then((resp) => {
                store.commit('SET_USER_SAVE_LOADER_STATUS', false);
                // console.log(resp.data.userdata);
                // store.commit('SET_IS_USER_LOGGED_IN_STATUS', true);
                // store.commit('SET_AUTH_TOKEN', resp.data);
                // store.commit('SET_AUTH', resp.data.userdata);
                // console.log('params',resp.data.nonce)
                //axios.defaults.headers.common['Authorization'] = 'Sasradar '+resp.data.nonce;
                //axios.defaults.headers.common['accessed-location-id'] = resp.data.accessed_location_id;
                //axios.defaults.headers.common['user-id'] = resp.data.userid;                
                resolve(true);
            }).catch((err) => {
                store.commit('SET_USER_SAVE_LOADER_STATUS', false);
                Toastr.error(err.response.data.message);
                resolve(false);
            });
        });
        promise.then((successMessage) => {
            if(successMessage){
                router.push({ name: 'Wellcome' })
            }
            
        }, error => {
              console.log('Promise rejected.');
              console.log(error.message);
        });
    },

    emailverification (store,params) {
        const promise= new Promise(function(resolve, reject) { 
        axios.get(`${url}wp/v2/emailverification`, { params }).then((resp) => {
            if (resp.data.status) {
                store.commit('SET_EMAILVERIFICATIONSTATUS', resp.data.status);   
            }
            resolve(true);
        }).catch((err) => {
            Toastr.error('Some internal error');
            resolve(false);
        });
       }); 
    },

    forgot (store, params) {
            store.commit('SET_FORM_LOADER_STATUS', true);
            axios.post(`${url}wp/v2/auth/forgot-password`, params).then((resp) => {
                if (resp.data.status == 1) {
                    store.commit('FORGOT_PASSWORD_MESSAGE', resp.data.message);
                    Toastr.success(resp.data.message);
                    params.resetForm();
                }
                store.commit('SET_FORM_LOADER_STATUS', false);
            }).catch((err) => {
                store.commit('FORGOT_PASSWORD_MESSAGE', '');
                store.commit('SET_FORM_LOADER_STATUS', false);
                Toastr.error(resp.data.message);
            });
     },

     reset (store, params) {
        const promise= new Promise(function(resolve, reject) {
            store.commit('SET_FORM_LOADER_STATUS', true);
            axios.post(`${url}wp/v2/auth/resetpassword`, params).then((resp) => {
                store.commit('SET_FORM_LOADER_STATUS', false);
                Toastr.success(resp.data.message);
            // router.push({ name: 'Login' });
               resolve(true);
            }).catch((err) => {
                Toastr.error(err.response.data.message);
                store.commit('FORGOT_PASSWORD_MESSAGE', '');
                store.commit('SET_FORM_LOADER_STATUS', false);
                //Toastr.handleServerError(err, params.setFieldError);
            });
        }); 
        promise.then((successMessage) => {
            if(successMessage)
               router.push({ name: 'LogIn' })
        }, error => {
              console.log('Promise rejected.');
              console.log(error.message);
        });   
    },

    userfollowaps (store, params) {
        return new Promise(function(resolve, reject) { 
            axios.get(`${url}wp/v2/adduserfollowapps`, { params }).then((resp) => { 
                store.commit('SET_USER_FOLLOWAPS', resp.data.result);
                if( resp.data.appfollowinglimit=='OVER')
                 store.commit('SET_APPSFOLLOWINGLIMITOVER', true);
                else
                  store.commit('SET_APPSFOLLOWINGLIMITOVER', false);
                
                if(params.action=='ADD'){
                    if(resp.data.message=='limit over error'){
                        
                        Toastr.error("You have exceeded the maximum number of app following requests"); 
                    }else{                       
                        Toastr.success("You are following this app.");
                    }
                    
                }
                else if(params.action=='Delete'){
                    Toastr.error("Successfully unfollowed this app"); 
                } 
                    
            }).catch((err) => {
                console.log(err)
            });
        });
    },

     GoogleSignup (store, params) {
        const promise= new Promise(function(resolve, reject) {
            store.commit('SET_USER_SAVE_LOADER_STATUS', true);
            axios.post(`${url}wp/v2/users/googleregister`, params).then((resp) => {
                store.commit('SET_USER_SAVE_LOADER_STATUS', false);
                console.log(resp.data.userdata);
                store.commit('SET_IS_USER_LOGGED_IN_STATUS', true);
                store.commit('SET_AUTH_TOKEN', resp.data);
                store.commit('SET_AUTH', resp.data.userdata);
                console.log('params',resp.data.nonce)
                //axios.defaults.headers.common['Authorization'] = 'Sasradar '+resp.data.nonce;
                //axios.defaults.headers.common['accessed-location-id'] = resp.data.accessed_location_id;
                //axios.defaults.headers.common['user-id'] = resp.data.userid;                
                resolve(true);
            }).catch((err) => {
                store.commit('SET_USER_SAVE_LOADER_STATUS', false);
                Toastr.error(err.response.data.message);
                resolve(false);
            });
        });
        promise.then((successMessage) => {
            if(successMessage){
                console.log('Promise sucess.');
                router.push({ name: 'Dashboard' })
            }
            
        }, error => {
              console.log('Promise rejected.');
              console.log(error.message);
        });
    },


   UpdateuserProfile (store, params) {         
        const promise= new Promise(function(resolve, reject) { 
        store.commit('SET_FORM_LOADER_STATUS', true);
        axios.post(`${url}wp/v2/updateuserfrofile`, params).then((resp) => {
            store.commit('SET_FORM_LOADER_STATUS', false);
            store.commit('SET_IS_USER_LOGGED_IN_STATUS', true);
            store.commit('SET_AUTH_TOKEN', resp.data);
            store.commit('SET_AUTH', resp.data.userdata);
            Toastr.success("Profile Update successfully.");
            resolve(true);
            //router.push({ name: 'HomePage' });
        }).catch((err) => {
           // Toastr.handleServerError(err, params.setFieldError);
            Toastr.error("Profile Update Faild.");
            store.commit('SET_FORM_LOADER_STATUS', false);
            resolve(false);
        });

       });
     },


     UpdateProfilePic (store, params) {        
        const promise= new Promise(function(resolve, reject) { 
        store.commit('SET_FORM_LOADER_STATUS', true);
        axios.post(`${url}wp/v2/updateuserprofilepicture`, params,{
            headers: {
                'Content-Type': 'multipart/form-data'
              }   
        }).then((resp) => {
            store.commit('SET_FORM_LOADER_STATUS', false);
            store.commit('SET_IS_USER_LOGGED_IN_STATUS', true);
            store.commit('SET_AUTH_TOKEN', resp.data);
            store.commit('SET_AUTH', resp.data.userdata);
            Toastr.success("Profile pic update successfully.");
            resolve(true);
            //router.push({ name: 'HomePage' });

           // console.log('res',resp)
        }).catch((err) => {
           // Toastr.handleServerError(err, params.setFieldError);
            Toastr.error("Profile Update Faild.");
            store.commit('SET_FORM_LOADER_STATUS', false);
            resolve(false);
        });

       });
     }, 


     PasswordChange (store, params) {        
        const promise= new Promise(function(resolve, reject) { 
        store.commit('SET_FORM_LOADER_STATUS', true);
        axios.post(`${url}wp/v2/passwordchange`, params).then((resp) => {
            if(resp.data.code==200)
              Toastr.success(resp.data.message);
            else
             Toastr.error(resp.data.message);
            
        }).catch((err) => {
           // Toastr.handleServerError(err, params.setFieldError);
            Toastr.error("Sorry! Failed to update your account details..");
            store.commit('SET_FORM_LOADER_STATUS', false);
            resolve(false);
        });

       });
     }, 


     GetMemberShipLevel (store, params) { 
        return new Promise(function(resolve, reject) { 
            axios.get(`${url}wp/v2/allmembershiplevel`, {params}).then((resp) => {
               store.commit('SET_MEMBERSHIPLEVELS', resp.data);
               store.commit('SET_USERPACKAGEDETAILS', resp.data);
             }).catch((err) => {
                console.log(err)
            });
           }); 
       
     },  
     
     UserAccountDelete (store, params) { 
        const promise= new Promise(function(resolve, reject) { 
           // store.commit('SET_FORM_LOADER_STATUS', true);
            axios.post(`${url}wp/v2/useraccountdelete`, params).then((resp) => {
                //console.log('Accountdelete',params)
                //console.log('responseAccountdelete',resp)
                if(resp.data.code==200){                                      
                  Toastr.success(resp.data.message);
                  store.commit('SET_ACCOUNTDELETED', true);  
                }                  
                else
                 Toastr.error(resp.data.message);
                
            }).catch((err) => {
               // Toastr.handleServerError(err, params.setFieldError);
                Toastr.error("Sorry! Failed to update your account details..");
                store.commit('SET_FORM_LOADER_STATUS', false);
                resolve(false);
            });
    
           });
     }, 

     Menulist(store, params){
        return new Promise(function(resolve, reject) { 
            axios.get(`${url}wp/v2/get_menu`, { params }).then((resp)=>{
                store.commit('SET_DEFULTMENU', resp.data);          
            }).catch((err) => {
                // Toastr.handleServerError(err);
                console.log(err)
            });
        });   
    },

    GetSinglepageContent(store, params){
        return new Promise(function(resolve, reject) { 
            axios.get(`${url}wp/v2/snglepagecontent`, { params }).then((resp)=>{
                store.commit('SET_PAGECONTENT', resp.data);          
            }).catch((err) => {
                // Toastr.handleServerError(err);
                console.log(err)
            });
        });   
    },

    SendContactmail (store, params) { 
        const promise= new Promise(function(resolve, reject) {             
            store.commit('SET_FORM_LOADER_STATUS', true);
            axios.post(`${url}contact-form-7/v1/contact-forms/107/feedback`, params).then((resp) => {
              // Toastr.success(resp.data.message);
              if(resp.data.status!='mail_sent'){
                Toastr.error(resp.data.message);
              }else{
                router.push({ name: 'Thankyou' })  
              }                
            }).catch((err) => {
                Toastr.error("Sorry! Mail not send.");
                resolve(false);
            });
    
           });

           
    }, 

    InquiryMailSend(store, params) { 
        const promise= new Promise(function(resolve, reject) {             
            store.commit('SET_FORM_LOADER_STATUS', true);
            axios.post(`${url}contact-form-7/v1/contact-forms/139/feedback`, params).then((resp) => {
              if(resp.data.status!='mail_sent'){
                Toastr.error(resp.data.message);
              }else{
                Toastr.success(resp.data.message);
              }                
            }).catch((err) => {
                Toastr.error("Sorry! Mail not send.");
                resolve(false);
            });
    
           });
    }, 

    SendUserReview(store, params) { 
        const promise= new Promise(function(resolve, reject) {             
            store.commit('SET_FORM_LOADER_STATUS', true);
            axios.post(`${url}wp/v2/submitreview`, params).then((resp) => {
              if(resp.data.status!='mail_sent'){
                Toastr.error(resp.data.message);
              }else{
                Toastr.success(resp.data.message);
              }                
            }).catch((err) => {
                Toastr.error("Sorry! Mail not send.");
                resolve(false);
            });
    
           });

           
    }, 

   


};

export default actions;