import { createRouter, createWebHistory } from 'vue-router'
import HomePage from '../src/pages/HomePage.vue'
import MostInstalled from '../src/pages/appranking/MostInstalled.vue'
import AppStoreRanking from '../src/pages/appranking/AppStoreRanking.vue'
import MostReviewsAdded from './pages/appranking/MostReviewsAdded.vue'
import NewestApp from './pages/appranking/NewestApp.vue'
import MostReviews from './pages/appranking/MostReviews.vue'
import BigGainer from './pages/appranking/BigGainer.vue'
import AppTracking from './pages/appstoreintelligence/AppTracking.vue'
import SignUp from './pages/SignUp.vue'
import Wellcome from './pages/Wellcome.vue'
import EmailVerification from './pages/EmailVerification.vue'
import LogIn from './pages/LogIn.vue'
import Forgot from './pages/Forgot.vue'
import ResetPassword from './pages/ResetPassword.vue'
import KeyWordTracking from './pages/appstoreintelligence/KeyWordTracking.vue'
import ChangeLogAppListing from './pages/appstoreintelligence/ChangeLogAppListing.vue'
import SidebySideComparison from './pages/SidebySideComparison.vue'
import SidebySideZeroState from './pages/SidebySideZeroState.vue'
/*import AboutDetails from '../src/components/AboutDetails.vue'*/
import MonitorCompetitorsads from '../src/pages/adstoreinspector/MonitorCompetitorsads.vue'
import LeaveProductFeed from '../src/components/LeaveProductFeed.vue'
import TrackAdsByKeyword from '../src/pages/adstoreinspector/TrackAdsByKeyword.vue'
import AdsKeywordDetails from '../src/pages/adstoreinspector/AdsKeywordDetails.vue'
import AdRaddarActiveScan from '../src/pages/adstoreinspector/AdRaddarActiveScan.vue'
import AdsByAppReport from '../src/pages/adstoreinspector/AdsByAppReport.vue'
import AdAppKeyDrilldown from '../src/pages/adstoreinspector/AdAppKeyDrilldown.vue'


import KeyTrackingDetailsPage from '../src/pages/appstoreintelligence/KeyTrackingDetailsPage.vue'
import AboutDetails from '../src/pages/appranking/AboutDetails.vue'
import DevelopersTeamDirectory from '../src/pages/DevelopersTeamDirectory.vue'
import DevelopersTeamName from '../src/pages/DevelopersTeamName.vue'
import AppListingChangelogs from '../src/pages/appstoreintelligence/AppListingChangelogs.vue'
import ChangeLogsDetails from '../src/pages/appstoreintelligence/ChangeLogsDetails.vue'
import ShopifyStorePoro from '../src/pages/shopifystoreprospector/ShopifyStorePoro.vue'
import ShopifyStorePoroExpan from '../src/pages/shopifystoreprospector/ShopifyStorePoroExpan.vue'
import CategoriesPage from '../src/pages/appstoreintelligence/CategoriesPage.vue'
import AccountManageProfileM from '../src/components/AccountManageProfileM.vue'
import PayWallSearchAdsByApp from '../src/pages/PayWallSearchAdsByApp.vue'
import PayWallSearchAdsByKeywords from '../src/pages/PayWallSearchAdsByKeywords.vue'
import PayWallChangeLogs from '../src/pages/PayWallChangeLogs.vue'
import PayWallAddRaddarActiveScan from '../src/pages/PayWallAddRaddarActiveScan.vue'
import ContactUs from '../src/pages/ContactUs.vue'
import DisclaimerPage from './pages/DisclaimerPage.vue'
import AdsByKeywordZeroState from '../src/pages/AdsByKeywordZeroState.vue'
import AdsByReportZeroState from '../src/pages/AdsByReportZeroState.vue'
import MainPage from './components/MainPage.vue'
import SearchResult from '../src/pages/SearchResult.vue'
import BlogPage from '../src/pages/BlogPage.vue'
import HomeContactUs from '../src/pages/HomeContactUs.vue'

// import TermsCondition from '../src/pages/TermsCondition.vue'
// import AcceptableUsePolicy from './pages/AcceptableUsePolicy.vue'
// import DisclaimerHomePage from './pages/DisclaimerHomePage.vue'
// import PrivacyPolicy from './pages/PrivacyPolicy.vue'
// import CookiePolicy from './pages/CookiePolicy.vue'

import Detailspage from '../src/pages/Detailspage.vue'
import Pagenotfound from '../src/pages/404notfoundpage.vue'
import Thankyou from '../src/pages/Thankyou.vue'
import BlogDetails from '../src/pages/BlogDetails.vue'
import ComingSoon from '../src/pages/ComingSoon.vue'







import store from '@/store'

const routes = [
   /* {
        name: 'HomePage',
        component: HomePage,
        path: '/',
        meta: {
            title: 'HomePage',
            requiresAuth: false
        },
    },*/
    {
        name: 'Dashboard',
        component: HomePage,
        path: '/dashboard',
        meta: {
            title: 'Dashboard',
            requiresAuth: false
        },
    },
    {
        name: 'MostInstalled',
        component: MostInstalled,
        path: '/mostinstalled',
        meta: {
            title: 'MostInstalled',
            requiresAuth: false
        },
    },
    {
        name: 'AppStoreRanking',
        component: AppStoreRanking,
        path: '/AppStoreRanking',
        meta: {
            title: 'AppStoreRanking',
            requiresAuth: false
        },
    },
   
    {
        name: 'MostReviewsAdded',
        component: MostReviewsAdded,
        path: '/MostReviewsAdded',
        meta: {
            title: 'MostReviewsAdded',
            requiresAuth: false
        },
    },
    {
        name: 'NewestApp',
        component: NewestApp,
        path: '/NewestApp',
        meta: {
            title: 'NewestApp',
            requiresAuth: false
        },
    },
    {
        name: 'MostReviews',
        component: MostReviews,
        path: '/MostReviews',
        meta: {
            title: 'MostReviews',
            requiresAuth: false
        },
    },
    {
        name: 'BigGainer',
        component: BigGainer,
        path: '/BigGainer',
        meta: {
            title: 'BigGainer',
            requiresAuth: false
        },
    },
    {
        name: 'AppTracking',
        component: AppTracking,
        path: '/app-tracking',
        meta: {
            title: 'AppTracking',
            requiresAuth: false
        },
    },
    {
        name: 'SignUp',
        component: SignUp,
        path: '/sign-up',
        meta: {
            title: 'SignUp',
            requiresAuth: false
        },
    },
    {
        name: 'EmailVerification',
        component: EmailVerification,
        path: '/email-verification/:act',
        meta: {
            title: 'Email Verification',
            requiresAuth: false
        },
    },

    {
        name: 'Wellcome',
        component: Wellcome,
        path: '/wellcome',
        meta: {
            title: 'Wellcome',
            requiresAuth: false
        },
    },

    {
        name: 'LogIn',
        component: LogIn,
        path: '/log-in',
        meta: {
            title: 'Login',
            requiresAuth: false
        },
    },
    {
        name: 'Forgot',
        component: Forgot,
        path: '/forgot',
        meta: {
            title: 'Forgot Password?',
            requiresAuth: false
        },
    },

    {
        name: 'ResetPassword',
        component: ResetPassword,
        path: '/resetpassword/:key/:login',
        meta: {
            title: 'Reset Password',
            requiresAuth: false
        },
    },

    {
        name: 'KeyWordTracking',
        component: KeyWordTracking,
        path: '/keyword-tracking',
        meta: {
            title: 'Keyword Tracking',
            requiresAuth: false
        },
    },
    {
        name: 'ChangeLogAppListing',
        component: ChangeLogAppListing,
        path: '/changelog-applisting',
        meta: {
            title: 'Change Log AppListing',
            requiresAuth: false
        },
    },
    {
        name: 'SidebySideComparison',
        component: SidebySideComparison,
        path: '/side-by-side-comparison',
        meta: {
            title: 'Side by Side Comparison',
            requiresAuth: false
        },
    },
    {
        name: 'SidebySideZeroState',
        component: SidebySideComparison,
        path: '/side-by-side-zerostate',
        meta: {
            title: 'Side by Side Zero State',
            requiresAuth: false
        },
    },

    {
        name: 'MonitorCompetitorsads',
        component: MonitorCompetitorsads,
        path: '/monitor-competitors-ads',
        meta: {
            title: 'Monitor Competitors ads',
            requiresAuth: false
        },
    },

    {
        name: 'LeaveProductFeed',
        component: LeaveProductFeed,
        path: '/leaveproductfeed',
        meta: {
            title: 'Leave Product Feed',
            requiresAuth: false
        },

    },

    {
        name: 'TrackAdsByKeyword',
        component: TrackAdsByKeyword,
        path: '/track-ads-by-keyword',
        meta: {
            title: 'Track Ads By Keyword',
            requiresAuth: false
        },

    },

    {
        name: 'AdsKeywordDetails',
        component: AdsKeywordDetails,
        path: '/ads-keyword-details',
        meta: {
            title: 'Ads Keyword Details',
            requiresAuth: false
        },

    },

    {
        name: 'AdRaddarActiveScan',
        component: AdRaddarActiveScan,
        path: '/ad-raddar-active-scan',
        meta: {
            title: 'Ads Raddar Active Scan',
            requiresAuth: false
        },

    },

    {
        name: 'AdsByAppReport',
        component: AdsByAppReport,
        path: '/ads-by-app-report',
        meta: {
            title: 'Ads By App Report',
            requiresAuth: false
        },

    },

    {
        name: 'AdAppKeyDrilldown',
        component: AdAppKeyDrilldown,
        path: '/ads-app-key-drilldown',
        meta: {
            title: 'Ads App Key Drilldown',
            requiresAuth: false
        },

    },

    {
        name: 'KeyTrackingDetailsPage',
        component: KeyTrackingDetailsPage,
        path: '/key-tracking-details-page/:keyword',
        meta: {
            title: 'Key Tracking Details',
            requiresAuth: false
        },

    },

    {
        name: 'AboutDetails',
        component: AboutDetails,
        path: '/about-details/:appid',
        meta: {
            title: 'About Details',
            requiresAuth: false
        },

    },

    {
        name: 'DevelopersTeamDirectory',
        component: DevelopersTeamDirectory,
        path: '/developers-team-directory',
        meta: {
            title: 'Developers Team Directory',
            requiresAuth: false
        },

    },

    {
        name: 'DevelopersTeamName',
        component: DevelopersTeamName,
        path: '/developers-team-name/:devloperid',
        meta: {
            title: 'Developers Team Name',
            requiresAuth: false
        },

    },

    {
        name: 'AppListingChangelogs',
        component: AppListingChangelogs,
        path: '/app-change-logs/:app_id',
        meta: {
            title: 'App Listing Change logs',
            requiresAuth: false
        },

    },

    {
        name: 'ChangeLogsDetails',
        component: ChangeLogsDetails,
        path: '/change-logs-Details/:app_id/:changedate',
        meta: {
            title: 'Change Logs Details',
            requiresAuth: false
        },

    },

    {
        name: 'ShopifyStorePoro',
        component: ShopifyStorePoro,
        path: '/shopify-store-poro',
        meta: {
            title: 'Shopify Store',
            requiresAuth: false
        },
    },

    {
        name: 'ShopifyStorePoroExpan',
        component: ShopifyStorePoroExpan,
        path: '/shopify-store-poro-expan',
        meta: {
            title: 'Shopify Store Poro Expan',
            requiresAuth: false
        },
    },

    {
        name: 'CategoriesPage',
        component: CategoriesPage,
        path: '/categories-page',
        meta: {
            title: 'Categories',
            requiresAuth: false
        },
    },
    
    {
        name: 'AccountManageProfileM',
        component: AccountManageProfileM,
        path: '/accountmanageprofilem',
        meta: {
            title: 'Account-Profile-Management',
            requiresAuth: false
        },
    },

    {
        name: 'PayWallSearchAdsByApp',
        component: PayWallSearchAdsByApp,
        path: '/paywallsearchadsbyapp',
        meta: {
            title: 'Pay Wall Search Ads By App',
            requiresAuth: false
        },
    },

    {
        name: 'PayWallSearchAdsByKeywords',
        component: PayWallSearchAdsByKeywords,
        path: '/paywallsearchadsbykeywords',
        meta: {
            title: 'Pay Wall Search Ads By Keywords',
            requiresAuth: false
        },
    },
    

    {
        name: 'PayWallChangeLogs',
        component: PayWallChangeLogs,
        path: '/paywallchangelogs',
        meta: {
            title: 'Pay Wall Change Logs',
            requiresAuth: false
        },
    },

    {
        name: 'PayWallAddRaddarActiveScan',
        component: PayWallAddRaddarActiveScan,
        path: '/paywalladdraddaractivescan',
        meta: {
            title: 'Pay Wall Add Raddar Active Scan',
            requiresAuth: false
        },
    },

    {
        name: 'ContactUs',
        component: ContactUs,
        path: '/contact-us',
        meta: {
            title: 'Contact Us',
            requiresAuth: false
        },
    },


   

    {
        name: 'AdsByKeywordZeroState',
        component: AdsByKeywordZeroState,
        path: '/ads-by-keyword-zero-state',
        meta: {
            title: 'Ads By Keyword Zero State',
            requiresAuth: false
        },
    },
 
    {
        name: 'AdsByReportZeroState',
        component: AdsByReportZeroState,
        path: '/ads-by-report-zero-state',
        meta: {
            title: 'Ads By Report Zero State',
            requiresAuth: false
        },
    },
    {
        name: 'MainPage',
        component: MainPage,
        path: '/',
        meta: {
            title: 'MainPage',
            requiresAuth: false
        },
    },  
    
    {
        name: 'SearchResult',
        component: SearchResult,
        path: '/search-result',
        meta: {
            title: 'Search Result',
            requiresAuth: false
        },
    }, 

    {
        name: 'BlogPage',
        component: BlogPage,
        path: '/blog',
        meta: {
            title: 'Blog',
            requiresAuth: false
        },
    },
    {
        name: 'BlogDetails',
        component: BlogDetails,
        path: '/blog/:slug',
        meta: {
            title: 'Blog Details',
            requiresAuth: false
        },
    },
    {
        name: 'HomeContactUs',
        component: HomeContactUs,
        path: '/contactus',
        meta: {
            title: 'Contact Us',
            requiresAuth: false
        },
    },
    {
        name: 'DisclaimerPage',
        component: DisclaimerPage,
        path: '/disclaimer',
        meta: {
            title: 'Disclaimer',
            requiresAuth: false
        },
    },

    {
        name: 'Detailspage',
        component: Detailspage,
        path: '/:menuslug',
        meta: {
            title: 'Page Content',
            requiresAuth: false
        },
    },
    
    {
        name: '404page',
        component: Pagenotfound,
        path: '/:pathMatch(.*)*',
        meta: {
            title: 'Page Not Found',
            requiresAuth: false
        },

    },

    {
        name: 'Thankyou',
        component: Thankyou,
        path: '/thankyou',
        meta: {
            title: 'Thank You',
            requiresAuth: false
        },

    },

    {
        name: 'ComingSoon',
        component: ComingSoon,
        path: '/coming-soon',
        meta: {
            title: 'Coming Soon',
            requiresAuth: false
        },

    },

    

   

   /*
   
    {
        name: 'TermsCondition',
        component: TermsCondition,
        path: '/terms-condition',
        meta: {
            title: 'Terms Condition',
            requiresAuth: false
        },
    }, 

    {
        name: 'AcceptableUsePolicy',
        component: AcceptableUsePolicy,
        path: '/acceptableusepolicy',
        meta: {
            title: 'Acceptable Use Policy',
            requiresAuth: false
        },
    },

    {
        name: 'DisclaimerHomePage',
        component: DisclaimerHomePage,
        path: '/disclaimer-page',
        meta: {
            title: 'Disclaimer',
            requiresAuth: false
        },
    },

    {
        name: 'PrivacyPolicy',
        component: PrivacyPolicy,
        path: '/privacy-policy',
        meta: {
            title: 'Privacy Policy',
            requiresAuth: false
        },
    },

    {
        name: 'CookiePolicy',
        component: CookiePolicy,
        path: '/cookie-policy',
        meta: {
            title: 'Cookie Policy',
            requiresAuth: false
        },
    },*/
]
const router = createRouter({
    history:createWebHistory(),
    routes,
    linkActiveClass: "active",
    linkExactActiveClass: "exact-active",
    scrollBehavior(to) {
    const nextRoute2 = to.matched.slice().reverse().find(r => r.meta && r.meta.title);
    if (['Dashboard',
         'HomePage',
         'NewestApp',
         'MostInstalled',
         'AppStoreRanking',
         'MostReviewsAdded',
         'NewestApp',
         'MostReviews',
         'BigGainer',
         'AppTracking',
         'KeyWordTracking',
         'ChangeLogAppListing',
         'SidebySideComparison',
         'SidebySideZeroState',
         'AccountManageProfileM',
         'AboutDetails',
        //  'TermsCondition'

        ].includes(nextRoute2.name)) {
        // document.querySelector('body').scrollTo(0,0);
        document.querySelector('.main_right-pn').scrollTo(0,0);
      }
      else if (['DisclaimerPage',
        'BlogPage','disclaimer-page','contactus'
        ].includes(nextRoute2.name)) {
            document.getElementById('app').scrollIntoView({ behavior: 'smooth' });
        } 
            
    }
    
})


router.beforeEach((to, from, next) => {  
    let x = document.querySelectorAll('.accordion-collapse');
    for(let i = 0; i < x.length; i++){
        x[i].classList.remove('show')
    }  
    const nextRoute = to.matched.slice().reverse().find(r => r.meta && r.meta.title);
    console.log('newrout',nextRoute)

    if (nextRoute) {
        document.title = nextRoute.meta.title;
    }else{
        next({ name: 'LogIn' }); 
    }

    const isAuthorised = store.state.authModule.isUserLoggedIn;

    if (isAuthorised && ['LogIn', 'SignUp', 'Index','Forgot','ResetPassword'].includes(nextRoute.name)) {
        next({ name: 'HomePage' });
    } 
    else if (!isAuthorised && ['Dashboard',
                               'HomePage',
                               'NewestApp',
                               'MostInstalled',
                               'AppStoreRanking',
                               'MostReviewsAdded',
                               'NewestApp',
                               'MostReviews',
                               'BigGainer',
                               'AppTracking',
                               'KeyWordTracking',
                               'ChangeLogAppListing',
                               'SidebySideComparison',
                               'SidebySideZeroState',
                               'AccountManageProfileM'].includes(nextRoute.name)) {
        next({ name: 'LogIn' });
    } 
    else if (!isAuthorised && nextRoute && nextRoute.meta.requiresAuth) {
        next({ name: 'LogIn' });
    }

    if (!to.matched.length) {
        next({ name: 'Error-404' });
    } else {
        next();
    }
    
});

export default router;

//SidebySideComparison