<template>
    <div class="card-section">
        <div class="row gy-3" v-if="istopcardload">
          <div class="col-lg-4 col-sm-4" data-aos="fade-up" data-aos-duration="1500">
            <router-link to="/AppStoreRanking" class="grow-on-hover" v-if="totalapsshow">
            <div class="item">
              <div class="ttx" >
                <h6>Total Apps </h6>
                <h5>{{totalapps}}</h5>
               
              </div>
              <img src="@/assets/images/card/Mask-group.png" alt="">
            </div>
             </router-link>
          </div>
          <div class="col-lg-4 col-sm-4" data-aos="fade-up" data-aos-duration="1500">
             <router-link to="/developers-team-directory" class="grow-on-hover" v-if="totaldevelopersshow">
            <div class="item">
              <div class="ttx" >
                <h6>Total Developer Teams</h6>
                <h5>{{totaldeveloper}}</h5>
               </div>
              <img src="@/assets/images/card/Group-2.png" alt="">
            </div>
             </router-link>
          </div>
          <div class="col-lg-4 col-sm-4" data-aos="fade-up" data-aos-duration="1500">
             <router-link to="/MostReviews" class="grow-on-hover" v-if="totalreviewshow">
            <div class="item" >
                <div class="ttx" >
                <h6>Total Reviews </h6>
                <h5>{{totalreview}}</h5>
                
              </div>
              <img src="@/assets/images/card/Maskgroup-3.png" alt="">
            </div>
            </router-link>
          </div>
        </div>
      </div>
</template>

<script>
import {mapState,mapActions} from 'vuex'
export default {
  name: 'TopCard',
  data() {
    return {
        istopcardload:false,
        isload:false,
        totalapsshow:false,
        totalreviewshow:false,
        totaldevelopersshow:false,
    };
  },

  computed: {
      ...mapState({
          user: state => state.authModule.user,
          totalapps: (state) => state.AppListModule.totalapps,
          totalreview: (state) => state.AppListModule.totalreviews,
          totaldeveloper: (state) => state.AppListModule.totaldevloper,
      }),
  },

  mounted() {  
      const vm = this;
      vm.totalapp();
      this.isload=true;
      this.totalapsshow=true;
      this.totalreviewshow=true;
      this.totaldevelopersshow=true;    
  },

  methods:{
    int(){          
      this.istopcardload=true;     
    },
     ...mapActions({         
          totalapp: 'AppListModule/getTotalAps',   
     }),
  },  
  watch: {
        isload(newval,oldval) {         
            this.int();      
        },
        totalapps(newval,oldval) { 
            this.totalapsshow=true;      
        },
        totalreview(newval,oldval) { 
            this.totalreviewshow=true;      
        },
        totaldeveloper(newval,oldval) {  
            this.totaldevelopersshow=true;      
        },
  }

}
</script>

<style scoped>
 .grow-on-hover {
  display: block;
  
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  transition-property: transform;
}
.grow-on-hover:hover, .grow-on-hover:focus, .grow-on-hover:active {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>