<template>
 <div class="ful-wdth">
  <confirm-dialogue ref="confirmDialogue"></confirm-dialogue>
   <div class="tab_boxe tab_box_margin">
          <div class="tab-content">
            <article>
              <h4>App Listing Page - Change Logs </h4>
              <p>If you're looking to grow your app's user base, you may want to pay attention to the top apps in your category. If a similar app is beating yours out for attention in the app store, it's likely that those developers are doing something right—and you can learn from what they're doing.</p>
              <p>Monitoring change logs will allow you to be aware of the changes made by your competitors and understand their strategy, which helps you improve your app.</p>
              <p>Step 1: Add an app to follow. We will run daily jobs to track any app listing changes</p>
              <p>Step 2: Run the report </p>
              <p>Step 3: Any changes detected will be reported on the timeline.  Check back often.</p>
              <p>Step 4: Click on the timeline to view the changes</p>
            </article>
          </div>
        </div>

        <div class="app_InputBox d-flex">
          <div class="app_InputBoxSub">
            <vue3-simple-typeahead
                id="compayarpps"
                v-model="searchkey"
                :value="searchkey"
                placeholder="Search app name"
                :items="searchAppresult"
                class="searching form-control txt-bx"
                :minInputLength="3"
                :itemProjection="
                (item) => {
                      var url1=item.app_logo;
                      url=url1.replace('%3', '');
                      var html=`<div class='compayarpps header-searching'><div class='img-sec'><img src='${url}'></div>`;
                      html=html+`<div class='text-part'><h3>`+item.app_title+`</h3>`;
                      html=html+'<p>'+item.app_rating+' of 5 stars ('+item.app_num_of_reviews+')</p></div><div>';
                      return html;
                }"
                @selectItem="selectItemAppsFlowing"
                @onInput="onInputEventHandlerApps"
                @onFocus="onFocusEventHandlerApps"
                @onBlur="onBlurEventHandlerApps"
                >  

                <template #list-item-text="slot">
                    <span v-html="slot.itemProjection(slot.item)" class="material-icons"></span>
                </template>      
            </vue3-simple-typeahead>

          </div>
          <div class="app_InputBoxBtn">
             <button class="app_In_Btn" type="button" @click="addapsinuserlist">Follow</button>
          </div>
        </div>

      


        


       
        <div class="changeLogosBtn"  v-if="userfollowappslist.length>0">
          <ul>
            <li class="changeLogosyellowBtn"><a href="javascript:void(0)" @click="unflowamultiplepps" >delete</a></li>
            <!-- <li><a href="#" class="ml-alrt">Email Alerts</a></li> -->
          </ul>
        </div>
        
         <div class="psrl-sec">
        <div class="loading" v-if="isloder">
           <div class="loader1">
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="margin:auto;display:block;" width="200px" height="200px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
<circle cx="75" cy="50" fill="#00d1ff" r="5">
  <animate attributeName="r" values="3;3;5;3;3" times="0;0.1;0.2;0.3;1" dur="1s" repeatCount="indefinite" begin="-0.9166666666666666s"></animate>
  <animate attributeName="fill" values="#00d1ff;#00d1ff;#00d1ff;#00d1ff;#00d1ff" repeatCount="indefinite" times="0;0.1;0.2;0.3;1" dur="1s" begin="-0.9166666666666666s"></animate>
</circle><circle cx="71.65063509461098" cy="62.5" fill="#00d1ff" r="5">
  <animate attributeName="r" values="3;3;5;3;3" times="0;0.1;0.2;0.3;1" dur="1s" repeatCount="indefinite" begin="-0.8333333333333334s"></animate>
  <animate attributeName="fill" values="#00d1ff;#00d1ff;#00d1ff;#00d1ff;#00d1ff" repeatCount="indefinite" times="0;0.1;0.2;0.3;1" dur="1s" begin="-0.8333333333333334s"></animate>
</circle><circle cx="62.5" cy="71.65063509461096" fill="#00d1ff" r="5">
  <animate attributeName="r" values="3;3;5;3;3" times="0;0.1;0.2;0.3;1" dur="1s" repeatCount="indefinite" begin="-0.75s"></animate>
  <animate attributeName="fill" values="#00d1ff;#00d1ff;#00d1ff;#00d1ff;#00d1ff" repeatCount="indefinite" times="0;0.1;0.2;0.3;1" dur="1s" begin="-0.75s"></animate>
</circle><circle cx="50" cy="75" fill="#00d1ff" r="5">
  <animate attributeName="r" values="3;3;5;3;3" times="0;0.1;0.2;0.3;1" dur="1s" repeatCount="indefinite" begin="-0.6666666666666666s"></animate>
  <animate attributeName="fill" values="#00d1ff;#00d1ff;#00d1ff;#00d1ff;#00d1ff" repeatCount="indefinite" times="0;0.1;0.2;0.3;1" dur="1s" begin="-0.6666666666666666s"></animate>
</circle><circle cx="37.50000000000001" cy="71.65063509461098" fill="#00d1ff" r="5">
  <animate attributeName="r" values="3;3;5;3;3" times="0;0.1;0.2;0.3;1" dur="1s" repeatCount="indefinite" begin="-0.5833333333333334s"></animate>
  <animate attributeName="fill" values="#00d1ff;#00d1ff;#00d1ff;#00d1ff;#00d1ff" repeatCount="indefinite" times="0;0.1;0.2;0.3;1" dur="1s" begin="-0.5833333333333334s"></animate>
</circle><circle cx="28.34936490538903" cy="62.5" fill="#00d1ff" r="5">
  <animate attributeName="r" values="3;3;5;3;3" times="0;0.1;0.2;0.3;1" dur="1s" repeatCount="indefinite" begin="-0.5s"></animate>
  <animate attributeName="fill" values="#00d1ff;#00d1ff;#00d1ff;#00d1ff;#00d1ff" repeatCount="indefinite" times="0;0.1;0.2;0.3;1" dur="1s" begin="-0.5s"></animate>
</circle><circle cx="25" cy="50" fill="#00d1ff" r="5">
  <animate attributeName="r" values="3;3;5;3;3" times="0;0.1;0.2;0.3;1" dur="1s" repeatCount="indefinite" begin="-0.4166666666666667s"></animate>
  <animate attributeName="fill" values="#00d1ff;#00d1ff;#00d1ff;#00d1ff;#00d1ff" repeatCount="indefinite" times="0;0.1;0.2;0.3;1" dur="1s" begin="-0.4166666666666667s"></animate>
</circle><circle cx="28.34936490538903" cy="37.50000000000001" fill="#00d1ff" r="5">
  <animate attributeName="r" values="3;3;5;3;3" times="0;0.1;0.2;0.3;1" dur="1s" repeatCount="indefinite" begin="-0.3333333333333333s"></animate>
  <animate attributeName="fill" values="#00d1ff;#00d1ff;#00d1ff;#00d1ff;#00d1ff" repeatCount="indefinite" times="0;0.1;0.2;0.3;1" dur="1s" begin="-0.3333333333333333s"></animate>
</circle><circle cx="37.499999999999986" cy="28.349364905389038" fill="#00d1ff" r="5">
  <animate attributeName="r" values="3;3;5;3;3" times="0;0.1;0.2;0.3;1" dur="1s" repeatCount="indefinite" begin="-0.25s"></animate>
  <animate attributeName="fill" values="#00d1ff;#00d1ff;#00d1ff;#00d1ff;#00d1ff" repeatCount="indefinite" times="0;0.1;0.2;0.3;1" dur="1s" begin="-0.25s"></animate>
</circle><circle cx="49.99999999999999" cy="25" fill="#00d1ff" r="5">
  <animate attributeName="r" values="3;3;5;3;3" times="0;0.1;0.2;0.3;1" dur="1s" repeatCount="indefinite" begin="-0.16666666666666666s"></animate>
  <animate attributeName="fill" values="#00d1ff;#00d1ff;#00d1ff;#00d1ff;#00d1ff" repeatCount="indefinite" times="0;0.1;0.2;0.3;1" dur="1s" begin="-0.16666666666666666s"></animate>
</circle><circle cx="62.5" cy="28.349364905389034" fill="#00d1ff" r="5">
  <animate attributeName="r" values="3;3;5;3;3" times="0;0.1;0.2;0.3;1" dur="1s" repeatCount="indefinite" begin="-0.08333333333333333s"></animate>
  <animate attributeName="fill" values="#00d1ff;#00d1ff;#00d1ff;#00d1ff;#00d1ff" repeatCount="indefinite" times="0;0.1;0.2;0.3;1" dur="1s" begin="-0.08333333333333333s"></animate>
</circle><circle cx="71.65063509461096" cy="37.499999999999986" fill="#00d1ff" r="5">
  <animate attributeName="r" values="3;3;5;3;3" times="0;0.1;0.2;0.3;1" dur="1s" repeatCount="indefinite" begin="0s"></animate>
  <animate attributeName="fill" values="#00d1ff;#00d1ff;#00d1ff;#00d1ff;#00d1ff" repeatCount="indefinite" times="0;0.1;0.2;0.3;1" dur="1s" begin="0s"></animate>
</circle>
            </svg>
           </div>
        </div>


        <div class="tab_Two_Box table-section">
          <div class="resp-table">
             <table class="skygren-table borderOff log-tbl"  v-if="userfollowappslist.length>0">
                <thead>
               <tr>
                  <th></th>
                  <th>Logo</th>
                  <th>App Name</th>
                  <th>View Report</th>
                  <th>Email Alerts</th>
                </tr>
                </thead>
              <tbody>
                <tr v-bind:key="result.app_id" v-for="result in userfollowappslist">
                  <td style="text-align: center;">
                    <div class="form-check tableChek">
                      <input class="chk-bx" v-model="deletedappsid" type="checkbox" :value="result.app_id" >
                    </div>
                  </td>
                  <td style="text-align: center;">
                    <router-link :to="'/about-details/' + result.app_id" class="td_link"><img :src=result.app_logo alt=""></router-link> 
                  </td>
                  <td style="text-align: left;"><router-link :to="'/about-details/' + result.app_id" class="td_link">{{result.app_title}}</router-link></td>
                  <!-- <td style="text-align: center;">App Listing 1</td> -->
                  <td style="text-align: center;">
                    <div class="borderBtn">
                         <router-link :to="'/app-change-logs/'+result.app_id">  Run Report </router-link>
                    </div>
                  </td>
                  <td style="text-align: center;">
                    <div class="form-check tableChek">
                      <input  v-if="userfollowapps.some(userfollowapps => userfollowapps.app_id === result.app_id && userfollowapps.is_send_report==='1')" class="form-check-input" @click="addremovesendreportsubscription($event)"    type="checkbox" :value="result.app_id" name="flexCheckDefault[]" checked="checked" >
                      <input v-else class="form-check-input" @click="addremovesendreportsubscription($event)"    type="checkbox" :value="result.app_id" name="flexCheckDefault[]" >
                    
                     </div>
                  </td>
                </tr>
                       
              </tbody>
             </table>

          

            <div class="data-nt" v-else>Sorry no data found.</div>

            <nav aria-label="navigation-cuts">
            <pagination 
            v-model="page" 
            :records="userfollowapptotal" 
            :per-page="10" 
            :options={chunk:5} 
            @paginate="nextprevious($event)"
            />          
            </nav>

              </div>

        </div>

      </div>



 </div>
</template>

<script>
import ConfirmDialogue from '@/components/ConfirmDialogue.vue'
import {mapState,mapActions} from 'vuex'
import { useRoute } from 'vue-router'
export default {
   name:'ChangeLogAppListing',
   components: {
    ConfirmDialogue,
  },
  data() {
        return {
          isloder:true,
          isload:false,
          startindex:1,
          popoularapsload:false,
          popoularaps:false,
          page: 1,
          params: {
                  page: 1,
                  per_page: 10,
                  user_id: '',
              },
          isfollowdataparams: {
                user_id: '',
                app_id: '',
                action:'',
            },
           searchappslist:[],
           filterdata: {
                input: '',
                selection: null,
                userid: null,
				}, 
          searchkey : null, 
          appfollowingid:false, 
          deletedappsid:[], 
          multipleremove:{
            userid:'',
            apsids:[],
            action:'Delete',
          },

          subscription:{
            userid:'',
            appsid:[]
          }         
        }
  },
   mounted() {  
        const vm = this;
        const route=useRoute();
        vm.isfollowdataparams.user_id=vm.user.data.userid;
        vm.filterdata.userid = this.user.data.userid; 
        vm.params.user_id=vm.user.data.userid;
          if(route.query.page){
          if(parseInt(route.query.page)>1){
              this.page=route.query.page;
              this.params.page=route.query.page;
              }else{
                this.page=1;
                this.params.page=1;
                vm.userfollowapsdetails( vm.isfollowdataparams);
              }
          }else{
              vm.userfollowapsdetails( vm.isfollowdataparams);
          }

        vm.SearchAppbykeyword(this.filterdata);
        vm.userfollowaps(vm.isfollowdataparams);
  },
  computed: {
      ...mapState({
          userfollowappslist: (state) => state.AppListModule.getuserfollowapplist,
          user: (state) => state.authModule.user,
          userfollowapps: (state) => state.authModule.userfollowapps,
          userfollowapptotal: (state) => state.AppListModule.userfollowapptotal,
          searchAppresult: (state) => state.AppListModule.searchAppresult,
          appfolowinglimitover: (state) => state.authModule.appfolowinglimitover,
      }),
  },
  methods:{
        ...mapActions({
                userfollowaps: 'authModule/userfollowaps',
                userfollowapsdetails: 'AppListModule/UserFollowApsDetails',
                SearchAppbykeyword: 'AppListModule/SearchAppbykeyword',
                RemoveAppsFromFollowingList: 'authModule/removeappsfromfollowinglist',
                AddRemoveEmailSubscription: 'authModule/AddRemoveEmailSubscription',

          }),
         nextprevious($data){ 
                this.$router.replace({ name: "AppTracking", query: {page: $data} })
                const vm = this; 
                this.isloder=true;
                vm.params.page=$data;
                vm.startindex=(($data-1)*10)+1;
                vm.userfollowapsdetails(this.params)
         },


         addremovesendreportsubscription(event){
              this.subscription.userid=this.user.data.userid;
              this.subscription.appsid=event.target.value;
              this.AddRemoveEmailSubscription(this.subscription);
         },
        

        // addFollow(event){
        //     this.params.page=1;
        //     this.startindex=1;
        //     this.isfollowdataparams.user_id=this.user.data.userid;
        //     this.isfollowdataparams.app_id=event;
        //     this.page=1;
        //     this.isloder=true;
        //     this.isfollowdataparams.action='ADD';
        //     this.userfollowaps(this.isfollowdataparams);
        // },
        
        async unflowamultiplepps(event){

           if(this.deletedappsid.length<=0){
              return false
           }else{
              
              this.multipleremove.userid=this.user.data.userid;
              this.multipleremove.apsids=this.deletedappsid;

              const ok = await this.$refs.confirmDialogue.show({
                title: 'Delete Selected aps',
                message: 'Are you sure you want to delete your selected aps?',
                okButton: 'Confirm Delete',
              })
              if (ok) {
                  this.isloder=true;
                  this.RemoveAppsFromFollowingList(this.multipleremove)
              } else {
                 this.deletedappsid=[]
              }
              
           }
            
        },
        onInputEventHandlerApps(event){
            this.filterdata.input = event.input;
        },
             
        selectItemAppsFlowing(item){
            this.searchkey=item.app_title;
            this.searchkey=item.app_title;
            this.appfollowingid=item.app_id;
            return item.app_title;  
        },

        addapsinuserlist(){
           if(this.appfollowingid)
            { 
                //this.addFollow(this.appfollowingid)
                this.params.page=1;
                this.startindex=1;
                this.isfollowdataparams.user_id=this.user.data.userid;
                this.isfollowdataparams.app_id=this.appfollowingid;
                this.page=1;
                this.isloder=true;
                this.isfollowdataparams.action='ADD';
                this.userfollowaps(this.isfollowdataparams);
                this.appfollowingid=false;
                this.searchkey='';
                this. searchappslist=[];
            }
        } 

    },
     watch: {
        isload(){ 
         //this.isload=true;
          this.userfollowapsdetails(this.params)
         },
         userfollowapps(){ 
          this.userfollowapsdetails(this.params)
          this.SearchAppbykeyword(this.filterdata);
         },
         userfollowappslist(){ 
          this.isload=true;
          this.isloder=false;

         },
         popoularaps(){
          this.popoularapsload =true;
         }
     }
} 
 
</script>

<style scoped>
.main_right-pn .table-section table tbody tr td:first-child {
  border-right: 1px solid #3f3f5299;
  padding-left: 10px;
padding-right: 10px;
}

/* .tab_Two_Box.table-section .tableChek{ padding-left: 0;} */

.main_right-pn .table-section table thead tr th .form-check.tableChek input[type="checkbox"]{
    margin-top: 0em !important;
    background-color: #454558;
    border: 1px solid #7D7D95;
    border-radius: 3px;
}

.psrl-sec{ position: relative; margin:30px 0;}
 .main_right-pn .tab_box_margin .tab-content article  {
    padding-bottom: 0px !important;
}
.main_right-pn .changeLogosBtn ul  {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 60px 0 20px 0;
}

:deep(#compayarpps) {
  height: 43px;
  border: 1px solid #5D5D76;
}

.main_right-pn .changeLogosBtn ul a  {
    text-decoration: none;
    font-weight: 500;
    font-size: 19px;
    line-height: 28px;
    text-align: center;
    letter-spacing: 0.02em;
    text-transform: capitalize;
    color: #FFFFFF;
    display: block;
    padding: 7px 33px;
}
.main_right-pn .changeLogosBtn ul .changeLogosyellowBtn  {
    background: #FF7301;
    border-radius: 4px;
    
}
.main_right-pn .tab_Two_Box .borderOff tbody td {
    border-left: none !important;
}
.main_right-pn .tab_Two_Box .borderOff tbody .borderBtn a  {
    text-decoration: none;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: #FFFFFF;
    background: #4BCCC0;
    border-radius: 4px;
    padding: 7px 30px;
}
.main_right-pn .tab_boxe {
  padding-top: 110px;
}
.main_right-pn .tab_boxe .tab-content article h4, .main_right-pn .tab_boxe .tab-content .media-assets h4 {
  font-weight: 300;
  font-size: 35px;
  text-transform: capitalize;
  color: #FFFFFF;
  margin: 0 0 30px 0;
  line-height: 1;
}
.main_right-pn .tab_boxe .tab-content article p {
  font-family: 'Rubik';
  font-weight: 400;
  font-size: 16px;
  color: #BBBBBB;
  margin: 0;
  line-height: 22px;
  padding-bottom: 30px;
}
.app_InputBox {
  width: 100%;
  max-width: 846px;
  margin-top: 36px;
  display: flex;
  flex-wrap: wrap;
  row-gap: 20px;
}
.app_InputBox .app_InputBoxSub {
  margin-right: 10px;
  width: 100%;
  max-width: 341px;
}
.app_InputBox .app_InputBoxSub select {
  width: 100%;
  height: 42px;
  background: #323243;
  border: 1px solid #5D5D76;
  font-family: 'Rubik';
  font-weight: 400;
  font-size: 17px;
  line-height: 20px;
  letter-spacing: 0.02em;
  color: rgba(255, 255, 255, 0.72);
  padding: 0 20px;
}
.app_InputBox .app_InputBoxBtn {
  display: flex;
  align-items: flex-end;
}
.app_InputBox .app_InputBoxBtn .app_In_Btn {
  background: #00C1EC;
  border-radius: 4px;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  text-transform: uppercase;
  color: #FFFFFF;
  text-shadow: 0px 0.5px 1px rgba(0, 0, 0, 0.25);
  padding: 8px 43px;
  border: none;
}

.main_right-pn .table-section table.log-tbl td:nth-child(4n){border-right: 1px solid rgba(63, 63, 82, 0.6); padding-right: 20px;}
.main_right-pn .table-section table.log-tbl td:last-child{ padding-left: 15px; padding-right: 15px;}




.app_InputBoxSub input[type="text"], .app_InputBoxSub input[type="search"] { background: #323243;
opacity: 0.65; border: 1px solid #5D5D76; border-radius: 4px; height:43px; line-height:43px; width: 100%; text-indent: 10px; color: #fff;}


.light-theme-np .main_right-pn .app_InputBoxSub input[type="text"], .light-theme-np .main_right-pn .app_InputBoxSub input[type="search"] { background: #e1e1e1;
opacity: 1; border: 1px solid #e1e1e1; border-radius: 4px; height:43px; line-height:43px; width: 100%; text-indent: 10px; color: #0f1215;}

.light-theme-np .main_right-pn .tab_boxe h4, .light-theme-np .main_right-pn .tab_boxe p, .light-theme-np .main_right-pn .changeLogosBtn ul a {
  color: #212529 !important;
}

.light-theme-np .main_right-pn .table-section table.log-tbl td[data-v-7db096ab]:nth-child(4n) {
  border-right: 1px solid rgba(99, 99, 102, 0.2);
  padding-right: 20px;}
 
.light-theme-np .main_right-pn .table-section table tbody tr td:first-child{ border-right: 1px solid rgba(99, 99, 102, 0.2);}
@media (max-width:740px) { 
.tab_Two_Box.table-section .tableChek{ padding-left: 0;}
}

</style>