<template>
  <section class="platfrom">
        <div class="platfrombg"></div>
        <div class="platfrombgOne"></div>
        <div class="container">
            <div class="row">
                <div class="col-lg-12 platfromHead">
                    <h2>All-in-One Platform</h2>
                </div>
                <div class="col-lg-4" data-aos="fade-up" data-aos-duration="1000">
                    <div class="platfromBox">
                        <div class="platFromBoxImage">
                            <img src="@/assets/images/homePage-1/platform1.png">
                        </div>
                        <div class="platFromBoxText">
                            <h3>Monitor app store benchmarks</h3>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4" data-aos="fade-up" data-aos-duration="1000">
                    <div class="platfromBox">
                        <div class="platFromBoxImage">
                            <img src="@/assets/images/homePage-1/platform2.png">
                        </div>
                        <div class="platFromBoxText">
                            <h3>Optimize your digital ad performance</h3>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4" data-aos="fade-up" data-aos-duration="1000">
                    <div class="platfromBox">
                        <div class="platFromBoxImage">
                            <img src="@/assets/images/homePage-1/platform3.png">
                        </div>
                        <div class="platFromBoxText">
                            <h3>Uncover Opportunities for growth</h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>

</script>

<style scope>
/* platfrom section start here */
img{ max-width: 100%;}
.platfrom  {
    margin-top: 120px;
    position: relative;
}
.platfrom .platfromHead h2  {
    font-weight: 500;
    font-size: 61px;
    line-height: 38px;
    text-transform: capitalize;
    color: #000000;
    text-align: center;
    padding-bottom: 74px;
}
.platfrom .platfromBox  {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    border-right: 1px dashed #B3B3B3;
    padding: 0 33px;
}
.platfrom [class^="col"]:last-child .platfromBox {
    border-right: none;
}
.platfrom .platfromBox .platFromBoxImage  {
    width: 120px;
}
.platfrom .platfromBox .platFromBoxImage img  {
    display: block;
}
.platfrom .platfromBox .platFromBoxText  {
    width: calc(100% - 120px);
}
.platfrom .platfromBox .platFromBoxText h3  {
    font-family: 'Rubik';
    font-weight: 300;
    font-size: 26px;
    line-height: 39px;
    text-transform: capitalize;
    color: #000000;
}
.platfrombg {
    position: absolute;
    top: 0px;
    left: 110px;
    content: "";
    background: url(@/assets/images/homePage-1/platformbg.png);
    width: 153px;
    height: 295px;
    background-repeat: no-repeat;
}
.platfrombgOne  {
    position: absolute;
    top: 0px;
    right: 80px;
    content: "";
    background: url(@/assets/images/homePage-1/platformbg1.png);
    width: 160px;
    height: 412px;
    background-repeat: no-repeat;
}

.container {
  width: 100%;
  max-width: 1543px !important;
}

@media (max-width: 1400px){
  .platfrom .platfromBox {
  padding: 0 20px;
}  

.platfrom .platfromBox .platFromBoxText h3{font-size: 21px;}
.platfrombg, .platfrombgOne{ display: none;}

}

  @media (max-width: 991px){
 .platfrom .platfromBox {
  padding: 15px 20px;
  border-top: 1px dashed #B3B3B3;
  border-right: none;
}
  }

@media (max-width: 740px){
.platfrom .platfromHead h2 {
  font-size: 28px;
  line-height: 34px;
  padding-bottom: 20px;
}

.platfrom .platfromBox {
  padding: 15px 0;
}
.platfrom .platfromBox .platFromBoxText h3 {
  font-size: 18px;
  line-height: 26px;
}
.appBetter .appBetterCenter .appBetterRight h2{
  font-weight: 500;
  font-size: 35px;
  line-height: 40px;
}





}

/* platfrom section end here */
</style>