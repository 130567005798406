<template>
<div class="col-xl-12 col-lg-12">
   
  <div class="row gy-4">
  <div class="col-xl-6 col-lg-12">
          <div class="tbl-head">
             <p green >App Store Breakdown</p>
            <h6>Total App Category</h6> 
         </div>
           <div class="total_cat_app">
               <canvas  id="myChartTwo"  width="400" height="350"></canvas>
           </div> 
    </div>


     <div class="col-xl-6 col-lg-12">
          <div class="tbl-head">
             <p green >App Store Breakdown</p>
            <h6>Free vs paid</h6> 
            </div>
                  <div class="total_free_vs_paid_app" style="tex">
                      <canvas id="myChart2" width="400" height="350"></canvas>
                    </div>
           </div>
  </div>  
 </div>    
</template>
<script>
import Chart from 'chart.js/auto';
import {mapState} from 'vuex'
export default {
  name: 'TabPanel',
  // props: {
  //   msg: String
  // },

   data(){
      return{
          chartTab:'tab 1',
          chatdata:{
                labels:[],
                data:[],
               },
            chartdataset:{
                labels:["Free","Paid"],
                data:[],
               }, 
          isload:false    
      }
   },
   computed: {
      ...mapState({
          current30daysgraphapps: state => state.AppListModule.current30daysapps,
          paidfreedata: state => state.AppListModule.paidfreedata,
      }),
  }, 

   mounted(){    
    this.isload=true;
},
methods:{

      int(){
          var ctx2=document.getElementById("myChartTwo").getContext("2d");            
          var myChartTwo=new Chart(
          ctx2,{
          type:"doughnut",
          data:{labels:this.chatdata.labels,
          datasets:[{data:this.chatdata.data,
          borderColor:["#685DC9","#C051CA","#BDD81A","#16DB88","#FEC33E","#F3884C","#7747DF","#007BFF","#2EC859"],
          backgroundColor:["#685DC9","#C051CA","#BDD81A","#16DB88","#FEC33E","#F3884C","#7747DF","#007BFF","#2EC859"],borderWidth:1}]},
          options:{
            responsive:!0,
            maintainAspectRatio:!1,
            plugins:{
              legend:{
                position:"right",
                  labels: {
                    font: {
                        size: 18,
                    }
                  },
                },   
              title:{
                display:!0
                }}}});

          myChartTwo;
          this.isload=true; 
      },

      paidfreechart(){
          var ctx = document.getElementById("myChart2"); 
          var myChart2 = new Chart(ctx, {
          type: 'doughnut',
          data: {
          labels: ["Free","Paid"],
          datasets: [{
                  label: '# of Tomatoes',
                  data: this.chartdataset.data,
                  backgroundColor: [
                  "#685DC9","#C051CA"
                  ],
                  borderColor: [
                  "#685DC9","#C051CA"
                  ],
                  borderWidth: 1
              }]
          },
          options: {
          responsive: false,
          plugins:{
            legend:{
              position:"right",
              labels: {
                    font: {
                        size: 18,
                    }
                  },
              },
            title:{display:!0}
            }
          }
          });
          myChart2;
      }
  },  

  watch:{

    current30daysgraphapps(){
       if(this.current30daysgraphapps.length>0){
            this.current30daysgraphapps.forEach((element, index) => {
            this.chatdata.labels.push(element.category_title);
            this.chatdata.data.push(element.category_app_count);
            });
            this.int();
          }
        
        //  if(this.paidfreedata){
        //     this.chartdataset.data.push(this.paidfreedata.totalfreecount);
        //     this.chartdataset.data.push(this.paidfreedata.totalpaidcount);
        //     this.paidfreechart();
        //   }

    },
    paidfreedata(){
        if(Object.keys(this.paidfreedata).length>0){
              this.chartdataset.data.push(this.paidfreedata.totalfreecount);
              this.chartdataset.data.push(this.paidfreedata.totalpaidcount);
              this.paidfreechart();
          }
    },
  }
}
</script>
<style scoped>
 .tab li{
font-weight: 600;
font-size: 16px;
text-transform: uppercase;
padding: 0 20px;
display: inline-block;
color: #000000;
background-color: #eaecee;
border-top-left-radius: .25rem;
border-top-right-radius: .25rem;
padding: .5rem 1rem;
cursor: pointer;
position: relative;
 }
.tab li.active:before{
border-top: 8px solid #ED553B;
border-left: 6px solid transparent;
border-right: 6px solid transparent;
position: absolute;
left: 20%;
bottom: -8px;
margin: 0 0 0 -4px;
content: "";
}

 .tab li.active{ color: #fff;
background-color: #ED553B;}


.total_cat_app, .total_free_vs_paid_app{
    background-color: #292938;
    padding: 10px;
    border: 1px solid #3f3f5299;
    margin: 0 auto;
}  

.total_free_vs_paid_app canvas{
    margin: 0 auto;
}  
</style>
 