import { createStore, createLogger } from 'vuex'
import AppListModule from '@/store/modules/latestapp'
import authModule from '@/store/modules/auth'
import AppreviewModule from '@/store/modules/appreview'
import BlogDetails from '@/store/modules/blog'
import createPersistedState from 'vuex-persistedstate'
import SecureLS from 'secure-ls'

const debug = process.env.NODE_ENV !== 'production'
const ls = new SecureLS({ isCompression: false });
const _ = require('lodash');

export const initialStoreModules = {
    authModule,
    AppListModule,
    AppreviewModule,
    BlogDetails,
};

const store = createStore({
    modules: _.cloneDeep(initialStoreModules),
    mutations: {
        RESET_STATE (state) {
            _.forOwn(initialStoreModules, (value, key) => {
                if (key !== 'authModule') {
                    state[key] = _.cloneDeep(value.state);
                }
            });
        },
    },
    strict: debug,
    plugins: debug ? [
                    createLogger(),
                    createPersistedState({
                        key: 'vuex',
                        storage: {
                            getItem: key => ls.get(key),
                            setItem: (key, value) => ls.set(key, value),
                            removeItem: key => ls.remove(key)
                        },
                        reducer (state) {
                            if (state.authModule.isUserLoggedIn === false) {
                                return {};
                            }

                            return state;
                        }
                    })
              ] : [
                createPersistedState({
                    key: 'vuex',
                    storage: {
                        getItem: key => ls.get(key),
                        setItem: (key, value) => ls.set(key, value),
                        removeItem: key => ls.remove(key)
                    },
                    reducer (state) {
                        if (state.authModule.isUserLoggedIn === false) {
                            return {};
                        }

                        return state;
                    }
                })
            ]
});

export default store;