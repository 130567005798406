<template>
  <div class="ful-wdth" v-if="isload">
     <div class="section__head">
        <h4>Most Installed Ranking</h4>
      </div>

      <div class="filter-mt-sect">
       <Form @submit="filterapps">
           <div class="item">
            <label for="">Category {{ selected }}</label>
            <Field name="selected_cat" v-model="selected_cat"  as="select" @change="selectsubcat($event)">
                <option v-bind:key="options.category_id" v-for="options in catoptions" :value="options.category_id">{{options.category_title}}</option>
            </Field>
           </div>  
          <div class="item">
            <label for="">Subcategory</label>

            <Field name="selected_subcat" v-model="selected_subcat"  as="select">
                <option v-bind:key="options.category_id" v-for="options in subcatoptions" :value="options.category_id">{{options.category_title}}</option>
            </Field>

            
          </div>
          <div class="item">
            <button class="btn" type="submit">Filter</button>
          </div>

           <div class="item">
            <button class="btn" type="button" @click="filterreset">Reset</button>
          </div>
        </Form>
      </div>
     
       <div class="table-section ">
           
         

            <div class="resp-table">
                  <div class="loading" v-if="displayload">
                    <div class="loader1">
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="margin:auto;display:block;" width="200px" height="200px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
<circle cx="75" cy="50" fill="#00d1ff" r="5">
  <animate attributeName="r" values="3;3;5;3;3" times="0;0.1;0.2;0.3;1" dur="1s" repeatCount="indefinite" begin="-0.9166666666666666s"></animate>
  <animate attributeName="fill" values="#00d1ff;#00d1ff;#00d1ff;#00d1ff;#00d1ff" repeatCount="indefinite" times="0;0.1;0.2;0.3;1" dur="1s" begin="-0.9166666666666666s"></animate>
</circle><circle cx="71.65063509461098" cy="62.5" fill="#00d1ff" r="5">
  <animate attributeName="r" values="3;3;5;3;3" times="0;0.1;0.2;0.3;1" dur="1s" repeatCount="indefinite" begin="-0.8333333333333334s"></animate>
  <animate attributeName="fill" values="#00d1ff;#00d1ff;#00d1ff;#00d1ff;#00d1ff" repeatCount="indefinite" times="0;0.1;0.2;0.3;1" dur="1s" begin="-0.8333333333333334s"></animate>
</circle><circle cx="62.5" cy="71.65063509461096" fill="#00d1ff" r="5">
  <animate attributeName="r" values="3;3;5;3;3" times="0;0.1;0.2;0.3;1" dur="1s" repeatCount="indefinite" begin="-0.75s"></animate>
  <animate attributeName="fill" values="#00d1ff;#00d1ff;#00d1ff;#00d1ff;#00d1ff" repeatCount="indefinite" times="0;0.1;0.2;0.3;1" dur="1s" begin="-0.75s"></animate>
</circle><circle cx="50" cy="75" fill="#00d1ff" r="5">
  <animate attributeName="r" values="3;3;5;3;3" times="0;0.1;0.2;0.3;1" dur="1s" repeatCount="indefinite" begin="-0.6666666666666666s"></animate>
  <animate attributeName="fill" values="#00d1ff;#00d1ff;#00d1ff;#00d1ff;#00d1ff" repeatCount="indefinite" times="0;0.1;0.2;0.3;1" dur="1s" begin="-0.6666666666666666s"></animate>
</circle><circle cx="37.50000000000001" cy="71.65063509461098" fill="#00d1ff" r="5">
  <animate attributeName="r" values="3;3;5;3;3" times="0;0.1;0.2;0.3;1" dur="1s" repeatCount="indefinite" begin="-0.5833333333333334s"></animate>
  <animate attributeName="fill" values="#00d1ff;#00d1ff;#00d1ff;#00d1ff;#00d1ff" repeatCount="indefinite" times="0;0.1;0.2;0.3;1" dur="1s" begin="-0.5833333333333334s"></animate>
</circle><circle cx="28.34936490538903" cy="62.5" fill="#00d1ff" r="5">
  <animate attributeName="r" values="3;3;5;3;3" times="0;0.1;0.2;0.3;1" dur="1s" repeatCount="indefinite" begin="-0.5s"></animate>
  <animate attributeName="fill" values="#00d1ff;#00d1ff;#00d1ff;#00d1ff;#00d1ff" repeatCount="indefinite" times="0;0.1;0.2;0.3;1" dur="1s" begin="-0.5s"></animate>
</circle><circle cx="25" cy="50" fill="#00d1ff" r="5">
  <animate attributeName="r" values="3;3;5;3;3" times="0;0.1;0.2;0.3;1" dur="1s" repeatCount="indefinite" begin="-0.4166666666666667s"></animate>
  <animate attributeName="fill" values="#00d1ff;#00d1ff;#00d1ff;#00d1ff;#00d1ff" repeatCount="indefinite" times="0;0.1;0.2;0.3;1" dur="1s" begin="-0.4166666666666667s"></animate>
</circle><circle cx="28.34936490538903" cy="37.50000000000001" fill="#00d1ff" r="5">
  <animate attributeName="r" values="3;3;5;3;3" times="0;0.1;0.2;0.3;1" dur="1s" repeatCount="indefinite" begin="-0.3333333333333333s"></animate>
  <animate attributeName="fill" values="#00d1ff;#00d1ff;#00d1ff;#00d1ff;#00d1ff" repeatCount="indefinite" times="0;0.1;0.2;0.3;1" dur="1s" begin="-0.3333333333333333s"></animate>
</circle><circle cx="37.499999999999986" cy="28.349364905389038" fill="#00d1ff" r="5">
  <animate attributeName="r" values="3;3;5;3;3" times="0;0.1;0.2;0.3;1" dur="1s" repeatCount="indefinite" begin="-0.25s"></animate>
  <animate attributeName="fill" values="#00d1ff;#00d1ff;#00d1ff;#00d1ff;#00d1ff" repeatCount="indefinite" times="0;0.1;0.2;0.3;1" dur="1s" begin="-0.25s"></animate>
</circle><circle cx="49.99999999999999" cy="25" fill="#00d1ff" r="5">
  <animate attributeName="r" values="3;3;5;3;3" times="0;0.1;0.2;0.3;1" dur="1s" repeatCount="indefinite" begin="-0.16666666666666666s"></animate>
  <animate attributeName="fill" values="#00d1ff;#00d1ff;#00d1ff;#00d1ff;#00d1ff" repeatCount="indefinite" times="0;0.1;0.2;0.3;1" dur="1s" begin="-0.16666666666666666s"></animate>
</circle><circle cx="62.5" cy="28.349364905389034" fill="#00d1ff" r="5">
  <animate attributeName="r" values="3;3;5;3;3" times="0;0.1;0.2;0.3;1" dur="1s" repeatCount="indefinite" begin="-0.08333333333333333s"></animate>
  <animate attributeName="fill" values="#00d1ff;#00d1ff;#00d1ff;#00d1ff;#00d1ff" repeatCount="indefinite" times="0;0.1;0.2;0.3;1" dur="1s" begin="-0.08333333333333333s"></animate>
</circle><circle cx="71.65063509461096" cy="37.499999999999986" fill="#00d1ff" r="5">
  <animate attributeName="r" values="3;3;5;3;3" times="0;0.1;0.2;0.3;1" dur="1s" repeatCount="indefinite" begin="0s"></animate>
  <animate attributeName="fill" values="#00d1ff;#00d1ff;#00d1ff;#00d1ff;#00d1ff" repeatCount="indefinite" times="0;0.1;0.2;0.3;1" dur="1s" begin="0s"></animate>
</circle>
            </svg>
                    </div>
          </div>
              <table class="blue-table multi-icon-color thd-center">
                <thead>
                  <tr>
                    <th>Rank</th>
                    <th>Logo</th>
                    <th>App Name</th>
                    <th>Rank Changes</th>
                    <th>Page</th>
                    <th>Rating</th>
                    <th>Reviews</th>
                    <th>Follow</th>
                  </tr>
                </thead>
                  <tbody v-if="appslistbymostinstallrank.length>0">
                   <tr v-bind:key="result.app_id" v-for="(result,index) in appslistbymostinstallrank" :id="'row_'+index">
                  <td style="text-align: center;">{{result.mostinstallrank}}</td>
                  <td style="text-align: center;">
                    <router-link :to="'/about-details/' + result.app_id"><img :src=result.app_logo></router-link>
                  </td>
                  <td><router-link :to="'/about-details/' + result.app_id">{{result.app_title}}</router-link></td>
                   <td style="text-align: center;">

                       {{result.install_rankchange>0?'+':''}}{{result.install_rankchange}}
                        <span>
                       <svg v-if="parseInt(result.install_rankchange)>0" xmlns="http://www.w3.org/2000/svg" width="11" height="11" fill="#3CAEA3" class="bi blue bi-arrow-up" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z"/>
                      </svg>

                      <svg v-if="parseInt(result.install_rankchange)<0" xmlns="http://www.w3.org/2000/svg" width="11" height="11" fill="#FF6D2E" class="bi red bi-arrow-down" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z"/>
                      </svg>

                     <svg v-if="parseInt(result.install_rankchange)==0" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#3CAEA3" class="bi blue bi-arrow-down-up" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M11.5 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L11 2.707V14.5a.5.5 0 0 0 .5.5zm-7-14a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L4 13.293V1.5a.5.5 0 0 1 .5-.5z">
                      </path></svg>
                    </span>
                   </td> 

                  
                  <td style="text-align: center;">{{params.page}}</td>
                  <td style="text-align: center;">{{result.app_rating==null?'N/A':result.app_rating}}</td>                
                  <td style="text-align: center;">{{result.app_num_of_reviews}}</td>
                  <td>
                      <div class="form-check tableChek">
                          <input  v-if="userfollowapps.some(userfollowapps => userfollowapps.app_id === result.app_id)" class="form-check-input" @click="unflowapps($event)"    type="checkbox" :value="result.app_id" name="flexCheckDefault[]" checked="checked" >
                    <input v-else class="form-check-input" @click="addFollow($event)"    type="checkbox" :value="result.app_id" name="flexCheckDefault[]" >
              
                      </div>
                    </td>
                </tr>
                
                  </tbody>

                   <tbody v-else>
                 <tr><td colspan="8">Sorry! no data found.</td>
                  </tr>
              </tbody> 

              </table>
            </div>
          </div>
          <nav aria-label="navigation-cuts">
               
       <pagination 
       v-model="page" 
       :records="totaldata" 
       :per-page="24" 
       :options={chunk:5} 
       @paginate="nextprevious($event)"
       />

      </nav>
    </div>
</template>
<script>
import { useRoute } from 'vue-router';
import moment from "moment";
import { mapState,mapActions } from 'vuex';
import { Field, Form } from 'vee-validate'
import * as yup from 'yup'


//import { useStore } from "vuex";
import axios from 'axios';
export default {
    name: 'MostInstalled',
    components:{
        Field,
        Form,
    },
    data() {
        return {           
            moment: moment,
            resultdata:[],
            isload:false,
            page: 1,
            startindex:1,
            url:process.env.VUE_APP_API_BASE_URL,
            totaldata:0,
            selected_cat: '',
            selected_subcat: '',
            catoptions: [],
            userfollowapp:[],
            subcatoptions: [{'category_id':'','category_title':'Select Subcategory','subcategory_description':''}],
            params: {
                  page: 1,
                  per_page: 24,
                  catid: '',
                  subcatid: '',
                  order: 'desc',
                  orderBy: 'app_published_date',
                  type: 'mostreviewadded',
              },
             isfollowdataparams: {
                  user_id: '',
                  app_id: '',
                  action:'',
              },  
             catparams:{
                  catid:''
             }, 
             selectedContact: {},
             selectedsubContact: {},
             filtercategoryid:'0',
             displayload:true
        }
    },
   
    computed:{
          ...mapState({
              totalapps: (state) => state.AppListModule.totalapps,
              appslistbymostinstallrank: (state) => state.AppListModule.mostinstallrankingaps,
              user: (state) => state.authModule.user,
              categorylist: (state) => state.AppListModule.categorylist,
              subcategorylist: (state) => state.AppListModule.subcategorylist,
              userfollowapps: (state) => state.authModule.userfollowapps,
              apscountcategorywise: (state) => state.AppListModule.apscountcategorywise,
              appfolowinglimitover: (state) => state.authModule.appfolowinglimitover,
          }),
    },
    mounted () {
        const vm = this;
        const route = useRoute();
        if(route.query.page){
          if(parseInt(route.query.page)>1){
             this.page=route.query.page;
             this.params.page=route.query.page;
          }else{
            this.page=1;
            this.params.page=1;            
            vm.getapplistBymostinstallrank(vm.params);
          }
        }else{
           vm.getapplistBymostinstallrank(vm.params);
        }
        vm.isfollowdataparams.user_id=vm.user.data.userid;
        vm.getappcatlist(vm.params);
        vm.getAppSubCategory(vm.catparams);
        //vm.userfollowaps(vm.isfollowdataparams);
        this.catoptions=this.categorylist;
        this.isload=true;
        this.totaldata=this.totalapps;        
    },
    async created () {
      
    },
    methods:{
        ...mapActions({
              getapplistBymostinstallrank: 'AppListModule/getapplistBymostinstallrank',
              getappcatlist: 'AppListModule/getAppCategory',
              getAppSubCategory: 'AppListModule/getAppSubCategory',
              userfollowaps: 'authModule/userfollowaps',
              userfollowapsdetails: 'AppListModule/UserFollowApsDetails',

          }),

          
          nextprevious($data){ 
                this.$router.replace({ name: "MostInstalled", query: {page: $data} })
                const vm = this; 
                this.displayload=true;
                vm.params.page=$data;
                vm.startindex=(($data-1)*24)+1;
               
          },

          selectsubcat(event){
             this.catparams.catid=event.target.value
             this.selected_subcat=''
            if(event.target.value!=''){
                this.getAppSubCategory(this.catparams);
            }              
            else{             
              this.subcatoptions=[{'category_id':'','category_title':'Select Subcategory','subcategory_description':''}]; 
            } 
              
          },

           addFollow(event){
              this.isfollowdataparams.user_id=this.user.data.userid;
              this.isfollowdataparams.app_id=event.target.value;
             if(this.appfolowinglimitover){
                 event.target.checked = false 
              }
              this.isfollowdataparams.action='ADD';
              this.userfollowaps(this.isfollowdataparams)
           },
           unflowapps(event){
                this.isfollowdataparams.user_id=this.user.data.userid;
                this.isfollowdataparams.app_id=event.target.value;
                this.isfollowdataparams.action='Delete';
                this.userfollowaps(this.isfollowdataparams)
           },
           filterapps(parameters){
              //console.log('parameters',parameters);
              this.$router.push({path: '/mostinstalled', query:''}) 
              this.params.page=1;
              this.displayload=true;
              if(parameters.selected_cat!='')
                  {
                     this.params.catid=parameters.selected_cat;
                     this.filtercategoryid=parameters.selected_cat;
                  }
              else   
                 this.params.catid='';

              if(parameters.selected_subcat!='')
                 {
                  this.params.subcatid=parameters.selected_subcat;
                  this.filtercategoryid=parameters.selected_subcat;
                 }
              else   
                this.params.subcatid='';
                this.startindex=1; 

                if(this.filtercategoryid)
                { 
                    this.totaldata=this.apscountcategorywise[this.filtercategoryid].category_app_count
                    this.page=1;
                }


              if(parameters.selected_cat=='' && parameters.selected_subcat=='')  
                 {
                   this.totaldata=this.totalapps
                   this.page=1;
                 }
               this.getapplistBymostinstallrank(this.params);   
           },
         
         filterreset(){
            this.$router.push({path: '/mostinstalled', query:''}) 
            this.params.page=1;
            this.displayload=true;
            this.startindex=1; 
            this.selected_cat='';
            this.selected_subcat='';
            this.params.catid='';
            this.params.subcatid='';
            this.selected_cat='';
            this.filtercategoryid=0;
            this.totaldata=this.totalapps
            this.subcatoptions=[{'category_id':'','category_title':'Select Subcategory','subcategory_description':''}]; 
            this.totaldata=this.totalapps
            this.page=1;
            this.getapplistBymostinstallrank() 
         }
    },

    watch: {
          appslistbymostinstallrank(){ 
          this.isload=true;
          this.displayload=false;
        },
       
        userfollowapps(){ 
          this.userfollowapsdetails(this.isfollowdataparams)
        },

        'params.page' (val){
            const vm = this;   
            vm.getapplistBymostinstallrank(vm.params);
        },
        subcategorylist(newval,oldval){
            this.subcatoptions=this.subcategorylist;
        }
  },

}
</script>



<style scoped>
 .loading{width: 100%; height: 100%;}
 .main_right-pn .table-section .resp-table{ position: relative;}
 .loading::before {
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    content: "";
  }
.loader1{ position: absolute; left: 50%; top:50%; transform: translate(-50%, -50%);} 
form{
  align-items: flex-end;
}
 .main_right-pn .filter-mt-sect{
    margin-bottom: 68px;
}
.main_right-pn .filter-mt-sect .item{
    display: flex;
    flex-direction: column;
}
.main_right-pn .filter-mt-sect form{
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
}
.main_right-pn .section__head h4 {
  font-weight: 300;
  font-size: 40px;
  color: #FFFFFF;
  margin-bottom: 0;
}
.main_right-pn .filter-mt-sect .item label{
    font-family: 'Rubik';
    font-weight: 400;
    font-size: 19px;
    letter-spacing: 0.02em;
    color: #FFFFFF;
    margin-bottom: 14px;
}

.main_right-pn .filter-mt-sect .item select{
    background: #323243;
    border: 1px solid #5D5D76;
    border-radius: 4px;
    width: 340px;
    font-family: 'Rubik';
    font-weight: 400;
    font-size: 17px;
    letter-spacing: 0.02em;
    color: #ffffffb8;
    height: 49.48px;
    padding-left: 20px;
}
.main_right-pn .filter-mt-sect .item select:focus{
    outline: none;
}
.main_right-pn .filter-mt-sect .item:last-child {
   justify-content: flex-end;
}
.main_right-pn .filter-mt-sect .item .btn{
    background: #00C1EC;
    border-radius: 4px;
    font-weight: 600;
    font-size: 18px;
    text-align: center;
    text-transform: uppercase;
    color: #FFFFFF;
    padding: 10px 44px;
    text-shadow: 0px 0.5px 1px #00000040;
}
.light-theme-np .main_right-pn .filter-mt-sect .item label,
.light-theme-np .main_right-pn .filter-mt-sect .item select{
    color: #212529;
}

.light-theme-np .main_right-pn .filter-mt-sect .item select{
    background-color: #e8e8e8e6;
    border: 1px solid #e7e7e757;
}
.light-theme-np .main_right-pn .filter-mt-sect .item select, .light-theme-np .main_right-pn .section__head h4 {
    color: #212529;
}

.main_right-pn nav[aria-label="navigation-cuts"] ul{
    justify-content: flex-end;
    column-gap: 10px;
}
.main_right-pn nav[aria-label="navigation-cuts"] ul .page-link{
    padding: 0;
    line-height: 1;
    font-family: 'Rubik';
    font-weight: 400;
    font-size: 15px;
    color: #FFFFFF;
    background: #444457b3;
    border: 0.5px solid #85858529;
    border-radius: 3px;
    display: flex;
    padding: 0 16px;
    height: 35px;
    align-items: center;
    justify-content: center;
    transition: all ease-in-out 300ms;
}
.main_right-pn nav[aria-label="navigation-cuts"] ul .active.page-link,
.main_right-pn nav[aria-label="navigation-cuts"] ul .page-link:hover {
    background: rgba(0, 209, 255, 0.7) !important;
    color: #fff !important;
}
/* Pagination end */

.light-theme-np .main_right-pn nav[aria-label="navigation-cuts"] ul .page-link{ color: #212529;}

.light-theme-np .main_right-pn nav[aria-label="navigation-cuts"] ul .page-link {
    background-color: #dadada;
}

@media (max-width:510px){
    .main_right-pn .filter-mt-sect .item,
    .main_right-pn .filter-mt-sect .item select{
        width: 100%;
    }
    .main_right-pn .filter-mt-sect .item:last-child{
        width: 100%;
    }
}
</style>

<style scoped>
 .main_right-pn table tbody td svg.blue{ color:#3CA7FF; fill:#3CA7FF;}
 .main_right-pn table tbody td svg.red{ color:#FF6D2E; fill:#FF6D2E;}
</style>

