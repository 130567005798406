<template>
      <section class="news">
        <div class="container">
            <div class="row">
                <div class="neWSbox">
                    <h2>Keep up to date with SAS Radar news.</h2>
                    <div class="newsInput">
                        <input class="nInput" type="text" placeholder="Enter Your Email Address">
                        <button class="nBtn" type="submit">Signup</button>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>

</script>

<style scoped>
/* news section start here */
img{ max-width: 100%;}
.news  {
    padding-top: 123px;
}
.news .neWSbox  {
    text-align: center;
}
.news .neWSbox h2  {
    font-weight: 500;
    font-size: 42px;
    line-height: 63px;
    color: #191927;
    padding-bottom: 24px;
}
.news .neWSbox .newsInput  {
    max-width: 808px;
    margin: 0 auto;
    position: relative;
}
.news .neWSbox .newsInput .nInput  {
    width: 100%;
    height: 67px;
    background: #FFFFFF;
    border: 1px solid #C9C9C9;
    box-shadow: 0px 4px 28px rgba(0, 0, 0, 0.06);
    border-radius: 15px;
    font-weight: 300;
    font-size: 17px;
    line-height: 26px;
    letter-spacing: 0.02em;
    color: #717171;
    padding-left: 37px;
    position: relative;
}
.news .neWSbox .newsInput .nBtn  {
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    letter-spacing: 0.11em;
    text-transform: uppercase;   
    color: #FFFFFF;
    width: 159.28px;
    height: 50.17px;
    position: absolute;
    right: 9px;
    top: 9px;
    background: #0165FE;
    border-radius: 10px;
    border: none;
    outline: none;
}
.news .neWSbox .newsInput .nBtn:hover  {
    background: #FEC33E;
}

@media (max-width: 740px){
.news .neWSbox h2{
  font-weight: 500;
  font-size: 24px !important;
  line-height: 32px;}
  .news .neWSbox .newsInput .nInput{ padding-left: 10px; font-size: 15px;}
  .news .neWSbox .newsInput .nBtn {
  font-weight: 600;
  font-size: 14px;
  line-height: 27px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #FFFFFF;
  width: 86.28px;
  height: 41.17px;
  position: absolute;
  right: 9px;
  top: 14px;
  background: #0165FE;
  border-radius: 10px;
  border: none;
  outline: none;
}
.freeSign .freeSignBox h2{
  font-weight: 300;
  font-size: 19px;
  line-height: 25px;
}

}

/* news section end here */

</style>