const mutations = {
    SET_LATESTAPS: (state, latestaps) => {
        state.latestaps = latestaps;
      },
    SET_DASHBOARDNEWESTAPP: (state, dashboardlatestaps) => {
      state.dashboardlatestaps = dashboardlatestaps;
    },  

    SET_DASHBOARDMOSTINSTALLAPP: (state, dashboardmostinstallaps) => {
      state.dashboardmostinstallaps = dashboardmostinstallaps;
    }, 

    SET_DASHBOARDRELEVENTAPP: (state, getdashboardrelevantaps) => {
      state.getdashboardrelevantaps = getdashboardrelevantaps;
    }, 

    SET_DASHBOARDUSERFOLLOWAPP: (state, getdashboarduserfollowaps) => {
      state.getdashboarduserfollowaps = getdashboarduserfollowaps;
    }, 

    SET_DASHBOARDMOSTREVIEWAPP: (state, dashboardmostreviewaddaps) => {
      state.dashboardmostreviewaddaps = dashboardmostreviewaddaps;
    }, 

    SET_TOTALAPPS: (state, totalapps) => {
      state.totalapps = totalapps;
    }, 

    SET_DATATOTAL: (state, datatotal) => {
      state.datatotal = datatotal;
    }, 
    SET_CATEGORY: (state, categorylist) => {
      state.categorylist = categorylist;
    },
    SET_LAST30DAYSAPPBYCAT: (state, current30daysapps) => {
      state.current30daysapps = current30daysapps;
    },
    SET_LASTADDED10APS: (state, lastadded10app) => {
      state.lastadded10app = lastadded10app;
    },
    SET_TOTALDEVELOPER: (state, totaldevloper) => {
      state.totaldevloper = totaldevloper;
    },
    SET_SUBCATEGORY: (state, subcategorylist) => {
      state.subcategorylist = subcategorylist;
    },

    SET_PAIDANDFREEDATA: (state, paidfreedata) => {
      state.paidfreedata = paidfreedata;
    },

    SET_APPSLINECHARTDATA: (state, appchartlinedata) => {
      state.appchartlinedata = appchartlinedata;
    },

    SET_APPBYREVIEWS: (state, getapplistbyreview) => {
      state.getapplistbyreview = getapplistbyreview;
    },

    SET_APPLISTBYSTORERANK: (state, getapplistbystorerank) => {
      state.getapplistbystorerank = getapplistbystorerank;
    },

    SET_TOTALDATACOUNT: (state, totaldatacount) => {
      state.totaldatacount = totaldatacount;
    },

    SET_TOTALREVIEWS: (state, totalreviews) => {
      state.totalreviews = totalreviews;
    },

    SET_APPBYMOSTADDEDREVIEW: (state, appbymostaddedreviews) => {
      state.appbymostaddedreviews = appbymostaddedreviews;
    },

    SET_MOSTINSTALLAPPRANKS: (state, mostinstallrankingaps) => {
      state.mostinstallrankingaps = mostinstallrankingaps;
    },

    SET_BIGGANNERSAPPLIST: (state, getbiggainersapplist) => {
      state.getbiggainersapplist = getbiggainersapplist;
    },
    

    SET_USERFOLLOWAPPSLIST: (state, getuserfollowapplist) => {
      state.getuserfollowapplist = getuserfollowapplist;
    },


    SET_USERFOLLOWAPSTOTAL: (state, userfollowapptotal) => {
      state.userfollowapptotal = userfollowapptotal;
    },

    SET_TOTALAPSCOUNTBYCAT: (state, totalapscountbycat) => {
      state.totalapscountbycat = totalapscountbycat;
    },

    SET_CATEGORYWISEAPSTOTAL: (state, apscountcategorywise) => {
      state.apscountcategorywise = apscountcategorywise;
    },

    SET_CATEGORYWWITHCHILDCATEGORY: (state, categorywithchildcategory) => {
      state.categorywithchildcategory = categorywithchildcategory;
    },

    SET_POPULARAPS: (state, popoularaps) => {
      state.popoularaps = popoularaps;
    },
    SET_RESENTAPS: (state, resentapps) => {
      state.resentapps = resentapps;
    },
    SET_TOTALAPPSTOREAPP: (state, totalappstoreapps) => {
      state.totalappstoreapps = totalappstoreapps;
    },
    SET_TOTALMOSTINSTALLAPPS: (state, totalmostinstallapps) => {
      state.totalmostinstallapps = totalmostinstallapps;
    },

    SET_TOTALMOSRIVIEWAPPS: (state, totalmostrivewapps) => {
      state.totalmostrivewapps = totalmostrivewapps;
    },
    SET_TOTALBIGANIERPPS: (state, totalbigginerapps) => {
      state.totalbigginerapps = totalbigginerapps;
    },

    SET_TOTALRIVEWADDEDAPPS: (state, totalrivewaddedapps) => {
      state.totalrivewaddedapps = totalrivewaddedapps;
    },

    SET_APPHISTORY: (state, apphistory) => {
      state.apphistory = apphistory;
    },

    SET_APPCATEGORYHISTORY: (state, appcategoryhistory) => {
      state.appcategoryhistory = appcategoryhistory;
    },

    SET_DEVLOPERHISTORY: (state, devloperhistory) => {
      state.devloperhistory = devloperhistory;
    },

    SET_RELATEDAPSLIST: (state, relatedaps) => {
      state.relatedaps = relatedaps;
    },

    SET_RELATEDAPSPRICETIRE: (state, relatedapspricetire) => {
      state.relatedapspricetire = relatedapspricetire;
    },

    SET_4WEEKREVIEWTOTALSINGLEAPP: (state, last4weekreview) => {
      state.last4weekreview = last4weekreview;
    },

    SET_4WEEKRATTINGINGLEAPP: (state, last4weekratting) => {
      state.last4weekratting = last4weekratting;
    },

    SET_4WEEKAPPMOSTANDOVERALLDATA: (state, apprankmostoverall) => {
      state.apprankmostoverall = apprankmostoverall;
    },

    SET_PICHARTDATA: (state, picchartdata) => {
      state.picchartdata = picchartdata;
    },

    SET_PRICEFETUREDATA: (state, apppricingfeture) => {
      state.apppricingfeture = apppricingfeture;
    },

    SET_LINEHARTDATA1: (state, linechartdata1) => {
      state.linechartdata1 = linechartdata1;
    },
    SET_CHARTRATTINGDATA: (state, rattingdata) => {
      state.rattingdata = rattingdata;
    },

    SET_ALLKEYWORDS: (state, allkeywords) => {
      state.allkeywords = allkeywords;
    },

   /* SET_ALLKEYWORDS: (state, allkeywords) => {
      state.allkeywords = allkeywords;
    },*/
    
    SET_ALLKEYWORDSBYUSERID: (state, alluserfollowkeywords) => {
      state.alluserfollowkeywords = alluserfollowkeywords;
    },

    SET_SEARCHAPPSRESULT: (state, searchAppresult) => {
      state.searchAppresult = searchAppresult;
    },

    SET_KEYWORDSEARCHRESULT: (state, keywordsearchresult) => {
      state.keywordsearchresult = keywordsearchresult;
    },

    SET_KEYWORDSEARCHTOTAL: (state, searchtotaldata) => {
      state.searchtotaldata = searchtotaldata;
    },

    SET_SEARCHRESULTTYPE: (state, searchresulttype) => {
      state.searchresulttype = searchresulttype;
    },

    
    SET_DEVELOPERLIST: (state, developerlist) => {
      state.developerlist = developerlist;
    },

    SET_DEVELOPERTOTAL: (state, totaldeveloper) => {
      state.totaldeveloper = totaldeveloper;
    },

    SET_DEVELOPERSAPPAS: (state, developerapplist) => {
      state.developerapplist = developerapplist;
    },

    SET_DEVELOPERSTOTALAPPAS: (state, totaldeveloperapp) => {
      state.totaldeveloperapp = totaldeveloperapp;
    },

    SET_DEVELOPERDETAILS: (state, developerdetails) => {
      state.developerdetails = developerdetails;
    },

    SET_APPLISTBYKEYWORDS: (state, applistbykeywords) => {
      state.applistbykeywords = applistbykeywords;
    },

    SET_KEYWORDMAPDATA: (state, keywordmapdata) => {
      state.keywordmapdata = keywordmapdata;
    },

    SET_COMPARISONAPPSLIST: (state, comparisonappsearch) => {
      state.comparisonappsearch = comparisonappsearch;
    },

    SET_COMPARISONAPPSDATA: (state, comparisonappsdata) => {
      state.comparisonappsdata = comparisonappsdata;
    },

    SET_COMPARISONAPPSDATAPLANE: (state, comparisonappsdataplane) => {
      state.comparisonappsdataplane = comparisonappsdataplane;
    },

    SET_COMPARISONCHARTDATAOPTION: (state, comparisonchratdataoption) => {
      state.comparisonchratdataoption = comparisonchratdataoption;
    },

    SET_COMPARISONCHARTDATALABEL: (state, comparisonchratdatalabels) => {
      state.comparisonchratdatalabels = comparisonchratdatalabels;
    },

    SET_COMPARISONCHARTCATOPTIONS: (state, comparisonchratdatacategoryoption) => {
      state.comparisonchratdatacategoryoption = comparisonchratdatacategoryoption;
    },

    SET_CATEGORYSBYAPPIDS: (state, categorylistrelatedwithappids) => {
      state.categorylistrelatedwithappids = categorylistrelatedwithappids;
    },

    SET_APPSWITHOUTPAGINATION: (state, allapslistwithoutpagination) => {
      state.allapslistwithoutpagination = allapslistwithoutpagination;
    },

    SET_DEVELOPERSWITHOUTPAGINATION: (state, alldeveloperwithoutpagination) => {
      state.alldeveloperwithoutpagination = alldeveloperwithoutpagination;
    },

    SET_DEFULTMODECSSFILE: (state, defultmode) => {
      state.defultmode = defultmode;
    },  
        
    SET_APPCURRENTDATA: (state, appcurrentdata) => {
      state.appcurrentdata = appcurrentdata;
    },  
    
    SET_APPCHANGELOGDATA: (state, appchangelogdata) => {
      state.appchangelogdata = appchangelogdata;
    }, 

    SET_APPRANKRELATEDWITHDATE: (state, appranddatewise) => {
      state.appranddatewise = appranddatewise;
    }, 

    SET_KEYBENIFITDETAILS: (state, keybenifitdetails) => {
      state.keybenifitdetails =  keybenifitdetails;
    }, 
};

export default mutations;