import {createApp } from 'vue'
import App from './App.vue'
import '@/assets/css/style.css'
import router from './routes'
//import axios from 'axios'
import store from './store';
import Pagination from 'v-pagination-3';
import SimpleTypeahead from 'vue3-simple-typeahead';
import 'vue3-simple-typeahead/dist/vue3-simple-typeahead.css';
import vue3GoogleLogin from 'vue3-google-login'
import AOS from 'aos';
import 'aos/dist/aos.css'
app.AOS = new AOS.init({ disable: "phone" });
createApp(App).use(router).component('pagination',Pagination).use(store).use(SimpleTypeahead)
.use(vue3GoogleLogin, {
    clientId: '734894089688-f27elmq0q1r9hirdkvblg0mb4mtc2tv1.apps.googleusercontent.com' }).use(AOS).mount('#app')

  
