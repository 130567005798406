<template>
 <div class="ful-wdth">
        <div class="tab_boxe accManageProManageBox">

        <h2>My Account</h2>

        <ul class="nav nav-tabs accManageProManageTab" id="myTab" role="tablist">
          <li class="nav-item" role="presentation">
            <button :class="'nav-link '+defulttab" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">Profile Management</button>
          </li>
          <!-- <li class="nav-item" role="presentation">
            <button class="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false">Billing & Invoice</button>
          </li> -->
          <li class="nav-item" role="presentation">
            <button :class="'nav-link '+membershiptabclass" id="contact-tab" data-bs-toggle="tab" data-bs-target="#contact" type="button" role="tab" aria-controls="contact" aria-selected="false">Manage my Subscription</button>
          </li>
          <li class="nav-item" role="presentation">
            <button class="nav-link" id="pricing-tab" data-bs-toggle="tab" data-bs-target="#pricing" type="button" role="tab" aria-controls="pricing" aria-selected="false">Delete My Account</button>
          </li>
          <li class="nav-item" role="presentation">
            <button class="nav-link" id="notification-tab" data-bs-toggle="tab" data-bs-target="#notification" type="button" role="tab" aria-controls="notification" aria-selected="false">notification</button>
          </li>
        </ul>
        <div class="tab-content accManageProManageContent" id="myTabContent">

          <!-- tab 1 -->
          <ProfileMyAccount :defultcontentclass="defultcontentclass"/>

          <!-- tab 2 -->
          <BillingInvoice/>

          <!-- tab 3 -->
          <ManageMySub :membershipcontentclass="membershipcontentclass"/>

          <!-- tab 4 -->
          <DeleteMyAcc/>

          <!-- tab 5 -->
          <NotificaTion/>
    </div>
</div>
</div>
</template>

<script>
import {mapState,mapActions} from 'vuex'
import ProfileMyAccount from '../pages/AccounyManageProfileManage/ProfileMyAccount.vue'
import BillingInvoice from '../pages/AccounyManageProfileManage/BillingInvoice.vue'
import ManageMySub from '../pages/AccounyManageProfileManage/ManageMySub.vue'
import DeleteMyAcc from '../pages/AccounyManageProfileManage/DeleteMyAcc.vue'
import NotificaTion from '../pages/AccounyManageProfileManage/NotificaTion.vue'
import { useRoute } from 'vue-router';
export default {
        name: 'AccountManageProfileM',
            data() {
                return {
                    defulttab:'active',
                    defultcontentclass:'tab-pane fade show active',
                    membershiptabclass :'',
                    membershipcontentclass :'tab-pane fade',
                };
            },
        components: {
            ProfileMyAccount,
            BillingInvoice,
            ManageMySub,
            DeleteMyAcc,
            NotificaTion
        },

        methods:{
            ...mapActions({
               GetMemberShipLevel: 'authModule/GetMemberShipLevel',
            }),
            
        },  
      
         mounted() {  
           const route = useRoute();
           this.GetMemberShipLevel();
           if(route.query.tab=='upgrade')
            {
              this.defulttab='';
              this.defultcontentclass='tab-pane fade';
              this.membershiptabclass ='active';
              this.membershipcontentclass ='tab-pane fade show active';
            }
            
        },

         watch: {
        '$route.query'(newId, oldId) { 
           if(newId.tab=='upgrade')
            {
              this.defulttab='';
              this.defultcontentclass='tab-pane fade';
              this.membershiptabclass ='active';
              this.membershipcontentclass ='tab-pane fade show active';
            }else{
              this.defulttab='active';
              this.defultcontentclass='tab-pane fade show active';
              this.membershiptabclass ='';
              this.membershipcontentclass ='tab-pane fade';  
            }
        }
     }     
}
</script>

<style>
.accManageProManageBox h2  {
    font-weight: 300  !important;
    font-size: 35px  !important;
    line-height: 52px  !important;
    padding-bottom: 29px !important;
}
.accManageProManageBox .accManageProManageTab {
    width: 100%  !important;
    display: flex  !important;
    justify-content: space-between  !important;
    align-items: center  !important;
}
.accManageProManageBox .accManageProManageTab .nav-item {
    margin-right: 0px !important;
    display: flex  !important;
    flex-grow: 1  !important;
    justify-content: center  !important;
    align-items: center  !important;
}
.accManageProManageBox .accManageProManageTab .nav-item .nav-link {
    flex-grow: 1  !important;
}
.accManageProManageBox .accManageProManageTab .nav-item .nav-link {
    border-radius: 0px !important;
    padding: 10px 0px !important;
}
.main_right-pn .tab_boxe .nav-tabs .nav-item .nav-link {
    color: #FFFFFF;
}
.main_right-pn .tab_boxe .nav-tabs .nav-item .nav-link.active {
    color: #fff;
    background-color: #00C1EC !important;
    border-color: #00C1EC !important;
}
.main_right-pn .tab_boxe .nav-tabs .nav-item .nav-link {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-transform: capitalize;
    
    background: rgba(48, 48, 64, 0.73);
    border: 0.5px solid rgba(155, 155, 155, 0.49);
    border-radius: 3px;
    padding: 10px 46px;
    transition: all ease-in-out 300ms;
}

.main_right-pn .tab_boxe h2 {
  font-weight: 300;
  font-size: 40px;
  line-height: 60px;
  text-transform: capitalize;
  color: #FFFFFF;
  padding-bottom: 45px;
  margin: 0;
}

/**********************    account management profile management page end    **************************/

/* / .light-theme-np{} / */

.light-theme-np .main_left-pn {
    box-shadow: 0px 0px 20px #d7d7d7;
    background-color: #fff;
}
.light-theme-np .main_right-pn .tab_boxe .nav-tabs .nav-item .nav-link, .light-theme-np .main_right-pn .tab_boxe .changeLogsBox .changeLogsSub ul li .changeDiv {
  background-color: #f2f4f2e6;
  border: 1px solid #e7e7e757;
}
.light-theme-np .main_right-pn .tab_boxe .nav-tabs .nav-item .nav-link{ background: #e1e1e1;}
.light-theme-np .accManageProManageBox .accManageProManageContent .tabeOne .tabsOne .name h4,
.light-theme-np .accManageProManageBox .accManageProManageContent .tabsTwo .tittles,
.light-theme-np .accManageProManageBox .accManageProManageContent .tabsTwo .FillOption label,
.light-theme-np .accManageProManageBox .accManageProManageContent .tabeFour .deleteBox h4,
.light-theme-np .accManageProManageBox .accManageProManageContent .tabeFour .deleteBox p,
.light-theme-np .accManageProManageBox .accManageProManageContent .tabeFour .deleteBox .deleteInput label,
.light-theme-np .accManageProManageBox .accManageProManageContent .paymentBox .paymentBoxOne p,
.light-theme-np .accManageProManageBox .accManageProManageContent .paymentBox .paymentBoxTwo h4,
.light-theme-np .accManageProManageBox .accManageProManageContent .paymentBox .paymentBoxTwo p,
.light-theme-np .magageFreeTop .free h5,
.light-theme-np .magageFreeTop .demo h6,
.light-theme-np .magageFreeTop .free h5 span,
.light-theme-np .magageFreeTop .demo h6 span,
.light-theme-np .magageFreeBottom .freeWrap .Starter .box .StarterSub h4,
.light-theme-np .magageFreeBottom .freeWrap .Starter .box .StarterSub .StarterFlex .right p,
.light-theme-np .magageFreeBottom .freeWrap .Starter .box .StarterSub .StarterFlex .right h5,
.light-theme-np .magageFreeBottom .freeWrap .Starter .box h5 span,
.light-theme-np .accManageProManageBox .accManageProManageContent .paymentBox .paymentBoxFour .paySub .text .textLeft h6,
.light-theme-np .accManageProManageBox .accManageProManageContent .paymentBox .paymentBoxFour .paySub .text .textLeft p,
.light-theme-np .accManageProManageBox .accManageProManageContent .paymentBox .paymentBoxFour .paySub .text .textLeft a,
.light-theme-np .accManageProManageBox .accManageProManageContent .paymentBox .paymentBoxFour .paySub .text .textRight h5,
.light-theme-np .accManageProManageBox .accManageProManageContent .paymentBox .paymentBoxFive .payChargeBox .chargeSub .title h4,
.light-theme-np .accManageProManageBox .accManageProManageContent .paymentBox .paymentBoxFive .payChargeBox .chargeSub .title p,
.light-theme-np .accManageProManageBox .accManageProManageContent .paymentBox .paymentBoxFive .payChargeBox .chargeSub .title p span,
.light-theme-np .accManageProManageBox .accManageProManageContent .paymentBox .invoiceBox h5,
.light-theme-np .accManageProManageBox .accManageProManageContent .paymentBox .invoiceBox ul li .invoiceSub span h6,
.light-theme-np .accManageProManageBox .accManageProManageContent .paymentBox .invoiceBox ul li .invoiceSub span p,
.light-theme-np .magageFreeTop .demo p,
.light-theme-np .magageFreeTop .cancelBtn ul li,
.light-theme-np .magageFreeTop .cancelBtn ul li a,
.light-theme-np .paymentPopUp .heading p,
.light-theme-np .paymentPopUp .popupBox .popupBoxOne .poPupLeft label,
.light-theme-np .paymentPopUp .popupBox .popupBoxOne .poPupRight .one .divide label,
.light-theme-np .paymentPopUp .popupBox .popupBoxOne .poPupRight .one .dividers label,
.light-theme-np .paymentPopUp .popupBox .popupBoxOne .poPupLeft p,
.light-theme-np .paymentPopUp .popupBoxOneBtn ul .popBtnBorder a,
.light-theme-np .contactUs .contactUsText p,
.light-theme-np .contactUs .contactUsText .contactInput label,
.light-theme-np .disclaimerBox p
  {
    color: #212529 !important;
}
.light-theme-np .accManageProManageBox .accManageProManageContent .tabeOne {
    background: #e1e1e1;
    border: 1px solid #c9c9c9;
}
.light-theme-np .accManageProManageBox .accManageProManageContent .tabeOne .tabsOne,
.light-theme-np .accManageProManageBox .accManageProManageContent .tabsTwo {
    background: #fff;
}
.light-theme-np .accManageProManageBox .accManageProManageContent .tabsTwo .FillOption .foInput {
    background: #e1e1e1;
    border: 1px solid #c9c9c9;
    color: #212529;
}
.light-theme-np .accManageProManageBox .accManageProManageContent .tabeFour .deleteBox {
    background: #eaecee;
}
.light-theme-np .accManageProManageBox .accManageProManageContent .tabeFour .deleteBox .deleteInput input  {
    background: #e1e1e1;
    border: 1px solid #c9c9c9;
}
.light-theme-np .accManageProManageBox .accManageProManageContent .tabeFour .deleteBox .deleteBtn ul .deleteBorder {
    border: 1.5px solid #212529;
}
.light-theme-np .accManageProManageBox .accManageProManageContent .tabeFour .deleteBox .deleteBtn ul .deleteBorder a  {
    color: #212529;
}
.light-theme-np .magageFreeBottom .freeWrap .Starter  {
    background: #f4f4f4;
    border: 1px solid #c9c9c9;
    box-shadow: none;
}
.light-theme-np .accManageProManageBox .accManageProManageContent .paymentBox .paymentBoxThree button,
.light-theme-np .accManageProManageBox .accManageProManageContent .paymentBox .paymentBoxFour .paySub,
.light-theme-np .accManageProManageBox .accManageProManageContent .paymentBox .paymentBoxFive  {
    background: #f0eeee;
    color: #212529;
}
.light-theme-np .accManageProManageBox .accManageProManageContent .paymentBox .paymentBoxFive .payChargeBox .chargeSub  {
    background: #d6d6d6;
}
.light-theme-np .accManageProManageBox .accManageProManageContent .paymentBox .invoiceBox ul li .invoiceSub  {
    background: #d6d6d6;
    border: 1px solid #d6d6d6;
}
.light-theme-np .accManageProManageBox .accManageProManageContent .paymentBox .paymentBoxThree .modal .modal-content  {
    background: #e1e1e1 !important;
    color: #212529 !important;
}
.light-theme-np .accManageProManageBox .accManageProManageContent .paymentBox .paymentBoxThree .modal .modal-content .modal-body p  {
    color: #212529 !important;
}
.light-theme-np .accManageProManageBox .accManageProManageContent .paymentBox .paymentBoxThree .modal .modal-content .modal-body ul li  {
    color: #212529 !important;
}
.light-theme-np .accManageProManageBox .accManageProManageContent .paymentBox .paymentBoxThree .modal .modal-content .modal-body ul li a  {
    background: #d6d6d6;
    border: 1px solid #d6d6d6;
    color: #212529 !important;
}
.light-theme-np .accManageProManageBox .accManageProManageContent .paymentBox .paymentBoxThree .modal .modal-content .modal-body ul li .modalBtn {
    background: #00D1FF;
}
.light-theme-np .accManageProManageBox .accManageProManageContent .paymentBox .paymentBoxThree .modal .modal-content .modal-header .btn-close  {
    color: #212529 !important;
}
.light-theme-np .paymentPopUp .popupBox  {
    background: #e1e1e1;
    border: 1px solid #c9c9c9;
}
.light-theme-np .paymentPopUp .popupBox .popupBoxOne .poPupRight .one .divide .fullSelect,
.light-theme-np .paymentPopUp .popupBox .popupBoxOne .poPupRight .one .divide .popFull,
.light-theme-np .paymentPopUp .popupBox .popupBoxOne .poPupRight .one .divide .divederSelect .halfSelect,
.light-theme-np .paymentPopUp .popupBox .popupBoxOne .poPupRight .one .dividers .divederSelect .halfSelect,
.light-theme-np .paymentPopUp .popupBox .popupBoxOne .poPupRight .one .divide .popFulles,
.light-theme-np .paymentPopUp .popupBox .popupBoxOne .poPupRight .one .divide .popHalf,
.light-theme-np .paymentPopUp .popupBox .popupBoxOne .poPupRight .one .dividers .popHalf  {
    background: #d6d6d6;
    border: 1px solid #d6d6d6;
    color: #212529 !important;
}

.light-theme-np .contactUs .contactUsText {
    background: #d6d6d6;
}
.light-theme-np .contactUs .contactUsText .contactInput .conIn,
.light-theme-np .contactUs .contactUsText .contactInput .conText,
.light-theme-np .contactUs .contactUsText .verification .item .verifImg,
.light-theme-np .contactUs .contactUsText .verification .item,
.light-theme-np .contactUs .contactUsText .verification .fild [type="text"]  {
    background: #e1e1e1 !important;
    color: #212529 !important;
    border: 1px solid #c9c9c9;
}
.light-theme-np .contactUs .contactUsText .verification .item .btn img  {
    filter: sepia(1) saturate(1) brightness(0.1) hue-rotate(135deg);
}

.light-theme-np .main_right-pn .tab_boxe h2, .light-theme-np .main_right-pn .tab_boxe .nav-tabs .nav-item .nav-link, .light-theme-np .main_right-pn .tab_boxe h3{color: #212529;}
.light-theme-np  .accManageProManageBox .accManageProManageContent .tabeOne h3, .light-theme-np .main_right-pn .tab_boxe h3{color: #212529;}
.light-theme-np .accManageProManageBox .accManageProManageContent .paymentBox .paymentBoxFive h2, .light-theme-np .accManageProManageBox .accManageProManageContent .paymentBox .paymentBoxFive .payChargeBox h3{color: #212529;}

.light-theme-np  .accManageProManageBox .accManageProManageContent .paymentBox .paymentBoxOne h3{color: #212529;}
.light-theme-np .accManageProManageBox .accManageProManageContent .paymentBox .paymentBoxFour .paySub .text .textRight a{color: #212529;}

/*about details page responsive start*/

.col-lg-4.accwidth:last-child .fillOpBox {
  margin-right: 0 !important;
}


@media (min-width:1400px) and (max-width:1799px)  {
    .main_right-pn .tab_boxe {
        padding-top: 30px;
    }
    .main_right-pn .tab_boxe h2,
    .main_right-pn .tab_tittle_border {
        padding-bottom: 0px;
        margin: 0;
    }
    .main_right-pn .tab_boxe .tab-content {
        padding-top: 50px;
    }
    .main_right-pn .tab_boxe .tab-content .tab_One_Box .tab_One_Content .tab_One_Text {
        padding-left: 15px !important;
    }
    .main_right-pn .tab_boxe .tab-content .tab_One_Box .tab_One_Content .tab_One_Option {
        padding-left: 15px !important;
    }
    .main_right-pn .table-section table thead th, .tab_Two_Box .skygren-table thead th {
        font-size: 16px;
    }
    .main_right-pn .table-section table tbody td, .tab_Two_Box .skygren-table tbody td {
        font-size: 14px;
    }
    .tab_Two_Box {
        padding-top: 50px;
    }
    .tabs_Head h2 {
        padding-top: 50px;
    }
    .main_right-pn .tab_boxe h2 {
        font-size: 30px;
    }
    .tab_Box_footer {
        padding: 50px 0 30px 0;
    }
    .main_right-pn .keyWordSecBox .table-section  {
        margin-bottom: 0px !important;
    }
    /* .keyWordFirstLeft .keyWordsubmit {
        width: 180.77px;
        height: 44.48px;
        font-size: 17px;
        margin-top: 20px;
    } */
}

@media (min-width:1280px) and (max-width:1400px)  {
    /* .main_right-pn .tab_boxe {
        padding-top: 30px;
    }    */
    .main_right-pn .tab_boxe h2,
    .main_right-pn .tab_tittle_border {
        padding-bottom: 0px;
    }
    .main_right-pn .tab_boxe .tab-content {
        padding-top: 50px;
    }
    .main_right-pn .tab_boxe .tab-content .tab_One_Box .tab_One_Content .tab_One_Text {
        padding-left: 15px !important;
    }
    .main_right-pn .tab_boxe .tab-content .tab_One_Box .tab_One_Content .tab_One_Option {
        padding-left: 15px !important;
    }
    .main_right-pn .table-section table thead th, .tab_Two_Box .skygren-table thead th {
        font-size: 16px;
    }
    .main_right-pn .table-section table tbody td, .tab_Two_Box .skygren-table tbody td {
        font-size: 14px;
    }
    .tab_Two_Box {
        padding-top: 50px;
    }
    .tabs_Head h2    {
        padding-top: 50px;
    }
    .main_right-pn .tab_boxe h2 {
        font-size: 30px;
    }
    .main_right-pn .tab_boxe .nav-tabs .nav-item .nav-link {
        font-size: 14px;
        padding: 7px 30px;
    }
    .main_right-pn .tab_boxe .tab-content .tab_One_Box .tab_One_Content .tab_One_Text h2 {
        font-size: 20px;
    }
    .main_right-pn .tab_boxe .tab-content .tab_One_Box .tab_One_Content .tab_One_Text h4 {
        font-size: 14px;
        padding-bottom: 5px;
    }
    .main_right-pn .tab_boxe .tab-content .tab_One_Box .tab_One_Content .tab_One_Option span {
        font-size: 14px;
        padding-left: 10px;
    }
    .main_right-pn .tab_boxe .tab-content .tab_One_Box .tab_One_Content .tab_One_Option ul li:not(:last-child) {
        padding-bottom: 15px;
        margin-bottom: 15px;
    }
    .main_right-pn .table-section table tbody td, .tab_Two_Box .skygren-table tbody td {
        font-size: 13px;
        padding: 12px;
    }
    .main_right-pn .tab_boxe h2 {
        font-size: 26px;
    }
    .tab_Box_footer {
        padding: 50px 0 30px 0;
    }
    .tab_Box_footer h3 {
        font-size: 13px;
    }
    .appsBox .appsSub .appSubProfile .proImg {
        width: 90px;
    }
    .appsBox .appsSub .appSubProfile .proText {
        width: calc(100% - 90px);
    }
    .main_right-pn .tab_boxe h2 {
  font-size: 26px !important;
}
}

@media (min-width:992px) {
    .oilPiechart #myChart2{
        width: 555px !important;
        height: 555px !important;
    }
}

@media (max-width:1366px)  {
    .keyWordFirstRight tbody tr td .keyBoxDiv h2, .modal-body .table-section .resp-table tr td .keyBoxDiv h2 {
        font-size: 13px;
    }
    .keyWordFirstRight tbody tr td .keyBoxDiv a, .modal-body .table-section .resp-table tr td .keyBoxDiv a {
        font-size: 10px;
        color: #FFFFFF;
        padding: 3px 6px;
    }
    .appsBox .appsSub .appSubProfile .proText h3 {
        font-size: 18px;
    }
    .appsBox .appsSub .appSubProfile {
        padding: 46px 15px;
    }
    .appsBox .appsSub .appSubOption {
        padding: 0 30px 20px 30px;
    }
}

@media (max-width:992px) {
    .oilPiechart #myChart2{
        width: 100% !important;
    }
}

@media (max-width:991px)  {
    .side-collapse .dl-mode-btn > span {
        display: block;
    }
    .main_right-pn .tab_boxe {
        padding-top: 50px;
    }
    .main_right-pn .tab_boxe h2,
    .tab_boxe .adsKeyDetaildsRe .tittle h2  {
        padding-bottom: 10px;
        line-height: 30px;
    }
    .main_right-pn .tab_boxe .nav-tabs {
        padding-top: 15px;
    }
    .main_right-pn .tab_boxe .nav-tabs .nav-item .nav-link {
        font-size: 13px;
        padding: 4px 15px;
        margin: 3px 0;
    }
    .main_right-pn .tab_boxe .tab-content {
        padding-top: 50px;
    }
    .main_right-pn .tab_boxe .tab-content .tab_One_Box .tab_One_Content .tab_One_Text {
        padding-left: 15px !important;
    }
    .main_right-pn .tab_boxe .tab-content .tab_One_Box .tab_One_Content .tab_One_Option {
        padding: 30px  15px !important;
    }
    .main_right-pn .tab_boxe .tab-content .tab_One_Box .tab_One_Content .tab_One_Text h2 {
        font-size: 19px;
        padding-bottom: 5px;
    }
    .main_right-pn .tab_boxe .tab-content .tab_One_Box .tab_One_Content .tab_One_Text h3 {
        font-size: 13px;
        padding-bottom: 8px;
    }
    .main_right-pn .tab_boxe .tab-content .tab_One_Box .tab_One_Content .tab_One_Text h4 {
        font-size: 14px;
        line-height: 20px;
        padding-bottom: 12px;
    }
    .main_right-pn .table-section table tbody td, .tab_Two_Box .skygren-table tbody td {
        font-size: 14px;
        padding: 12px;
    }
    .main_right-pn .table-section table thead th, .tab_Two_Box .skygren-table thead th {
        font-size: 16px;
    }
    .tablePaddTop  {
        padding-top: 20px;
    }
    .tab_Two_Box {
        padding-top: 50px;
    }
    .main_right-pn .tab_boxe h2,
    .tab_boxe .adsKeyDetaildsRe .tittle h2 {
        font-size: 24px;
    }
    .tabs_Head h2 {
        padding-top: 40px;
    }
    .tab_Box_footer {
        padding: 35px 0 30px 0;
    }
    .tab_Box_footer h3 {
        font-size: 13px;
    }
    .sky_Green_Body {
        margin-top: 20px;
    }
    .sky_Green_Body h3 {
        padding: 50px 5px;
    }
    .changeRound {
        display: none;
    }
    .main_right-pn .tab_boxe .changeLogsBox .changeLogsSub ul li   {
        width: 100%;
        overflow-y: hidden;
        overflow-x: scroll;
        white-space: nowrap;
    }
    .main_right-pn .tab_boxe .changeLogsBox .changeLogsSub ul li .changeDiv{
        width: 250%;
    }
    .main_right-pn .tab_boxe .changeLogsBox .changeLogsSub ul li .changeDiv::after{
        display: none;
    }
    .main_right-pn .tab_boxe .changeLogsBox .changeLogsSub ul li .changeDiv span {
        font-size: 14px;
    }
    .main_right-pn .tab_boxe .changeLogsBox .changeLogsSub ul li .changeDiv .changeDivBtn a {
        font-size: 13px;
        padding: 2px 10px;
    }
    .main_right-pn .tab_boxe .changeLogsBox {
        padding-top: 30px;
    }
    .main_right-pn .tab_boxe .changeLogsBox .changeLogsSub ul li:not(:last-child) {
        margin-bottom: 25px;
    }
    .main_right-pn .tab_boxe .changeLogsBox .changeLogsStatus .changeLogsText h3 {
        font-size: 21px;
    }
    .main_right-pn .tab_boxe .changeLogsBox .changeLogsStatus .changeLogsText span {
        font-size: 12px;
    }
    .main_right-pn .tab_boxe .changeLogsBox .changeLogsStatus .changeLogsText span::after {
        top: 5px;
    }
    .main_right-pn .tab_boxe .changeLogsBox .changeLogsSub ul li .changeDiv span  {
        margin-right: 15px;
        padding-right: 15px
    }
    .logsFillterBtn {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 40px 0 30px 0;
    }
    .main_right-pn .tab_boxe .changeLogsBox .changeLogsStatus .changeLogsText span {
        padding-right: 6px;
        margin-right: 8px;
    }
    .logsMainBox .logsMainLeft, .logsMainBox .logsMainRight {
        padding: 15px 0;
    }
    .logsMainBox .logsMainLeft .logsMainLeftSub, .logsMainBox .logsMainRight .logsMainLeftSub {
        padding: 20px 20px 24px 20px;
    }
    .keyWordFirstLeft .kwflOInput  {
        flex-direction: column;
    }
    .keyWordFirstLeft .kwflOInput .keyWordsubmit {
        width: 180.77px;
        height: 44.48px;
        font-size: 17px;
        margin-top: 20px;
        margin-left: 0px;
    }
    .keyWordFirstLeft p {
        font-size: 19px;
        line-height: 31px;
        padding-top: 20px;
        padding-bottom: 30px;
    }
    .keyWordFirstRight .heading h3 {
        font-size: 21px;
    }
    .keyWordSecBox .heading {
        padding-top: 30px;
        padding-bottom: 20px;
    }
    .keyWordSecBox .heading h3 {
        font-size: 21px;
    }
    .keyWordSecBox .heading a {
        font-size: 15px;
        padding: 4px 24px;
    }
    .tab_boxe .sidePagePara {
        font-size: 18px;
    }
    .tab_boxe .appsBox {
        padding-top: 15px;
    }
    .tab_boxe .appsBox .comGap  {
        padding: 15px;
    }
    .tab_boxe .comparingThree {
        padding-top: 5px;
    }
    .tab_boxe .comparingThree .compds  {
        padding: 15px;
    }
    .tab_boxe .comparingThree .comThreeBox {
        padding: 50px 40px;
    }
    .tab_boxe .comparingThree .comThreeBox h3 {
        font-size: 20px;
        padding-top: 35px;
    }
    .adsKeyWordsp p {
        font-size: 18px;
        padding-top: 20px;
        margin-bottom: 0;
    }
    .adsKeyTableTopP  {
        padding-top: 0px !important;
    }
    .main_right-pn .tab_Two_Box .borderOff tbody .borderBtn a {
        font-size: 12px;
        padding: 5px 16px;
    }
    .tab_boxe .adsKeyDetaildsRe {
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    .tab_boxe .adsKeyDetaildsRe .tittle  {
        text-align: center;
    }
    .tab_boxe .adsKeyDetaildsRe .tittle p {
        font-size: 18px;
        line-height: 27px;
        margin-bottom: 20px;
    }
    .tab_boxe .adsKeyDetaildsRe .btn a {
        font-size: 15px;
        padding: 9px 22px;
    }
    .adsReportTableCenter table thead th {
        padding: 20px !important;
    }
    .keyZeroStateBtns .btnn {
        padding: 12px 9px;
    }
    .keyZeroStateBtns .btnn a {
        font-size: 18px;
        padding: 6.5px 20.3px;
    }
    .keyZeroStateBtns .keyZeroStatePara {
        padding: 50px 60px 40px 60px;
    }
    .keyZeroStateBtns .keyZeroStatePara p {
        font-size: 20px;
        line-height: 30px;
    }
    .adsKeyFeedBack a {
        font-size: 15px;
        padding: 12px 23px;
    }
    .adsReportTableTop table tbody tr td a  {
        font-size: 16px !important;
    }
    .adsByAppReportProfile  {
        flex-direction: column;
    }
    .adsByAppReportProfileBtn {
        padding-top: 20px;
    }
    .adsByAppReportProfile .adsByAppReportProfileBtn a {
        font-size: 14px;
        padding: 9px 18px;
    }
    .adsByAppReportHead h2 {
        padding: 50px 0 20px 0 !important;
    }
    .adsReportHours h3 {
        font-size: 12px;
    }
    .adsKeyWordsp p {
        font-size: 17px;
        padding-bottom: 15px;
    }
    .adsKeyReQueue h3 {
        font-size: 24px;
        padding: 0px 0 10px 0;
    }
    .adsKeyReQueue .table-section table tbody tr td a  {
        font-size: 14px;
    } 
    .adsReportZeroBox {
        padding: 26px;
        margin: 60px 0;
    }
    .adsReportZeroBox p  {
        font-size: 18px;
        line-height: 30px;
    }
    .main_right-pn .tab_Two_Box .borderOffBtn tbody .borderBtn a {
        font-size: 13px;
        padding: 6px 18px;
    }
    .adKeySummaryHead .fill select  {
        height: 40px;
        font-size: 14px;
    }
    .adKeySummaryHead  {
        flex-direction: column;
        justify-content: center;
    }
    .adKeySummaryHead .tittle h3  {
        font-size: 24px;
        padding-bottom: 10px;
    }
    .adKeySummaryHead  {
        padding: 36px 0 20px 0;
    }
    .paywallChangeLogsBox .paywallChangeLogsRight  {
        padding-top: 30px;
    }
    .paywallChangeLogsBox .paywallChangeLogsLeft .logsLeftSub  {
        padding-bottom: 20px;
    }
    /* .shopifyStoreTable .coomingText {
        top: 290px;
        left: -50px;
    } */
    .shopifyStoreTable .coomingText h2  {
        font-size: 123px !important;
        white-space: nowrap;
    }
    .shopifyStoreTable table tbody tr td {
        padding: 15px 15px !important;
    }
    /* .coomingText {
        top: 1400px;
        left: -30px;
    } */
    .coomingText h2  {
        font-size: 120px !important;
    }
    .padingTwoSide  {
        padding: 0 0px;
    }
    .shopifyStoreExpanded .expandThreeDiv {
        padding: 20px 10px 0 10px;
    }
    .shopifyStoreExpanded .expendFourDiv {
        padding: 20px 11px 0 11px;
    }
    .accManageProManageBox .accManageProManageContent .tabeOne {
        padding: 0px 15px 50px;
    }
    .accManageProManageBox .accManageProManageContent .tabeOne h3,
    .accManageProManageBox .accManageProManageContent .tabeFour h3 {
        font-weight: 500;
        font-size: 24px;
        line-height: 80px;
    }
    .accManageProManageBox .accManageProManageContent .tabeOne .tabsOne {
        padding: 15px 20px;
    }
    .accManageProManageBox .accManageProManageContent .tabeOne .tabsOne .name h4 {
        font-size: 18px !important;
    }
    .accManageProManageBox .accManageProManageContent .tabeOne .AcctabsPad {
        margin: 20px 0;
    }
    .accManageProManageBox .accManageProManageContent .tabsTwo {
        padding: 15px 20px;
    }
    .accManageProManageBox .accManageProManageContent .tabsTwo .FillOption {
        flex-wrap: wrap;
        flex-direction: column;
    }
    .accManageProManageBox .accManageProManageContent .tabsTwo .FillOption .fillOpBox {
        margin-bottom: 15px;
        margin-right: 0px;
        width: 100%;
    }
    .accwidth  {
        width: 100%;
    }
    .accManageProManageBox .accManageProManageContent .tabsTwo .fillOpBtn a {
        font-size: 14px;
        width: 160.98px;
        height: 39.48px;
    }
    .accManageProManageBox .accManageProManageContent .tabeFour .deleteBox {
        padding: 30px 15px;
    }
    .accManageProManageBox .accManageProManageContent .tabeFour .deleteBox h4 {
        font-size: 19px;
    }
    .accManageProManageBox .accManageProManageContent .tabeFour .deleteBox p {
        font-size: 14px;
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .accManageProManageBox .accManageProManageContent .tabeFour .deleteBox .deleteBtn ul li {
        margin-right: 15px;
        margin-bottom: 10px;
    }
    .accManageProManageBox .accManageProManageContent .tabeFour .deleteBox .deleteBtn ul li a {
        padding: 10px 38px;
        font-size: 14px;
        line-height: 21px;
    }
    .accManageProManageBox .accManageProManageContent .paymentBox .paymentBoxOne h3 {
        font-size: 24px;
    }
    .accManageProManageBox .accManageProManageContent .paymentBox .paymentBoxOne p {
        font-size: 16px;
    }
    .accManageProManageBox .accManageProManageContent .paymentBox .paymentBoxTwo {
        padding-top: 20px;
    }
    .accManageProManageBox .accManageProManageContent .paymentBox .paymentBoxTwo h4 {
        font-size: 24px;
    }
    .accManageProManageBox .accManageProManageContent .paymentBox .paymentBoxTwo p {
        font-size: 14px;
    }
    .accManageProManageBox .accManageProManageContent .paymentBox .paymentBoxThree {
        padding: 15px 0 36px 0;
    }
    .accManageProManageBox .accManageProManageContent .paymentBox .paymentBoxFive {
        padding: 30px 20px;
        margin-top: 20px;
    }
    .accManageProManageBox .accManageProManageContent .paymentBox .paymentBoxFive h2 {
        font-weight: 400;
        font-size: 26px;
    }
    .accManageProManageBox .accManageProManageContent .paymentBox .paymentBoxFive .payChargeBox h3 {
        font-weight: 400;
        font-size: 18px;
        padding: 26px 0 16px 0;
    }
    .accManageProManageBox .accManageProManageContent .paymentBox .paymentBoxFive .payChargeBox .chargeSub {
        padding: 15px 15px;
    }
    .accManageProManageBox .accManageProManageContent .paymentBox .paymentBoxFive .payChargeBox .chargeSub .title h4 {
        font-weight: 500;
        font-size: 19px;
    }
    .accManageProManageBox .accManageProManageContent .paymentBox .paymentBoxFive .payChargeBox .chargeSub .title p {
        font-weight: 300;
        font-size: 15px;
        line-height: 21px;
    }
    .accManageProManageBox .accManageProManageContent .paymentBox .invoiceBox ul li .invoiceSub {
        overflow-x: scroll;
        overflow-y: hidden;
        white-space: nowrap;
    }
    .accManageProManageBox .accManageProManageContent .paymentBox .invoiceBox ul li .invoiceSub span  {
        margin-right: 15px;
    }
    .accManageProManageBox .accManageProManageContent .paymentBox .paymentBoxThree .modal .modal-dialog {
        margin: 7.75rem 10px;
    }    
    .accManageProManageBox .accManageProManageContent .paymentBox .paymentBoxThree .modal .modal-content  {
        padding: 40px 30px !important;
    }
    .accManageProManageBox .accManageProManageContent .paymentBox .paymentBoxThree button {
        font-weight: 300;
        font-size: 17px;
        padding: 13px 24px;
    }
    .accManageProManageBox .accManageProManageContent .paymentBox .paymentBoxThree button span  {
        padding-right: 15px;
    }
    .accManageProManageBox .accManageProManageContent .paymentBox .paymentBoxThree .modal .modal-content .modal-body ul li {
        margin-top: 13px;
    }
    .magageFreeBottom .headline h3 {
        font-size: 16px;
        padding: 10px 40px;
    }
    .magageFreeBottom .freeWrap .Starter {
        margin-bottom: 20px;
    }
    .freeWrapMargin  {
        margin-bottom: 20px;
    }
    .magageFreeTop .tittle h2 {
        font-size: 26px;
    }
    .magageFreeTop .free h5, .magageFree .magageFreeTop .demo h6 {
        font-size: 18px;
        padding-bottom: 5px;
    }
    .magageFreeBottom .freeWrap .Starter .box {
        padding: 30px 40px 150px 40px;
    }
    .magageFreeTop .free {
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
    }
    .paymentPopUp .heading h3 {
        font-size: 26px;
    }
    .paymentPopUp .heading p {
        font-size: 15px;
        padding-bottom: 20px;
    }
    .paymentPopUp .popupBox {
        padding: 40px 0px;
    }
    .popDirection  {
        flex-direction: column;
    }
    .poPupLeft,
    .poPupRight  {
        max-width: 100% !important;
    }
    .poPupRight  {
        padding-top: 20px;
    }
    .paymentPopUp .popupBox .popupBoxOne .poPupRight .one {
        width: 100%;
    }
    .paymentPopUp .popupBox .popupBoxOne {
        padding-bottom: 20px;
    }
    .paymentPopUp .popupBox .popupBoxOne .poPupLeft label {
        font-size: 22px;
    }
    .paymentPopUp .popupBox .popupBoxOne .poPupRight .one .divide label, .paymentPopUp .popupBox .popupBoxOne .poPupRight .one .dividers label {
        font-size: 15px;
    }
    .paymentPopUp .popupBoxOneBtn ul li a {
        padding: 8.5px 32px;
    }
    .paymentPopUp .popupBoxOneBtn ul {
        padding: 0 20px;
    }
    .contactUs .contactUsText {
        padding: 35px 15px 40px 15px;
    }
    .contactUs .contactUsText h3 {
        font-size: 28px;
        line-height: 32px;
    }
    .contactUs .contactUsText p {
        font-size: 17px;
        padding-bottom: 20px;
    }
    .disclaimerBox {
        padding-top: 10px;
    }
    .disclaimerBox p {
        font-size: 14px;
        line-height: 26px;
    }
    .disclaimerBox p:not(:last-child) {
        padding-bottom: 22px;
    }
}
@media (max-width:767px)   {
.accManageProManageBox .accManageProManageContent .tabeOne{ padding: 10px 10px !important;}
.accManageProManageBox .accManageProManageContent .tabsTwo, .accManageProManageBox .accManageProManageContent .paymentBox .paymentBoxFive{ padding: 17px !important;}
.accManageProManageBox .accManageProManageContent .tabeOne h3{ font-size: 25px !important;}
.accManageProManageBox .accManageProManageContent .paymentBox .paymentBoxFour .paySub .text .textRigh{ display: block !important;}
.accManageProManageBox .accManageProManageContent .paymentBox .invoiceBox ul li .invoiceSub{ display: block;}
.accManageProManageBox .accManageProManageContent .paymentBox .paymentBoxOne h3{ font-size: 25px !important;}
.accManageProManageBox .accManageProManageContent .paymentBox .paymentBoxThree button{ font-size: 15px !important;}
.paymentPopUp .popupBox{ padding: 20px 10px;}
.paymentPopUp .popupBox .popupBoxOne .poPupRight .one, .paymentPopUp .popupBox .popupBoxOne .poPupRight .one .divide .popHalf{ width: 100% !important;}
.magageFreeBottom .headline h3{ padding: 0 !important;}
.magageFreeBottom .headline{ display: block !important;}
.accManageProManageBox .accManageProManageContent .paymentBox .paymentBoxFour .paySub .text .textRight{ display: block !important;}
.accManageProManageBox .accManageProManageContent .paymentBox .invoiceBox ul li .invoiceSub{ display: block !important;}
.paymentPopUp .popupBox{ padding: 10px !important;}
.paymentPopUp .popupBoxOneBtn ul li a{padding: 11.5px 40px !important;}
.magageFreeBottom .headline h3{ font-size: 17px !important; text-align:center;}
.magageFreeBottom .freeWrap .Starter .box{
  text-align: center;
  padding: 17px 16px 76px 17px !important;
}
.magageFreeBottom .freeWrap .Starter .startBtn{ bottom:17px !important;}
.magageFreeBottom .freeWrap .Starter .box .fGreen {
  color: #3CAEA3;
  font-size: 38px;
  padding: 20px 0 !important;
}
.magageFreeBottom .freeWrap .Starter .box .fviolet{ padding: 20px 0 !important;}
.magageFreeBottom .freeWrap .Starter .box h5{padding: 20px 0 !important;}
.magageFreeTop .demo{ display: block !important;}
.magageFreeTop .demo p{ width: 100% !important;}
.accManageProManageBox .accManageProManageContent .tabeFour .deleteBox{padding: 30px 11px !important;}
.accManageProManageBox .accManageProManageContent .tabeFour .deleteBox .deleteBtn ul li a{
  padding: 13px 13px !important;}
/* .accManageProManageBox .accManageProManageContent .paymentBox .paymentBoxFour .paySub .text .textRight a{ position: inherit !important;}
.paymentPopUp .popupBox .popupBoxOne .poPupRight .divFlex{ display: block !important;} */
.paymentPopUp .popupBox .popupBoxOne .poPupRight .one .dividers{
  width: 100% !important;
}
.accManageProManageBox .accManageProManageContent .tabeFour .deleteBox p{ font-size:16px; line-height:22px;}
.nav-tabs {
    border-bottom: none !important;
}
.textRight .form-check{ padding-left: 0 !important;}
.accManageProManageBox .accManageProManageContent .paymentBox .invoiceBox ul li .invoiceSub span{ margin-right: 0 !important;}
.poPupRight .row{ padding-left: 0 !important; padding-right: 0 !important; --bs-gutter-x:0 !important;}
.accManageProManageBox .accManageProManageContent .paymentBox .paymentBoxThree .modal .modal-content{ padding: 0 !important;}
.magageFreeTop .free{ text-align: left !important;  align-items: flex-start !important;}
.magageFreeBottom .freeWrap .Starter .box .StarterSub .StarterFlex .right p{ padding-bottom:20px !important; font-size: 18px !important;line-height: 27px !important;}
.magageFreeTop {padding: 0 10px;}
.magageFreeBottom .freeWrap .Starter .box .StarterSub .StarterFlex .right h4{padding-bottom: 9px;}
.accManageProManageBox .accManageProManageContent .tabeFour .deleteBox p{ font-size: 17px !important;}
.accManageProManageBox .accManageProManageContent .tabeFour h3{ font-size: 22px !important;}
.accManageProManageBox .accManageProManageContent .tabeFour .deleteBox h4{ font-size: 18px !important;}
.paymentPopUp .heading h3{ font-size:25px !important;}

}

@media (max-width:667px)   {
    .row {
        margin: 0 !important;
    }
    .main_right-pn .tab_boxe {
        padding-top: 30px;
    }
    .main_right-pn .tab_boxe .nav-tabs {
        padding-top: 5px;
    }
    .main_right-pn .tab_boxe .nav-tabs .nav-item .nav-link {
        font-size: 12px;
        padding: 4px 8px;
    }
    .main_right-pn .tab_boxe .tab-content {
        padding-top: 30px;
    }
    .main_right-pn .tab_boxe .tab-content .tab_One_Box .tab_One_Content .tab_One_Image {
        padding-bottom: 15px;
    }
    .main_right-pn .table-section table thead th, .tab_Two_Box .skygren-table thead th {
        font-size: 14px;
    }
    .main_right-pn .table-section table tbody td, .tab_Two_Box .skygren-table tbody td {
        font-size: 12px;
        padding: 10px;
    }
    .tab_Two_Box {
        padding-top: 30px;
    }
    .sky_Green_Body h3 {
        font-size: 18px;
    }
    .sky_Green_Body {
        height: auto;
    }
    .main_right-pn .tab_boxe h2 {
        font-size: 20px;
    }
    .tabs_Head h2 {
        padding-top: 30px;
    }
    .keyWordFirstRight .heading {
        flex-direction: column;
    }
    .keyWordSecBox .heading {
        flex-direction: column;
    }
    .comparfningHeadUl,
    .tab_Three_Box .comparingLink {
        flex-direction: column;
        padding-top: 20px;
        padding-bottom: 30px;
    }
    .comparingOption {
        padding-top: 10px;
    }
    .tab_Three_Box .comparingLink .comparingSelect {
        max-width: 360px;
    }
    .appsBox .appsSub .appSubProfile {
        padding: 30px 20px;
        flex-direction: column;
        justify-content: center;
        text-align: center;
    }
    .appsBox .appsSub .appSubOption {
        padding: 0 50px 15px 50px;
    }
    .appsBox .appsSub .appSubProfile .proImg {
        width: 100%;
        padding-bottom: 10px;
    }
    .appsBox .appsSub .appSubProfile .proText {
        width: 100%;
    }
    .appsBox .appsSub .appSubProfile .proText h3 {
        font-size: 18px;
        line-height: 28px;
    }
    .appsBox .appsSub .appSubProfile .proText h4 {
        font-size: 14px;
    }
    .appsBox .appsSub .appSubProfile .proText p {
        font-size: 14px;
    }
    .appsBox .appsSub .appSubOption p {
        font-size: 14px;
        text-align: center;
        line-height: 40px;
    }
    .compareupper  {
        text-align: center;
    }
    .compareupper h2 {
        padding-bottom: 0 !important;
    }
    .appsBox .appsSub .appSubHead h2 {
        font-size: 22px;
        line-height: 12px;
    }
    .tab_Three_Box .comparingLink .comparingOption ul li a, .comparfningHeadUl ul li a {
        font-size: 12px;;
    }
    .tab_boxe .comparingThree .comThreeBox {
        padding: 40px 30px;
    }
    .tab_boxe .comparingThree .comThreeBox h3 {
        font-size: 18px;
        padding-top: 25px;
    }
    .tab_boxe .sidePagePara {
        font-size: 16px;
        line-height: 26px;
    }
    .tab_boxe .comparingThree .comThreeBox a .threeDiv {
        width: 150.25px;
        height: 150.25px;
    }
    .tab_boxe .adsKeyDetaildsRe .tittle p {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 15px;
    }
    .tab_boxe .adsKeyDetaildsRe .btn a {
        font-size: 13px;
        padding: 8px 20px;
    }
    .keyZeroStateBtns .btnn {
        padding: 10px 8px;
    }
    .keyZeroStateBtns .btnn a {
        font-size: 16px;
        padding: 5.5px 18.3px;
    }
    .keyZeroStateBtns .keyZeroStatePara {
        padding: 30px 40px 20px 40px;
    }
    .keyZeroStateBtns .keyZeroStatePara p {
        font-size: 18px;
        line-height: 26px;
    }
    .adsReportTableTop table tbody tr td a  {
        font-size: 14px !important;
    }
    .adsByAppReportProfile .adsByAppReportProfileImage  {
        flex-direction: column;
    }
    .adsByAppReportProfile .adsByAppReportProfileImage .Textbox  {
        padding-top: 20px;
    }
    .adsKeyWordsp p {
        font-size: 17px;
        padding-bottom: 15px;
    }
    .adsByAppBox .accordion-items {
        padding: 10px 10px;
        margin-bottom: 15px;
        overflow-x: scroll;
        overflow-y: hidden;
    }
    .adsByAppBox .accordion-button {
        padding: 10px 5px;
    }
    .adsByAppBox .accItemBox .accItemChekDiv .accItemChekProfile .btn img {
        padding-right: 5px;
    }
    .adsByAppBox .accItemBox .accItemChekDiv .accItemChekProfile .btn span {
        padding-right: 10px;
        margin-right: 10px;
        font-size: 13px;
    }
    .adsByAppBox .accItemBox .accItemChekBtn a {
        font-size: 12px;
        padding: 4px 10px;
    }
    .adsByAppBox .accordion-button:not(.collapsed)::after {
        width: 6px;
    }
    .adsByAppBox .accordion-button::after {
        width: 6px;
        height: 10px;
    }
    .adsByAppBox .accordion-body .accBodyInputBox .accbBbtn {
        margin-top: 10px;
    }
    .adsKeyReQueue h3 {
        font-size: 20px;
        padding: 0px 0 0px 0;
    }
    .adsKeyReQueue .table-section table tbody tr td a  {
        font-size: 12px;
    }
    .adsReportZeroBox {
        padding: 20px;
        margin: 50px 0;
    }
    .adsReportZeroBox p  {
        font-size: 16px;
        line-height: 26px;
    } 
    .main_right-pn .tab_Two_Box .borderOffBtn tbody .borderBtn a {
        font-size: 11px;
        padding: 5px 15px;
    }
    .adKeySummaryHead  {
        padding: 20px 0 14px 0;
    }
    .coomingText h2  {
        font-size: 50px !important;
    }
    .accManageProManageBox .accManageProManageContent .paymentBox .paymentBoxFour .paySub {
        padding: 20px 0px 20px 0px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 15px;
    }
    .accManageProManageBox .accManageProManageContent .paymentBox .paymentBoxFour .paySub .text .textLeft h6 {
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        padding-top: 10px;
    }
    .accManageProManageBox .accManageProManageContent .paymentBox .paymentBoxFour .paySub .text .textLeft p {
        font-weight: 300;
        font-size: 13px;
        line-height: 20px;
    }
    .accManageProManageBox .accManageProManageContent .paymentBox .paymentBoxFour .paySub .text .textLeft a {
        font-size: 13px;
        line-height: 14px;
    }
    .accManageProManageBox .accManageProManageContent .paymentBox .paymentBoxFour .paySub .text .textRight h5 {
        font-weight: 300;
        font-size: 13px;
        line-height: 30px;
        margin-right: 30px;
    }
    .accManageProManageBox .accManageProManageContent .paymentBox .paymentBoxFour .paySub .text {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .accManageProManageBox .accManageProManageContent .paymentBox .paymentBoxFour .paySub .text .textRight {
        justify-content: center;
    }
    .main_right-pn .tab_boxe .nav-tabs .nav-item .nav-link{width: 100%;}
    .accManageProManageBox .accManageProManageTab .nav-item{ display: block !important; width: 100%;}
    .main_right-pn .tab_boxe .nav-tabs {display: block !important;}
} 

/*about details page responsive end*/
</style>