<template>
      <section class="appBetter">
        <div class="appBetterbg"></div>
        <div class="container">
            <div class="row appBetterCenter">
                <div class="col-lg-6">
                    <div class="appBetterLeft" data-aos="fade-up" data-aos-duration="1000">
                        <img src="@/assets/images/homePage-1/appbetter1.png" alt="">
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="appBetterRight" data-aos="fade-up" data-aos-duration="1000">
                        <h2>Let's help make your <span>app</span> better</h2>
                        <p>If your app is struggling, you're not alone.  In order to cut through the noise and stand out from the crowd, you need to be better at optimizing your app than your competitors.  Our tool can help you understand the marketplace and learn from the movers and shakers.</p>
                         <router-link v-if="!user.data" to="/sign-up">Free Sign Up <span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-arrow-right-circle-fill" viewBox="0 0 16 16">
                                <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z"/>
                              </svg>
                        </span></router-link>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import {mapState} from 'vuex'
export default {
  name: 'HomePlatfrom',
  data() {
    return {
      
    };
  },
  computed: {
      ...mapState({
          user: state => state.authModule.user,
      }),
  },
}
</script>

<style scoped>
/* app better section start here */

.appBetter  {
    position: relative;
    margin-top: 160px;
}
.appBetter .appBetterCenter  {
    align-items: center;
    z-index: 1;
}
.appBetter .appBetterCenter .appBetterLeft img {
    display: block;
}
.appBetterbg {
    position: absolute;
    top: 0px;
    left: 110px;
    content: "";
    background: url(@/assets/images/homePage-1/appbetter2.png);
    width: 787px;
    height: 767px;
    background-repeat: no-repeat;
    z-index: -1;
}
.appBetter .appBetterCenter .appBetterRight h2  {
    font-weight: 500;
    font-size: 63px;
    line-height: 76px;
    color: #222222;
}
.appBetter .appBetterCenter .appBetterRight h2 span{
    color: #FF792E;
}
.appBetter .appBetterCenter .appBetterRight p  {
    font-weight: 300;
    font-size: 19px;
    line-height: 35px;  
    color: #515151;
    padding: 26px 0 46px 0;
}
.appBetter .appBetterCenter .appBetterRight a  {
    text-decoration: none;
    display: inline-block;
    font-weight: 500;
    font-size: 19px;
    line-height: 28px;
    background: #FFCF1F;
    border-radius: 50px;
    letter-spacing: 0.02em;
    color: #1C202B;
    padding: 13.6px 34px;
    transition: all ease-in-out 300ms;
}
.appBetter .appBetterCenter .appBetterRight a:hover  {
    background: #FF792E;
    color: #FFFFFF;
}
.appBetter .appBetterCenter .appBetterRight a span  {
    padding-left: 15px;
}
.appBetter .appBetterCenter .appBetterRight a span svg  {
    transition: all ease-in-out 300ms;
}
img{ max-width: 100%;}

.gainMainBox .rightArrow::before{ z-index: -1;}
@media (max-width: 991px){
.appBetterbg{ width:100%; left: 0;}
}
@media (max-width: 991px){
.gainImageBox .gainMainBoxFlex .gainAfterYellow::after { right: 0;}
}

@media (max-width: 1400px){
.appBetter .appBetterCenter .appBetterRight h2{
  font-weight: 500;
  font-size: 45px;}

}

@media (max-width: 740px){

.appBetter .appBetterCenter .appBetterRight h2{
font-weight: 500;
font-size: 25px;
line-height: 30px;
padding-top: 30px;
}

.appBetter .appBetterCenter .appBetterRight p{
  font-weight: 300;
  font-size: 16px;
  line-height: 29px;
  color: #515151;
  padding: 26px 0 26px 0;
}




}
/* app better section end here */
</style>