<template>
<div class="inner-header">
 <HeaderHome/>
 </div>
 <div class="ful-wdth grey-bg pt-5">
<div class="container blog-container">
 
 <div v-if="isloaded"><h2>{{pagecontent.post_title}}</h2>
 <div  v-html="pagecontent.content"></div>  </div>
<div class="loader-sec" v-if="loaderloaded"><img src="@/assets/images/Sas-loader-02.gif"></div>
 </div>

 

 <div class="container blog-container" v-if="pagenotfound">
 <h2>404 Error</h2>
 <div><p>Page Not Found.</p></div>
 </div>
</div>
<div class="inner-home">
<FooterHome/>
 </div>
</template>

<script>
import HeaderHome from '@/components/Mainpage/HeaderHome.vue'
import FooterHome from '@/components/Mainpage/FooterHome.vue'
import { useRoute } from 'vue-router'
import {mapState,mapActions} from 'vuex'
export default {
    name: 'SinglePageDetails',
    data() {      
      return {
            params:{
              slug: '',  
            },
            isloaded:false,
            pagenotfound:false,
            isloder:true,
            loaderloaded:true,
         };
     }, 
    components: {
     HeaderHome,
     FooterHome,
    },
    methods:{
          ...mapActions({  
              Pagecontentbyslug: 'authModule/GetSinglepageContent',  
         }),
      },
   mounted(){
         const route=useRoute();
         this.params.slug=route.params.menuslug;
         this.Pagecontentbyslug(this.params);
      },

   computed:{
      ...mapState({
         pagecontent: (state) => state.authModule.pagecontent,
       }),  
   },
   watch: {
         pagecontent(newval,oldval) { 
             this.isloder=false;
             if(this.pagecontent.post_title){
                document.title = this.pagecontent.post_title;
                this.isloaded=true;
                 this.loaderloaded=false;
             }else{
                this.isloaded=false;
                document.title = 'Page not Found.';
                this.pagenotfound=true;
             }
             
                
         },
         '$route.params'(newId, oldId) {
            this.isloder=true; 
            this.isloaded=false;
            // this.loaderloaded=false,
            this.pagenotfound=false;
            this.params.slug=newId.menuslug;
            this.Pagecontentbyslug(this.params);
         }  
     } ,

    
   
}
</script>


