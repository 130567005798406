<template>
  <div class="ful-wdth blk-bg login-page"> 
    

    <div class="signup-box top-sign-pad" v-if="pageload">
       <div class="logo-login"><router-link to="/"><img src="@/assets/images/logo/Sas-rader-logo.png"></router-link></div>    
        <div v-if="verificationstatus=='1' && pageload">
         <h2>Thank you for confirmation</h2>
         <p>Your account is verified, You can login now</p>
          <p class="pt-3"> <router-link to="/log-in" class="sn-in">Log In</router-link></p>
   
        </div>

        <div v-if="verificationstatus=='2' && pageload">
         <h2>You are registered with us</h2>
         <p>Your account is verified, You can login now</p>
         <p class="pt-3"> <router-link to="/log-in" class="sn-in">Log In</router-link></p>
        </div>

        <div v-if="verificationstatus=='0' && pageload">
         <h2>Sorry invalid access token</h2>
        </div>

    </div> 

    <div class="loading" v-else>
           <div class="loader1">
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="margin:auto;display:block;" width="200px" height="200px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
<circle cx="75" cy="50" fill="#00d1ff" r="5">
  <animate attributeName="r" values="3;3;5;3;3" times="0;0.1;0.2;0.3;1" dur="1s" repeatCount="indefinite" begin="-0.9166666666666666s"></animate>
  <animate attributeName="fill" values="#00d1ff;#00d1ff;#00d1ff;#00d1ff;#00d1ff" repeatCount="indefinite" times="0;0.1;0.2;0.3;1" dur="1s" begin="-0.9166666666666666s"></animate>
</circle><circle cx="71.65063509461098" cy="62.5" fill="#00d1ff" r="5">
  <animate attributeName="r" values="3;3;5;3;3" times="0;0.1;0.2;0.3;1" dur="1s" repeatCount="indefinite" begin="-0.8333333333333334s"></animate>
  <animate attributeName="fill" values="#00d1ff;#00d1ff;#00d1ff;#00d1ff;#00d1ff" repeatCount="indefinite" times="0;0.1;0.2;0.3;1" dur="1s" begin="-0.8333333333333334s"></animate>
</circle><circle cx="62.5" cy="71.65063509461096" fill="#00d1ff" r="5">
  <animate attributeName="r" values="3;3;5;3;3" times="0;0.1;0.2;0.3;1" dur="1s" repeatCount="indefinite" begin="-0.75s"></animate>
  <animate attributeName="fill" values="#00d1ff;#00d1ff;#00d1ff;#00d1ff;#00d1ff" repeatCount="indefinite" times="0;0.1;0.2;0.3;1" dur="1s" begin="-0.75s"></animate>
</circle><circle cx="50" cy="75" fill="#00d1ff" r="5">
  <animate attributeName="r" values="3;3;5;3;3" times="0;0.1;0.2;0.3;1" dur="1s" repeatCount="indefinite" begin="-0.6666666666666666s"></animate>
  <animate attributeName="fill" values="#00d1ff;#00d1ff;#00d1ff;#00d1ff;#00d1ff" repeatCount="indefinite" times="0;0.1;0.2;0.3;1" dur="1s" begin="-0.6666666666666666s"></animate>
</circle><circle cx="37.50000000000001" cy="71.65063509461098" fill="#00d1ff" r="5">
  <animate attributeName="r" values="3;3;5;3;3" times="0;0.1;0.2;0.3;1" dur="1s" repeatCount="indefinite" begin="-0.5833333333333334s"></animate>
  <animate attributeName="fill" values="#00d1ff;#00d1ff;#00d1ff;#00d1ff;#00d1ff" repeatCount="indefinite" times="0;0.1;0.2;0.3;1" dur="1s" begin="-0.5833333333333334s"></animate>
</circle><circle cx="28.34936490538903" cy="62.5" fill="#00d1ff" r="5">
  <animate attributeName="r" values="3;3;5;3;3" times="0;0.1;0.2;0.3;1" dur="1s" repeatCount="indefinite" begin="-0.5s"></animate>
  <animate attributeName="fill" values="#00d1ff;#00d1ff;#00d1ff;#00d1ff;#00d1ff" repeatCount="indefinite" times="0;0.1;0.2;0.3;1" dur="1s" begin="-0.5s"></animate>
</circle><circle cx="25" cy="50" fill="#00d1ff" r="5">
  <animate attributeName="r" values="3;3;5;3;3" times="0;0.1;0.2;0.3;1" dur="1s" repeatCount="indefinite" begin="-0.4166666666666667s"></animate>
  <animate attributeName="fill" values="#00d1ff;#00d1ff;#00d1ff;#00d1ff;#00d1ff" repeatCount="indefinite" times="0;0.1;0.2;0.3;1" dur="1s" begin="-0.4166666666666667s"></animate>
</circle><circle cx="28.34936490538903" cy="37.50000000000001" fill="#00d1ff" r="5">
  <animate attributeName="r" values="3;3;5;3;3" times="0;0.1;0.2;0.3;1" dur="1s" repeatCount="indefinite" begin="-0.3333333333333333s"></animate>
  <animate attributeName="fill" values="#00d1ff;#00d1ff;#00d1ff;#00d1ff;#00d1ff" repeatCount="indefinite" times="0;0.1;0.2;0.3;1" dur="1s" begin="-0.3333333333333333s"></animate>
</circle><circle cx="37.499999999999986" cy="28.349364905389038" fill="#00d1ff" r="5">
  <animate attributeName="r" values="3;3;5;3;3" times="0;0.1;0.2;0.3;1" dur="1s" repeatCount="indefinite" begin="-0.25s"></animate>
  <animate attributeName="fill" values="#00d1ff;#00d1ff;#00d1ff;#00d1ff;#00d1ff" repeatCount="indefinite" times="0;0.1;0.2;0.3;1" dur="1s" begin="-0.25s"></animate>
</circle><circle cx="49.99999999999999" cy="25" fill="#00d1ff" r="5">
  <animate attributeName="r" values="3;3;5;3;3" times="0;0.1;0.2;0.3;1" dur="1s" repeatCount="indefinite" begin="-0.16666666666666666s"></animate>
  <animate attributeName="fill" values="#00d1ff;#00d1ff;#00d1ff;#00d1ff;#00d1ff" repeatCount="indefinite" times="0;0.1;0.2;0.3;1" dur="1s" begin="-0.16666666666666666s"></animate>
</circle><circle cx="62.5" cy="28.349364905389034" fill="#00d1ff" r="5">
  <animate attributeName="r" values="3;3;5;3;3" times="0;0.1;0.2;0.3;1" dur="1s" repeatCount="indefinite" begin="-0.08333333333333333s"></animate>
  <animate attributeName="fill" values="#00d1ff;#00d1ff;#00d1ff;#00d1ff;#00d1ff" repeatCount="indefinite" times="0;0.1;0.2;0.3;1" dur="1s" begin="-0.08333333333333333s"></animate>
</circle><circle cx="71.65063509461096" cy="37.499999999999986" fill="#00d1ff" r="5">
  <animate attributeName="r" values="3;3;5;3;3" times="0;0.1;0.2;0.3;1" dur="1s" repeatCount="indefinite" begin="0s"></animate>
  <animate attributeName="fill" values="#00d1ff;#00d1ff;#00d1ff;#00d1ff;#00d1ff" repeatCount="indefinite" times="0;0.1;0.2;0.3;1" dur="1s" begin="0s"></animate>
</circle>
            </svg>
           </div>
          </div>
  </div>
  
</template>

<style scoped>
.signup-box.top-sign-pad {
  padding-top: 70px;
  text-align: center;
}
.loading{ width: 100%;}
.loader1 {
  top: 50%;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>

<script>
    import { mapState, mapActions } from 'vuex'
    import { useRoute } from 'vue-router'
    export default {
        name: "EmailVerificationstatus",
        data () {
            return {
                query:{
                 act:'',
                _embed:true,
               },
               pageload:false, 
            };
        },

        components: {
            
        },
        computed:{
            ...mapState({
                verificationstatus: state => state.authModule.emailverificationstatus,
            }),
        },

        mounted() {
            const route=useRoute();
            this.query.act=route.params.act;
            this.EmailVerification(this.query);
           
        },
        methods: {
            ...mapActions({
                EmailVerification: 'authModule/emailverification',
            })
       },
       watch: {
          verificationstatus(newval,oldval) { 
             this.pageload=true;
         },
       } 
    }
</script>