<template>
    <div class="copy-rrt"  v-if="todoLisloginflug">
        <p> © 2022 SAS Radar. All rights reserved.</p>
      </div>
</template>

<script>
import {mapState,mapActions} from 'vuex'
export default {
  name: 'FooterSection',
  props: ['islogin'],
   data() {
    return {
      todoLisloginflug : this.islogin,
      pageload:false,
    };
  },

  computed: {
      ...mapState({
          user: state => state.authModule.user,
          //accessed: state => state.authModule.accessed,
         // companyLocations: state => state.authModule.companyLocations,
      }),
  },

  mounted() {  
       /*this.todoLisloginflug=true;*/
      // console.log('user',this.user)
       //this.todoLisloginflug=true
       this.pageload=true 
       if(this.user.data)  {
          this.todoLisloginflug=true
        }
       else{
           this.todoLisloginflug=false
        }    
  },

  methods:{
    int(){  
        if(this.user)  {
          this.todoLisloginflug=true
         // console.log('user',this.user) 
        }else{
           this.todoLisloginflug=false
        }     
          
    },
     ...mapActions({
          logoutUser: 'authModule/logout',
        }),
  },  
  watch: {
        user() { 
        this.int();
      
    },
  }
  
}
</script>