<template>
 <div class="ful-wdth">
    <div class="tab_boxe tab_box_margin adsKeypFont">
          <div class="tab-content">
            <article>
              <h4>Monitor Competitor's Ad Activity</h4>
              <p>This powerful tool will help you monitor and analyze your competitor's ads strategy. Follow your competitor on important search keywords. We will monitor their activities throughout the day and provide you a report on their ad behavior. </p>
            </article>
          </div>
        </div>

        <div class="app_InputBox d-flex adskeyWidth adsAppOverMargin">
          <div class="app_InputBoxSub ads-search">
          
            <input type="text" placeholder="Search for apps to follow">
          </div>
          <div class="app_InputBoxBtn">
            <button type="submit" class="app_In_Btn">Follow</button>
          </div>
        </div>

        <!-- <div class="changeLogosBtn adsByKlogoBtn">
          <ul>
            <li class="changeLogosyellowBtn"></li>
          </ul>
        </div> -->
        <!-- <a href="#">delete</a> -->

        <button type="button" class="btn dlt-btn">delete</button>

        <div class="adsKeyWordsp adsAppOverpPadding">
            <p>Here are the apps you're following.  Note: New reports will take a few hours to process.</p>
        </div>

        <div class="adsByAppBox">
            <div class="accordion-items">
                <div class="accItemBox">
                    <div class="accItemChekDiv">
                        <div class="accItemChekBox">
                            <div class="form-check tableChek chekBoxLeft">
                                <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                            </div>
                        </div>
                        <div class="accItemChekProfile">
                            <button class="accordion-button btn" v-on:click="acdnbtn('one')" type="button" id="acdnbtnOne" :class="acdnbtn1DivShow? 'collapsed' :''"><img src="@/assets/images/table-icon/tabl-1.png" alt=""><span>Foridev - Quick Shopping List</span></button>
                        </div>
                    </div>
                    <div class="accItemChekBtn">
                         <router-link to="/ads-by-app-report"> Report </router-link>
                    </div>
                </div>
                <div  v-show="acdnbtn1DivShow" class="accordion-collapse collapse show" id="tggl-body-one">
                  <div class="accordion-body">
                    <div class="accBodyInputBox">
                        <input type="text" class="accBin" placeholder="Add a keyword to follow
                        ">
                        <button type="submit" class="accbBbtn">ADD</button>
                    </div>
                    <div class="accBodyChekBox">
                        <ul>
                            <li>
                                <div class="form-check tableChek chekBoxLeft">
                                   <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-square" viewBox="0 0 16 16"><path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"></path><path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"></path></svg>
                                </div>
                                <span>Store Management</span>
                            </li>
                            <li>
                                <div class="form-check tableChek chekBoxLeft">
                                 <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-square" viewBox="0 0 16 16"><path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"></path><path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"></path></svg>
                                </div>
                                <span>Managing Inventory</span>
                            </li>
                            <li>
                                <div class="form-check tableChek chekBoxLeft">
                                   <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-square" viewBox="0 0 16 16"><path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"></path><path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"></path></svg>
                                </div>
                                <span>Business Insights</span>
                            </li>
                            <li>
                                <div class="form-check tableChek chekBoxLeft">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-square" viewBox="0 0 16 16"><path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"></path><path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"></path></svg>
                                </div>
                                <span>Deliver the goods</span>
                            </li>
                            <li>
                                <div class="form-check tableChek chekBoxLeft">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-square" viewBox="0 0 16 16"><path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"></path><path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"></path></svg>
                                </div>
                                <span>Product Variations</span>
                            </li>
                            <li>
                                <div class="form-check tableChek chekBoxLeft">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-square" viewBox="0 0 16 16"><path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"></path><path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"></path></svg>
                                </div>
                                <span>Bring in customers</span>
                            </li>
                            <li>
                                <div class="form-check tableChek chekBoxLeft">
                                   <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-square" viewBox="0 0 16 16"><path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"></path><path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"></path></svg>
                                </div>
                                <span>Shipping Solutions</span>
                            </li>
                        </ul>
                    </div>
                    <p>*Each new keyword may take a few hours to process.</p>
                  </div>
                </div>
            </div>

            <div class="accordion-items">
                <div class="accItemBox">
                    <div class="accItemChekDiv">
                        <div class="accItemChekBox">
                            <div class="form-check tableChek chekBoxLeft">
                                <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                            </div>
                        </div>
                        <div class="accItemChekProfile">
                            <button class="accordion-button btn" v-on:click="acdnbtn('two')" type="button" id="acdnbtnTwo" :class="acdnbtn2DivShow? 'collapsed' :''"><img src="@/assets/images/table-icon/tabl-1.png" alt=""><span>Foridev - Quick Shopping List</span></button>
                        </div>
                    </div>
                    <div class="accItemChekBtn">
                          <router-link to="/ads-by-app-report"> Report </router-link>
                    </div>
                </div>
                <div  v-show="acdnbtn2DivShow" class="accordion-collapse collapse show" id="tggl-body-two">
                  <div class="accordion-body">
                    <div class="accBodyInputBox">
                        <input type="text" class="accBin" placeholder="Add a keyword to follow
                        ">
                        <button type="submit" class="accbBbtn">ADD</button>
                    </div>
                    <div class="accBodyChekBox">
                        <ul>
                            <li>
                                <div class="form-check tableChek chekBoxLeft">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-square" viewBox="0 0 16 16"><path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"></path><path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"></path></svg>
                                </div>
                                <span>Store Management</span>
                            </li>
                            <li>
                                <div class="form-check tableChek chekBoxLeft">
                                   <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-square" viewBox="0 0 16 16"><path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"></path><path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"></path></svg>
                                </div>
                                <span>Managing Inventory</span>
                            </li>
                            <li>
                                <div class="form-check tableChek chekBoxLeft">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-square" viewBox="0 0 16 16"><path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"></path><path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"></path></svg>
                                </div>
                                <span>Business Insights</span>
                            </li>
                            <li>
                                <div class="form-check tableChek chekBoxLeft">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-square" viewBox="0 0 16 16"><path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"></path><path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"></path></svg>
                                </div>
                                <span>Deliver the goods</span>
                            </li>
                            <li>
                                <div class="form-check tableChek chekBoxLeft">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-square" viewBox="0 0 16 16"><path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"></path><path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"></path></svg>
                                </div>
                                <span>Product Variations</span>
                            </li>
                            <li>
                                <div class="form-check tableChek chekBoxLeft">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-square" viewBox="0 0 16 16"><path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"></path><path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"></path></svg>
                                </div>
                                <span>Bring in customers</span>
                            </li>
                            <li>
                                <div class="form-check tableChek chekBoxLeft">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-square" viewBox="0 0 16 16"><path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"></path><path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"></path></svg>
                                </div>
                                <span>Shipping Solutions</span>
                            </li>
                        </ul>
                    </div>
                     <p>*Each new keyword may take a few hours to process.</p>
                  </div>
                </div>
            </div>

            <div class="accordion-items">
                <div class="accItemBox">
                    <div class="accItemChekDiv">
                        <div class="accItemChekBox">
                            <div class="form-check tableChek chekBoxLeft">
                                <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                            </div>
                        </div>
                        <div class="accItemChekProfile">
                            <button class="accordion-button btn" v-on:click="acdnbtn('three')" type="button" id="acdnbtnThree" :class="acdnbtn3DivShow? 'collapsed' :''"><img src="@/assets/images/table-icon/tabl-1.png" alt=""><span>Foridev - Quick Shopping List</span></button>
                        </div>
                    </div>
                    <div class="accItemChekBtn">
                        <router-link to="/ads-by-app-report"> Report </router-link>
                    </div>
                </div>
                <div v-show="acdnbtn3DivShow" class="accordion-collapse collapse show" id="tggl-body-Three">
                  <div class="accordion-body">
                    <div class="accBodyInputBox">
                        <input type="text" class="accBin" placeholder="Add a keyword to follow
                        ">
                        <button type="submit" class="accbBbtn">ADD</button>
                    </div>
                    <div class="accBodyChekBox">
                        <ul>
                            <li>
                               <div class="form-check tableChek chekBoxLeft">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-square" viewBox="0 0 16 16"><path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"></path><path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"></path></svg>
                                </div>
                                <span>Store Management</span>
                            </li>
                            <li>
                                <div class="form-check tableChek chekBoxLeft">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-square" viewBox="0 0 16 16"><path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"></path><path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"></path></svg>
                                </div>
                                <span>Managing Inventory</span>
                            </li>
                            <li>
                                <div class="form-check tableChek chekBoxLeft">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-square" viewBox="0 0 16 16"><path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"></path><path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"></path></svg>
                                </div>
                                <span>Business Insights</span>
                            </li>
                            <li>
                                <div class="form-check tableChek chekBoxLeft">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-square" viewBox="0 0 16 16"><path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"></path><path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"></path></svg>
                                </div>
                                <span>Deliver the goods</span>
                            </li>
                            <li>
                                <div class="form-check tableChek chekBoxLeft">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-square" viewBox="0 0 16 16"><path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"></path><path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"></path></svg>
                                </div>
                                <span>Product Variations</span>
                            </li>
                            <li>
                               <div class="form-check tableChek chekBoxLeft">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-square" viewBox="0 0 16 16"><path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"></path><path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"></path></svg>
                                </div>
                                <span>Bring in customers</span>
                            </li>
                            <li>
                               <div class="form-check tableChek chekBoxLeft">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-square" viewBox="0 0 16 16"><path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"></path><path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"></path></svg>
                                </div>
                                <span>Shipping Solutions</span>
                            </li>
                        </ul>
                    </div>
                    <p>*Each new keyword may take a few hours to process.</p>
                  </div>
                </div>
            </div>

           

        </div>
 </div>
</template>

<style scoped>
 .adsByAppBox .accordion-body .accBodyInputBox .accbBbtn{ margin-left: 10px;}
 .adsByAppBox .accordion-body .accBodyChekBox .form-check.tableChek{
  display: unset !important;}
  .adsByAppBox .accordion-body .accBodyChekBox .form-check{ padding-left: 0;}
</style>

<script>
export default {
  name: 'AboutView',
  data() {
    return {
        acdnbtn1DivShow:false,
        acdnbtn2DivShow:false,
        acdnbtn3DivShow:false,
      }
  },
  methods:{
     acdnbtn(type){
         if(type=='one'){
            this.acdnbtn1DivShow = true;
             this.acdnbtn2DivShow = false;
             this.acdnbtn3DivShow = false;
         } else if(type=='two') {
             this.acdnbtn2DivShow = true;
             this.acdnbtn1DivShow = false;
             this.acdnbtn3DivShow = false;
         } else if(type=='three') { 
             this.acdnbtn3DivShow = true;
             this.acdnbtn1DivShow = false;
             this.acdnbtn2DivShow = false;
         } else{
             this.acdnbtn3DivShow = false;
             this.acdnbtn1DivShow = false;
             this.acdnbtn2DivShow = false;
        }
     }
  }
}
</script>


<style scoped>

.adsByAppBox .form-check-input{
 background: #303040; border: 1px solid rgba(255,255,255,.25);
}

.light-theme-np .adsByAppBox .form-check-input{background: #c1bdbd;}

.dlt-btn{background: #ff7301;border-radius: 4px;padding: 7px 33px; text-decoration: none;font-weight: 500;
font-size: 19px;line-height: 28px;text-align: center;letter-spacing: .02em;text-transform: capitalize;color: #fff; margin:40px 0;}
  .adsAppOverMargin  {
    margin: 0;
}
.adsAppOverpPadding p  {
    padding-top: 0px;
}

.adsByAppBox .accordion-items  {
    width: 100%;
    background: #303040;
    border: 0.5px solid #747474;
    border-radius: 3px;
    padding: 20px 30px;
    margin-bottom: 26px;
}
.adsByAppBox .accItemBox  {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}
.adsByAppBox .accItemBox .accItemChekDiv  {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.adsByAppBox .accItemBox .accItemChekDiv .accItemChekBox .chekBoxLeft  {
    padding-left: 20px;
}
.adsByAppBox .accordion-button:not(.collapsed) {
    color: #fff !important;
    background: #303040 !important;
    box-shadow: none !important;
}
.adsByAppBox .accordion-button {
    color: none !important;
    background-color: none !important;
    box-shadow: none !important;
}
.adsByAppBox .accItemBox .accItemChekDiv .accItemChekProfile .btn span {
    padding-right: 26px;
    border-right: 1px dotted #C0C0C0;
    margin-right: 23px;
}
.adsByAppBox .accItemBox .accItemChekDiv .accItemChekProfile .btn img  {
    display: block;
    padding-right: 30px;
}
.adsByAppBox .accordion-button:not(.collapsed)::after {
    background-image: url(@/assets/images/icon/nextWhite.png);
    transform: rotate(0deg);
    background-position: center;
    background-size: contain;
    width: 10px;
}
.adsByAppBox .accItemBox .accItemChekBtn a  {
    background: #4BCCC0;
    border-radius: 4px;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: #FFFFFF;
    padding: 5px 13px;
    text-decoration: none;
}
.adsByAppBox .accordion-items {
    background: #303040;
    border: 0.5px solid #747474;
}
.adsByAppBox .accordion-button {
    color: #fff !important;
    background-color: #303040 !important;
}
.adsByAppBox .accordion-button::after {
    width: 20px;
    height: 20px;
    background-image: url(@/assets/images/icon/nextWhite.png);
    transform: rotate(90deg);
    background-position: center;
    background-size: auto;
    background-repeat: no-repeat;
}
.adsByAppBox .accordion .collapse:not(.show) {
    display: none;
    transition: .2s ease-in-out;
}
.adsByAppBox .accordion .accordion-collapse{
    padding-top: 16px;
    padding-bottom: 20px;
}
.adsByAppBox .accordion-body .accBodyInputBox  {
    width: 100%;
}
.adsByAppBox .accordion-body .accBodyInputBox .accBin  {
    max-width: 379.56px;
    width: 100%;
    height: 49.48px;
    background: #323243;
    border: 1px solid #5D5D76;
    border-radius: 4px;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 35px;
    letter-spacing: 0.02em;
    color: #ffffff;
    padding-left: 25px;
    outline: none;
}
.adsByAppBox .accordion-body .accBodyInputBox .accbBbtn  {
    background: #685DC9;
    border-radius: 4px;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    text-align: center;
    text-transform: uppercase;
    color: #FFFFFF;
    outline: none;
    padding: 11px 29px;
    border: none;
}
.adsByAppBox .accordion-body .accBodyChekBox  {
    padding: 37px 0 20px 0;
    width: 100%;
}
.adsByAppBox .accordion-body .accBodyChekBox ul  {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
}
.adsByAppBox .accordion-body .accBodyChekBox ul li  {
    width: 33.333%;
    padding-bottom: 36px;
    display: flex;
    
}
.adsByAppBox .accordion-body .accBodyChekBox .form-check.tableChek {
    display: unset;
    width: auto !important;
    padding: 2px 8px;
}
.adsByAppBox .accordion-body .accBodyChekBox ul li span {
    font-family: 'Rubik';
    font-weight: 400;
    font-size: 17px;
    color: rgb(255, 255, 255);
    margin-top: -4px;
    margin-left: 0;
    display: block;
}

.black .adsByAppBox .accordion-body p{ color: #00d0fe;}

.adsByAppBox .accordion-body p  {
    font-family: 'Rubik';
    font-weight: 400;
    font-size: 17px;
    color: #ED553B;
}
 .form-check.tableChek.chekBoxLeft svg{ float:left; margin-right:10px; color:#fff;}
 .light-theme-np .main_right-pn .form-check.tableChek.chekBoxLeft svg{ color:#000;}
 .ads-search input[type="text"]{background: #323243;opacity: 0.65;border: 1px solid #5D5D76;border-radius: 4px; height:43px; line-height:43px; color:#fff;width:100%; text-indent:15px; font-weight:500;}
 .main_right-pn .tab_boxe .tab-content article h4, .main_right-pn .tab_boxe .tab-content .media-assets h4 {
  font-weight: 300;
  font-size: 40px;
  text-transform: capitalize;
  color: #FFFFFF;
  margin: 0 0 30px 0;
  line-height: 1;
}
.main_right-pn .tab_boxe .tab-content article p {
  font-family: 'Rubik';
  font-weight: 400;
  font-size: 16px;
  color: #BBBBBB;
  margin: 0;
  line-height: 22px;
  padding-bottom: 30px;
}
.adsKeypFont .tab-content article p {
  font-size: 21px !important;
  line-height: 29px !important;
}
.adskeyWidth {
  max-width: 629px;
  width: 100%;
}
.adskeyWidth .app_InputBoxSub {
  max-width: 476px;
  width: 100%;
  margin-right: 10px;
}
.adskeyWidth .app_InputBoxBtn {
  max-width: 143px;
}
.app_InputBox .app_InputBoxBtn {
  display: flex;
  align-items: flex-end;
}
.app_InputBox .app_InputBoxBtn .app_In_Btn {
  background: #00C1EC;
  border-radius: 4px;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  text-transform: uppercase;
  color: #FFFFFF;
  text-shadow: 0px 0.5px 1px rgba(0, 0, 0, 0.25);
  padding: 8px 43px;
  border: none;
}
.main_right-pn .changeLogosBtn ul a {
  text-decoration: none;
  font-weight: 500;
  font-size: 19px;
  line-height: 28px;
  text-align: center;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  color: #FFFFFF;
}
.main_right-pn .changeLogosBtn ul .changeLogosyellowBtn {
  background: #FF7301;
  border-radius: 4px;
  padding: 7px 33px;
}
.main_right-pn .changeLogosBtn ul {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 60px 0 20px 0;
}
.adsKeyWordsp p {
  font-family: 'Rubik';
  font-weight: 300;
  font-size: 19px;
  line-height: 25px;
  color: #EDEDED;
}
.main_right-pn .tab_boxe {
  padding-top: 110px;
}
.light-theme-np .main_right-pn .tab_boxe .tab-content article h4, .light-theme-np .main_right-pn .tab_boxe .tab-content article p, .light-theme-np .adsKeyWordsp p{color: #212529 !important;}
.light-theme-np .adsByAppBox .accordion-items {
  background: #e1e1e1;
  border: 1px solid #cacaca;
}
.light-theme-np .adsByAppBox .accordion-button:not(.collapsed) {
  color: #212529 !important;
  background: #e1e1e1 !important;
  box-shadow: none !important;
}
.light-theme-np .adsByAppBox .accordion-button:not(.collapsed)::after {
  background-image: url(@/assets/images/icon/nextBlack.png) !important;
}
.light-theme-np .adsByAppBox .accordion-body .accBodyInputBox .accBin {
    background: #f4f4f4;
    border: 1px solid #c9c9c9;
    color:#212529;
}
.light-theme-np .adsByAppBox .accordion-button {
    color: #212529 !important;
    background-color: #e1e1e1 !important;
}
.light-theme-np .app_InputBox .app_InputBoxSub select, .light-theme-np .keyWordFirstLeft .keyWordInput {
    background: #f2f4f2e6;
    border: 1px solid #e7e7e757;
    color: rgba(48, 48, 64, 0.73);
}
.light-theme-np .ads-search input[type="text"]{
  background: #e1e1e1; border:1px solid #cacaca; color: #212529;}
.light-theme-np .adsByAppBox .accordion-body .accBodyChekBox ul li span {
 color: #212529;
}

.form-check.tableChek {
    display: flex !important;
    width: 100% !important;
    /* justify-content: center !important; */
    align-items: center !important;
    /* padding: 0; */
    margin: 0 !important;
}
.adsByAppBox .accordion-body .accBodyChekBox ul li {
    width: 294pxv!important;
    padding-bottom: 36px !important;
}
.adsByAppBox .accordion-body .accBodyChekBox ul {
    display: flex !important;
    justify-content: flex-start !important;
    align-items: center !important;
    flex-wrap: wrap !important;
}
@media (max-width:767px){
.adsKeypFont .tab-content article p {
  font-size: 16px !important;
  line-height: 23px !important;
}
.adsByAppBox .accordion-body .accBodyChekBox ul li { width:100%;}
.app_InputBox .app_InputBoxBtn .app_In_Btn {
  background: #00C1EC;
  border-radius: 4px;
  font-weight: 600;
  font-size: 15px;
  line-height: 27px;
  text-align: center;
  text-transform: uppercase;
  color: #FFFFFF;
  text-shadow: 0px 0.5px 1px rgba(0, 0, 0, 0.25);
  padding: 8px 11px;
  border: none;
}
.adsByAppBox .accItemBox .accItemChekDiv .accItemChekProfile .btn img{ padding-right: 10px; margin: 10px 0 0;}
.adsByAppBox .accItemBox .accItemChekDiv .accItemChekProfile .btn span {
  padding-right: 14px;
  border-right: 1px dotted #C0C0C0;
  margin-right: 20px;
}
.adsByAppBox .accordion-body .accBodyChekBox{ width: 100%;}
.adsByAppBox .accordion-body .accBodyChekBox ul{ display: block !important;}
.accordion-body {
  padding: 10px !important;
}
.form-check.tableChek.chekBoxLeft svg{ margin-top: 0;}
.adsByAppBox .accordion-body .accBodyInputBox .accbBbtn{ display:block; margin-left: 0; width: 100%;}
.adsByAppBox .accordion-body .accBodyChekBox{ padding-bottom: 20px;}
}

.adsByAppBox .accItemBox .accItemChekDiv .accItemChekProfile .btn img{ margin-top: 10px;}
</style>
