<template>

  <div id="app" :class="themeColor ? 'light-theme-np' : 'black'" v-if="['HomePage','Dashboard','MostInstalled','AppStoreRanking','MostReviewsAdded','NewestApp','MostReviews','BigGainer','AppTracking','ResetPassword','KeyWordTracking','ChangeLogAppListing','SidebySideComparison',
  'SidebySideZeroState','AboutDetails','MonitorCompetitorsads','LeaveProductFeed','TrackAdsByKeyword','AdsKeywordDetails',
  'AdRaddarActiveScan', 'AdsByAppReport','AdAppKeyDrilldown','KeyTrackingDetailsPage','AboutDetails','DevelopersTeamDirectory',
  'DevelopersTeamName','AppListingChangelogs','ChangeLogsDetails','ShopifyStorePoro','ShopifyStorePoroExpan','CategoriesPage',
  'AccountManageProfileM','PayWallSearchAdsByApp','PayWallSearchAdsByKeywords','PayWallChangeLogs','PayWallAddRaddarActiveScan',
  'ContactUs','DisclaimerPage','AdsByKeywordZeroState','AdsByReportZeroState','SearchResult','ComingSoon',].includes($route.name)" >
    <div class="total-sec">
      <div class="main_left-pn">
      <LeftSidebar v-model='toggleActive' :x='byColor' :islogin='islogin'/>
      </div>
      <div class="main_right-pn">
        <PageHeader :toggle='toggleFunc' :islogin='islogin' />
        <main>
        <router-view/> 
        </main>      
        <FooterSection :islogin='islogin'/>   
      </div>

    </div>
  </div>
  <div v-else>
  <router-view/>
  </div>
</template>

<script>

import LeftSidebar from './components/LeftSidebar.vue'
import PageHeader from './components/PageHeader.vue'
import FooterSection from './components/FooterSection.vue'
import {mapActions,mapState} from 'vuex'

export default {
  name: 'App',
  components: {
    LeftSidebar,
    PageHeader,
    FooterSection
  },
  data(){
    return{
      toggleActive:false,
      themeColor:false,
      islogin:false
    }
    
  },
  mounted() { 
        this.Menulist();
        this.themeColor=this.defultmodecss
        //this.totalapp();
        //this.get30daysappsbycategorywise(); 
        //this.PaidandFreeData();
        //this.allapsanddevloper();
        // this.allappsdevlopers();
  },

   computed: {
      ...mapState({
          defultmodecss: state => state.AppListModule.defultmode, 
      }),
   },
 methods:{
    toggleFunc(){
        this.toggleActive = !this.toggleActive;
    },

    byColor(){
       if(this.defultmodecss)
          {
            this.changedefultcss(false)
            this.themeColor=false
          }
       else
          {
            this.changedefultcss(true)
            this.themeColor=true
          }
        //this.themeColor = !this.themeColor;
        //this.themeColor=this.defultmodecss

      //console.log(this.themeColor);
    },
    ...mapActions({         
        Menulist: 'authModule/Menulist', 
        changedefultcss: 'AppListModule/ChangeDefultCss', 
            //totalapp: 'AppListModule/getTotalAps',
            // totalreviews: 'AppListModule/getTotalReviews',
            //get30daysappsbycategorywise: 'AppListModule/getLast30daysapsbycategory',
            // PaidandFreeData: 'AppListModule/PaidandFreeData',
            // allapsanddevloper: 'AppListModule/GetAllAppsWithoutpagination', 
            // allappsdevlopers: 'AppListModule/GetAllDeveloperswithoutdeveloper',       
    }),

  },
   watch:{
         toggleActive(val, newval){
         if(val){
          let x = document.querySelectorAll('.accordion-collapse');
          for(let i = 0; i < x.length; i++){
            x[i].classList.remove('show')
          }
       }
      
     }
  }
  
}

</script>

<style scoped>
 main{ width:100%}
</style>


