import router from '@/routes'

const mutations = {
    SET_AUTH (state, user) {
        state.user = user;
    },

    SET_AUTH_TOKEN (state, token) {
        state.authToken = token;
    },

    SET_USER_MEMBERSHIP (state, membership) {
        state.membership = membership;
    },

    SET_USER_FOLLOWAPS (state, userfollowapps) {
        state.userfollowapps = userfollowapps;
    },

    SET_COMPANY_USER_PERMISSIONS (state, permissions) {
        state.companyUserPermissions = permissions;
    },

    SET_COMPANY_LOCATIONS (state, locations) {
        state.companyLocations = locations;
    },

    SET_IS_ACCESSED_ACCOUNT (state, accessed) {
        state.accessed = accessed;
    },

    SET_FILE_STORAGE (state, fileStorage) {
        state.fileStorage = fileStorage;
    },

    LOGOUT_USER (state) {
        state.user = {};
        state.authToken = {};
        state.isUserLoggedIn = false;
        router.push({ name: 'LogIn' });
    },

    FORGOT_PASSWORD_MESSAGE (state, message) {
        state.forgotPasswordMessage = message;
    },

    SET_FORM_LOADER_STATUS (state, status) {
        state.formLoader = status;
    },

    SET_IS_USER_LOGGED_IN_STATUS (state, status) {
        state.isUserLoggedIn = status;
    },

    SET_USER_INTEGRATIONS (state, integrations) {
        state.integrations = integrations;
    },

    SET_ORGANIZATION_LOCATIONS (state, locations) {
        state.organizationLocations = locations;
    },

    SET_LOCATION_TABS (state, tabs) {
        const groupOrder = ['leads', 'trials', 'clients', 'staff', 'lost'];
        const array = [
            {
                type: 'leads',
                items: []
            },
            {
                type: 'trials',
                items: []
            },
            {
                type: 'clients',
                items: []
            },
            {
                type: 'staff',
                items: []
            },
            {
                type: 'lost',
                items: []
            }
        ];

        for (let groupIndex in array) {
            for (let statusIndex in tabs[groupOrder[groupIndex]]) {
                array[groupIndex].items.push(
                    {
                        title: tabs[groupOrder[groupIndex]][statusIndex].title,
                        value: tabs[groupOrder[groupIndex]][statusIndex].value
                    }
                );
            }
        }

        state.locationTabs = array;
    },

    SET_ORGANIZATION_USERS (store, users) {
        store.organizationUsers = users;
    },

    SET_ORGANIZATION_SUPER_USERS (store, users) {
        store.organizationSuperUsers = users;
    },

    SET_USER_LOADER_STATUS (store, status) {
        store.userLoader = status;
    },

    SET_ORGANIZATION_LOCATION_LOADER_STATUS (store, status) {
        store.locationLoader = status;
    },

    SET_ORGANIZATION_LOCATION_SAVE_LOADER_STATUS (store, status) {
        store.locationSaveLoader = status;
    },

    SET_WHITE_LABEL_SAVE_LOADER_STATUS (store, status) {
        store.whiteLabelLoader = status;
    },

    SET_PERMISSIONS (store, permissions) {
        store.permissions = permissions;
    },

    SET_USER_SAVE_LOADER_STATUS (store, status) {
        store.userSaveLoader = status;
    },

    SET_FIT_FUNNEL_WEBHOOK (store, webhooks) {
        store.fitFunnelWebhooks = webhooks;
    },

    SET_CUSTOM_EMAIL_DOMAINS (store, domains) {
        store.customEmailDomains = domains;
    },

    SET_CUSTOM_EMAIL_DOMAIN_LOADER_STATUS (store, status) {
        store.emailDomainLoader = status;
    },

    SET_DOMAIN_DNS_LOADER_STATUS (store, status) {
        store.domainDNSLoader = status;
    },

    SET_DOMAIN_DNS_DETAILS (store, dnsDetails) {
        store.domainDNSDetails = dnsDetails;
    },

    SET_CUSTOM_EMAIL_DOMAIN_SENDER_LOADER_STATUS (store, status) {
        store.customEmailDomainSenderLoader = status;
    },

    SET_CUSTOM_EMAIL_DOMAIN_SENDERS (store, senders) {
        store.customEmailDomainSenders = senders;
    },

    SET_ORGANIZATION_USER_LOADER_STATUS (store, status) {
        store.organizationUserLoader = status;
    },

    SET_APPSFOLLOWINGLIMITOVER (store, appfolowinglimitover) {
        store.appfolowinglimitover = appfolowinglimitover;
    },

    SET_KEYWORDFOLLOWINGLIMITOVER (store, keywordfolowinglimitover) {
        store.keywordfolowinglimitover = keywordfolowinglimitover;
    },
    SET_MEMBERSHIPLEVELS (store, membershiplevellisting) {
        store.membershiplevellisting = membershiplevellisting;
    },
    SET_USERPACKAGEDETAILS (store, userselectedpackagedetails) {
        store.userselectedpackagedetails = userselectedpackagedetails;
    },

    SET_ACCOUNTDELETED (store, accountdeleted) {
        store.accountdeleted = accountdeleted;
    },

    SET_DEFULTMENU (store, defaultmenu) {
        store.defaultmenu = defaultmenu;
    },

    SET_PAGECONTENT (store, pagecontent) {
        store.pagecontent = pagecontent;
    },
    SET_EMAILVERIFICATIONSTATUS: (state, emailverificationstatus) => {
        state.emailverificationstatus = emailverificationstatus;
      },
   
};

export default mutations;