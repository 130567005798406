<template>
  <div class="tab_boxe shopifyStoreExpanded">
        <h2>Shopify Stores</h2>

        <div class="scoreBox">
            <h3>2,559,866 Stores</h3>
        </div>

        <div class="app_InputBox d-flex appInMt">
          <div class="app_InputBoxSub">
            <label>Market Category</label>
            <select>
              <option>Select</option>
              <option>Select</option>
              <option>Select</option>
              <option>Select</option>
            </select>
          </div>
          <div class="app_InputBoxSub">
            <label>Ranking</label>
            <select>
              <option>Select</option>
              <option>Select</option>
              <option>Select</option>
              <option>Select</option>
            </select>
          </div>
          <div class="app_InputBoxBtn">
            <button type="submit" class="app_In_Btn">filter</button>
          </div>
        </div>

        <div class="tab_Two_Box table-section shopifyExpanOne">
          <div class="resp-table">
            <table class="skygren-table">
              <thead class="voilet">
                <tr>
                  <th>Store Name</th>
                  <th>Website</th>
                  <th>Description</th>
                  <th>#of App</th>
                  <th>Social</th>
                  <th>Alexa Rank</th>
                  <th>Contact Info</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>site.myshopify</td>
                  <td>www.website.com</td>
                  <td>By DelightChat Inbox & Marketing</td>
                  <td>18</td>
                  <td>8</td>
                  <td>215</td>
                  <td>info.globo@gmail.com</td>
                </tr>
                <!-- <tr>
                    <td>site.myshopify</td>
                    <td>www.website.com</td>
                    <td>Instagram Feed in Instagram Gallery Style with Grid & Slider</td>
                    <td>18</td>
                    <td>8</td>
                    <td>215</td>
                    <td>info.globo@gmail.com</td>
                </tr>
                <tr>
                    <td>site.myshopify</td>
                    <td>www.website.com</td>
                    <td>By DelightChat Inbox & Marketing</td>
                    <td>18</td>
                    <td>8</td>
                    <td>215</td>
                    <td>info.globo@gmail.com</td>
                </tr>     -->
              </tbody>
            </table>
          </div>
        </div>

        <div class="col-lg-12 expandThreeDiv">
            <div class="row">
                <div class="col-lg-4">
                    <div class="exThreeOne">
                        <img src="@/assets/images/logs/mask1.jpg" alt="">
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="exThreeTwo">
                        <h2>fashion: fashion nova</h2>
                        <ul>
                            <li><span>Website:</span>www.fashionnova.com</li>
                            <li><span>Shop ID:</span>325</li>
                            <li><span>Country:</span>United States</li>
                            <li><span>Theme Store Id:</span>2354</li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="exThreeThree">
                        <div class="boxes">
                            <h4>Traffic Ranking</h4>
                            <h3>1,255,236</h3>
                        </div>
                        <h2>Address</h2>
                        <ul>
                            <li><span>Street:</span>1234 Doe Meadow Drived</li>
                            <li><span>City:</span>Saint Cloud</li>
                            <li><span>State:</span>Kansas</li>
                            <li><span>Zip code:</span>1234</li>
                            <li><span>Country:</span>United States</li>
                            <li><span>Tel:</span>1800 1234 5678</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-lg-12 padingTwoSide">
            <div class="row">
                <div class="col-lg-8">
                    <div class="tab_Two_Box table-section shopifyExpanTwo">
                        <div class="resp-table shopifyStoreposition">
                          <table class="skygren-table">
                            <thead class="voilet">
                              <tr>
                                <th>Social Platform</th>
                                <th>Link</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>Facebook </td>
                                <td>facebook.com/sitename</td>
                              </tr>
                              <tr>
                                  <td>Twitter</td>
                                  <td>twitter.com/sitename</td>
                              </tr>
                              <tr>
                                  <td>Instagram</td>
                                  <td>instagram.com/sitename</td>
                              </tr>    
                            </tbody>
                          </table>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="tab_Two_Box table-section shopifyExpanThree">
                        <div class="resp-table shopifyStoreposition">
                          <table class="skygren-table">
                            <thead class="voilet">
                              <tr>
                                <th>Email Contacts</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>hello.jackson@gmail.com</td>
                              </tr>
                              <tr>
                                  <td>Info.stallone@gmail.com</td>
                              </tr>
                              <tr>
                                  <td>hi.kidman@gmail.com</td>
                              </tr>    
                            </tbody>
                          </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-lg-12 expendFourDiv">
            <h2>Shopify Apps Detected</h2>
            <div class="tab_Two_Box table-section shopifyExpanFour">
                <div class="resp-table">
                  <table class="skygren-table">
                    <thead class="skyBackground">
                      <tr>
                        <th>Developer</th>
                        <th>App Name</th>
                        <th>Last Detected</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Shopify</td>
                        <td>Facebook Social</td>
                        <td>05/28/2022</td>
                      </tr>
                      <tr>
                          <td>ShopFlex Incy</td>
                          <td>Shopping Cart</td>
                          <td>05/16/2022</td>
                      </tr>
                      <tr>
                          <td>Kiwidrop Inc</td>
                          <td>Promotions</td>
                          <td>05/12/2022</td>
                      </tr>    
                    </tbody>
                  </table>
                </div>
            </div>
        </div>

        <div class="coomingText">
            <h2>Coming Soon</h2>
        </div>

        <div class="app_Store_topPadding padingTwoSide">
          <nav aria-label="navigation-cuts">
            <ul class="pagination">
              <li class="page-item"><a class="active page-link" href="#">1</a></li>
              <li class="page-item"><a class="page-link" href="#">2</a></li>
              <li class="page-item"><a class="page-link" href="#">3</a></li>
              <li class="page-item"><a class="page-link" href="#">3</a></li>
              <li class="page-item"><a class="page-link" href="#">Next</a></li>
            </ul>
          </nav>
        </div>

      </div>  
</template>

<script>

</script>

<style scoped>
.light-theme-np .main_right-pn .tab_boxe h2,  .light-theme-np .main_right-pn .tab_boxe h3, .light-theme-np .main_right-pn .tab_boxe h2, .light-theme-np .shopifyStoreExpanded .expandThreeDiv .exThreeTwo ul li, .light-theme-np .main_right-pn .tab_boxe h3, .light-theme-np .main_right-pn .tab_boxe h2, .light-theme-np .shopifyStoreExpanded .expandThreeDiv .exThreeThree ul li{ color: #212529;}
.light-theme-np .shopifyStoreExpanded .expandThreeDiv .exThreeThree .boxes h3{ color: #212529;}
.light-theme-np .app_InputBox .app_InputBoxSub label {
  color: #212529;
}
.light-theme-np .app_InputBox .app_InputBoxSub select, .light-theme-np .keyWordFirstLeft .keyWordInput {
  background: #f2f4f2e6;
  border: 1px solid #e7e7e757;
  color: rgba(48, 48, 64, 0.73);
}
.app_InputBox {
  width: 100%;
  max-width: 846px;
  margin-top: 36px;
  display: flex;
  flex-wrap: wrap;
  row-gap: 20px;
}
.app_InputBox .app_InputBoxSub {
  margin-right: 10px;
  width: 100%;
  max-width: 341px;
}
.app_InputBox .app_InputBoxSub label {
  display: block;
  font-family: 'Rubik';
  font-weight: 400;
  font-size: 19px;
  line-height: 35px;
  letter-spacing: 0.02em;
  color: #FFFFFF;
}

.app_InputBox .app_InputBoxSub select {
  width: 100%;
  height: 42px;
  background: #323243;
  border: 1px solid #5D5D76;
  font-family: 'Rubik';
  font-weight: 400;
  font-size: 17px;
  line-height: 20px;
  letter-spacing: 0.02em;
  color: rgba(255, 255, 255, 0.72);
  padding: 0 20px;
}

.app_InputBox .app_InputBoxSub {
  margin-right: 10px;
  width: 100%;
  max-width: 341px;
}

.app_InputBox .app_InputBoxBtn {
  display: flex;
  align-items: flex-end;
}

.app_InputBox .app_InputBoxBtn .app_In_Btn {
  background: #00C1EC;
  border-radius: 4px;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  text-transform: uppercase;
  color: #FFFFFF;
  text-shadow: 0px 0.5px 1px rgba(0, 0, 0, 0.25);
  padding: 8px 43px;
  border: none;
}
.shopifyStoreTable table thead tr > :nth-child(2) {
  text-align: left !important;
}

.app_InputBox .app_InputBoxSub {
  margin-right: 10px;
  width: 100%;
  max-width: 341px;
}

.shopifyStoreTable .shopifyStoreposition  {
    position: relative;
    overflow: hidden;
}
.shopifyStoreTable table tbody tr td  {
    padding: 28px 26px !important;
}
.shopifyStoreTable table thead tr > :nth-child(2)  {
    text-align: left !important;
}
.shopifyStoreTable table tbody tr > :nth-child(1),
.shopifyStoreTable table tbody tr > :nth-child(4),
.shopifyStoreTable table tbody tr > :nth-child(5),
.shopifyStoreTable table tbody tr > :nth-child(6),
.shopifyStoreTable table tbody tr > :nth-child(7)   {
    text-align: center !important;
}
.shopifyStoreTable table tbody tr > :nth-child(3)  {
    white-space: unset;
    width: 350px;
    line-height: 19px;
}
.shopifyStoreTable .coomingText {
    position: fixed;
    /* top: 400px;
    left: 10px; */
    top: 48%;
    opacity: 0.6;
    transform: rotate(-35deg);
}
.shopifyStoreTable .coomingText h2  {
    font-family: 'Poppins';
    font-weight: 600;
    font-size: 125px !important;
    text-transform: capitalize;
    color: rgba(241, 241, 241, 0.8);
    padding: 0;
}

/**********************    shopify store porospector expanded page start    **************************/

.shopifyStoreExpanded   {
    position: relative;
}
.shopifyStoreExpanded h2  {
    padding: 0px !important;
}
.shopifyStoreExpanded .scoreBox  {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
}
.shopifyStoreExpanded .scoreBox h3  {
    font-weight: 400;
    font-size: 22px;
    line-height: 33px;
    text-align: center;
    text-transform: capitalize;
    color: #FFFFFF;
}
.shopifyStoreExpanded .appInMt {
    margin: 0;
}
.shopifyExpanOne table thead tr > :nth-child(7),
.shopifyExpanOne table tbody tr > :nth-child(7)   {
    text-align: left !important;
}
.shopifyExpanOne table thead tr > :nth-child(2)   {
    text-align: left !important;
}
.shopifyExpanOne table tbody tr > :nth-child(4),
.shopifyExpanOne table tbody tr > :nth-child(5),
.shopifyExpanOne table tbody tr > :nth-child(6)     {
    text-align: center !important;
}
.shopifyExpanTwo table thead tr th  {
    text-align: left !important;
}
.shopifyExpanThree table thead tr th  {
    text-align: left !important;
}
.shopifyStoreExpanded .expendFourDiv  {
    padding: 84px 50px 0 50px;
}
.shopifyStoreExpanded .expendFourDiv h2  {
    padding: 0 !important;
}
.shopifyStoreExpanded .expendFourDiv .shopifyExpanFour  {
    padding-top: 32px;
    padding-bottom: 35px;
}
.shopifyStoreExpanded .expendFourDiv .shopifyExpanFour table thead tr > :nth-child(1),
.shopifyStoreExpanded .expendFourDiv .shopifyExpanFour table thead tr > :nth-child(2)  {
    text-align: left !important;
}
.shopifyStoreExpanded .expandThreeDiv  {
    padding: 55px 50px 0 50px;
}
.shopifyStoreExpanded .expandThreeDiv .exThreeOne ,
.shopifyStoreExpanded .expandThreeDiv .exThreeTwo,
.shopifyStoreExpanded .expandThreeDiv .exThreeThree  {
    height: 100%;
}
.shopifyStoreExpanded .expandThreeDiv .exThreeOne img  {
    width: 100%;
    display: block;
    height: 100%;
}
.shopifyStoreExpanded .expandThreeDiv .exThreeTwo h2  {
    font-family: 'Rubik';
    font-weight: 400;
    font-size: 30px;
    line-height: 36px;
    text-transform: capitalize;
    color: #FFFFFF;
    padding-bottom: 22px !important;
}
.shopifyStoreExpanded .expandThreeDiv .exThreeTwo ul li  {
    font-family: 'Rubik';
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #FFFFFF;
    padding-bottom: 20px;
    display: flex;
}
.shopifyStoreExpanded .expandThreeDiv .exThreeTwo ul li span  {
    width: 143px;
}
.shopifyStoreExpanded .expandThreeDiv .exThreeThree .boxes  {
    width: 100%;
    background: rgba(65, 65, 83, 0.6);
    border: 1px solid #414153;
    border-radius: 3px;
    text-align: center;
    padding: 11px 15px;
}
.shopifyStoreExpanded .expandThreeDiv .exThreeThree .boxes h4  {
    font-family: 'Rubik';
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.02em;
    text-transform: capitalize;
    color: #FFFFFF;
    margin: 0;
}
.shopifyStoreExpanded .expandThreeDiv .exThreeThree .boxes h3  {
    font-family: 'Rubik';
    font-weight: 500;
    font-size: 35px;
    line-height: 41px;
    letter-spacing: 0.05em;
    text-transform: capitalize;
    color: #FFFFFF;
    margin: 0;
}
.shopifyStoreExpanded .expandThreeDiv .exThreeThree h2  {
    padding: 25px 0 8px 0 !important;
    font-family: 'Rubik';
    font-weight: 400;
    font-size: 27px;
    line-height: 32px;
    letter-spacing: 0.02em;
    text-transform: capitalize;
    color: #FFFFFF;
}
.shopifyStoreExpanded .expandThreeDiv .exThreeThree ul li  {
    font-family: 'Rubik';
    font-weight: 400;
    font-size: 16px;
    line-height: 27px;
    letter-spacing: 0.02em;
    text-transform: capitalize;
    color: #D1D1D1;
}
.shopifyStoreExpanded .expandThreeDiv .exThreeThree ul li span  {
    font-weight: 500;
}
.shopifyStoreExpanded .expandThreeDiv .exThreeThree ul li:not(:last-child)  {
    padding-bottom: 10px;
}
.padingTwoSide  {
    padding: 0 50px;
}
.shopifyStoreExpanded .coomingText {
    position: fixed;
    /* top: 1038px;
    left: 370px; */
    /* width: 100vw;
    height: 100vh; */
    top: 40%;
    left: 20%;
    opacity: 0.6;
    transform: rotate(-35deg);
}
.shopifyStoreExpanded .coomingText h2 {
    font-family: 'Poppins';
    font-weight: 600;
    font-size: 135px !important;
    /* line-height: 318px; */
    text-transform: capitalize;
    color: rgba(241, 241, 241, 0.8);
    padding: 0;
}

/**********************    shopify store porospector expanded page end    **************************/




.tab_boxe nav[aria-label="navigation-cuts"] ul{
    justify-content: flex-end;
    column-gap: 10px;
}
.tab_boxe nav[aria-label="navigation-cuts"] ul .page-link{
    padding: 0;
    line-height: 1;
    font-family: 'Rubik';
    font-weight: 400;
    font-size: 15px;
    color: #FFFFFF;
    background: #444457b3;
    border: 0.5px solid #85858529;
    border-radius: 3px;
    display: flex;
    padding: 0 16px;
    height: 35px;
    align-items: center;
    justify-content: center;
}
.tab_boxe nav[aria-label="navigation-cuts"] ul .active.page-link,
.tab_boxe .page-link:hover  {
    background: rgba(0, 209, 255, 0.7) !important;
    color: #fff !important;
}
.tab_boxe h2 {
    font-weight: 300;
    font-size: 40px;
    line-height: 60px;
    text-transform: capitalize;
    color: #FFFFFF;
    padding-bottom: 45px;
    margin: 0;
}
.app_InputBox .app_InputBoxSub label {
    display: block;
    font-family: 'Rubik';
    font-weight: 400;
    font-size: 19px;
    line-height: 35px;
    letter-spacing: 0.02em;
    color: #FFFFFF;
}
.light-theme-np .app_InputBox .app_InputBoxSub label {
    color: #212529;
}
.app_InputBoxBtn {
    display: flex;
    align-items: flex-end;
}
.tab_Two_Box table .voilet {
    background: #685DC9 !important;
}

/*about details page responsive start*/




@media (min-width:1400px) and (max-width:1799px)  {
    .tab_boxe {
        padding-top: 30px;
    }
    .tab_boxe h2,
    .main_right-pn .tab_tittle_border {
        padding-bottom: 0px;
        margin: 0;
    }
    .tab_boxe .tab-content {
        padding-top: 50px;
    }
    .tab_boxe .tab-content .tab_One_Box .tab_One_Content .tab_One_Text {
        padding-left: 15px !important;
    }
    .tab_boxe .tab-content .tab_One_Box .tab_One_Content .tab_One_Option {
        padding-left: 15px !important;
    }
    .tab_boxe h2 {
        font-size: 30px;
    }
    /* .keyWordFirstLeft .keyWordsubmit {
        width: 180.77px;
        height: 44.48px;
        font-size: 17px;
        margin-top: 20px;
    } */
}

@media (min-width:1280px) and (max-width:1400px)  {
    /* .main_right-pn .tab_boxe {
        padding-top: 30px;
    }    */
    .tab_boxe h2,
    .main_right-pn .tab_tittle_border {
        padding-bottom: 0px;
    }
    .tab_boxe .tab-content {
        padding-top: 50px;
    }
    .tab_boxe .tab-content .tab_One_Box .tab_One_Content .tab_One_Text {
        padding-left: 15px !important;
    }
    .tab_boxe .tab-content .tab_One_Box .tab_One_Content .tab_One_Option {
        padding-left: 15px !important;
    }
    .tab_boxe h2 {
        font-size: 30px;
    }
    .tab_boxe .nav-tabs .nav-item .nav-link {
        font-size: 14px;
        padding: 7px 30px;
    }
    .tab_boxe .tab-content .tab_One_Box .tab_One_Content .tab_One_Text h2 {
        font-size: 20px;
    }
    .tab_boxe .tab-content .tab_One_Box .tab_One_Content .tab_One_Text h4 {
        font-size: 14px;
        padding-bottom: 5px;
    }
    .tab_boxe .tab-content .tab_One_Box .tab_One_Content .tab_One_Option span {
        font-size: 14px;
        padding-left: 10px;
    }
    .tab_boxe .tab-content .tab_One_Box .tab_One_Content .tab_One_Option ul li:not(:last-child) {
        padding-bottom: 15px;
        margin-bottom: 15px;
    }
    .tab_boxe h2 {
        font-size: 26px;
    }
    .tab_Two_Box {
  padding-top: 50px;
}

}





@media (max-width:991px)  {

    .tab_boxe {
        padding-top: 50px;
    }
    .tab_boxe h2,
    .tab_boxe .adsKeyDetaildsRe .tittle h2  {
        padding-bottom: 10px;
        line-height: 30px;
    }
    .tab_boxe .nav-tabs {
        padding-top: 15px;
    }
    .tab_boxe .nav-tabs .nav-item .nav-link {
        font-size: 13px;
        padding: 4px 15px;
        margin: 3px 0;
    }
    .tab_boxe .tab-content {
        padding-top: 50px;
    }
    .tab_boxe .tab-content .tab_One_Box .tab_One_Content .tab_One_Text {
        padding-left: 15px !important;
    }
    .tab_boxe .tab-content .tab_One_Box .tab_One_Content .tab_One_Option {
        padding: 30px  15px !important;
    }
    .tab_boxe .tab-content .tab_One_Box .tab_One_Content .tab_One_Text h2 {
        font-size: 19px;
        padding-bottom: 5px;
    }
    .tab_boxe .tab-content .tab_One_Box .tab_One_Content .tab_One_Text h3 {
        font-size: 13px;
        padding-bottom: 8px;
    }
    .tab_boxe .tab-content .tab_One_Box .tab_One_Content .tab_One_Text h4 {
        font-size: 14px;
        line-height: 20px;
        padding-bottom: 12px;
    }
    .tab_boxe h2,
    .tab_boxe .adsKeyDetaildsRe .tittle h2 {
        font-size: 24px;
    }
    .tab_boxe .changeLogsBox .changeLogsSub ul li   {
        width: 100%;
        overflow-y: hidden;
        overflow-x: scroll;
        white-space: nowrap;
    }
    .tab_boxe .changeLogsBox .changeLogsSub ul li .changeDiv   {
        width: 250%;
    }
    .tab_boxe .changeLogsBox .changeLogsSub ul li .changeDiv::after  {
        display: none;
    }
        .tab_boxe .changeLogsBox .changeLogsSub ul li .changeDiv span {
        font-size: 14px;
    }
        .tab_boxe .changeLogsBox .changeLogsSub ul li .changeDiv .changeDivBtn a {
        font-size: 13px;
        padding: 2px 10px;
    }
        .tab_boxe .changeLogsBox {
        padding-top: 30px;
    }
     .tab_boxe .changeLogsBox .changeLogsSub ul li:not(:last-child) {
        margin-bottom: 25px;
    }
     .tab_boxe .changeLogsBox .changeLogsStatus .changeLogsText h3 {
        font-size: 21px;
    }
     .tab_boxe .changeLogsBox .changeLogsStatus .changeLogsText span {
        font-size: 12px;
    }
     .tab_boxe .changeLogsBox .changeLogsStatus .changeLogsText span::after {
        top: 5px;
    }
     .tab_boxe .changeLogsBox .changeLogsSub ul li .changeDiv span  {
        margin-right: 15px;
        padding-right: 15px
    }
    .logsFillterBtn {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 40px 0 30px 0;
    }
     .tab_boxe .changeLogsBox .changeLogsStatus .changeLogsText span {
        padding-right: 6px;
        margin-right: 8px;
    }
    .tab_boxe .sidePagePara {
        font-size: 18px;
    }
    .tab_boxe .appsBox {
        padding-top: 15px;
    }
    .tab_boxe .appsBox .comGap  {
        padding: 15px;
    }
    .tab_boxe .comparingThree {
        padding-top: 5px;
    }
    .tab_boxe .comparingThree .compds  {
        padding: 15px;
    }
    .tab_boxe .comparingThree .comThreeBox {
        padding: 50px 40px;
    }
    .tab_boxe .comparingThree .comThreeBox h3 {
        font-size: 20px;
        padding-top: 35px;
    }
    .tab_boxe .adsKeyDetaildsRe {
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    .tab_boxe .adsKeyDetaildsRe .tittle  {
        text-align: center;
    }
    .tab_boxe .adsKeyDetaildsRe .tittle p {
        font-size: 18px;
        line-height: 27px;
        margin-bottom: 20px;
    }
    .tab_boxe .adsKeyDetaildsRe .btn a {
        font-size: 15px;
        padding: 9px 22px;
    }
}

@media (max-width:667px)   {
    .row {
        margin: 0 !important;
    }
    .tab_boxe {
        padding-top: 30px;
    }
    .tab_boxe .nav-tabs {
        padding-top: 5px;
    }
    .tab_boxe .nav-tabs .nav-item .nav-link {
        font-size: 12px;
        padding: 4px 8px;
    }
    .tab_boxe .tab-content {
        padding-top: 30px;
    }
    .tab_boxe .tab-content .tab_One_Box .tab_One_Content .tab_One_Image {
        padding-bottom: 15px;
    }
    .tab_boxe h2 {
        font-size: 20px;
    }
    .tab_boxe .comparingThree .comThreeBox {
        padding: 40px 30px;
    }
    .tab_boxe .comparingThree .comThreeBox h3 {
        font-size: 18px;
        padding-top: 25px;
    }
    .tab_boxe .sidePagePara {
        font-size: 16px;
        line-height: 26px;
    }
    .tab_boxe .comparingThree .comThreeBox a .threeDiv {
        width: 150.25px;
        height: 150.25px;
    }
    .tab_boxe .adsKeyDetaildsRe .tittle p {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 15px;
    }
    .tab_boxe .adsKeyDetaildsRe .btn a {
        font-size: 13px;
        padding: 8px 20px;
    }
    .shopifyStoreExpanded .expandThreeDiv, .shopifyStoreExpanded .expendFourDiv{
    padding: 55px 10px 0 10px;
    }
    .padingTwoSide{ padding:0 10px;}
    .shopifyStoreExpanded .expandThreeDiv .exThreeTwo h2{ font-size:24px; line-height:30px;}
    .shopifyStoreExpanded .coomingText h2{ font-size:35px !important;}
} 

/*about details page responsive end*/
.table-section table{
    min-width: unset
}
.table-section table tr td{
    text-align: left;
    padding-left: 20px;
}
  @media (max-width: 1799px){
.main_right-pn .table-section {
  margin-bottom: 0;
}}
</style>