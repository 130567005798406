<template>
  <div class="tab-pane fade" id="notification" role="tabpanel" aria-labelledby="notification-tab">
         
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>

</style>