<template>
   <!-- footer section start here -->

    <footer>
        <div class="container">
            <div class="row">
                <div class="col-lg-3">
                    <div class="footerLogo">
                        <router-link to="./"><img src="@/assets/images/homePage-1/logofooter.png"></router-link>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="footerList">
                        <ul v-if="defaultmenu.footer_menu">
                            <li v-bind:key="menus.title" v-for="menus in defaultmenu.footer_menu">
                                <router-link :to="'/'+menus.menuslug">{{menus.title}}</router-link>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-3">
                    <div class="footerIcon">
                        <ul class="d-flex">
                            <li><a href="#">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"/></svg>
                            </a></li>
                            <li><a href="#">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"/></svg>
                            </a></li>
                        </ul>
                        <p> © 2022 SAS Radar. All rights reserved.</p>
                    </div>
                </div>
            </div>
        </div>
    </footer>

    <!-- footer section end here -->
</template>

<script>
import { mapState} from 'vuex'
export default {
   name: "MenuList",
   data() {
    return {
        menuload:true,
    };
  },
  computed: {
      ...mapState({
          defaultmenu: state => state.authModule.defaultmenu,
      }),
  },
}
</script>

<style>
/* footer section start here */

footer  {
    padding-top: 103px;
}
footer .footerLogo a  {
    text-decoration: none;
    display: inline-block;
}
footer .footerLogo img  {
    display: block;
}
footer .footerList  {
    padding-top: 10px;
}
footer .footerList ul {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    flex-wrap: wrap;
}
footer .footerList ul li  {
    padding-bottom: 42px;
    width: 50%;
}
footer .footerList ul li a  {
    font-weight: 300;
    font-size: 17px;
    letter-spacing: 0.03em;
    color: #686868;
    text-decoration: none;
    display: inline-block;
}
footer .footerIcon ul  {
    padding-bottom: 30px;
    justify-content: flex-end;
}
footer .footerIcon ul li  {
    padding-left: 10px;
}
footer .footerIcon ul li a  {
    text-decoration: none;
    border-radius: 50px;
    border: 1px solid #262626;
    width: 34px;
    height: 34px;
    display: flex;
    justify-content: center;
    align-items: center;
}
footer .footerIcon ul li a svg  {
    width: 19px;
    height: 19px;
}
footer .footerIcon p  {
    font-weight: 300;
    font-size: 16px;
    letter-spacing: 0.02em;
    color: #686868;
}
footer .footerIcon {
    text-align: right;
}

.toggleC  {
    display: none;
}

.footerLogo img{ max-width: 100%;}
/* footer section end here */

@media (max-width: 991px){
footer {
  padding-top: 53px;
}
.footerLogo{ padding-bottom: 30px;}
 footer .footerIcon ul {
  padding-bottom: 30px;
  justify-content: center;
}

footer .footerList ul li {
  width:auto;
  padding: 0 10px;
}
footer .footerList ul {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
  padding-bottom: 20px;
}
.footerLogo{ text-align: center;}
footer .footerIcon p{ text-align:center;}

}

@media (max-width:540px) {
footer .footerIcon ul {
  padding-bottom: 30px;
  justify-content: center;
}
footer .footerList ul li {
  padding-bottom: 12px;}
  
}

</style>