<template>
      <header class="topheader">
        <nav class="navbar navbar-expand-lg navbar-light">
            <div class="container-fluid">
              <router-link class="navbar-brand" to="./">
                  <img src="@/assets/images/homePage-1/logo.png">
              </router-link>
              <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
              </button>
              <div class="collapse navbar-collapse headerRight navToogle" id="navbarNavAltMarkup">
                <div class="navbar-nav" v-if="defaultmenu.primary_menu">
                <router-link v-bind:key="menus.title" v-for="(menus,index) in defaultmenu.primary_menu"  :class="index==0?'nav-link active':'nav-link'" aria-current="page" :to="menus.title=='Home'?'/':'/'+menus.menuslug">{{menus.title}}</router-link>

                </div>
                <div class="navber-btn">
                    <ul class="d-flex" v-if="todoLisloginflug">
                        <li class="navBtnBorder"><router-link to="/dashboard">My Account</router-link></li>
                         <li class="navBtnBackground"><a @click="logoutLogedUser">Logout</a></li>
                    </ul>

                    <ul class="d-flex" v-else>
                        <li class="navBtnBorder"><router-link to="/log-in">Login</router-link></li>                        
                        <li class="navBtnBackground"><router-link to="/sign-up">Free Sign Up</router-link></li>                       
                    </ul>
                </div>
                <button class="toggleC" id="toggleC">
                    <i class="fa-solid fa-xmark"></i>
                  </button>
              </div>
            </div>
        </nav>
    </header>
</template>

<script>
import {mapState,mapActions} from 'vuex'
export default {
  name: 'PageHeader',
  props: ['toggle','islogin'],

  data() {
    return {
      todoLisloginflug : this.islogin,
      pageload:false,
    };
  },

  computed: {
      ...mapState({
          user: state => state.authModule.user,
          defaultmenu: state => state.authModule.defaultmenu,
      }),
  },
created: function() {
  document.addEventListener.call(window, 'scroll', function() {
	if(window.scrollTop > 100) {
		// var topHeader  = document.getElementsByTagName('.topheader');
		// topHeader.classList.add("active");		
	}
});
},
  mounted() {
       this.pageload=true 
       if(this.user.data)  {
          this.todoLisloginflug=true
        }
       else{
           this.todoLisloginflug=false
        }  
  },

  methods:{
    int(){  
        if(this.user.data)  {
          this.todoLisloginflug=true
        }else{
           this.todoLisloginflug=false
        } 
    },
    logoutLogedUser(){
      this.logoutUser()
    },

     ...mapActions({
          logoutUser: 'authModule/logout',
        }),
  },  
  watch: {
        user() { 
        this.int();
      
    },
  }
}



</script>

<style scoped>
/* header section start here */
.navbar-light .navbar-nav .nav-link.exact-active{ color:#ffcf1f !important;}
header {
    position: absolute;
    width: 100%;
    z-index: 9999;
}
header nav .headerRight  {
    justify-content: flex-end;
}
header nav .headerRight .nav-link {
    padding: 0px 37px !important;
    font-weight: 400;
    font-size: 17px;
    line-height: 26px;
    letter-spacing: 0.02em;
    color: #FFFFFF !important;
}
header nav .headerRight .navber-btn  {
    padding-left: 37px;
}
header nav .headerRight .navber-btn ul li:not(:last-child)  {
    margin-right: 15px;
}
header nav .headerRight .navber-btn ul li a  {
    display: inline-block;
    text-decoration: none;
    font-weight: 500;
    padding: 9px 40px;
    letter-spacing: 0.02em;
    border-radius: 50px;
    transition: all ease-in-out 300ms;
    border: 2px solid #FFFFFF;
}

header nav .headerRight .navber-btn ul li+li a{ border: none;}
/* header nav .headerRight .navber-btn .navBtnBorder  {
    font-size: 16px;
    line-height: 24px;
    border: 2px solid #FFFFFF;
    border-radius: 50px;
    transition: all ease-in-out 300ms;
} */
/* header nav .headerRight .navber-btn .navBtnBorder:hover  {
    border: 2px solid #FF8A00;
} */
header nav .headerRight .navber-btn .navBtnBorder a  {
    color: #FFFFFF !important;
}
header nav .headerRight .navber-btn .navBtnBackground a  {
    color: #151515 !important;
}
header nav .headerRight .navber-btn .navBtnBackground  {
    font-size: 17px;
    line-height: 26px;
    color: #151515 !important;
    background: #FFCF1F;
    border-radius: 50px;
}
header nav .headerRight .navber-btn .navBtnBorder a:hover  {
    background: #FF8A00;
    color: #FFFFFF !important;
    border-color: #FF8A00;
}
header nav .headerRight .navber-btn ul li a{ cursor: pointer;}

@media (min-width: 992px){
  header nav .headerRight .nav-link{
            padding: 0 16px !important;
            font-weight: 400;
            background:#191927;
  }

            
}


@media (max-width: 991px){
  .navbar-collapse{ background: #191927; padding-bottom: 30px;}
   header nav .headerRight .navber-btn{padding-top: 11px;}
  header nav .headerRight .nav-link{padding: 5px 0 !important;}
  header nav .headerRight .navber-btn{ padding-left: 0;}
  .navbar-light .navbar-toggler {
  color: rgba(0,0,0,.55);
  border-color: #fff;
  background: #fff;
}
}

@media (max-width: 740px){
  .navbar-collapse{ background: #191927; padding-bottom: 30px;}
.navbar-light .navbar-toggler {
  color: rgba(0,0,0,.55);
  border-color: #fff;
  background: #fff;
}
header nav .headerRight .navber-btn ul li a{padding: 9px 29px;}
header nav .headerRight .navber-btn{
  padding-left: 0;
}

  header nav .headerRight .nav-link{padding: 5px 0 !important;}
  .navbar-brand {
  width: 208px;
}
 
}
/* header section end here */

</style>