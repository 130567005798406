const state = {
    authToken: {},
    user: {},
    membership: {},
    userfollowapps: {},
    companyUserPermissions: [],
    companyLocations: [],
    accessed: false,
    fileStorage: {},
    integrations: {},
    forgotPasswordMessage: '',
    formLoader: false,
    isUserLoggedIn: false,
    organizationLocations: [],
    locationTabs: [],
    organizationUsers: [],
    organizationSuperUsers: [],
    userLoader: false,
    locationLoader: false,
    locationSaveLoader: false,
    whiteLabelLoader: false,
    permissions: [],
    userSaveLoader: false,
    fitFunnelWebhooks: [],
    customEmailDomains: {},
    emailDomainLoader: false,
    domainDNSDetails: {},
    domainDNSLoader: false,
    customEmailDomainSenderLoader: false,
    customEmailDomainSenders: {},
    organizationUserLoader: false,
    appfolowinglimitover: false,
    keywordfolowinglimitover: false,
    membershiplevellisting: false,
    userselectedpackagedetails: false,
    accountdeleted:false,
    defaultmenu:[],
    pagecontent:[],
    emailverificationstatus:3,
};

export default state;