<template>


      <!-- Feedback section content start -->
      <div class="feedback-content">
           <div class="appSubClose" ><a href="javascript:void(0)"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#000" class="bi bi-x" viewBox="0 0 16 16" data-v-1116d673=""><path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" data-v-1116d673=""></path></svg></a></div>
        <div class="row">
            
            <div class="col-md-12 col-lg-12 col-xl-12">
                 <!-- <div class="rating-sct">
                    <div class="rating-list">
                        <ul>
                            <li>
                                <input type="radio" id="test" name="radio-group">
                                <label for="test2">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
                                        <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
                                    </svg>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
                                        <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
                                    </svg>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
                                        <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
                                    </svg>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
                                        <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
                                    </svg>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
                                        <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
                                    </svg>
                                </label>
                            </li>
                            <li>
                                <input type="radio" id="test2" name="radio-group">
                                <label for="test2">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
                                        <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
                                    </svg>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
                                        <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
                                    </svg>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
                                        <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
                                    </svg>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
                                        <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
                                    </svg>
                                </label>
                            </li>
                            <li>
                                <input type="radio" id="test2" name="radio-group">
                                <label for="test2">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
                                        <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
                                    </svg>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
                                        <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
                                    </svg>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
                                        <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
                                    </svg>
                                </label>
                            </li>
                            <li>
                                <input type="radio" id="test2" name="radio-group">
                                <label for="test2">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
                                        <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
                                    </svg>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
                                        <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
                                    </svg>
                                </label>
                            </li>
                            <li>
                                <input type="radio" id="test2" name="radio-group">
                                <label for="test2">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
                                        <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
                                    </svg>
                                </label>
                            </li>
                        </ul>
                    </div>
                </div> -->
                
                <div class="text-side">
                    <article>  
                        <h3><span>Hi</span>, please feel free to leave any product feedback to improve the product.</h3>
                    </article>
                    <div class="rate">
    <input type="radio" id="star5" name="rate" value="5" />
    <label for="star5" title="text">5 stars</label>
    <input type="radio" id="star4" name="rate" value="4" />
    <label for="star4" title="text">4 stars</label>
    <input type="radio" id="star3" name="rate" value="3" />
    <label for="star3" title="text">3 stars</label>
    <input type="radio" id="star2" name="rate" value="2" />
    <label for="star2" title="text">2 stars</label>
    <input type="radio" id="star1" name="rate" value="1" />
    <label for="star1" title="text">1 star</label>
  </div>
                    <form action="#">
                        <div class="form-group">
                            <label for="exampleFormControlTextarea1">Message</label>
                            <textarea class="form-control" id="exampleFormControlTextarea1" rows="3" placeholder="Describe the feature and ways we can make it better for you. " ></textarea>
                          </div>
                          <div class="verification">
                              <div class="item">
                                  <div class="verif-img">
                                      <img src="@/assets/images/verification/verification.png" alt="">
                                  </div>
                                  <button class="btn verif-btn">
                                      <img src="@/assets/images/verification/reload.png" alt="">
                                  </button>
                              </div>
                              <div class="fild">
                                  <input type="text" name="" id="">
                              </div>
                          </div>
                          <button type="submit" class="btn">Submit</button>
                    </form>
                </div>
            </div>
        </div>
      </div>
      <!-- Feedback section content end -->

</template>

<script>
export default {

}
</script>

<style>
/* Feedback section content start */


.feedback-content{ position: relative;}
.appSubClose{
  position: absolute;
  top: -12px;
  right: -17px;
}
.appSubClose a{
  background: #62b8ff;
  border: 5px solid #181826;
  border-radius: 50px;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.text-side form {
  width: 100%;
  float: left;
  padding-bottom: 37px;
}
.rate:not(:checked) > input {
    position:absolute;
    top:-9999px;
}
.rate:not(:checked) > label {
    float:right;
    width:1em;
    overflow:hidden;
    white-space:nowrap;
    cursor:pointer;
    font-size:30px;
    color:#ccc;
}
.rate:not(:checked) > label:before {
    content: '★ ';
}
.rate > input:checked ~ label {
    color: #ffc700;    
}
.rate:not(:checked) > label:hover,
.rate:not(:checked) > label:hover ~ label {
    color: #deb217;  
}
.rate > input:checked + label:hover,
.rate > input:checked + label:hover ~ label,
.rate > input:checked ~ label:hover,
.rate > input:checked ~ label:hover ~ label,
.rate > label:hover ~ input:checked ~ label {
    color: #c59b08;
}
.main_right-pn .feedback-content{
    background-color: #2C2C3B;
    margin-top: 120px;
}
.main_right-pn .feedback-content .row{
    margin: 0;
    row-gap: 20px;
}
.main_right-pn .feedback-content .rating-sct .rating-list ul {
  width: auto;
}


.light-theme-np .main_right-pn .feedback-content .text-side .verification .fild [type="text"]{ color:#000;}
.light-theme-np .main_right-pn .feedback-content .text-side .form-group textarea{ color: #000;}
.main_right-pn .feedback-content .row>*{
    padding: 0;
}
.main_right-pn .feedback-content .rating-sct {
     background-image: url('@/assets/images/verification/ve-bg.png');
    height: 100%;
    background-size: cover;
    background-position: center; 
    background: none !important;
}
.main_right-pn .feedback-content .rating-sct .rating-list{
    display: flex;
    justify-content: flex-end;
    padding: 80px 20px 0 0;
}
.main_right-pn .feedback-content .rating-sct .rating-list li{
    display: flex;
    align-items: center;
    column-gap: 10px;
    margin-bottom: 20px;
}
.main_right-pn .feedback-content .rating-sct .rating-list li:last-child{
    margin-bottom: 0;
}
.main_right-pn .feedback-content .rating-sct .rating-list li input[type=radio] {
    padding: 0.6em;
    -webkit-appearance: none;
    outline: 0.1em solid black;
    outline-offset: 0.1em;
}
.main_right-pn .feedback-content .rating-sct .rating-list li input[type=radio]:checked {
    display: inline-block;
    background-image: url('@/assets/images/icon/check-green.svg');
    background-size: cover;
    background-position: center;
}
.main_right-pn .feedback-content .rating-sct .rating-list li label svg{
    fill: #D69909;
    color: #D69909;
    flex-shrink: 0;
    position: relative;
    top: -1px;
}
.main_right-pn .feedback-content .text-side{
    padding: 80px;
}
.main_right-pn .feedback-content .text-side h3{
    font-weight: 300;
    font-size: 35px;
    line-height: 47px;
    color: #FFFFFF;
    margin-bottom: 30px;
}
.main_right-pn .feedback-content .text-side h3 span{
    font-weight: 500;
    font-size: 36px;
}
.main_right-pn .feedback-content .text-side .form-group label{
    font-family: 'Rubik';
    font-weight: 400;
    font-size: 18px;
    text-transform: capitalize;
    color: #FFFFFF;
    margin-bottom: 8px;
}
.main_right-pn .feedback-content .text-side .form-group textarea{
    background: #414155;
    border: 1px solid #686882;
    border-radius: 2px;
    font-family: 'Rubik';
    font-weight: 400;
    font-size: 14px;
    color: #FFFFFF;
    min-height: 174px;
}
.main_right-pn .feedback-content .text-side .verification{
    display: flex;
    gap: 19px;
    flex-wrap: wrap;
    margin: 32px 0 30px;
}
.main_right-pn .feedback-content .text-side .verification .item{
    display: flex;
}
.main_right-pn .feedback-content .text-side .verification .item .verif-img{
    background-color: #414155;
    width: 180px;
    display: flex;
    justify-content: center;
}
.main_right-pn .feedback-content .text-side .verification .item .verif-btn{
    background: transparent;
    border-radius: 0;
    border-left: 1px solid #686882;
}
.main_right-pn .feedback-content .text-side .verification .item,
.main_right-pn .feedback-content .text-side .verification .fild [type="text"]{
    background: #414155;
    border: 1px solid #686882;
    border-radius: 2px;
    height: 100%;
}
.main_right-pn .feedback-content .text-side .verification .fild{
    flex-grow: 1; width: 50%;
}
.main_right-pn .feedback-content .text-side .verification .fild [type="text"]{
    width: 100%;
    color: #fff;
}
.main_right-pn .feedback-content .text-side [type="submit"]{
    background: #3CAEA3;
    border-radius: 4px;
    font-weight: 600;
    font-size: 20px;
    text-align: center;
    text-transform: uppercase;
    color: #FFFFFF;
    padding: 10px 48px;
}

/* Feedback section content end */

/* light theme start here */

.light-theme-np .main_left-pn {
    box-shadow: 0px 0px 20px #d7d7d7;
    background-color: #fff;
}
.light-theme-np .main_left-pn .logo-sect h4,
.light-theme-np .main_left-pn .accd-sect .accordion-button,
.light-theme-np .main_left-pn .accd-sect .accordion-item .accordion-body li,
.light-theme-np .main_left-pn .dl-mode-btn span,
.light-theme-np .main_right-pn header .ite_sm:first-of-type h5,
.light-theme-np .main_right-pn header .ite_sm:last-child .prof li .nav-link,
.light-theme-np .main_right-pn .card-section [class^="col"]:nth-child(odd) .item .ttx h6,
.light-theme-np .main_right-pn .card-section [class^="col"]:nth-child(odd) .item .ttx h5,
.light-theme-np .main_right-pn .chat-bar .chat-title h5,
.light-theme-np .main_right-pn .chat-bar .chat-title > div span:first-child,
.light-theme-np .main_right-pn .table-section .ciel h6,
.light-theme-np .main_right-pn .chat-bar .chat-title > div input[type="date"],
.light-theme-np .main_right-pn .table-section .tbl-head h6,
.light-theme-np .main_right-pn .table-section table tbody td,
.light-theme-np .main_right-pn .copy-rrt p,
.light-theme-np .main_right-pn .section__head h4,
.light-theme-np .main_right-pn .drag-opt article h5,
.light-theme-np .main_right-pn .drag-opt article p,
.light-theme-np .main_right-pn .table-section.spg-table .tbl-head p,
.light-theme-np .main_right-pn header .ite_sm:last-child .form-group .form-control,
.light-theme-np .main_right-pn nav[aria-label="navigation-cuts"] ul .page-link,
.light-theme-np .main_right-pn .drag-opt .up-drag .btn svg,
.light-theme-np .main_right-pn .filter-mt-sect .item label,
.light-theme-np .main_right-pn .filter-mt-sect .item select,
.light-theme-np .main_right-pn .feedback-content .text-side h3,
.light-theme-np .main_right-pn .feedback-content .text-side .form-group label,
.light-theme-np .main_right-pn .chat-bar .chat-title h5,
.light-theme-np .main_right-pn .grap-item h5,
.light-theme-np .main_right-pn .grap-item .head-flex li,
.light-theme-np .tab-ppr p, .light-theme-np .main_right-pn .table-section .tbl-head p.min-para{
    color: #212529;
}

.light-theme-np .main_right-pn .feedback-content .text-side .verification .item,
.light-theme-np .main_right-pn .feedback-content .text-side .verification .fild [type="text"],
.light-theme-np .main_right-pn .feedback-content .text-side .form-group textarea {
    background: #ebebeb;
    border: 1px solid #d4d4d5;
    border-radius: 2px;
}
.light-theme-np .main_right-pn .feedback-content .text-side .verification .item .verif-img{
    background: #ebebeb;
}
.light-theme-np .main_right-pn .feedback-content .text-side .verification .item .verif-btn{
    border-left: 1px solid #d4d4d5;
}

.light-theme-np .main_left-pn .accd-sect .accordion-button img,
.light-theme-np .main_left-pn .accd-sect .accordion-button::after,
.light-theme-np .main_right-pn .feedback-content .text-side .verification .item .verif-btn img{
    filter: sepia(1) saturate(1) brightness(0.1) hue-rotate(135deg);
}
.light-theme-np .accordion-collapse {
    border-left: 1px solid #cad0d6;
    box-shadow: 7px 0px 12px -8px #a9a9a9;
}
.light-theme-np .main_right-pn .feedback-content,
.light-theme-np .main_right-pn .growth-chh {
    background-color: #eaeaea;
}
.light-theme-np .main_right-pn,
.light-theme-np .accordion-collapse{
    background-color: #fff;
}
.light-theme-np .slider:before,
.light-theme-np .main_right-pn header .navbar-collapse::after,
.light-theme-np .main_right-pn header .navbar-collapse::before {
    background-color: #191927;
}
.light-theme-np .main_right-pn header .navbar-collapse::before {
    box-shadow: 0 11px #191927;
}
.light-theme-np .main_right-pn header .ite_sm:last-child .form-group .form-control,
.light-theme-np .main_right-pn .chat-bar .chat-title > div .select-ar:last-child,
.light-theme-np .main_right-pn .filter-mt-sect .item select{
    background-color: #e8e8e8e6;
    border: 1px solid #e7e7e757;
}
.light-theme-np .main_right-pn header .ite_sm:last-child .form-group [type="submit"] svg,
.light-theme-np .main_right-pn header .ite_sm:last-child .notify{
    fill: #5e5e6d;
    color: #5e5e6d;
}
.light-theme-np .main_right-pn header .ite_sm:last-child .notify .badge-nofy{
    border-color: #fff;
}
.light-theme-np .main_right-pn .card-section [class^="col"]:nth-child(odd) .item,
.light-theme-np .main_right-pn nav[aria-label="navigation-cuts"] ul .page-link {
    background-color: #dadada;
}
.light-theme-np .main_left-pn .logo-sect .close-nav button svg {
    fill: #000000 !important;
}
.light-theme-np .main_right-pn .table-section table tbody > :nth-child(odd) {
    background-color: #eaecee;
}
.light-theme-np .main_right-pn .table-section table tbody > :nth-child(even) {
    background-color: #e1e1e1;
}
.light-theme-np .main_right-pn .table-section table tbody td,
.light-theme-np .main_right-pn .table-section table tbody tr td:last-child{
    border-color: #d6d6d6;
}
.light-theme-np .main_right-pn .table-section ul.nav.nav-tabs .nav-item .nav-link {
    font-weight: 600;
    font-size: 16px;
    text-transform: uppercase;
    color: #000000;
    background-color: #eaecee;
    border: unset !important;
}
.light-theme-np .main_right-pn .table-section ul.nav.nav-tabs {
    gap: 10px;
    border-bottom-color: #d6d6d6;
}
.light-theme-np .main_right-pn .table-section ul.nav.nav-tabs .nav-item .nav-link.active {
    color: #fff;
    background-color: #ED553B;
}
.light-theme-np .main_left-pn::-webkit-scrollbar-thumb {
    background: #d6d6d6; 
}
.light-theme-np .main_right-pn .drag-opt .up-drag {
    background: #ececec7a;
    border: 0.5px solid #74747414;
    box-shadow: 0px 4px 4px #8f8f8f70;
}
.light-theme-np .main_right-pn .drag-opt .up-drag .btn {
    background: #e1e1e1;
    border: 1px solid #cacaca;
}
/* light theme start here */
.main_right-pn .feedback-content .rating-sct {
    background-image: url('@/assets/images/verification/ve-bg.png');
    height: 100%;
    background-size: cover;
    background-position: center;
}
@media (max-width:1440px){
 
    /* Feedback section content start */
    .main_right-pn .feedback-content .text-side {
        padding: 40px;
    }
    .main_right-pn .feedback-content .text-side h3 {
        font-size: 30px;
        line-height: 40px;
        margin-bottom: 20px;
    }
    .main_right-pn .feedback-content .text-side .form-group label {
        font-size: 16px;
    }
    .main_right-pn .feedback-content .text-side .form-group textarea {
        min-height: 140px;
    }
    .main_right-pn .feedback-content .text-side [type="submit"] {
        font-size: 18px;
        padding: 7px 37px;
    }
    .main_right-pn .feedback-content .text-side h3 span {
        font-size: 25px;
    }
    /* Feedback section content end */
}
@media (max-width:991px)  {

    /* Feedback section content start */
    .main_right-pn .feedback-content .text-side h3 {
        font-size: 22px;
        line-height: 30px;
        margin-bottom: 10px;
    }
    .main_right-pn .feedback-content .text-side h3 span {
        font-size: 23px;
    }
    .main_right-pn .feedback-content .text-side .form-group label {
        font-size: 14px;
        margin-bottom: 6px;
    }
    .main_right-pn .feedback-content {
        margin-top: 70px;
    }
    /* Feedback section content end */
}
@media (max-width:767px) {


    /* Feedback section content start */
    .main_right-pn .feedback-content .rating-sct .rating-list {
        padding: 80px 10px;
    }
    .main_right-pn .feedback-content {
        margin-top: 40px;
    }
    /* Feedback section content end */
}
@media (max-width:576px){
    /* Feedback section content start */
    .main_right-pn .feedback-content .text-side {
        padding: 20px;
    }
    .main_right-pn .feedback-content .text-side h3 {
        font-size: 20px;
    }
    .main_right-pn .feedback-content .text-side h3 span {
        font-size: 21px;
    }
    .main_right-pn .feedback-content .rating-sct .rating-list {
        padding: 20px 10px 150px;
    }
    /* Feedback section content end */
}

</style>