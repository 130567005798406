<template>
      <section class="freeSign">
        <div class="container">
            <div class="row">
                <div class="freeSignBox" data-aos="fade-up" data-aos-duration="1000">
                    <h2>Don’t miss out on vital information to make your app more successful.</h2>
                    <span>
                        <router-link v-if="!user.data" to="/sign-up">Free Sign Up
                          <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="#fff" class="bi bi-arrow-right-circle-fill" viewBox="0 0 16 16">
                              <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z"/>
                         </svg>
                        </router-link>
                    </span>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import {mapState} from 'vuex'
export default {
  name: 'HomeFreeSign',
  data() {
    return {
      
    };
  },
  computed: {
      ...mapState({
          user: state => state.authModule.user,
      }),
  },
}
</script>

<style scoped>
/* free sign section start here */

.freeSign  {
    position: relative !important;
}
.freeSign .freeSignBox  {
    max-width: 1324px !important;
    width: 100% !important;
    background-image: url(@/assets/images/homePage-1/signup.png);
    background-position: center !important;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    position: relative !important;
    margin: 0 auto 0 auto !important;
    padding: 65px 185px !important;
    border-radius: 16px !important;
}
.freeSign .freeSignBox h2  {
    font-weight: 300;
    font-size: 40px;
    line-height: 47px;
    text-align: center;
    color: #FFFFFF;
    margin: 0;
}
.freeSign .freeSignBox span  {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.freeSign .freeSignBox span a  {
    display: inline-block;
    font-weight: 500;
    font-size: 22px;
    line-height: 33px;
    letter-spacing: 0.02em;
    background: #FF8A00;
    border-radius: 50px;
    color: #FFFFFF;
    text-decoration: none;
    padding: 16px 59px;
    transition: all ease-in-out 300ms;
}
.freeSign .freeSignBox span a:hover  {
    background: #FEC33E;
    color: #FFFFFF;
}
  @media (max-width: 991px){
.freeSign .freeSignBox{ padding: 65px 85px !important;}
  }

@media (max-width: 740px){
 .freeSign .freeSignBox {
  padding: 25px 0 40px !important;
}
.freeSign .freeSignBox h2{
  font-weight: 300;
  font-size: 22px;
  line-height: 30px;}

.freeSign .freeSignBox span a{
  display: inline-block;
  font-weight: 500;
  font-size: 19px;
  line-height: 26px;
 border-radius: 50px;
  color: #FFFFFF;
  text-decoration: none;
  padding: 16px 19px;}
.freeSign .freeSignBox span{ width:195px}

.news .neWSbox .newsInput .nInput{padding-left: 12px;}
.news .neWSbox .newsInput .nBtn{width: 127.28px;}

.news .neWSbox h2{
  font-weight: 500;
  font-size: 24px !important;
  line-height: 32px;}
  .news .neWSbox .newsInput .nInput{ padding-left: 10px; font-size: 15px;}
  .news .neWSbox .newsInput .nBtn {
  font-weight: 600;
  font-size: 14px;
  line-height: 27px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #FFFFFF;
  width: 86.28px;
  height: 41.17px;
  position: absolute;
  right: 9px;
  top: 14px;
  background: #0165FE;
  border-radius: 10px;
  border: none;
  outline: none;
}
.freeSign .freeSignBox h2{
  font-weight: 300;
  font-size: 16px;
  line-height: 22px;
}
}
/* free sign section end here */
</style>