<template>
    <div class="col-xl-6 col-lg-12" v-if="istopcardload">
           <div class="tbl-head">
            <p green >Newest App </p>
            <h6><router-link :to="'/NewestApp/'">Newest App added to the app store</router-link></h6>
          </div>
          <div class="resp-table">
            <table class="purpl-table">
              <thead>
                <tr>
                  <th>Newest</th>
                  <th>App Logo</th>
                  <th>App Name</th>
                  <th>Data Added</th>
                </tr>
              </thead>
              <tbody v-if="lastadded10apps.length>0">
                <tr v-bind:key="result.app_id" v-for="(result,index) in lastadded10apps.slice(0,5)">
                  <td>{{index+1}}</td>
                  <td><router-link :to="'/about-details/' + result.app_id"><img :src=result.app_logo></router-link></td>
                  <td><router-link :to="'/about-details/' + result.app_id">{{result.app_title}}</router-link></td>
                  <td>{{ moment(result.app_published_date).fromNow()}}</td>
                </tr>
               
              </tbody>

              <tbody v-else>
                <tr><td colspan="5">Sorry! no data found.</td></tr>
              </tbody>
            </table>
          </div>
         
        </div>
</template>

<script>
import {mapState} from 'vuex'
import moment from "moment";
export default {
  name: 'StoreTable',
  props: {
    msg: String
  },
  data() {
    return {
        istopcardload:false,
        moment: moment,
    };
  },
 // lastadded10apps: state => state.AppListModule.lastadded10app,
   computed: {
      ...mapState({
          lastadded10apps: state => state.AppListModule.dashboardlatestaps,
      }),
  },
   mounted() { 
     this.istopcardload=true; 
  },

   methods:{
     int(){          
      this.istopcardload=true;     
    }    
  }, 
  watch: {
        istopcardload(newval,oldval) {
        this.int();      
       },
  }  
}
</script>