<template>
 <div class="ful-wdth">
   <div class="section__head">
        <h4>Developer Team "{{developerdetails.dev_support_email}}"</h4>
      </div>

      <div class="team-name-sect"  v-if="developerapplist.length>0 && isloaded" >
        <div class="row gy-4" data-aos="fade-up" data-aos-duration="1500">
          <div class="col-xl-4 col-sm-6" v-bind:key="result.dev_id" v-for="result in developerapplist">
            <div class="item">
              <router-link :to="'/about-details/' + result.rowdata.app_id"><img :src=result.rowdata.app_logo alt=""></router-link>
              <article>
                <h4><router-link :to="'/about-details/' + result.rowdata.app_id"> {{result.rowdata.app_title}}</router-link></h4>
                <p><span>by</span> {{developerdetails.dev_name}}</p>
                <p>{{result.rowdata.app_brief_description}}</p>
                <p class="pb-2"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
                  <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
                </svg>{{result.rowdata.app_rating}} ({{result.rowdata.app_num_of_reviews}})</p>
                <a>{{result.planetitle}} </a>
              </article>
            </div>
          </div>
         
        </div>
      </div>


      <nav aria-label="navigation-cuts">
        <pagination 
        v-model="page" 
        :records="totaldeveloper" 
        :per-page="15" 
        :options={chunk:5} 
        @paginate="nextprevious($event)"
        />  
      </nav>


 </div>
</template> 

<script>
import moment from "moment";
import { useRoute } from 'vue-router'
import { mapState,mapActions } from 'vuex';

   export default {
     name : 'DevelopersTeamDirectoryname',
      data() {      
      return {
            page: 1,
            params: {
                  page: 1,
                  per_page: 24,
                  order: 'desc',
                  devloperid:''
              }, 
             startindex:1, 
             istopcardload:1,  
             isloaded:false
         };
     }, 
     components: {
               
            },
       
     mounted(){
         const route=useRoute();
         this.params.devloperid=route.params.devloperid;

          if(route.query.page){
          if(parseInt(route.query.page)>1){
              this.page=route.query.page;
              this.params.page=route.query.page;
              }else{
                this.page=1;
                this.params.page=1;
                this.GetAppListByDeveloperId(this.params)
              }
          }else{
              this.GetAppListByDeveloperId(this.params)
          }
         //this.GetAppListByDeveloperId(this.params);
         this.Getdeveloperdetails(this.params);

        },
      computed:{
        ...mapState({
            user: (state) => state.authModule.user,
            developerapplist: (state) => state.AppListModule.developerapplist,
            totaldeveloper: (state) => state.AppListModule.totaldeveloperapp,
            developerdetails: (state) => state.AppListModule.developerdetails,
        }),
         
    },

  methods:{
        int(){          
          this.istopcardload=true;     
        }, 
        
        nextprevious($data){ 
            this.$router.replace({ name: "DevelopersTeamName", query: {page: $data} })
            const vm = this; 
            vm.params.page=$data;
            vm.startindex=(($data-1)*24)+1;
            this.GetAppListByDeveloperId(this.params);
        },
        ...mapActions({         
            GetAppListByDeveloperId: 'AppListModule/GetAppListByDeveloperId', 
            Getdeveloperdetails: 'AppListModule/Getdeveloperdetails', 
        }),
     },
  watch: {
         developerapplist(newval,oldval) { 
         this.isloaded=true;
       },  
     }
   }  
</script>





<style scoped>
 .team-name-sect .item article a {
  font-weight: 400;
  font-size: 15px;
  color: #1BF197;
  text-decoration: none;
  margin-top: 0;
  display: block;
  background: none;
border: none;
padding-left: 0;
}
.team-name-sect .item article > :nth-child(4) {
  font-size: 15px;
  color: #FFFFFF;
}
.team-name-sect .item article > :nth-child(4) svg {
  color: #FEC33E;
  fill: #FEC33E;
  width: 14px;
  height: 14px;
  position: relative;
  top: -2px;
  margin-right: 3px;
}
.team-name-sect .item {
  background: #303040b3;
  border: 0.5px solid #74747429;
  border-radius: 3px;
  display: flex;
  padding: 30px;
  align-items: flex-start;
  gap: 30px;
  height: 100%;
-webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  position: relative;
}

.team-name-sect .item::after {
  content: "";
  border-radius: 5px;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  opacity: 0;
  -webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.light-theme-np .team-name-sect .item {
  background-color: #f6f6f6;
}

.team-name-sect .item:hover { -webkit-transform: scale(1.05, 1.05);
  transform: scale(1.05, 1.05);}

.team-name-sect .item:hover::after {
    opacity: 1;
}

.team-name-sect{ height: auto; margin-bottom: 40px;}

.light-theme-np .team-name-sect .item article > :nth-child(4){color: #212529;}
  @media (max-width: 1440px){
.team-name-sect .item article > :nth-child(2), .team-name-sect .item article > :nth-child(3), .team-name-sect .item article > :nth-child(4), .team-name-sect .item article a {
  font-size: 13px;

}
.team-name-sect .item article a{ padding: 0;}
.team-name-sect .item {
  padding: 20px;
  gap: 18px;
}

  }
</style>