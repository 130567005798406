<template>
  <div class="ful-wdth">
     <div class="section__head">
        <h4>Developers Team Directory</h4>
      </div>

    <!-- 1 table row section start Here  -->

      <div class="table-section reper-table">
        <div class="row">
          <div class="col-xl-12 col-lg-12 col-12" >
            <div class="resp-table" v-if="developerlist.length>0">
              <table class="purpl-table">
                <thead>
                  <tr>
                   
                    <th>Developer </th>
                    <th># of Apps</th>
                    <th>Avg Rating</th>
                    <th>Total Reviews</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-bind:key="result.dev_id" v-for="result in developerlist">
                   
                    <td><router-link :to="'/developers-team-name/' + result.dev_id" >{{result.dev_name}} <!--dev_name  dev_support_email  dev_id--></router-link></td>
                    <td>{{result.no_of_developer_app}} </td>
                    <td><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
                      <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
                    </svg> {{result.dev_average_rating==null?0:result.dev_average_rating}} </td>
                    <td>{{result.totalreviews}} </td>
                  </tr>
                  

                </tbody>
              </table>
            
            </div>
           
          </div>
        </div>
      </div>

      <nav aria-label="navigation-cuts">
         <pagination 
        v-model="page" 
        :records="totaldevloper" 
        :per-page="24" 
        :options={chunk:5} 
        @paginate="nextprevious($event)"
        />  
      </nav>
  </div>
</template>

<style scoped>
 .main_right-pn .table-section.reper-table table tbody tr > :nth-child(1), .main_right-pn .table-section.reper-table table thead tr > :nth-child(1) {
  text-align: left;
  padding-left: 40px;
}
.main_right-pn .table-section table.purpl-table thead th{ text-transform: inherit;}
</style>

<script>
import { useRoute } from 'vue-router';
import moment from "moment";
import { mapState,mapActions } from 'vuex';

   export default {
     name : 'DevelopersTeamDirectory',
      data() {      
      return {
            page: 1,
            params: {
                  page: 1,
                  per_page: 24,
                  order: 'desc',
              }, 
             startindex:1, 
             istopcardload:1,
         };
     }, 
     components: {
               
            },
       
     mounted(){
              const route = useRoute();
              if(route.query.page){
                    if(parseInt(route.query.page)>1){
                      this.page=route.query.page;
                      this.params.page=route.query.page;
                    }else{
                        this.page=1;
                        this.params.page=1;
                        this.GetAppDeveloperList(this.params)
                    }
              }else{
                this.GetAppDeveloperList(this.params)
              }

            
            // this.isloaded=true;      
        },
      computed:{
        ...mapState({
            user: (state) => state.authModule.user,
            developerlist: (state) => state.AppListModule.developerlist,
            totaldevloper: (state) => state.AppListModule.totaldevloper,
        }),
         
    },

  methods:{
        int(){          
          this.istopcardload=true;     
        }, 
        
        nextprevious($data){ 
            this.$router.replace({ name: "DevelopersTeamDirectory", query: {page: $data} })
            const vm = this; 
            vm.params.page=$data;
            vm.startindex=(($data-1)*24)+1;
            this.GetAppDeveloperList(this.params)
        },
        ...mapActions({         
            GetAppDeveloperList: 'AppListModule/GetAppDeveloperList', 
        }),
     },
  watch: {
         developerlist(newval,oldval) { 
         this.isloaded=true;
       },  
     }
   }  
</script>