<template>
  <div :class="defultcontentclass" id="home" role="tabpanel" aria-labelledby="home-tab">

            <div class="tabeOne">
                <h3>My Profile</h3>

                <div class="tabsOne">
                    <Form @submit="photoupload" id="profilepic_frm" :validation-schema="profilepicvalidation" v-slot="{errors}" >
                    <div class="profile">
                        
                       <img v-if="user.data.avatar_url!=''" :src="user.data.avatar_url" alt=""> 
                        <img v-else src="@/assets/images/profile/Group123.png" alt="">
                        <span>
                            <label for="dfg">
                                <Field   :class="{ 'has-error': errors.profilepic }"  name="profilepic" hidden type="file"  id="dfg"  v-on:change="uploadfile"/>
                                <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="#fff" class="bi bi-camera-fill" viewBox="0 0 16 16">
                                    <path d="M10.5 8.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z"></path>
                                    <path d="M2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 1 3.172 4H2zm.5 2a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1zm9 2.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0z"></path>
                                  </svg>
                                
                            </label>                          
                        </span>
                        <button type="submit" id="imageupload_btn" style="font-size:0px;display:none;" value="dfdsfs"/>
                         
                        
                    </div>
                    <div class="name" v-if="first_name">
                        <h4>{{first_name}} {{last_name}}</h4>
                    </div>

                    <ErrorMessage name="profilepic" class="text-danger" /> 
                     </Form>
                </div>
                <div class="tabsTwo AcctabsPad">
                     <Form @submit="updateprofile" :validation-schema="schema" v-slot="{ errors }" class="profilepicfrom">
                    <div class="tittles">
                        <h4>Profile Information</h4>
                    </div>
                    <div class="FillOption">
                        <div class="col-lg-4 accwidth">
                            <div class="fillOpBox">
                                <label>First name </label>
                                <Field name="first_name" :class="'foInput '+{ 'has-error': errors.first_name }"  type="text"  v-model="first_name" placeholder="First Name"/>
                                <ErrorMessage name="first_name" class="text-danger" /> 
                            </div>
                        </div>
                        <div class="col-lg-4 accwidth">
                            <div class="fillOpBox">
                                <label>Last name</label>
                                <Field  name="last_name" :class="'foInput '+{ 'has-error': errors.last_name }" type="text"  v-model="last_name" placeholder="Last Name"/>
                                 <ErrorMessage name="last_name" class="text-danger" /> 
                            </div>
                        </div>
                        <div class="col-lg-4 accwidth">
                            <div class="fillOpBox">
                                <label>Email</label>
                                <Field name="email" class="foInput" type="text"  v-model="email" readonly placeholder="hello.robartm@gmail.com"/>
                            </div>
                        </div>
                    </div>
                    <div class="fillOpBtn">
                        <input type="submit" value="Save Changes">
                    </div>
                    </Form>
                </div>
                <div class="tabsTwo">
                    <div class="tittles">
                        <h4>Reset Password</h4>
                    </div>
                    <Form @submit="userpasswordchange" :validation-schema="schema2" v-slot="{errors}" >
                    <div class="FillOption">
                        <div class="col-lg-4 accwidth">
                            <div class="fillOpBox">
                                <label>Old Password</label>
                                <Field name="oldpassword" type="password" placeholder="Type Password" :class="'foInput '+{ 'has-error': errors.oldpassword }" />
          <ErrorMessage name="oldpassword" class="text-danger" />
                            </div>
                        </div>
                        <div class="col-lg-4 accwidth">
                            <div class="fillOpBox">
                                <label>New Password</label>
                                <Field name="password" type="password" placeholder="Type Password" :class="'foInput '+{ 'has-error': errors.password }" />
          <ErrorMessage name="password" class="text-danger" />
                            </div>
                        </div>
                        <div class="col-lg-4 accwidth">
                            <div class="fillOpBox">
                                <label>Confirm new password</label>
                                <Field name="cpassword" type="password" placeholder="Type Password" :class="'foInput '+{ 'has-error': errors.cpassword }" />
          <ErrorMessage name="cpassword" class="text-danger" />
                            </div>
                        </div>
                    </div>
                    <div class="fillOpBtn">
                         <Field type="hidden" name="userid" :value="user.data.userid"/>
                         <input type="submit" value="Save Changes" >
                    </div>
                    </Form>
                </div>
            </div>

          </div>
</template>

<script>
import { Field, Form, ErrorMessage } from 'vee-validate'
import * as yup from 'yup'
import {mapState,mapActions} from 'vuex'
export default {
     props:['defultcontentclass'],
     name: 'PageHeader',
      data() {
            const schema = yup.object({
                first_name: yup.string().trim().required().label('Please Enter your First Name'),
                last_name: yup.string().trim().required().label('Please Enter your Last Name'),
            });

            const profilepicvalidation = yup.object().shape({
                profilepic: yup.mixed().required('File is required').test("type", "Only the following formats are accepted: .jpeg, .jpg, .bmp, .png and .tif", 
                (value) => {
                        return value && (
                            value[0].type === "image/jpeg" ||
                            value[0].type === "image/bmp" ||
                            value[0].type === "image/png" ||
                            value[0].type === 'application/gif' ||
                            value[0].type === "application/tif"
                        );
                    }),
               });

            const schema2 = yup.object({
                oldpassword: yup.string().required().label('Please Enter your old password'),
                password: yup.string().required().matches(
                  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
                  "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
                ),
                cpassword: yup.string().required('Confirm password is a required field').oneOf([yup.ref('password'), null], 'Passwords must match')
            });

           /* const profilepicvalidation = yup.object({
                profilepic: yup.mixed().required('Please upload your profile pic'),
            });*/

            return {
                schema,
                profilepicvalidation,
                schema2,
                first_name:'',
                last_name:'',
                email:'',
            };
           
        },
    components: {
        Field,
        Form,
        ErrorMessage,
    },
    computed: {
        ...mapState({
             user: state => state.authModule.user,
        }),
    },

    mounted() { 
       this.first_name=this.user.data.first_name;  
       this.last_name=this.user.data.last_name; 
       this.email=this.user.data.email; 

       //console.log('userdata',this.user)
    },
    methods:{

            ...mapActions({
                UpdateuserProfile: 'authModule/UpdateuserProfile',
                UpLoadProfilePic: 'authModule/UpdateProfilePic',
                PasswordChange: 'authModule/PasswordChange',
            }),
            updateprofile (params, { setFieldError }) {
              const vm = this;
              params.setFieldError = setFieldError;
              vm.UpdateuserProfile(params);

            },

            photoupload (params, { setFieldError }) {
             params.setFieldError=setFieldError;

             let formData = new FormData();
             formData.append('profilepic', params.profilepic[0]);
             formData.append('userid', this.user.data.userid); 
             this.UpLoadProfilePic(formData);
            },
          
            uploadfile(event){
                // let formData = new FormData();
                //  formData.append('profilepic', event.target.files[0]);
                //   formData.append('userid', this.user.data.userid); 
                //  this.UpLoadProfilePic(formData);
                document.getElementById('imageupload_btn').click();
            },
            userpasswordchange (params, { setFieldError }) {
              const vm = this;
              params.setFieldError = setFieldError;
              vm.PasswordChange(params);
            },
    },  
    watch: {
        user(){
            //console.log('profileimage',this.user);
            if(this.user.data)
            {
                this.first_name=this.user.data.first_name;  
                this.last_name=this.user.data.last_name; 
                this.email=this.user.data.email;  
            }
        }
    }
}
</script>

<style scoped>
.accManageProManageBox .accManageProManageContent  {
    padding-top: 22px !important;
}
.accManageProManageBox .accManageProManageContent .tabeOne  {
    background: rgba(48, 48, 64, 0.65);
    border: 0.5px solid rgba(116, 116, 116, 0.16);
    border-radius: 3px;
    padding: 10px 48px 101px 48px;
}
.accManageProManageBox .accManageProManageContent .tabeOne h3  {
    font-weight: 500;
    font-size: 34px;
    line-height: 108px;
    text-transform: uppercase;
    color: #FFFFFF;
}
.accManageProManageBox .accManageProManageContent .tabeOne .tabsOne  {
    background: #323243;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 28px 44px;
}
.accManageProManageBox .accManageProManageContent .tabeOne .tabsOne .profile  {
    position: relative;
    width: 73px;
    height: 73px;
    margin-right: 28px;
}
.accManageProManageBox .accManageProManageContent .tabeOne .tabsOne .profile img  {
    width: 100%;
    border-radius: 50px;
    display: block;
    position: relative;
}
.accManageProManageBox .accManageProManageContent .tabeOne .tabsOne .profile span {
    position: absolute;
    right: 0;
    bottom: 0;
    background: black;
    border-radius: 50px;
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.accManageProManageBox .accManageProManageContent .tabeOne .tabsOne .profile span label   {
    width: 25px;
    height: 25px;
    text-align: center;
    cursor: pointer;
}
.accManageProManageBox .accManageProManageContent .tabeOne .tabsOne .name h4  {
    font-family: 'Rubik' !important;
    font-weight: 400 !important;
    font-size: 21px !important;
    line-height: 25px !important;
    color: #FFFFFF !important;
}
.accManageProManageBox .accManageProManageContent .tabeOne .AcctabsPad  {
    margin: 37px 0;
}
.accManageProManageBox .accManageProManageContent .tabsTwo  {
    background: rgba(48, 48, 64, 0.65);
    border: 0.5px solid rgba(116, 116, 116, 0.16);
    border-radius: 3px;
    padding: 32px 48px 43px 48px;
}
.accManageProManageBox .accManageProManageContent .tabsTwo .tittles  {
    font-weight: 300;
    font-size: 32px;
    line-height: 20px;
    text-transform: capitalize;
    color: #FFFFFF;
}
.accManageProManageBox .accManageProManageContent .tabsTwo .FillOption  {
    padding: 16px 0 32px 0;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;

}
.accManageProManageBox .accManageProManageContent .tabsTwo .FillOption .fillOpBox  {
    margin-right: 18px;
}
.accManageProManageBox .accManageProManageContent .tabsTwo .FillOption label  {
    display: block;
    font-weight: 400;
    font-size: 17px;
    line-height: 16px;
    color: #FFFFFF;
    padding-bottom: 16px;
}
.accManageProManageBox .accManageProManageContent .tabsTwo .FillOption .foInput  {
    width: 100%;
    height: 49.48px;
    background: #404053;
    border: 1px solid #67677C;
    border-radius: 4px;
    outline: none;
    padding-left: 16px;
    font-family: 'Rubik';
    font-weight: 400;
    font-size: 17px;
    line-height: 35px;
    letter-spacing: 0.02em;
    color: #ffffffb8;
}
.accManageProManageBox .accManageProManageContent .tabsTwo .fillOpBtn  {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
}
.accManageProManageBox .accManageProManageContent .tabsTwo .fillOpBtn a,.accManageProManageBox .accManageProManageContent .tabsTwo .fillOpBtn input[type="submit"]  {
    text-decoration: none;
    font-weight: 600;
    font-size: 17px;
    line-height: 26px;
    text-align: center;
    text-transform: uppercase;
    color: #FFFFFF;
    width: 186.98px;
    height: 49.48px;
    background: #00C1EC;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
}

#profilepic_frm {
  display: flex;
  flex-wrap: wrap;
}

.name {
  justify-content: center;
  display: flex;
  align-items: center;
}
.accManageProManageBox .accManageProManageContent .tabsTwo .fillOpBtn input[type="submit"]{ border: none;}

</style>