<template>
 <div class="ful-wdth">
   <div class="tab_boxe">
        <div class="adsKeyDetaildsRe">
            <div class="tittle">
                <h2>keyword: Timer Countdown</h2>
                <p>Below are the search ads that appear for this keyword.</p>
            </div>
            <div class="btn">
                <a href="#">Download CSV</a>
            </div>
        </div>

        <div class="tab_Two_Box table-section adsReportTableCenter">
            <div class="resp-table scrl-br">
              <table class="skygren-table">
                <thead class="skyBackground">
                  <tr>
                    <th>Date M-D-Y</th>
                    <th>Ad Position</th>
                    <th>Logo</th>
                    <th>App Name</th>
                    <th>Description</th>
                    <th>Developer</th>
                    <th>App Ranking</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>5/11/2022 12.20</td>
                    <td>1</td>
                    <td>
                      <img src="@/assets/images/table-icon/tabl-1.png">
                    </td>
                    <td>Advance Bulk Price Rounder</td>
                    <td>Create Rounded Prices for Your Products in One Click</td>
                    <td>Onlinestorebiz</td>
                    <td>23</td>
                  </tr>

                  <tr>
                    <td>4/30/2022 14.36</td>
                    <td>2</td>
                    <td>
                      <img src="@/assets/images/table-icon/tabl-2.png">
                    </td>
                    <td>Rewind Backups</td>
                    <td>Automated Store Backups. Easily Undo, Recover, and Restore.</td>
                    <td>Rewind</td>
                    <td>34</td>
                  </tr>

                  <tr>
                    <td>4/24/2022 16.18</td>
                    <td>3</td>
                    <td>
                      <img src="@/assets/images/table-icon/tabl-3.png">
                    </td>
                    <td>Shuttle Sync with Etsy</td>
                    <td>Effortless sync of products, inventories, orders & reviews</td>
                    <td>omniWP</td>
                    <td>36</td>
                  </tr>

                  <tr>
                    <td>4/18/2022 16.36</td>
                    <td>4</td>
                    <td>
                      <img src="@/assets/images/table-icon/tabl-4.png">
                    </td>
                    <td>Kopy Copy products</td>
                    <td>Copy and import products from any store in 1 click</td>
                    <td>Versus Labs</td>
                    <td>42</td>
                  </tr>

                  <tr>
                    <td>4/10/2022 16.55</td>
                    <td>5</td>
                    <td>
                      <img src="@/assets/images/table-icon/tabl-5.png">
                    </td>
                    <td>HubRise</td>
                    <td>Receive orders in 3rd party EPOS solutions</td>
                    <td>HubRise</td>
                    <td>56</td>
                  </tr>

                  <tr>
                    <td>3/33/2022 17.32</td>
                    <td>6</td>
                    <td>
                      <img src="@/assets/images/table-icon/tabl-6.png">
                    </td>
                    <td>Chargeflow Dispute Chargebacks</td>
                    <td>Automate Chargebacks, Fight Fraud Prevention, Order Protection</td>
                    <td>Chargeflow, Inc.</td>
                    <td>72</td>
                  </tr>

                  <tr>
                    <td>3/20/2022 17.54</td>
                    <td>7</td>
                    <td>
                      <img src="@/assets/images/table-icon/tabl-7.png">
                    </td>
                    <td>Foridev ‑ Quick Shopping List</td>
                    <td>How to bundle products, Chained Products, Grouped Products</td>
                    <td>Pluginappstore</td>
                    <td>96</td>
                  </tr>

                  <tr>
                    <td>3/18/2022 18.02</td>
                    <td>8</td>
                    <td>
                      <img src="@/assets/images/table-icon/tabl-8.png">
                    </td>
                    <td>Filter by Price & Bulk Edit</td>
                    <td>Simply filter products by price and bulk edit</td>
                    <td>Techxt</td>
                    <td>102</td>
                  </tr>

                  <tr>
                    <td>3/5/2022 18.20</td>
                    <td>9</td>
                    <td>
                      <img src="@/assets/images/table-icon/tabl-9.png">
                    </td>
                    <td>DraftMate</td>
                    <td>Automate resending draft orders and increase sales.</td>
                    <td>Morrison </td>
                    <td>114</td>
                  </tr>

                  <tr>
                    <td>3/3/2022 18.34</td>
                    <td>10</td>
                    <td>
                      <img src="@/assets/images/table-icon/tabl-10.png">
                    </td>
                    <td>Postmen: Easy Shipping Labels</td>
                    <td>Customer Service CRM: WhatsApp, IG, FB, Email, Live Chat</td>
                    <td>HubRise</td>
                    <td>125</td>
                  </tr>

                  <tr>
                    <td>2/20/2022 18.45</td>
                    <td>11</td>
                    <td>
                      <img src="@/assets/images/table-icon/tabl-11.png">
                    </td>
                    <td>Thank You Email Marketing Tool</td>
                    <td>Auto Thankyou email, Email marketing, Cart Recovery,Newsletter</td>
                    <td>Autoketing</td>
                    <td>126</td>
                  </tr>

                  <tr>
                    <td>3/20/2022 17.54</td>
                    <td>12</td>
                    <td>
                      <img src="@/assets/images/table-icon/tabl-7.png">
                    </td>
                    <td>Novo</td>
                    <td>Powerfully Simple Business Banking</td>
                    <td>Novo</td>
                    <td>137</td>
                  </tr>

                  <tr>
                    <td>2/3/2022 20.20</td>
                    <td>13</td>
                    <td>
                      <img src="@/assets/images/table-icon/tabl-13.png">
                    </td>
                    <td>Segments Analytics</td>
                    <td>Increase repeat revenue with prebuilt segmentation & insights</td>
                    <td>Tresl Inc.</td>
                    <td>138</td>
                  </tr>

                  <tr>
                    <td>1/15/2022 20.31</td>
                    <td>14</td>
                    <td>
                      <img src="@/assets/images/table-icon/tabl-14.png">
                    </td>
                    <td>Metorik: Reports & Emails</td>
                    <td>Your co-pilot for reporting, segmenting & email automation</td>
                    <td>Metorik... </td>
                    <td>142</td>
                  </tr>

                  <tr>
                    <td>1/10/2022 20.38</td>
                    <td>15</td>
                    <td>
                      <img src="@/assets/images/table-icon/tabl-15.png">
                    </td>
                    <td>Backups</td>
                    <td>Automated Backups. Easily Undo, Recover, Rewind, and Restore.</td>
                    <td>BackupMaster.io</td>
                    <td>156</td>
                  </tr>

                  <tr>
                    <td>1/10/2022 22.31</td>
                    <td>16</td>
                    <td>
                      <img src="@/assets/images/table-icon/tabl-16.png">
                    </td>
                    <td>Give & Grow Donations</td>
                    <td>Donate in minutes to 2MM+ nonprofits, increasing LTV & AOV</td>
                    <td>Pledge</td>
                    <td>164</td>
                  </tr>

                  <tr>
                    <td>1/5/2022 22.43</td>
                    <td>17</td>
                    <td>
                      <img src="@/assets/images/table-icon/tabl-1.png">
                    </td>
                    <td>Replaceit ‑ Bulk edit products</td>
                    <td>Easily replace words in your product descriptions.</td>
                    <td>uznet</td>
                    <td>176</td>
                  </tr>

                  <tr>
                    <td>1/4/2022 22.56</td>
                    <td>18</td>
                    <td>
                      <img src="@/assets/images/table-icon/tabl-3.png">
                    </td>
                    <td>Parabola: Workflow Automation</td>
                    <td>Automate complex store processes, save hours every day</td>
                    <td>Parabola</td>
                    <td>184</td>
                  </tr>

                  <tr>
                    <td>1/2/2022 23.36</td>
                    <td>19</td>
                    <td>
                      <img src="@/assets/images/table-icon/tabl-4.png">
                    </td>
                    <td>ME SMS Abandoned Cart Recovery</td>
                    <td>SMS Automation Text Marketing Recover Cart Abandoned Checkout</td>
                    <td>Transcy</td>
                    <td>192</td>
                  </tr>

                </tbody>
              </table>
            </div>
          </div>
    
          <div class="app_Store_topPadding">
            <nav aria-label="navigation-cuts">
              <ul class="pagination">
                <li class="page-item"><a class="active page-link" href="#">1</a></li>
                <li class="page-item"><a class="page-link" href="#">2</a></li>
                <li class="page-item"><a class="page-link" href="#">3</a></li>
                <li class="page-item"><a class="page-link" href="#">3</a></li>
                <li class="page-item"><a class="page-link" href="#">Next</a></li>
              </ul>
            </nav>
          </div>


      </div>
 </div>
</template>

<style scoped>

.scrl-br{ overflow: auto;}

.tab_boxe .adsKeyDetaildsRe {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
}

.tab_boxe .adsKeyDetaildsRe .tittle h2 {
  font-weight: 300;
  font-size: 40px !important;
  line-height: 60px;
  text-transform: capitalize;
  color: #FFFFFF;
  padding-bottom: 10px;
}
.tab_boxe .adsKeyDetaildsRe .tittle p {
  font-family: 'Rubik';
  font-weight: 300;
  font-size: 28px;
  line-height: 33px;
  letter-spacing: 0.01em;
  color: #FFFFFF;
  margin-bottom: 0px;
}
.tab_boxe .adsKeyDetaildsRe .btn a {
  text-decoration: none;
  background: #685DC9;
  border-radius: 4px;
  font-weight: 600;
  font-size: 17px;
  line-height: 26px;
  text-align: center;
  text-transform: uppercase;
  color: #FFFFFF;
  text-shadow: 0px 0.5px 1px rgba(0, 0, 0, 0.25);
  padding: 13px 30px;
}

 .adsKeypFont .tab-content article p  {
    font-size: 21px !important;
    line-height: 29px !important;
}
.adskeyWidth  {
    max-width: 629px;
    width: 100%;
}
.adskeyWidth .app_InputBoxSub  {
    max-width: 476px;
    width: 100%;
}
.adskeyWidth .app_InputBoxBtn  {
    max-width: 143px;
}
.adsKeyWordsp p  {
    font-family: 'Rubik';
    font-weight: 300;
    font-size: 19px;
    line-height: 25px;
    color: #EDEDED;
    padding-top: 82px;
}
.adsKeyTableTopP  {
    padding-top: 0px;
}
.adsByKlogoBtn ul  {
    padding: 34px 0 15px 0 !important;
}
.adsKeyTableTopP table tbody tr > :nth-child(3)  {
    text-align: right !important;
}
.adsKeyFeedBack {
    position: fixed;
    top: 48%;
    right: -50px;
    transform: rotate(-90deg);
}
.adsKeyFeedBack a {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #FFFFFF;
    background: #ED553B;
    border-radius: 6px;
    padding: 17px 24px;
    text-decoration: none;
}
.adsKeypFont .tab-content article p {
  font-size: 21px !important;
  line-height: 29px !important;
}
.app_InputBox .app_InputBoxBtn .app_In_Btn {
  background: #00C1EC;
  border-radius: 4px;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  text-transform: uppercase;
  color: #FFFFFF;
  text-shadow: 0px 0.5px 1px rgba(0, 0, 0, 0.25);
  padding: 8px 43px;
  border: none;
}


.app_InputBox .app_InputBoxSub {
  margin-right: 10px;
  width: 100%;
  max-width: 341px;
}
.adskeyWidth .app_InputBoxSub {
  max-width: 476px;
  width: 100%;
}
.adskeyWidth {
  max-width: 629px;
  width: 100%;
}
.adsKeyWordsp p {
  font-family: 'Rubik';
  font-weight: 300;
  font-size: 19px;
  line-height: 25px;
  color: #EDEDED;
  padding-top: 82px;
}
.main_right-pn .changeLogosBtn ul .changeLogosyellowBtn {
  background: #FF7301;
  border-radius: 4px;
  padding: 7px 33px;
}
.main_right-pn .changeLogosBtn ul a {
  text-decoration: none;
  font-weight: 500;
  font-size: 19px;
  line-height: 28px;
  text-align: center;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  color: #FFFFFF;
}
.main_right-pn .changeLogosBtn ul {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;}
  .adsByKlogoBtn ul {
  padding: 34px 0 15px 0 !important;
}
.main_right-pn .tab_Two_Box .borderOff tbody .borderBtn a {
  text-decoration: none;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #FFFFFF;
  background: #4BCCC0;
  border-radius: 4px;
  padding: 7px 30px;
}
.main_right-pn .tab_Two_Box .borderOff tbody td {
  border-left: none !important;
}
.main_right-pn .table-section table tbody td, .tab_Two_Box .skygren-table tbody td{ text-align: left;}
.app_InputBoxSub input[type="text"] { width: 100%; border: 1px solid #5D5D76;border-radius: 4px; background: #323243;height: 43px; line-height: 43px; color: #EDEDED; text-indent: 10px;}
.main_right-pn .table-section table tbody td, .tab_Two_Box .skygren-table tbody td {
 
  padding: 12px;}
  .light-theme-np .main_right-pn .tab_boxe .tab-content article h4, .light-theme-np .main_right-pn .tab_boxe .tab-content article p, .light-theme-np .adsActiveandScanUlP p, .light-theme-np .adsKeyWordsp p{color: #212529 !important;}
.light-theme-np .app_InputBox .app_InputBoxSub input[type="text"]{background: #f2f4f2e6;
border: 1px solid #e7e7e757;
color: rgba(48, 48, 64, 0.73);}


.light-theme-np .tab_boxe .adsKeyDetaildsRe .tittle h2, .light-theme-np .tab_boxe .adsKeyDetaildsRe .tittle p{ color: #000;}

@media (max-width:767px){
.adsKeypFont .tab-content article p  {
    font-size: 16px !important;
    line-height: 23px !important;
}
.tab_boxe .adsKeyDetaildsRe{ display: block !important;}
.app_InputBox .app_InputBoxBtn .app_In_Btn{
  background: #00C1EC;
  border-radius: 4px;
  font-weight: 600;
  font-size: 15px;
  line-height: 27px;
  text-align: center;
  text-transform: uppercase;
  color: #FFFFFF;
  text-shadow: 0px 0.5px 1px rgba(0, 0, 0, 0.25);
  padding: 8px 17px;}
  .adsKeyWordsp p{ padding-top: 20px;}
  .tab_boxe .adsKeyDetaildsRe .tittle h2 { font-size:30px !important; line-height: 40px !important;}
  .tab_boxe .adsKeyDetaildsRe .tittle p{ font-size:25px !important; line-height: 30px;}
  .adsKeyDetaildsRe .btn{ display: table !important; margin: 0 auto;}
  .tab_boxe .adsKeyDetaildsRe .tittle{ padding-bottom: 20px;}
}
@media (max-width:767px){
  .adsByKlogoBtn ul[data-v-e95eb1ca]{ padding: 0 0 15px 0 !important;}
}


</style>