<template>
      <section class="homeBanner">
        <div class="homeShape">
            <img src="@/assets/images/homePage-1/bannerShape.png" alt="">
        </div>
        <div class="homeBanLeft">
        </div>
        <div class="homeBanRight">
        </div>
        <div class="container">
            <div class="row">
                <div class="homeBanText" data-aos="fade-up" data-aos-duration="1500">
                    <h1>Actionable Intelligence</h1>
                    <h2>For the Shopify App Store</h2>
                    <p>We track Shopify apps globally & identify insights for your research, marketing, and sales needs.</p>
                   <router-link v-if="!user.data" to="/sign-up">Free Sign Up<span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="#fff" class="bi bi-arrow-right-circle-fill" viewBox="0 0 16 16">
                            <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z"/>
                          </svg>
                    </span></router-link>
                </div>
            </div>
        </div>
    </section>

    <section class="homeBanImage">
        <div class="container">
            <div class="row">
                <div class="homeBanImg" data-aos="fade-up" data-aos-duration="1000">
                    <img src="@/assets/images/homePage-1/banner.png" alt="">
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import {mapState} from 'vuex'
export default {
  name: 'PageBanner',
  props: ['toggle','islogin'],

  data() {
    return {
      todoLisloginflug : this.islogin,
      pageload:false,
    };
  },

  computed: {
      ...mapState({
          user: state => state.authModule.user,
      }),
  },
}
</script>

<style scope>
/* banner section start here */
.homeBanner  {
    background: #191927;
    width: 100%;
    position: relative;
    min-height: 1074px;
}
.homeBanner .homeShape  {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
}
.homeBanner .homeShape img  {
    width: 100%;
    display: block;
    max-width: 100%;
}
.homeBanner .homeBanText  {
    position: relative;
    text-align: center;
    color: #FFFFFF;
    padding-top: 224px;
}
.homeBanner .homeBanText h1  {
    font-weight: 500;
    font-size: 81px;
    line-height: 80px;
    letter-spacing: -0.03em;
    color: #FFFFFF;
    text-shadow: 0px 4px 4px #000000;
}
.homeBanner .homeBanText h2  {
    font-weight: 275;
    font-size: 40px;
    line-height: 60px;
    text-transform: capitalize;
    color: #FFFFFF;
}
.homeBanner .homeBanText p  {
    max-width: 689px;
    margin: 0 auto;
    font-weight: 300;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    letter-spacing: 0.02em;
    color: #FFFFFF;
    padding-bottom: 36px;
}
.homeBanner .homeBanText a  {
    text-decoration: none;
    display: inline-block;
    font-weight: 500;
    font-size: 19px;
    line-height: 28px;
    letter-spacing: 0.02em;
    color: #FFFFFF;
    background: linear-gradient(270deg, #3798FF 0%, #007BFF 102.52%);
    border-radius: 50px;
    padding: 13.6px 34px;
    transition: all ease-in-out 300ms;
}
.homeBanner .homeBanText a:hover  {
    background: #FF8A00;
}
.homeBanner .homeBanText a span  {
    padding-left: 36px;
}
.homeBanImage  {
    position: relative;
    z-index: 1;
}
.homeBanImage .homeBanImg img  {
    display: block;
    margin: -430px auto 0 auto;
    box-shadow: 0px 4px 57px rgba(0, 0, 0, 0.15);
    max-width: 100%;
}
.homeBanLeft img,
.homeBanRight img  {
    display: block;
}
.homeBanLeft {
    position: absolute;
    top: 310px;
    left: 110px;
    content: "";
    background: url(@/assets/images/homePage-1/bannerLeft.png);
    width: 460px;
    height: 547px;
    background-repeat: no-repeat;
}
.homeBanRight  {
    position: absolute;
    top: 264px;
    right: 56px;
    content: "";
    background: url(@/assets/images/homePage-1/bannerRight.png);
    width: 287px;
    height: 986px;
    background-repeat: no-repeat;
}

@media (max-width: 1400px){

.homeBanLeft{ left: 0;}
.homeBanRight{ right: 0;}

}

  @media (max-width: 991px){
.homeBanLeft, .homeBanRight{ display:none;}
.homeBanner .homeBanText h1{ font-size: 60px;}
.homeBanner .homeBanText{  padding-top: 150px;}
.homeBanner{ min-height:991px;}
.homeBanImage .homeBanImg img {margin: -467px auto 0 auto;}
  }

@media (max-width: 740px){
.homeBanner .homeBanText h1 {
  font-size: 26px;
  line-height: 30px;
  padding-bottom: 0;
}
.homeBanner .homeBanText h2 {
  font-size: 27px;
  line-height: 32px;
  padding-bottom: 20px;
  }
  .homeBanImage .homeBanImg img {
  margin: -50px auto 0 auto;
}
.homeBanner {
  min-height: 420px;
}
.homeBanner .homeBanText p{ font-size:17px;}
.homeBanner .homeBanText {
  padding-top: 140px;
}
.homeBanner .homeBanText h2 {
  font-size: 25px;}
}

/* banner section end here */
</style>