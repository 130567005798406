<template>
      <section class="gain">
        <div class="container zindex">
            <div class="row">
                <div class="col-lg-12 gainHead" data-aos="fade-up" data-aos-duration="1000">
                    <h3>Gain Valuable Insights To </h3>
                    <h2>Outsmart Your Competition </h2>
                </div>
                <div class="gainMainBox">
                    <div class="row gainMainBoxFlex">
                        <div class="col-lg-6" data-aos="fade-up" data-aos-duration="1000">
                            <div class="gainBox">
                                <h4>App Ranking</h4>
                                <div class="row">
                                    <div class="col-lg-6">
                                        <div class="gainOption">
                                            <div class="optionImg">
                                                <img src="@/assets/images/homePage-1/gainIcon1.png" alt="">
                                            </div>
                                            <div class="optionText">
                                                <p>The latest app store ranking for each category and sub category</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6" data-aos="fade-up" data-aos-duration="1000">
                                        <div class="gainOption">
                                            <div class="optionImg">
                                                <img src="@/assets/images/homePage-1/gainIcon2.png" alt="">
                                            </div>
                                            <div class="optionText">
                                                <p>Find out the big movers and losers</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6" data-aos="fade-up" data-aos-duration="1000">
                                        <div class="gainOption">
                                            <div class="optionImg">
                                                <img src="@/assets/images/homePage-1/gainIcon3.png" alt="">
                                            </div>
                                            <div class="optionText">
                                                <p>Daily updates on the newest apps hitting the market</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6" data-aos="fade-up" data-aos-duration="1000">
                                        <div class="gainOption">
                                            <div class="optionImg">
                                                <img src="@/assets/images/homePage-1/gainIcon4.png" alt="">
                                            </div>
                                            <div class="optionText">
                                                <p>User reviews and app rating trends</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <router-link v-if="!user.data" to="/sign-up">Learn More</router-link>
                                <router-link v-else to="/categories-page">Learn More</router-link>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="gainImageBox leftArrow">
                                <div class="row gainMainBoxFlex">
                                    <div class="gainNoneImage">
                                        <img src="@/assets/images/homePage-1/gain1.png" alt="">
                                    </div>
                                    <div class="col-lg-6 gainDisNone">
                                        <img class="gainPadd" src="@/assets/images/homePage-1/gainImg1.png" alt="">
                                        <img src="@/assets/images/homePage-1/gainImg2.png" alt="">
                                    </div>
                                    <div class="col-lg-6 gainAfterYellow gainDisNone">
                                        <img src="@/assets/images/homePage-1/gainImg3.png" alt="">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="gainMainBox">
                    <div class="row gainMainBoxFlex flexGainReverse">
                        <div class="col-lg-6">
                            <div class="gainImageBox rightArrow">
                                <div class="row">
                                    <div class="gainNoneImage">
                                        <img src="@/assets/images/homePage-1/gain2.png" alt="">
                                    </div>
                                    <div class="col-lg-3 gainDisNone">
                                        <img class="gaintimg" src="@/assets/images/homePage-1/gainImg4.png" alt="">
                                    </div>
                                    <div class="col-lg-6 gainDisNone">
                                        <img src="@/assets/images/homePage-1/gainImg5.png" alt="">
                                    </div>
                                    <div class="col-lg-3 gainAfterblue gainDisNone">
                                        <img class="gaintbimg" src="@/assets/images/homePage-1/gainImg6.png" alt="">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6" data-aos="fade-up" data-aos-duration="1000">
                            <div class="gainBox">
                                <h4>App Store Intelligence</h4>
                                <div class="row">
                                    <div class="col-lg-6">
                                        <div class="gainOption">
                                            <div class="optionImg">
                                                <img src="@/assets/images/homePage-1/gainIcon5.png" alt="">
                                            </div>
                                            <div class="optionText">
                                                <p>Follow your competitor's app performance</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6" data-aos="fade-up" data-aos-duration="1000">
                                        <div class="gainOption">
                                            <div class="optionImg">
                                                <img src="@/assets/images/homePage-1/gainIcon6.png" alt="">
                                            </div>
                                            <div class="optionText">
                                                <p>Organic keyword ranking</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6" data-aos="fade-up" data-aos-duration="1000">
                                        <div class="gainOption">
                                            <div class="optionImg">
                                                <img src="@/assets/images/homePage-1/gainIcon7.png" alt="">
                                            </div>
                                            <div class="optionText">
                                                <p>Change logs of your competitor's app listing pages</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6" data-aos="fade-up" data-aos-duration="1000">
                                        <div class="gainOption">
                                            <div class="optionImg">
                                                <img src="@/assets/images/homePage-1/gainIcon8.png" alt="">
                                            </div>
                                            <div class="optionText">
                                                <p>Compare pricing tiers</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                               <router-link v-if="!user.data" to="/sign-up">Learn More</router-link>
                                <router-link v-else to="/categories-page">Learn More</router-link>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="gainMainBox gainbp">
                    <div class="row gainMainBoxFlex">
                        <div class="col-lg-6" data-aos="fade-up" data-aos-duration="1000">
                            <div class="gainBox">
                                <h4>Search<br> Ad Store Intelligence</h4>
                                <div class="row">
                                    <div class="col-lg-6">
                                        <div class="gainOption">
                                            <div class="optionImg">
                                                <img src="@/assets/images/homePage-1/gainIcon9.png" alt="">
                                            </div>
                                            <div class="optionText">
                                                <p>Learn who else is bidding on you keywords and track historical ad position</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6" data-aos="fade-up" data-aos-duration="1000">
                                        <div class="gainOption">
                                            <div class="optionImg">
                                                <img src="@/assets/images/homePage-1/gainIcon10.png" alt="">
                                            </div>
                                            <div class="optionText">
                                                <p>Track-specific competitors on important keywords</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6" data-aos="fade-up" data-aos-duration="1000">
                                        <div class="gainOption">
                                            <div class="optionImg">
                                                <img src="@/assets/images/homePage-1/gainIcon11.png" alt="">
                                            </div>
                                            <div class="optionText">
                                                <p>Downloadable search ad data for further analysis</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                               <router-link v-if="!user.data" to="/sign-up">Learn More</router-link>
                                <router-link v-else to="/categories-page">Learn More</router-link>
                            </div>
                        </div>
                        <div class="col-lg-6" data-aos="fade-up" data-aos-duration="1000">
                            <div class="gainImageBox">
                                <div class="row">
                                    <div class="gainNoneImage">
                                        <img src="@/assets/images/homePage-1/gain3.png" alt="">
                                    </div>
                                    <div class="col-lg-3 gainDisNone">
                                        <img class="gaintbimg" src="@/assets/images/homePage-1/gainImg7.png" alt="">
                                    </div>
                                    <div class="col-lg-6 gainAftergreen gainDisNone">
                                        <img src="@/assets/images/homePage-1/gainImg8.png" alt="">
                                    </div>
                                    <div class="col-lg-3 gainDisNone">
                                        <img class="gaintimg" src="@/assets/images/homePage-1/gainImg9.png" alt="">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import {mapState} from 'vuex'
export default {
  name: 'HomeFreeSign',
  data() {
    return {
      
    };
  },
  computed: {
      ...mapState({
          user: state => state.authModule.user,
      }),
  },
}
</script>

<style scoped>
/* gain section start here */
.gain  {
    width: 100%;
    background: url(@/assets/images/homePage-1/allIcon.png) #191927;
    position: relative;
    margin-top: 300px;
    z-index: 0;
}
.gain .zindex  {
    position: relative;
    z-index: 1;
}
.gain::after  {
    position: absolute;
    top: 99%;
    left: 0px;
    content: "";
    background: url(@/assets/images/homePage-1/gainbackgroundBottom.png);
    width: 100%;
    height: 155px;
    background-repeat: repeat;
}
.gain::before  {
    position: absolute;
    top: -1%;
    left: 0px;
    content: "";
    background: url(@/assets/images/homePage-1/gainbackgroundTop.png);
    width: 100%;
    height: 155px;
    background-repeat: repeat;
}
.gain .gainHead  {
    text-align: center;
    padding-top: 60px;
}
.gain .gainHead h3  {
    font-weight: 300;
    font-size: 48px;
    line-height: 78px;
    text-align: center;
    color: #FFFFFF;
    padding-top: 15px;
}
.gain .gainHead h2  {
    font-weight: 600;
    font-size: 74px;
    line-height: 78px;
    text-align: center;
    color: #FFFFFF;
}
.gain .gainMainBox  {
    padding-top: 179px;
}
.gain .gainMainBox .gainMainBoxFlex  {
    align-items: center;
}
.gain .gainBox h4  {
    font-weight: 500;
    font-size: 54px;
    line-height: 55px;
    color: #FFFFFF;
    padding-bottom: 57px;
}
.gain .gainBox .gainOption  {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding-bottom: 42px;
}
.gain .gainBox .gainOption .optionImg  {
    width: 77px;
}
.gain .gainBox .gainOption .optionImg img  {
    display: block;
}
.gain .gainBox .gainOption .optionText  {
    width: calc(100% - 77px);
}
.gain .gainBox .gainOption .optionText p  {
    font-weight: 300;
    font-size: 20px;
    line-height: 29px;
    letter-spacing: 0.02em;
    color: #FFFFFF;
}
.gainBox a  {
    text-decoration: none;
    display: inline-block;
    background: #FEC33E;
    border-radius: 50px;
    font-weight: 400;
    font-size: 17px;
    line-height: 26px;
    letter-spacing: 0.02em;
    color: #000000;
    padding: 10px 35px;
    transition: all ease-in-out 300ms;
}
.gainBox a:hover  {
    background: #FF792E;
    color: #FFFFFF;
}
.gainImageBox .gainMainBoxFlex img  {
    display: block;
    position: relative;
}
.gainImageBox .gainMainBoxFlex .gainPadd  {
    padding-bottom: 30px;
}
.gainbp  {
    padding-bottom: 255px;
}
.gainMainBox .gainMainBoxFlex .gainImageBox  {
    position: relative;
}
.gainMainBox .gainMainBoxFlex .gainImageBox img  {
    width: 100%;
    display: block;
    z-index: 1;
    position: relative;
}
.gainMainBox .gainMainBoxFlex .gainImageBox .gaintimg  {
    padding-top: 90px;
}
.gainMainBox .gainMainBoxFlex .gainImageBox .gaintbimg  {
    padding-top: 310px;
}
.gainImageBox .gainMainBoxFlex .gainAfterYellow,
.gainMainBoxFlex .gainAfterblue,
.gainMainBoxFlex .gainAftergreen  {
    position: relative;
}
.gainImageBox .gainMainBoxFlex .gainAfterYellow::after {
    content: "";
    width: 211px;
    height: 211px;
    top: -82px;
    right: -70px;
    background: #FFAF2E;
    position: absolute;
    border-radius: 100px;
}
.gainMainBoxFlex .gainAfterblue::after {
    content: "";
    width: 211px;
    height: 211px;
    top: 215px;
    left: -88px;
    background: #007BFF;
    position: absolute;
    border-radius: 100px;
}
.gainMainBoxFlex .gainAftergreen::after {
    content: "";
    width: 211px;
    height: 211px;
    top: 215px;
    left: -88px;
    background: #26CBB3;
    position: absolute;
    border-radius: 100px;
}
.gainMainBox .leftArrow,
.gainMainBox .rightArrow  {
    position: relative;
}
.gainMainBox .leftArrow::before {
    position: absolute;
    bottom: -199px;
    left: -205px;
    content: "";
    background: url(@/assets/images/homePage-1/arrow1.png);
    width: 197px;
    height: 274px;
    background-repeat: no-repeat;
}
.gainMainBox .rightArrow::before {
    position: absolute;
    bottom: -226px;
    right: -169px;
    content: "";
    background: url(@/assets/images/homePage-1/arrow2.png);
    width: 215px;
    height: 309px;
    background-repeat: no-repeat;
    z-index: -1;
}
.gainMainBoxFlex .gainNoneImage  {
    display: none;
    padding: 30px 10px 20px 10px;
}
.gainMainBoxFlex .gainNoneImage img  {
    width: 100%;
    display: block;
    margin: 0 auto;
}

  @media (max-width: 991px){
.gainMainBoxFlex .gainNoneImage {
  display: block;
}
.gainDisNone{ display: none;}
  .gainMainBox .leftArrow::before{ left: 0;}
  .gainMainBox .rightArrow::before { right:0}
  }

@media (max-width: 1400px){
  .platfrom .platfromBox {
  padding: 0 20px;
}  
.gainImageBox .gainMainBoxFlex .gainAfterYellow::after{ right: 0;}
.homeBanLeft{ left: 0;}
.homeBanRight{ right: 0;}
.gain .gainHead h2{
  font-weight: 600;
  font-size: 55px;}



}

@media (max-width: 991){
.gain .gainMainBox {
  padding-top: 89px;
}
.gainbp {
  padding-bottom: 145px;
}
.gain .gainMainBox {
  padding-top: 79px;
}

}

@media (max-width: 740px){

.gain .gainHead h3{
  font-weight: 300;
  font-size: 30px;
  line-height: 36px;
  text-align: center;
  color: #FFFFFF;
  padding-top: 15px;
}

.gain .gainHead h2{
  font-weight: 600;
  font-size: 40px;
  line-height:45px;
  padding-top: 20px;
}
.gain .gainMainBox {
  padding-top: 79px;
}

.gain .gainBox h4 {
  font-weight: 500;
  font-size: 28px;
  line-height:40px;}
.gainbp{ padding-bottom: 40px;}

.news .neWSbox h2 {
  font-weight: 500;
  font-size: 36px;
  line-height: 43px;}

  .gain .gainHead{
  text-align: center;
  padding-top: 30px;
}
.gain .gainHead h3{ font-size: 20px; line-height: 24px;}
.gain .gainHead h2{
  font-weight: 600;
  font-size: 23px;
  line-height: 33px;}
.gain .gainMainBox{
  padding-top: 40px;
}

.gain .gainBox .gainOption .optionText p{
  font-weight: 300;
  font-size: 15px;
  line-height: 23px;}
 

}

  

/* gain section end here */
</style>