<template>
 <div class="ful-wdth">
   <div class="tab_boxe categoriesBox" v-if="isload">
        <h2>Categories</h2>

        <div class="tab_Two_Box table-section categoriesTable categoriesBorder">
            <div class="resp-table">
              <table class="skygren-table">
                <thead class="voilet">
                  <tr>
                    <th><router-link :to="'/AppStoreRanking/'">All</router-link></th>
                    <th><router-link :to="'/AppStoreRanking/'">{{totalaps}} apps</router-link></th>
                  </tr>
                </thead>
              </table>
            </div>
        </div>
        <div  v-if="categorywithchildcategory.length>0">
            <div class="tab_Two_Box table-section categoriesTable" v-bind:key="result.category_id" v-for="result in categorywithchildcategory">
              <div class="resp-table">
                <table class="skygren-table">
                  <thead class="voilet">
                    <tr>
                      <th> <router-link :to="'/AppStoreRanking/?catid=' + result.category_id">{{result.cat_name}}</router-link></th>
                      <th><router-link :to="'/AppStoreRanking/?catid=' + result.category_id">{{result.totalaps}} </router-link></th>
                    </tr>
                  </thead>

                  <tbody  v-if="result.subcat.length>0">
                    <tr v-bind:key="result1.app_id" v-for="result1 in result.subcat">
                        <td> <router-link :to="'/AppStoreRanking/?catid=' + result.category_id+'&subcatid='+result1.category_id">{{result1.category_title}}</router-link></td>
                        <td><router-link :to="'/AppStoreRanking/?catid=' + result.category_id+'&subcatid='+result1.category_id">{{result1.category_app_count}}</router-link></td>
                    </tr>
                  </tbody>

                  

                 
                 
                </table>
              </div>
            </div>
        </div>
      
      </div>
 </div>
</template>

<script>
import {mapState,mapActions} from 'vuex'
export default {
  name: 'CategoriesDetails',  
  data() {
    return {
        isload:false
    };
  },
  //topappreviews: (state) => state.AppreviewModule.topappreviews,
  computed: {
    ...mapState({
        totalaps: (state) =>  state.AppListModule.totalapscountbycat,
        categorydetails: (state) =>  state.AppListModule.apscountcategorywise,
        categorywithchildcategory: (state) =>  state.AppListModule.categorywithchildcategory,
    }),
  },

  mounted() {  
      const vm = this;
      vm.categorywithchild();
      this.isload=true;
  },
  methods:{
      ...mapActions({
            categorywithchild: 'AppListModule/CategoryWithChildcategory',
        }),   
  },  
  watch: {
     
  }

}
</script>

<style scoped>
 .categoriesBox h2  {
    font-weight: 300 !important;
    font-size: 49px !important;
    line-height: 74px !important;
    padding-bottom: 36px !important;
}
.categoriesBox .categoriesTable  {
    padding-top: 0px !important;
    padding-bottom: 28px;
}
.categoriesBox .categoriesTable table thead tr > :nth-child(1),
.categoriesBox .categoriesTable table tbody tr > :nth-child(1),
.categoriesBorder table thead tr > :nth-child(1)  {
    text-align: left;
    width: 80%;
}
.categoriesBox .categoriesTable table thead tr > :nth-child(2),
.categoriesBox .categoriesTable table tbody tr > :nth-child(2),
.categoriesBorder table thead tr > :nth-child(2)  {
    text-align: center;
    width: 20%;
}
.main_right-pn .table-section table.skygren-table thead, .tab_Two_Box .skygren-table thead:not(:last-child) {
    border-left: none !important;
    border-right: none !important;
}
.categoriesBorder table thead,
.categoriesBorder table thead tr th  {
    border: none !important;
}
.categoriesBorder .resp-table  {
    border-radius: 5px !important;
}
.categoriesBox .categoriesTable {
  padding-top: 0px !important;
  padding-bottom: 28px;
}

.main_right-pn .tab_boxe h2 {
  font-weight: 300;
  font-size: 40px;
  line-height: 60px;
  text-transform: capitalize;
  color: #FFFFFF;
  padding-bottom: 45px;
  margin: 0;
}
.light-theme-np .main_right-pn .tab_boxe h2{color: #212529;}
 .main_right-pn .table-section table tbody td, .main_right-pn .table-section table.skygren-table thead th{ padding: 18px; font-size: 17px;}
 .voilet th a{  color:#fff; display: block;}
  @media (max-width: 1799px){
.main_right-pn .table-section table thead th {
  padding: 16px 15px;
}
.main_right-pn .table-section {
  margin-bottom: 0px;
}
.main_right-pn .table-section table tbody td, .tab_Two_Box .skygren-table tbody td {
  font-size: 17px;
  padding: 18px;
}
  }

    @media (min-width: 1280px) and (max-width: 1400px){
.main_right-pn .table-section table tbody td, .tab_Two_Box .skygren-table tbody td {
  font-size: 13px;
  padding: 12px;
}
    }
 @media (max-width:767px){
    .categoriesBox h2 {
        font-weight: 300 !important;
        font-size: 25px !important;
        line-height: 28px !important;
        padding-bottom: 16px !important;
      }
 }

  .table-section table tbody td a{ display: block;}

</style>