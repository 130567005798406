<template>
  <div class="tab_boxe">
        <h2>Shopify Stores</h2>
        <div class="app_InputBox d-flex">
          <div class="app_InputBoxSub">
            <label>Market Category</label>
            <select>
              <option>Select</option>
              <option>Select</option>
              <option>Select</option>
              <option>Select</option>
            </select>
          </div>
          <div class="app_InputBoxSub">
            <label>Ranking</label>
            <select>
              <option>Select</option>
              <option>Select</option>
              <option>Select</option>
              <option>Select</option>
            </select>
          </div>
          <div class="app_InputBoxBtn">
            <button type="submit" class="app_In_Btn">filter</button>
          </div>
        </div>

        <div class="tab_Two_Box table-section shopifyStoreTable">
          <div class="resp-table shopifyStoreposition">
           <router-link to="/shopify-store-poro-expan">
            <table class="skygren-table">
              <thead class="voilet">
                <tr>
                  <th>Store Name</th>
                  <th>Website</th>
                  <th>Description</th>
                  <th>#of App</th>
                  <th>Social</th>
                  <th>Alexa Rank</th>
                  <th>Contact Info</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>site.myshopify</td>
                  <td>www.website.com</td>
                  <td>By DelightChat Inbox & Marketing</td>
                  <td>18</td>
                  <td>8</td>
                  <td>215</td>
                  <td>info.globo@gmail.com</td>
                </tr>
                <tr>
                    <td>site.myshopify</td>
                    <td>www.website.com</td>
                    <td>Instagram Feed in Instagram Gallery Style with Grid & Slider</td>
                    <td>18</td>
                    <td>8</td>
                    <td>215</td>
                    <td>info.globo@gmail.com</td>
                </tr>
                <tr>
                    <td>site.myshopify</td>
                    <td>www.website.com</td>
                    <td>By DelightChat Inbox & Marketing</td>
                    <td>18</td>
                    <td>8</td>
                    <td>215</td>
                    <td>info.globo@gmail.com</td>
                  </tr>
                  <tr>
                    <td>site.myshopify</td>
                    <td>www.website.com</td>
                    <td>By DelightChat Inbox & Marketing</td>
                    <td>18</td>
                    <td>8</td>
                    <td>215</td>
                    <td>info.globo@gmail.com</td>
                  </tr>
                  <tr>
                    <td>site.myshopify</td>
                    <td>www.website.com</td>
                    <td>By DelightChat Inbox & Marketing</td>
                    <td>18</td>
                    <td>8</td>
                    <td>215</td>
                    <td>info.globo@gmail.com</td>
                  </tr>
                  <tr>
                    <td>site.myshopify</td>
                    <td>www.website.com</td>
                    <td>By DelightChat Inbox & Marketing</td>
                    <td>18</td>
                    <td>8</td>
                    <td>215</td>
                    <td>info.globo@gmail.com</td>
                  </tr>
                  <tr>
                    <td>site.myshopify</td>
                    <td>www.website.com</td>
                    <td>By DelightChat Inbox & Marketing</td>
                    <td>18</td>
                    <td>8</td>
                    <td>215</td>
                    <td>info.globo@gmail.com</td>
                  </tr>
                  <tr>
                    <td>site.myshopify</td>
                    <td>www.website.com</td>
                    <td>By DelightChat Inbox & Marketing</td>
                    <td>18</td>
                    <td>8</td>
                    <td>215</td>
                    <td>info.globo@gmail.com</td>
                  </tr>
                  <tr>
                    <td>site.myshopify</td>
                    <td>www.website.com</td>
                    <td>By DelightChat Inbox & Marketing</td>
                    <td>18</td>
                    <td>8</td>
                    <td>215</td>
                    <td>info.globo@gmail.com</td>
                  </tr>
                  <tr>
                    <td>site.myshopify</td>
                    <td>www.website.com</td>
                    <td>By DelightChat Inbox & Marketing</td>
                    <td>18</td>
                    <td>8</td>
                    <td>215</td>
                    <td>info.globo@gmail.com</td>
                  </tr>
                  <tr>
                    <td>site.myshopify</td>
                    <td>www.website.com</td>
                    <td>By DelightChat Inbox & Marketing</td>
                    <td>18</td>
                    <td>8</td>
                    <td>215</td>
                    <td>info.globo@gmail.com</td>
                  </tr>    
              </tbody>
            </table>
           </router-link>
            <div class="coomingText">
                <h2>Coming Soon</h2>
              </div>
          </div>
        </div>

        <div class="app_Store_topPadding">
          <nav aria-label="navigation-cuts">
            <ul class="pagination">
              <li class="page-item"><a class="active page-link" href="#">1</a></li>
              <li class="page-item"><a class="page-link" href="#">2</a></li>
              <li class="page-item"><a class="page-link" href="#">3</a></li>
              <li class="page-item"><a class="page-link" href="#">3</a></li>
              <li class="page-item"><a class="page-link" href="#">Next</a></li>
            </ul>
          </nav>
        </div>

      </div>  
</template>

<script>

</script>

<style scoped>
/**********************    shopify store porospector page start    **************************/
.app_InputBox {
  width: 100%;
  max-width: 846px;
  margin-top: 36px;
  display: flex;
  flex-wrap: wrap;
  row-gap: 20px;
}
.app_InputBox .app_InputBoxSub {
  margin-right: 10px;
  width: 100%;
  max-width: 341px;
}
.app_InputBox .app_InputBoxSub label {
  display: block;
  font-family: 'Rubik';
  font-weight: 400;
  font-size: 19px;
  line-height: 35px;
  letter-spacing: 0.02em;
  color: #FFFFFF;
}

.app_InputBox .app_InputBoxSub select {
  width: 100%;
  height: 42px;
  background: #323243;
  border: 1px solid #5D5D76;
  font-family: 'Rubik';
  font-weight: 400;
  font-size: 17px;
  line-height: 20px;
  letter-spacing: 0.02em;
  color: rgba(255, 255, 255, 0.72);
  padding: 0 20px;
}

.light-theme-np .app_InputBox .app_InputBoxSub select{ background: #e9e9ed; color: #000;}

.app_InputBox .app_InputBoxSub {
  margin-right: 10px;
  width: 100%;
  max-width: 341px;
}

.app_InputBox .app_InputBoxBtn {
  display: flex;
  align-items: flex-end;
}

.app_InputBox .app_InputBoxBtn .app_In_Btn {
  background: #00C1EC;
  border-radius: 4px;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  text-transform: uppercase;
  color: #FFFFFF;
  text-shadow: 0px 0.5px 1px rgba(0, 0, 0, 0.25);
  padding: 8px 43px;
  border: none;
}
.shopifyStoreTable table thead tr > :nth-child(2) {
  text-align: left !important;
}

.app_InputBox .app_InputBoxSub {
  margin-right: 10px;
  width: 100%;
  max-width: 341px;
}

.shopifyStoreTable .shopifyStoreposition  {
    position: relative;
   
}
.shopifyStoreTable table tbody tr td  {
    padding: 28px 26px !important;
}
.shopifyStoreTable table thead tr > :nth-child(2)  {
    text-align: left !important;
}
.shopifyStoreTable table tbody tr > :nth-child(1),
.shopifyStoreTable table tbody tr > :nth-child(4),
.shopifyStoreTable table tbody tr > :nth-child(5),
.shopifyStoreTable table tbody tr > :nth-child(6),
.shopifyStoreTable table tbody tr > :nth-child(7)   {
    text-align: center !important;
}
.shopifyStoreTable table tbody tr > :nth-child(3)  {
    white-space: unset;
    width: 350px;
    line-height: 19px;
}
.shopifyStoreTable .coomingText {
    position: fixed;
    /* top: 400px;
    left: 10px; */
    top: 48%;
    opacity: 0.6;
    transform: rotate(-35deg);
}
.shopifyStoreTable .coomingText h2  {
    font-family: 'Poppins';
    font-weight: 600;
    font-size: 125px !important;
    text-transform: capitalize;
    color: rgba(241, 241, 241, 0.8);
    padding: 0;
}

/**********************    shopify store porospector page end    **************************/
.app_InputBox {
    width: 100%;
    max-width: 846px;
    margin-top: 36px;
    /* display: flex !important; */
    flex-wrap: wrap;
    row-gap: 20px;
}
.table-section table.skygren-table thead {
    border-left: 1px solid #3CAEA3;
    border-right: 1px solid #3CAEA3;
    background-color: #3CAEA3;
}
/* / .light-theme-np{} / */

.light-theme-np .main_left-pn {
    box-shadow: 0px 0px 20px #d7d7d7;
    background-color: #fff;
}
.light-theme-np .tab_boxe h2,
.light-theme-np .main_right-pn .tab-content .tab_One_Box .tab_One_Content .tab_One_Text h2,
.light-theme-np  .tab_boxe .tab-content .tab_One_Box .tab_One_Content .tab_One_Text h4,
.light-theme-np  .tab_boxe .tab-content .tab_One_Box .tab_One_Content .tab_One_Text h5 span,
.light-theme-np .tab_boxe .tab-content .tab_One_Box .tab_One_Content .tab_One_Option span,
.light-theme-np  .tab_boxe h2,
.light-theme-np  .tab_boxe h3,
.light-theme-np .tab_Box_footer h3,
.light-theme-np .tab_boxe .tab-content article h4,
.light-theme-np .tab_boxe .tab-content .media-assets h4,
.light-theme-np .tab_boxe .tab-content article p,
.light-theme-np .tab_boxe .tab-content .media-assets .page__loop,
.light-theme-np .tab_boxe .tab-content .pricing-card .item ul li,
.light-theme-np .tab_boxe .tab-content .pricing-card ul li svg,
.light-theme-np .tab_boxe .tab-content .pricing-card .tile h4,
.light-theme-np .tab_boxe .tab-content .pricing-card .item .tile h4 span,
.light-theme-np .tab_boxe .tab-content .competitor-table .heading h4,
.light-theme-np .tab_boxe .nav-tabs .nav-item .nav-link,
.light-theme-np .tab_tittle_border a,
.light-theme-np .tab_boxe .changeLogsBox .changeLogsStatus .changeLogsText h3,
.light-theme-np .tab_boxe .changeLogsBox .changeLogsStatus .changeLogsText span, 
.light-theme-np .tab_boxe .changeLogsBox .changeLogsSub ul li .changeDiv span,
.light-theme-np .tab_boxe .changeLogosVersion .chVerLeft p,
.light-theme-np .tab_boxe .changeLogosVersion .chVerRight p,
.light-theme-np .tab_boxe .logsFillterBtn a,
.light-theme-np .tab_boxe .logsMainBox .logsMainLeft .logsMainLeftSub h4,
.light-theme-np .tab_boxe .logsMainBox .logsMainRight .logsMainLeftSub h4,
.light-theme-np .tab_boxe .logsMainBox .logsMainLeft .logsMainLeftSub a,
.light-theme-np  .tab_boxe .logsMainBox .logsMainRight .logsMainLeftSub a,
.light-theme-np .tab_boxe .logsMainBox .logsMainLeft .logsMainLeftSub h5,
.light-theme-np  .tab_boxe .logsMainBox .logsMainRight .logsMainLeftSub h5,
.light-theme-np .keyWordFirstLeft p,
.light-theme-np .tab_boxe .sidePagePara,
.light-theme-np .appsBox .appsSub .appSubProfile .proText h4,
.light-theme-np .appsBox .appsSub .appSubProfile .proText p,
.light-theme-np .appsBox .appsSub .appSubProfile .proText h4 span,
.light-theme-np .appsBox .appsSub .appSubProfile .proText h5,
.light-theme-np .appsBox .appsSub .appSubOption p,
.light-theme-np .tab_Three_Box .comparingLink .comparingOption ul li a,
.light-theme-np .comparfningHeadUl ul li a,
.light-theme-np .adsKeyWordsp p,
.light-theme-np .tab_boxe .adsKeyDetaildsRe .tittle p,
.light-theme-np .keyZeroStateBtns .keyZeroStatePara p,
.light-theme-np .adsByAppReportProfile .adsByAppReportProfileImage .Textbox h4 span,
.light-theme-np .adsByAppReportProfile .adsByAppReportProfileImage .Textbox p,
.light-theme-np .adsByAppReportProfile .adsByAppReportProfileImage .Textbox h5,
.light-theme-np .adsReportTableTop table tbody tr td a,
.light-theme-np .adsByAppBox .accordion-body .accBodyChekBox ul li span,
.light-theme-np .adsByAppBox .accordion-body p,
.light-theme-np .adsKeyReQueue h3,
.light-theme-np .adskeySuReport .adskeySuReportpBox h4,
.light-theme-np .adskeySuReport .adskeySuReportpBox p,
.light-theme-np .adsReportZeroBox p,
.light-theme-np .adsActiveandScanUlP p,
.light-theme-np .shopifyStoreExpanded .expandThreeDiv .exThreeTwo ul li,
.light-theme-np .shopifyStoreExpanded .expandThreeDiv .exThreeThree ul li   {
    color: #212529 !important;
}

.light-theme-np .main_right-pn .tab_boxe .logsFillterBtn a svg  {
    color: #212529 !important;
    fill: #212529;
}


.light-theme-np .tab_boxe .nav-tabs .nav-item .nav-link,
.light-theme-np .tab_boxe .changeLogsBox .changeLogsSub ul li .changeDiv {
    background-color: #f2f4f2e6;
    border: 1px solid #e7e7e757;
}

.light-theme-np .tab_boxe .changeLogsBox .changeLogsSub ul li .changeDiv::after  {
    background: #f2f4f2e6;
}

.light-theme-np .tab_boxe .changeLogsBox .changeLogsSub ul li .changeRound span  {
    background: #fff;
}

.light-theme-np .main_right-pn .tab_boxe .tab-content .pricing-card .item{
    background: #EAECEE;
}
.light-theme-np .tab_boxe .tab-content .tab_Three_Box canvas,
.light-theme-np .tab_boxe .tab_Three_Box canvas {
    background: #fff;
    border-color: #d6d6d6;
}
.light-theme-np .tab_boxe .comparingThree .comThreeBox {
    background: #ececec7a;
    border: 0.5px solid #74747414;
    box-shadow: 0px 4px 4px #8f8f8f70;
}
.light-theme-np .tab_boxe .comparingThree .comThreeBox a .threeDiv {
    background: #d6d6d6;
    border: 1px solid #d6d6d6;
}
.light-theme-np .tab_boxe .comparingThree .comThreeBox a .threeDiv .btn {
    background: #e1e1e1;
    border: 1px solid #cacaca;
}
.tab_boxe nav[aria-label="navigation-cuts"] ul{
    justify-content: flex-end;
    column-gap: 10px;
}
.tab_boxe nav[aria-label="navigation-cuts"] ul .page-link{
    padding: 0;
    line-height: 1;
    font-family: 'Rubik';
    font-weight: 400;
    font-size: 15px;
    color: #FFFFFF;
    background: #444457b3;
    border: 0.5px solid #85858529;
    border-radius: 3px;
    display: flex;
    padding: 0 16px;
    height: 35px;
    align-items: center;
    justify-content: center;
}
.tab_boxe nav[aria-label="navigation-cuts"] ul .active.page-link,
.tab_boxe .page-link:hover  {
    background: rgba(0, 209, 255, 0.7) !important;
    color: #fff !important;
}
.tab_boxe h2 {
    font-weight: 300;
    font-size: 40px;
    line-height: 60px;
    text-transform: capitalize;
    color: #FFFFFF;
    padding-bottom: 45px;
    margin: 0;
}
.app_InputBox .app_InputBoxSub label {
    display: block;
    font-family: 'Rubik';
    font-weight: 400;
    font-size: 19px;
    line-height: 35px;
    letter-spacing: 0.02em;
    color: #FFFFFF;
}
.light-theme-np .app_InputBox .app_InputBoxSub label {
    color: #212529;
}
.app_InputBoxBtn {
    display: flex;
    align-items: flex-end;
}
.tab_Two_Box table .voilet {
    background: #685DC9 !important;
}
@media (max-width:1799px){
   .main_right-pn .table-section table thead th {
  padding: 16px 15px;
} 
}

@media (min-width:1400px) and (max-width:1799px)  {
    .tab_boxe {
        padding-top: 30px;
    }
    .tab_boxe h2,
    .tab_boxe .tab-content {
        padding-top: 50px;
    }
    .tab_boxe .tab-content .tab_One_Box .tab_One_Content .tab_One_Text {
        padding-left: 15px !important;
    }
    .tab_boxe .tab-content .tab_One_Box .tab_One_Content .tab_One_Option {
        padding-left: 15px !important;
    }
    .tab_boxe h2 {
        font-size: 30px;
    }

}

@media (min-width:1280px) and (max-width:1400px)  {
    /* .main_right-pn .tab_boxe {
        padding-top: 30px;
    }    */
    .tab_boxe h2,
    .main_right-pn .tab_tittle_border {
        padding-bottom: 0px;
    }
    .tab_boxe .tab-content {
        padding-top: 50px;
    }
    .tab_boxe .tab-content .tab_One_Box .tab_One_Content .tab_One_Text {
        padding-left: 15px !important;
    }
    .tab_boxe .tab-content .tab_One_Box .tab_One_Content .tab_One_Option {
        padding-left: 15px !important;
    }
    .tab_boxe h2 {
        font-size: 30px;
    }
    .tab_boxe .nav-tabs .nav-item .nav-link {
        font-size: 14px;
        padding: 7px 30px;
    }
    .tab_boxe .tab-content .tab_One_Box .tab_One_Content .tab_One_Text h2 {
        font-size: 20px;
    }
    .tab_boxe .tab-content .tab_One_Box .tab_One_Content .tab_One_Text h4 {
        font-size: 14px;
        padding-bottom: 5px;
    }
    .tab_boxe .tab-content .tab_One_Box .tab_One_Content .tab_One_Option span {
        font-size: 14px;
        padding-left: 10px;
    }
    .tab_boxe .tab-content .tab_One_Box .tab_One_Content .tab_One_Option ul li:not(:last-child) {
        padding-bottom: 15px;
        margin-bottom: 15px;
    }
    .tab_boxe h2 {
        font-size: 26px;
    }
    .tab_Two_Box {
  padding-top: 50px;
}
}
@media (max-width:991px)  {
    .tab_boxe {
        padding-top: 50px;
    }
    .tab_boxe h2,
    .tab_boxe .adsKeyDetaildsRe .tittle h2  {
        padding-bottom: 10px;
        line-height: 30px;
    }
    .tab_boxe .nav-tabs {
        padding-top: 15px;
    }
    .tab_boxe .nav-tabs .nav-item .nav-link {
        font-size: 13px;
        padding: 4px 15px;
        margin: 3px 0;
    }
    .tab_boxe .tab-content {
        padding-top: 50px;
    }
    .tab_boxe .tab-content .tab_One_Box .tab_One_Content .tab_One_Text {
        padding-left: 15px !important;
    }
    .tab_boxe .tab-content .tab_One_Box .tab_One_Content .tab_One_Option {
        padding: 30px  15px !important;
    }
    .tab_boxe .tab-content .tab_One_Box .tab_One_Content .tab_One_Text h2 {
        font-size: 19px;
        padding-bottom: 5px;
    }
    .tab_boxe .tab-content .tab_One_Box .tab_One_Content .tab_One_Text h3 {
        font-size: 13px;
        padding-bottom: 8px;
    }
    .tab_boxe .tab-content .tab_One_Box .tab_One_Content .tab_One_Text h4 {
        font-size: 14px;
        line-height: 20px;
        padding-bottom: 12px;
    }
    .tab_boxe h2,
    .tab_boxe .adsKeyDetaildsRe .tittle h2 {
        font-size: 24px;
    }
    .tab_boxe .changeLogsBox .changeLogsSub ul li   {
        width: 100%;
        overflow-y: hidden;
        overflow-x: scroll;
        white-space: nowrap;
    }
    .tab_boxe .changeLogsBox .changeLogsSub ul li .changeDiv   {
        width: 250%;
    }
    .tab_boxe .changeLogsBox .changeLogsSub ul li .changeDiv::after  {
        display: none;
    }
    .tab_boxe .changeLogsBox .changeLogsSub ul li .changeDiv span {
        font-size: 14px;
    }
    .tab_boxe .changeLogsBox .changeLogsSub ul li .changeDiv .changeDivBtn a {
        font-size: 13px;
        padding: 2px 10px;
    }
    .tab_boxe .changeLogsBox {
        padding-top: 30px;
    }
    .tab_boxe .changeLogsBox .changeLogsSub ul li:not(:last-child) {
        margin-bottom: 25px;
    }
    .tab_boxe .changeLogsBox .changeLogsStatus .changeLogsText h3 {
        font-size: 21px;
    }
    .tab_boxe .changeLogsBox .changeLogsStatus .changeLogsText span {
        font-size: 12px;
    }
    .tab_boxe .changeLogsBox .changeLogsStatus .changeLogsText span::after {
        top: 5px;
    }
    .tab_boxe .changeLogsBox .changeLogsSub ul li .changeDiv span  {
        margin-right: 15px;
        padding-right: 15px
    }

    .tab_boxe .changeLogsBox .changeLogsStatus .changeLogsText span {
        padding-right: 6px;
        margin-right: 8px;
    }

    .tab_boxe .sidePagePara {
        font-size: 18px;
    }
    .tab_boxe .appsBox {
        padding-top: 15px;
    }
    .tab_boxe .appsBox .comGap  {
        padding: 15px;
    }
    .tab_boxe .comparingThree {
        padding-top: 5px;
    }
    .tab_boxe .comparingThree .compds  {
        padding: 15px;
    }
    .tab_boxe .comparingThree .comThreeBox {
        padding: 50px 40px;
    }
    .tab_boxe .comparingThree .comThreeBox h3 {
        font-size: 20px;
        padding-top: 35px;
    }

    .tab_boxe .adsKeyDetaildsRe {
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    .tab_boxe .adsKeyDetaildsRe .tittle  {
        text-align: center;
    }
    .tab_boxe .adsKeyDetaildsRe .tittle p {
        font-size: 18px;
        line-height: 27px;
        margin-bottom: 20px;
    }
    .tab_boxe .adsKeyDetaildsRe .btn a {
        font-size: 15px;
        padding: 9px 22px;
    }

    .shopifyStoreTable .shopifyStoreposition { overflow-x: scroll;}

}

@media (max-width:667px)   {

    .tab_boxe {
        padding-top: 30px;
    }
    .tab_boxe .nav-tabs {
        padding-top: 5px;
    }
    .tab_boxe .nav-tabs .nav-item .nav-link {
        font-size: 12px;
        padding: 4px 8px;
    }
    .tab_boxe .tab-content {
        padding-top: 30px;
    }
    .tab_boxe .tab-content .tab_One_Box .tab_One_Content .tab_One_Image {
        padding-bottom: 15px;
    }
    .tab_boxe h2 {
        font-size: 20px;
    }

    .tab_boxe .comparingThree .comThreeBox {
        padding: 40px 30px;
    }
    .tab_boxe .comparingThree .comThreeBox h3 {
        font-size: 18px;
        padding-top: 25px;
    }
    .tab_boxe .sidePagePara {
        font-size: 16px;
        line-height: 26px;
    }
    .tab_boxe .comparingThree .comThreeBox a .threeDiv {
        width: 150.25px;
        height: 150.25px;
    }
    .tab_boxe .adsKeyDetaildsRe .tittle p {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 15px;
    }
    .tab_boxe .adsKeyDetaildsRe .btn a {
        font-size: 13px;
        padding: 8px 20px;
    }
    .shopifyStoreTable .coomingText h2{font-size: 35px !important;}

} 
@media (max-width:1440px) {
    .tab_boxe .tab-content .pricing-card .item .hrad h5 {
        font-size: 20px;
    }
    .tab_boxe .tab-content .pricing-card .item .tile h4 {
        font-size: 25px;
    }
    .tab_boxe .tab-content .pricing-card .item ul li {
        font-size: 14px;
        padding: 10px 0;
    }
    .tab_boxe .tab-content .pricing-card .item ul {
        padding: 0 25px 20px;
    }
    .tab_boxe .tab-content .competitor-table .heading h4 {
        font-size: 35px;
        
    }
}

@media (max-width:1280px) {
    .tab_boxe .tab-content .competitor-table .heading {
        margin: 80px 0 30px;
    }

}
@media (max-width:991px) {
    .tab_boxe .tab-content article h4,
    .tab_boxe .tab-content .media-assets h4 {
        font-size: 30px;
        margin: 0 0 20px 0;
    }
    .tab_boxe .tab-content article p,
    .adsKeypFont .tab-content article p {
        font-size: 14px !important;
        line-height: 20px !important;
    }
    .tab_boxe .tab-content article p:first-of-type {
        margin: 0 0 20px 0;
    }
    .main_right-pn .tab_boxe .tab-content article {
        padding-bottom: 50px;
    }
    .tab_boxe .tab-content .competitor-table .heading h4 {
        font-size: 25px;
    }
}
@media (max-width:767px) {
    .tab_boxe .tab-content .competitor-table .heading {
        margin: 40px 0 25px;
    }

}


</style>