<template>
  <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">

             <div class="paymentBox">
                <div class="paymentBoxOne">
                    <h3>Payment Method</h3>
                    <p>Update your billing details and address</p>
                </div>
                <div class="paymentBoxTwo">
                    <h4>Card Details</h4>
                    <p>Select default payment method</p>
                </div>
                <div class="paymentBoxThree">
                        <!-- Button trigger modal -->
                        <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal">
                          <span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="#00D1FF" class="bi bi-plus-circle-fill" viewBox="0 0 16 16">
                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z"/>
                              </svg>
                        </span>
                        Add New Payment Method
                        </button>

                        <!-- Modal -->
                        <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                          <div class="modal-dialog">
                            <div class="modal-content">
                              <div class="modal-header">
                                <!-- <h5 class="modal-title" id="exampleModalLabel">Modal title</h5> -->
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">X</button>
                              </div>
                              <div class="modal-body">
                                <h3>Add Payment Method</h3>
                                <p>Never miss a month We'll only use your backup payment method if we can't process your main one.</p>
                                <ul>
                                  <li>
                                    <span><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-lock-fill" viewBox="0 0 16 16">
                                      <path d="M8 1a2 2 0 0 1 2 2v4H6V3a2 2 0 0 1 2-2zm3 6V3a3 3 0 0 0-6 0v4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2z"/>
                                    </svg></span>
                                    Secure Server</li>
                                  <li><a href="#paymntsec">
                                    <span><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-credit-card-2-front-fill" viewBox="0 0 16 16">
                                      <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4zm2.5 1a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h2a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-2zm0 3a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zm0 2a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1zm3 0a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1zm3 0a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1zm3 0a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1z"/>
                                    </svg></span>
                                    Credit or Debit Card</a></li>
                                  <li>or</li>
                                  <li><a href="">
                                    <span><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-paypal" viewBox="0 0 16 16">
                                      <path d="M14.06 3.713c.12-1.071-.093-1.832-.702-2.526C12.628.356 11.312 0 9.626 0H4.734a.7.7 0 0 0-.691.59L2.005 13.509a.42.42 0 0 0 .415.486h2.756l-.202 1.28a.628.628 0 0 0 .62.726H8.14c.429 0 .793-.31.862-.731l.025-.13.48-3.043.03-.164.001-.007a.351.351 0 0 1 .348-.297h.38c1.266 0 2.425-.256 3.345-.91.379-.27.712-.603.993-1.005a4.942 4.942 0 0 0 .88-2.195c.242-1.246.13-2.356-.57-3.154a2.687 2.687 0 0 0-.76-.59l-.094-.061ZM6.543 8.82a.695.695 0 0 1 .321-.079H8.3c2.82 0 5.027-1.144 5.672-4.456l.003-.016c.217.124.4.27.548.438.546.623.679 1.535.45 2.71-.272 1.397-.866 2.307-1.663 2.874-.802.57-1.842.815-3.043.815h-.38a.873.873 0 0 0-.863.734l-.03.164-.48 3.043-.024.13-.001.004a.352.352 0 0 1-.348.296H5.595a.106.106 0 0 1-.105-.123l.208-1.32.845-5.214Z"/>
                                    </svg></span>
                                    Paypal</a></li>
                                  <li><a href="#" class="modalBtn">Close</a></li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                </div>
                <div class="paymentBoxFour">
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="paySub">
                                <div class="img">
                                    <img src="@/assets/images/payment/img1.jpg" alt="">
                                </div>
                                <div class="text">
                                    <div class="textLeft">
                                        <h6>Mastercard ending in 5878</h6>
                                        <p>Exp: 06/2026</p>
                                        <a href="#">Edit</a>
                                    </div>
                                    <div class="textRight">
                                        <h5>Set as default</h5>
                                        <div class="form-check check">
                                            <input class="form-check-input1" type="checkbox" value="" id="flexCheckDefault">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="paySub">
                                <div class="img">
                                    <img src="@/assets/images/payment/img2.jpg" alt="">
                                </div>
                                <div class="text">
                                    <div class="textLeft">
                                        <h6>Visa ending in 6969</h6>
                                        <p>Exp: 02/2023</p>
                                        <a href="#">Edit</a>
                                    </div>
                                    <div class="textRight">
                                        <h5>Set as default</h5>
                                        <div class="form-check check">
                                            <input class="form-check-input1" type="checkbox" value="" id="flexCheckDefault">
                                        </div>
                                        <a href="#">Remove</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="paymentBoxFive">
                    <h2>Billing History</h2>
                    <div class="payChargeBox">
                        <h3>Upcoming Charges</h3>
                        <div class="chargeSub">
                            <div class="title">
                                <h4>Next Billing Date</h4>
                                <p>06/27/2022</p>
                            </div>
                            <div class="date">
                                <div class="title">
                                    <h4>Amount</h4>
                                    <p>$29.99 <span>Taxes Included</span></p>
                                </div>
                            </div>
                        </div>
                        <div class="invoiceBox">
                            <h5>Access to your past invoices</h5>
                            <ul>
                                <li>
                                    <div class="invoiceSub">
                                        <span>
                                            <h6>Date charged</h6>
                                            <p>5/12/2022</p>
                                        </span>
                                        <span>
                                            <h6>Invoice #</h6>
                                            <p>DE668R524</p>
                                        </span>
                                        <span>
                                            <h6>Duration</h6>
                                            <p>5/12/2022-6/13/2022 </p>
                                        </span>
                                        <span>
                                            <h6>Status</h6>
                                            <p>Paid</p>
                                        </span>
                                        <span>
                                            <button class="inVoiceBtnOne"><img src="@/assets/images/payment/svg2.svg"></button>
                                            <button class="inVoiceBtnTwo"><img src="@/assets/images/payment/svg1.svg"></button>
                                        </span>
                                    </div>
                                </li>
                                <li>
                                    <div class="invoiceSub">
                                        <span>
                                            <h6>Date charged</h6>
                                            <p>5/12/2022</p>
                                        </span>
                                        <span>
                                            <h6>Invoice #</h6>
                                            <p>DE668R524</p>
                                        </span>
                                        <span>
                                            <h6>Duration</h6>
                                            <p>5/12/2022-6/13/2022 </p>
                                        </span>
                                        <span>
                                            <h6>Status</h6>
                                            <p>Paid</p>
                                        </span>
                                        <span>
                                            <button class="inVoiceBtnOne"><img src="@/assets/images/payment/svg2.svg"></button>
                                            <button class="inVoiceBtnTwo"><img src="@/assets/images/payment/svg1.svg"></button>
                                        </span>
                                    </div>
                                </li>
                                <li>
                                    <div class="invoiceSub">
                                        <span>
                                            <h6>Date charged</h6>
                                            <p>5/12/2022</p>
                                        </span>
                                        <span>
                                            <h6>Invoice #</h6>
                                            <p>DE668R524</p>
                                        </span>
                                        <span>
                                            <h6>Duration</h6>
                                            <p>5/12/2022-6/13/2022 </p>
                                        </span>
                                        <span>
                                            <h6>Status</h6>
                                            <p>Paid</p>
                                        </span>
                                        <span>
                                            <button class="inVoiceBtnOne"><img src="@/assets/images/payment/svg2.svg"></button>
                                            <button class="inVoiceBtnTwo"><img src="@/assets/images/payment/svg1.svg"></button>
                                        </span>
                                    </div>
                                </li>
                                <li>
                                    <div class="invoiceSub">
                                        <span>
                                            <h6>Date charged</h6>
                                            <p>5/12/2022</p>
                                        </span>
                                        <span>
                                            <h6>Invoice #</h6>
                                            <p>DE668R524</p>
                                        </span>
                                        <span>
                                            <h6>Duration</h6>
                                            <p>5/12/2022-6/13/2022 </p>
                                        </span>
                                        <span>
                                            <h6>Status</h6>
                                            <p>Paid</p>
                                        </span>
                                        <span>
                                            <button class="inVoiceBtnOne"><img src="@/assets/images/payment/svg2.svg"></button>
                                            <button class="inVoiceBtnTwo"><img src="@/assets/images/payment/svg1.svg"></button>
                                        </span>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="app_Store_topPadding navAccount">
                    <nav aria-label="navigation-cuts">
                      <ul class="pagination">
                        <li class="page-item"><a class="active page-link" href="#">1</a></li>
                        <li class="page-item"><a class="page-link" href="#">2</a></li>
                        <li class="page-item"><a class="page-link" href="#">3</a></li>
                        <li class="page-item"><a class="page-link" href="#">4</a></li>
                        <li class="page-item"><a class="page-link" href="#">Next</a></li>
                      </ul>
                    </nav>
                </div>

             </div>

             <div class="paymentPopUp">
                <div class="heading">
                  <h3>Payment Method</h3>
                  <p>Update your billing details and address</p>
                </div>
                <div class="popupBox" id="paymntsec">
                  <div class="popupBoxOne">
                    <div class="row popDirection">
                      <div class="poPupLeft">
                        <label>Card details</label>
                      </div>
                      <div class="poPupRight">
                        <div class="row">
                          <div class="one">
                            <div class="divide">
                              <label>Name on card</label>
                            <input class="popFull" type="text" placeholder="Robart Morrone">
                            </div>
                          </div>
                          <div class="one">
                            <div class="divide">
                              <label>Exp. date</label>
                                <div class="divederSelect">
                                  <select class="halfSelect">
                                    <option>Month</option>
                                    <option>Jan</option>
                                    <option>Feb</option>
                                    <option>Mar</option>
                                    <option>April</option>
                                    <option>May</option>
                                    <option>June</option>
                                    <option>July</option>
                                    <option>Aug</option>
                                    <option>Sep</option>
                                    <option>Oct</option>
                                    <option>Nov</option>
                                    <option>Dec</option>
                                </select>
                                <select class="halfSelect">
                                  <option>Year</option>
                                  <option>2000</option>
                                  <option>2001</option>
                                  <option>2002</option>
                                  <option>2003</option>
                                  <option>2004</option>
                                  <option>2005</option>
                                  <option>2006</option>
                                  <option>2007</option>
                                  <option>2008</option>
                                  <option>2009</option>
                                  <option>2010</option>
                                </select>
                                </div>
                            </div>
                          </div>
                          <div class="one">
                            <div class="divide"><label>Card number</label>
                              <input class="popFulles" type="text" placeholder="1234 5678 9876 6541">
                              <span>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#fff" class="bi bi-credit-card-2-front-fill" viewBox="0 0 16 16">
                                  <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4zm2.5 1a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h2a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-2zm0 3a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zm0 2a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1zm3 0a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1zm3 0a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1zm3 0a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1z"/>
                                </svg>
                              </span>
                            </div>
                          </div>
                          <div class="one">
                            <div class="divide">
                              <label>CVV</label>
                              <input class="popHalf" type="text" placeholder="***">
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="popupBoxOne">
                    <div class="row popDirection">
                      <div class="poPupLeft">
                        <label>Email address</label>
                        <p>Invoice will be sent to this email address</p>
                      </div>
                      <div class="poPupRight">
                        <div class="row">
                          <div class="one">
                            <div class="divide">
                                <input class="popFull pfullMbottotm" type="text" placeholder="hello.robartm@gmail.com">
                                <input class="popFull iconPosition" type="text" placeholder="Add another">
                                <span class="inputPlus">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#00D1FF" class="bi bi-plus-circle" viewBox="0 0 16 16">
                                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                    <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                                  </svg>
                                </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="popupBoxOne padBattom">
                    <div class="row popDirection">
                      <div class="poPupLeft">
                        <label>Billing address</label>
                      </div>
                      <div class="poPupRight">
                        <div class="row">
                          <div class="one">
                            <div class="divide">
                              <label>Country</label>
                              <select class="fullSelect">
                                <option>USA</option>
                                <option>USA</option>
                                <option>USA</option>
                                <option>USA</option>
                                <option>USA</option>
                            </select>
                            </div>
                          </div>
                          <div class="one">
                            <div class="divide">
                              <label>Street Address</label>
                            <input class="popFull" type="text" placeholder="212 Lombard Street">
                            </div>
                          </div>
                          <div class="one">
                            <div class="divide">
                              <label>City</label>
                            <input class="popFull" type="text" placeholder="San Francisco">
                            </div>
                          </div>
                          <div class="one divFlex">
                            <div class="dividers">
                              <label>State/Province</label>
                                <div class="divederSelect">
                                  <select class="halfSelect">
                                    <option>California</option>
                                    <option>California</option>
                                    <option>California</option>
                                    <option>California</option>
                                    <option>California</option>
                                  </select>
                                </div>
                            </div>
                            <div class="dividers">
                              <label>Zip/Postal Code</label>
                              <input class="popHalf" type="text" placeholder="90001">
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="popupBoxOneBtn">
                    <ul>
                      <li class="popBtnbackground"><a href="#">Save</a></li>
                      <li class="popBtnBorder"><a href="#">Cancel</a></li>
                    </ul>
                  </div>
                </div>
              </div>  

          </div>
</template>

<script>

</script>

<style scoped>
.accManageProManageBox .accManageProManageContent .paymentBox .paymentBoxOne  {
    padding-top: 20px;
}
.accManageProManageBox .accManageProManageContent .paymentBox .paymentBoxOne h3  {
    font-weight: 500;
    font-size: 34px;
    line-height: 30px;
    text-transform: uppercase;
    color: #FFFFFF;
}
.accManageProManageBox .accManageProManageContent .paymentBox .paymentBoxOne p  {
    font-weight: 300;
    font-size: 20px;
    line-height: 30px;
    color: #ffffffdb;
}
.accManageProManageBox .accManageProManageContent .paymentBox .paymentBoxTwo  {
    padding-top: 52px;
}
.accManageProManageBox .accManageProManageContent .paymentBox .paymentBoxTwo h4  {
    font-weight: 500;
    font-size: 27px;
    line-height: 30px;
    color: #FFFFFF;
}
.accManageProManageBox .accManageProManageContent .paymentBox .paymentBoxTwo p  {
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;    
    color: #ffffffdb;
}
.accManageProManageBox .accManageProManageContent .paymentBox .paymentBoxThree  {
    padding: 37px 0 46px 0;
}
.accManageProManageBox .accManageProManageContent .paymentBox .paymentBoxThree button  {
    background: rgba(48, 48, 64, 0.65);
    border: 1px solid #00D1FF;
    border-radius: 3px;
    font-weight: 500;
    font-size: 23px;
    line-height: 30px;
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px 39px;
    text-decoration: none;
    display: inline-block;
}
.accManageProManageBox .accManageProManageContent .paymentBox .paymentBoxThree button span  {
    padding-right: 30px;
}
.accManageProManageBox .accManageProManageContent .paymentBox .paymentBoxThree .modal .modal-content  {
    max-width: 531px !important;
    padding: 53px 62px !important;
    background-color: #282838 !important;
    border: none !important;
    box-shadow: 0px 4px 80px rgba(0, 0, 0, 0.82) !important;
    border-radius: 6px !important;
}
.modal-header {
    padding: 0rem !important;
    border-bottom: none !important;
}
.accManageProManageBox .accManageProManageContent .paymentBox .paymentBoxThree .modal .modal-content .modal-header .btn-close {
    position: absolute;
    top: 3px;
    right: -16px;
    border: none;
    font-size: 16px;
    color: rgb(255, 255, 255);
    outline: none;
    background: none;
}
.accManageProManageBox .accManageProManageContent .paymentBox .paymentBoxThree .modal .modal-content .modal-body  {
    text-align: center;
}
.accManageProManageBox .accManageProManageContent .paymentBox .paymentBoxThree .modal .modal-content .modal-body h3  {
    font-weight: 500;
    font-size: 26px;
    line-height: 30px;
    text-align: center;
    color: #FFFFFF;
}
.accManageProManageBox .accManageProManageContent .paymentBox .paymentBoxThree .modal .modal-content .modal-body p  {
    font-weight: 400;
    font-size: 17px;
    line-height: 30px;
    text-align: center;
    color: #ffffffab;
    padding-bottom: 10px;
}
.accManageProManageBox .accManageProManageContent .paymentBox .paymentBoxThree .modal .modal-content .modal-body ul li  {
    font-weight: 500;
    font-size: 19px;
    line-height: 30px;
    text-align: center;
    color: #FFFFFF;
    margin-top: 30px;
}
.accManageProManageBox .accManageProManageContent .paymentBox .paymentBoxThree .modal .modal-content .modal-body ul li span  {
    margin-left: 15px;
}
.accManageProManageBox .accManageProManageContent .paymentBox .paymentBoxThree .modal .modal-content .modal-body ul li a  {
    text-decoration: none;
    background: #3D3D4E;
    border: 1px solid #3D3D4E;
    border-radius: 3px;
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    text-align: center;
    color: #FFFFFF;
    display: block;
    padding: 14px 0;
}
.accManageProManageBox .accManageProManageContent .paymentBox .paymentBoxThree .modal .modal-content .modal-body ul li .modalBtn  {
    background: #00D1FF;
}
.accManageProManageBox .accManageProManageContent .paymentBox .paymentBoxThree .modal .modal-dialog {
    margin: 7.75rem auto;
}
.accManageProManageBox .accManageProManageContent .paymentBox .paymentBoxFour .paySub  {
    background: rgba(48, 48, 64, 0.65);
    border: 0.5px solid rgba(116, 116, 116, 0.16);
    border-radius: 3px;
    padding: 23px 23px 23px 32px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}
.accManageProManageBox .accManageProManageContent .paymentBox .paymentBoxFour .paySub .img  {
    width: 95px;
}
.accManageProManageBox .accManageProManageContent .paymentBox .paymentBoxFour .paySub .img img  {
    display: block;
}
.accManageProManageBox .accManageProManageContent .paymentBox .paymentBoxFour .paySub .text .textLeft h6  {
    font-weight: 500;
    font-size: 18px;
    line-height: 30px;
    color: #FFFFFF;
}
.accManageProManageBox .accManageProManageContent .paymentBox .paymentBoxFour .paySub .text .textLeft p  {
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    color: #FFFFFF;
}
.accManageProManageBox .accManageProManageContent .paymentBox .paymentBoxFour .paySub .text .textLeft a  {
    font-size: 14px;
    line-height: 30px;
    text-decoration: underline;
    color: #FFFFFF;
}
.accManageProManageBox .accManageProManageContent .paymentBox .paymentBoxFour .paySub .text  {
    width: calc(100% - 95px);
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    position: relative;
}
.accManageProManageBox .accManageProManageContent .paymentBox .paymentBoxFour .paySub .text .textLeft  {
    width: 100%;
}
.accManageProManageBox .accManageProManageContent .paymentBox .paymentBoxFour .paySub .text .textRight h5  {
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    color: #FFFFFF;
    margin-right: 6px;
}
.accManageProManageBox .accManageProManageContent .paymentBox .paymentBoxFour .paySub .text .textRight  {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
}
.accManageProManageBox .accManageProManageContent .paymentBox .paymentBoxFour .paySub .text .textRight a {
    position: absolute;
    bottom: 0px;
    right: 8px;
    font-weight: 400;
    font-size: 14px;
    line-height: 30px;
    text-decoration-line: underline;
    color: #FFFFFF;
}
.accManageProManageBox .accManageProManageContent .paymentBox .paymentBoxFour .paySub .text .textRight .check  {
    margin-top: -8px;
}
.accManageProManageBox .accManageProManageContent .paymentBox .paymentBoxFive   {
    background: rgba(48, 48, 64, 0.65);
    border: 0.5px solid rgba(116, 116, 116, 0.16);
    border-radius: 3px;
    padding: 48px 68px;
    margin-top: 74px;
}
.accManageProManageBox .accManageProManageContent .paymentBox .paymentBoxFive h2  {
    font-weight: 500;
    font-size: 34px;
    line-height: 30px;
    color: #FFFFFF;
    padding-bottom: 0 !important;
    margin: 0;
}
.accManageProManageBox .accManageProManageContent .paymentBox .paymentBoxFive .payChargeBox h3  {
    font-weight: 400;
    font-size: 21px;
    line-height: 30px;
    color: #FFFFFF;
    padding: 38px 0 16px 0;
    margin: 0;

}
.accManageProManageBox .accManageProManageContent .paymentBox .paymentBoxFive .payChargeBox .chargeSub  {
    background: #323243;
    padding: 22px 38px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    text-align: left;
    width: 100%;
    flex-wrap: wrap;
}
.accManageProManageBox .accManageProManageContent .paymentBox .paymentBoxFive .payChargeBox .chargeSub .title h4 {
    font-weight: 600;
    font-size: 21px;
    line-height: 30px;
    color: #FFFFFF;
}
.accManageProManageBox .accManageProManageContent .paymentBox .paymentBoxFive .payChargeBox .chargeSub .title p  {
    font-weight: 400;
    font-size: 17px;
    line-height: 30px;
    color: #FFFFFF;
}
.accManageProManageBox .accManageProManageContent .paymentBox .paymentBoxFive .payChargeBox .chargeSub .title p span  {
    font-size: 15px;
    color: #BEBEBE;
}
.accManageProManageBox .accManageProManageContent .paymentBox .invoiceBox  {
    padding-top: 42px;
}
.accManageProManageBox .accManageProManageContent .paymentBox .invoiceBox h5  {
    font-weight: 400;
    font-size: 21px;
    line-height: 30px;
    color: #FFFFFF;
    padding-bottom: 10px !important;
}
.accManageProManageBox .accManageProManageContent .paymentBox .invoiceBox ul li .invoiceSub  {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    background: #323243;
    padding: 26px 36px;
    margin: 5px 0;
}
.accManageProManageBox .accManageProManageContent .paymentBox .invoiceBox ul li .invoiceSub span h6  {
    font-weight: 400;
    font-size: 17px;
    line-height: 20px;
    color: #c2c2c2f0;
    padding-bottom: 3px;
}
.accManageProManageBox .accManageProManageContent .paymentBox .invoiceBox ul li .invoiceSub span p  {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    color: #FFFFFF;
}
.accManageProManageBox .accManageProManageContent .paymentBox .invoiceBox ul li .invoiceSub span button  {
    outline: none;
    background: none;
    border: none;
    cursor: pointer;
    padding: 0 5px;
}
.accManageProManageBox .accManageProManageContent .paymentBox .navAccount  {
    padding-top: 36px;
}



.paymentPopUp .heading  {
    padding-top: 45px;
}
.paymentPopUp .heading h3  {
    font-weight: 500;
    font-size: 34px;
    line-height: 30px;
    text-transform: uppercase;
    color: #FFFFFF;
}
.paymentPopUp .heading p  {
    font-weight: 300;
    font-size: 20px;
    line-height: 30px;
    color: rgba(255, 255, 255, 0.86);
    padding-bottom: 56px;
}
.paymentPopUp .popupBox  {
    background: rgba(48, 48, 64, 0.65);
    border: 0.5px solid rgba(116, 116, 116, 0.16);
    border-radius: 3px;
    padding: 75px 58px;
}
.paymentPopUp .popupBox .popupBoxOne   {
    padding-bottom: 60px;
}
.paymentPopUp .popupBox .popupBoxOne .poPupLeft  {
    max-width: 235px;
}
.paymentPopUp .popupBox .popupBoxOne .poPupRight  {
    max-width: calc(100% - 235px);
}
.paymentPopUp .popupBox .popupBoxOne .poPupLeft label  {
    font-weight: 500;
    font-size: 27px;
    line-height: 30px;
    color: #FFFFFF;
}
.paymentPopUp .popupBox .popupBoxOne .poPupLeft p  {
    font-weight: 400;
    font-size: 17px;
    line-height: 22px;
    letter-spacing: 0.02em;
    color: rgba(255, 255, 255, 0.84);
}
.paymentPopUp .popupBox .popupBoxOne .poPupRight .one  {
    width: 50%;
}
.paymentPopUp .popupBox .popupBoxOne .poPupRight .one .divide  {
    padding-bottom: 26px;
    width: 100%;
    position: relative;
}
.paymentPopUp .popupBox .popupBoxOne .poPupRight .one .divide label,
.paymentPopUp .popupBox .popupBoxOne .poPupRight .one .dividers label  {
    display: block !important;
    font-weight: 400;
    font-size: 17px;
    line-height: 16px;
    color: #FFFFFF;
    padding-bottom: 10px;
}
.paymentPopUp .popupBox .popupBoxOne .poPupRight .one .divide .popFull  {
    width: 100%;
    height: 49px;
    background: #404053;
    opacity: 0.65;
    border: 1px solid #67677C;
    outline: none;
    font-family: 'Rubik';
    font-weight: 400;
    font-size: 17px;
    line-height: 35px;
    letter-spacing: 0.02em;
    color: rgba(255, 255, 255, 0.72);
    padding-left: 15px;
}
.paymentPopUp .popupBox .popupBoxOne .poPupRight .one .divide .divederSelect   {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}
.paymentPopUp .popupBox .popupBoxOne .poPupRight .one .divide .divederSelect .halfSelect,
.paymentPopUp .popupBox .popupBoxOne .poPupRight .one .dividers .divederSelect .halfSelect  {
    width: 50%;
    height: 49px;
    background: #404053;
    opacity: 0.65;
    border: 1px solid #67677C;
    border-radius: 4px;
    outline: none;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 35px;
    letter-spacing: 0.02em;
    color: rgba(255, 255, 255, 0.72);
    padding-left: 15px;
    margin-right: 10px;
}
.paymentPopUp .popupBox .popupBoxOne .poPupRight .one .divide .popFulles   {
    width: 100%;
    height: 49px;
    background: #404053;
    opacity: 0.65;
    border: 1px solid #67677C;
    outline: none;
    font-family: 'Rubik';
    font-weight: 400;
    font-size: 17px;
    line-height: 35px;
    letter-spacing: 0.02em;
    color: rgba(255, 255, 255, 0.72);
    padding-left: 40px;
    position: relative;
}
.paymentPopUp .popupBox .popupBoxOne .poPupRight .one .divide span {
    position: absolute;
    left: 16px;
    top: 37px;
}
.paymentPopUp .popupBox .popupBoxOne .poPupRight .one .divide .popHalf,
.paymentPopUp .popupBox .popupBoxOne .poPupRight .one .dividers .popHalf  {
    width: 50%;
    height: 49px;
    background: #404053;
    opacity: 0.65;
    border: 1px solid #67677C;
    outline: none;
    font-family: 'Rubik';
    font-weight: 400;
    font-size: 17px;
    line-height: 35px;
    letter-spacing: 0.02em;
    color: rgba(255, 255, 255, 0.72);
    padding-left: 15px;
}
.paymentPopUp .popupBox .popupBoxOne .poPupRight .one .divide .fullSelect  {
    width: 100%;
    height: 49px;
    background: #404053;
    opacity: 0.65;
    border: 1px solid #67677C;
    outline: none;
    font-family: 'Rubik';
    font-weight: 400;
    font-size: 17px;
    line-height: 35px;
    letter-spacing: 0.02em;
    color: rgba(255, 255, 255, 0.72);
    padding-left: 15px;
}
.poPupRight .one .divide .pfullMbottotm  {
    margin-bottom: 27px;
}
.poPupRight .one .divide .iconPosition  {
    position: relative;
    padding-left: 52px !important;
}
.paymentPopUp .popupBox .popupBoxOne .poPupRight .one .divide .inputPlus  {
    position: absolute;
    top: 88px;
    left: 20px;
}
.paymentPopUp .popupBox .popupBoxOne .poPupRight .divFlex  {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}
.paymentPopUp .popupBox .popupBoxOne .poPupRight .one .dividers  {
    width: 50%;
}
.paymentPopUp .popupBox .popupBoxOne .poPupRight .one .dividers .divederSelect  {
    margin-right: 10px;
}
.paymentPopUp .popupBox .popupBoxOne .poPupRight .one .dividers .divederSelect .halfSelect  {
    width: 100%;
}
.paymentPopUp .popupBox .popupBoxOne .poPupRight .one .dividers .popHalf  {
    width: 100%;
}
.paymentPopUp .popupBoxOneBtn ul  {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.paymentPopUp .popupBoxOneBtn ul li a  {
    text-decoration: none;
    display: block;
    font-weight: 600;
    font-size: 17px;
    line-height: 26px;
    text-align: center;
    text-transform: uppercase;
    color: #FFFFFF;
    text-shadow: 0px 0.5px 1px rgba(0, 0, 0, 0.25);
    padding: 11.5px 48px;
}
.paymentPopUp .popupBoxOneBtn ul li:not(:last-child)  {
    margin-right: 13px;
}
.paymentPopUp .popupBoxOneBtn ul .popBtnbackground  {
    background: #00C1EC;
    border-radius: 4px;
}
.paymentPopUp .popupBoxOneBtn ul .popBtnBorder  {
    border: 1px solid #B3B3B3;
    border-radius: 4px;
}
.paymentPopUp .padBattom  {
    padding-bottom: 35px !important;
}

@media (max-width:991px)   {
.paymentPopUp .popupBox .popupBoxOne .poPupRight .one{ width:100%;}
}

@media (max-width:767px)   {
.accManageProManageBox .accManageProManageContent .paymentBox .paymentBoxThree .modal .modal-content{ padding:20px 0 !important;}
.accManageProManageBox .accManageProManageContent .paymentBox .paymentBoxFour .paySub .text .textRight{
  display: flex !important;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}
.accManageProManageBox .accManageProManageContent .paymentBox .paymentBoxFour .paySub .text{ width:100%;}
.accManageProManageBox .accManageProManageContent .paymentBox .paymentBoxFour .paySub .text .textRight a{ bottom: 7px;}
.paymentPopUp .popupBox .popupBoxOne .poPupRight .one .divide .divederSelect .halfSelect:last-child {
  margin-right: 0;
}
.accManageProManageBox .accManageProManageContent .paymentBox .paymentBoxThree button{ width: 100%;}
}

</style>