<template>
  <div class="ful-wdth blk-bg login-page">
   
    <div class="signup-box top-pad-sgn">
       <div class="logo-login"><router-link to="/"><img src="@/assets/images/logo/Sas-rader-logo.png"></router-link></div>
       <h2>Thank you your! registration successfully done.</h2>
       <p>Email Verification link has been sent to your email. Please confirm to join our family. 
        In case you are unable to find the link in your inbox please search for it in your spam box.</p>
    </div> 
    
    
  </div>
  
</template>

<style scoped>
.top-pad-sgn{ padding-top: 70px !important; text-align: center;}
.signup-box.top-pad-sgn{ padding: 30px 34px;}
.top-pad-sgn h2 {font-size: 24px;}
.signup-box.top-pad-sgn p {font-size: 14px;padding-top: 21px;}
</style>

<script>

    export default {
        name: "WellCome",
        data () {
            return {
                
            };
        },

        components: {
        },
        computed:{
           
        },

        mounted() {
        },

        methods: {
          
      },
    }
</script>