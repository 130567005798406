<template>
    <div class="col-xl-6 col-lg-12" v-if="istopcardload">
           <div class="tbl-head">
             <p blu ># Of Apps </p>
            <h6>Added Per Category In Last 30 Days</h6>
          </div>
          
          <div class="resp-table">
            <table class="last-table">
              <thead>
                <tr>
                  <th>App Category</th>
                  <th># Of App</th>
                  <th>Change</th>
                </tr>
              </thead>
              <tbody v-if="current30daysappslist.length>0">

                <tr v-bind:key="apps.category_id" v-for="apps in current30daysappslist">
                  <td>{{apps.category_title}}</td>
                  <td>{{apps.category_app_count}}</td>
                  <td>  {{apps.appchanges==NULL?'0':apps.appchanges}}
                    <svg v-if="apps.appchanges>0 && apps.appchanges!=NULL" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#00CBF8" class="bi bi-arrow-up" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z"/>
                    </svg>

                   <svg v-if="apps.appchanges==0" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#FF6D2E" class="bi bi-arrow-down-up" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M11.5 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L11 2.707V14.5a.5.5 0 0 0 .5.5zm-7-14a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L4 13.293V1.5a.5.5 0 0 1 .5-.5z"/>
                   </svg>

                   <svg v-if="apps.appchanges<0 && apps.appchanges!=NULL " xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#FF6D2E" class="bi bi-arrow-down" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z"></path></svg>


                  </td>
                </tr>


                
              </tbody>

               <tbody v-else>
                <tr><td colspan="5">Sorry! no data found.</td></tr>
              </tbody>
            </table>
          </div>
        </div>
</template>

<script>
import {mapState} from 'vuex'
export default {
  name: 'CategoryTable',
  props: {
    msg: String
  },
  data() {
    return {
        istopcardload:false,
    };
  },
  computed: {
      ...mapState({
          current30daysappslist: state => state.AppListModule.current30daysapps,
         // totalapps1: (state) => state.AppListModule.totalapps,
      }),
  },
   mounted() {  
     this.istopcardload=true;  
  },

   methods:{
     int(){          
      this.istopcardload=true;     
    }    
  }, 
  watch: {
        current30daysappslist(newval,oldval) {
          this.int();      
       },
  }  
}
</script>