<template>
  <div class="ful-wdth">
    <div class="tab_boxe">
        <h2>Search Ads Report</h2>

        <div class="adsByAppReportProfile">
            <div class="adsByAppReportProfileImage">
                <div class="imgbox">
                    <img src="@/assets/images/profile/proimg.jpg">
                </div>
                <div class="Textbox">
                    <h3>Foridev - Quick Shopping List</h3>
                    <h4><span>by</span> Foridev</h4>
                    <p>How to bundle products, Chained Products, Grouped Products</p>
                    <h5>
                        <span><svg xmlns="http://www.w3.org/2000/svg" width="15" height="14" fill="#FEC33E" class="bi bi-star-fill" viewBox="0 0 16 16">
                            <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path>
                          </svg></span>
                        4.9 of 5 Stars (1275)</h5>
                    <a href="#">10-day free trial</a>    
                </div>
            </div>
            <div class="adsByAppReportProfileBtn">
                <a href="#">Download CSV</a>
            </div>
        </div>

        <div class="adsByAppReportHead">
            <h2>Daily Keyword Report <span>*</span></h2>
        </div>

        <div class="tab_Two_Box table-section adsReportTableTop">
          <div class="resp-table">
            <table class="skygren-table">
              <thead class="skyBackground">
                <tr>
                  <th>Date</th>
                  <th>Keyword Ad</th>
                  <th>Ad Position</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                    <td>5/12/2022 14:30</td>
                    <td>Store Management</td>
                    <td>5</td>
                </tr>
                <tr>
                    <td>5/12/2022 14:00</td>
                    <td>Business Insights</td>
                    <td>12</td>
                </tr>

                <tr>
                    <td>5/12/2022 13:30</td>
                    <td>Product Variations</td>
                    <td>2</td>
                </tr>
                <tr>
                    <td>5/12/2022 13:00</td>
                    <td>Shipping Solutions</td>
                    <td>6</td>
                </tr>
                <tr>
                    <td>5/12/2022 12:30</td>
                    <td>Managing Inventory</td>
                    <td>14</td>
                </tr>
                <tr>
                    <td>5/12/2022 12:00</td>
                    <td>Deliver the goods</td>
                    <td>5</td>
                </tr>
                <tr>
                    <td>5/12/2022 11:30</td>
                    <td>Bring in customers</td>
                    <td>9</td>
                </tr>
                <tr>
                    <td>5/12/2022 11:00</td>
                    <td>Recovering Order</td>
                    <td>8</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class="adsReportHours">
            <h3><span>*</span> New Keywords may take up to 24 hrs to report.</h3>
        </div>

        <div class="app_Store_topPadding">
          <nav aria-label="navigation-cuts">
            <ul class="pagination">
              <li class="page-item"><a class="active page-link" href="#">1</a></li>
              <li class="page-item"><a class="page-link" href="#">2</a></li>
              <li class="page-item"><a class="page-link" href="#">3</a></li>
              <li class="page-item"><a class="page-link" href="#">3</a></li>
              <li class="page-item"><a class="page-link" href="#">Next</a></li>
            </ul>
          </nav>
        </div>

        
      </div>
  </div>
</template>



<style scoped>
.adsByAppReportProfile  {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
}
.adsByAppReportProfile .adsByAppReportProfileImage  {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    max-width: 524px;
}
.adsByAppReportProfile .adsByAppReportProfileImage .imgbox  {
    padding-right: 30px;
}
.adsByAppReportProfile .adsByAppReportProfileImage .imgbox img  {
    display: block;
}
.adsByAppReportProfile .adsByAppReportProfileImage .Textbox h3  {
    font-family: 'Rubik';
    font-weight: 400;
    font-size: 28px;
    line-height: 33px;
    text-transform: capitalize;
    color: #FFFFFF;
}
.adsByAppReportProfile .adsByAppReportProfileImage .Textbox h4  {
    font-family: 'Rubik';
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    text-transform: capitalize;
    color: #C0C0C0;
}
.adsByAppReportProfile .adsByAppReportProfileImage .Textbox h4 span  {
    color: #FFFFFF;
}
.adsByAppReportProfile .adsByAppReportProfileImage .Textbox p  {
    font-family: 'Rubik';
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    text-transform: capitalize;
    color: rgba(255, 255, 255, 0.83);
}
.adsByAppReportProfile .adsByAppReportProfileImage .Textbox h5  {
    font-family: 'Rubik';
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    color: #FFFFFF;
} 
.adsByAppReportProfile .adsByAppReportProfileImage .Textbox a  {
    font-family: 'Rubik';
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    text-transform: capitalize;
    color: #1BF197;
    text-decoration: none;
}
.adsByAppReportProfile .adsByAppReportProfileBtn a {
    text-decoration: none;
    background: #685DC9;
    border-radius: 4px;
    font-weight: 600;
    font-size: 17px;
    line-height: 26px;
    text-align: center;
    text-transform: uppercase;
    color: #FFFFFF;
    text-shadow: 0px 0.5px 1px rgba(0, 0, 0, 0.25);
    padding: 13px 30px;
}
.adsByAppReportHead h2  {
    font-weight: 300;
    font-size: 40px;
    line-height: 60px;
    color: #FFFFFF;
    padding: 75px 0 30px 0 !important;
    margin: 0;
}
.adsByAppReportHead h2 span  {
    color: #FEC33E;
}
.adsReportTableTop  {
    padding-top: 0px !important;
}
.adsReportHours h3  {
    font-family: 'Rubik';
    font-weight: 400;
    font-size: 17px;
    line-height: 20px;
    color: #D0D0D0;
}
.adsReportHours h3 span  {
    color: #FEC33E;
}
.adsReportTableTop table thead tr th  {
    text-align: left !important;
}
.adsReportTableTop table thead tr > :nth-child(3),
.adsReportTableTop table tbody tr > :nth-child(3)  {
    text-align: center !important;
    width: 210px;
}
.adsReportTableTop table thead tr > :nth-child(1),
.adsReportTableTop table tbody tr > :nth-child(1)  {
    width: 250px;
}
.adsReportTableTop table tbody tr td a  {
    font-family: 'Rubik';
    font-weight: 400;
    font-size: 16px;
    color: #E0E0E0;
}
.main_right-pn .adsReportTableTop table tbody td {
    padding: 30px;
}
.main_right-pn .tab_boxe h2 {
  font-weight: 300;
  font-size: 40px;
  line-height: 60px;
  text-transform: capitalize;
  color: #FFFFFF;
  padding-bottom: 45px;
  margin: 0;
}
.adsReportTableTop table thead tr > :nth-child(1), .adsReportTableTop table tbody tr > :nth-child(1) {
  width: 250px;
}

.main_right-pn .table-section table tbody td, .tab_Two_Box .skygren-table tbody td { text-align: left;}
.light-theme-np .main_right-pn .tab_boxe h2, .light-theme-np .main_right-pn .tab_boxe h3, .light-theme-np .adsByAppReportProfile .adsByAppReportProfileImage .Textbox h4 span, .light-theme-np .adsByAppReportProfile .adsByAppReportProfileImage .Textbox p, .light-theme-np .adsByAppReportProfile .adsByAppReportProfileImage .Textbox h5, .light-theme-np .adsReportTableTop table tbody tr td a{color: #212529;}

  @media (max-width: 1799px){
.main_right-pn .table-section table thead th {
  padding: 16px 15px;
}
  }

  @media (min-width: 1280px) and (max-width: 1400px){
.main_right-pn .tab_boxe h2 {
  font-size: 26px;
}
.main_right-pn .table-section table tbody td, .tab_Two_Box .skygren-table tbody td {
  font-size: 13px;
  padding: 12px;
}

}
</style>