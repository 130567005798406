<template>
  <div class="ful-wdth">
    <div class="tab_boxe">
        <h2 class="tab_Boxe_Tittle">Ad Radar is another great tool to help optimize 
            search ad campaigns</h2>

        <div class="row paywallChangeLogsBox">
            <div class="col-lg-6 paywallChangeLogsLeft">
                <h3>Ad Radar can help you stick close to your competitors.
                </h3>
                <div class="logsLeftSub d-flex">
                    <div class="logsSymbol">
                        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="#4CDBCD" class="bi bi-check-lg" viewBox="0 0 16 16">
                            <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z"/>
                          </svg>
                    </div>
                    <div class="logsText">
                        <h3>Follow an app and run Ad redar to detect surprising keywords your competitor's ads appear on.</h3>
                    </div>
                </div>
                <div class="logsLeftSub d-flex">
                    <div class="logsSymbol">
                        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="#4CDBCD" class="bi bi-check-lg" viewBox="0 0 16 16">
                            <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z"/>
                          </svg>
                    </div>
                    <div class="logsText">
                        <h3>We use our large keyword bank to detect competitors search ads. new keywords are added daily.</h3>
                    </div>
                </div>
                <div class="logsLeftSub d-flex">
                    <div class="logsSymbol">
                        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="#4CDBCD" class="bi bi-check-lg" viewBox="0 0 16 16">
                            <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z"/>
                          </svg>
                    </div>
                    <div class="logsText">
                        <h3>Run the report weekly to discover new ketwords to bolster your search ad compaign.</h3>
                    </div>
                </div>
                <div class="logsLeftSub d-flex">
                    <div class="logsSymbol">
                        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="#4CDBCD" class="bi bi-check-lg" viewBox="0 0 16 16">
                            <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z"/>
                          </svg>
                    </div>
                    <div class="logsText">
                        <h3>Downloadable date via CSV format</h3>
                    </div>
                </div>
                <div class="logsLeftBtn">
                    <a href="#">Upgrade</a>
                </div>
            </div>
            <div class="col-lg-6 paywallChangeLogsRight">
                <img src="@/assets/images/logs/logImg3.jpg" alt="">
            </div>
        </div>

      </div>
  </div>
</template>