<template>
 <div class="ful-wdth">
   <div class="tab_boxe">
        <h2 class="addHeading">Disclaimer</h2>

            <div class="disclaimerBox">
                <p>The information contained in this website is for general information purposes only. SasRador is not affiliated or endorsed by Shopify, Inc. While we endeavor to keep the information up to date and correct, we make no representations or warranties of any kind, express or implied, about the completeness, accuracy, reliability, suitability or availability with respect to the website or the informotion, products, services, or related graphics contained on the website For any purpose.</p>
                <p>Any reliance you place on such information is therefore strictly at your own risk-. In no event will we be liable for any loss or damage including without limitation, indirect or consequential loss or damage, or any loss or domage whatsoever arising -from loss of data or profits arising ou+t of, or in connection with, the use of this website.</p>
                <P>Through this website you are able to link to other websites which are not under our control. We have no control over the nature, content and availability of those sites. The inclusion of any links does not necessarily imply a recommendation or endorse the views expressed within them.</P>
                <p>Every effort is mode to keep She website up and running smoothly. However, we take no responsibility for, and will not be liable For, She website being unavailable due to technical or other issues beyond our control.</p>
            </div>

      </div>  
 </div>
</template>