<template>
   <div class="ful-wdth blk-bg login-page">
    <div class="signup-box">
       <div class="logo-login"><router-link to="/"><img src="@/assets/images/logo/Sas-rader-logo.png"></router-link></div>
      <h2>Reset Password</h2>
     <Form @submit="resetpassword" :validation-schema="schema" v-slot="{ errors }">
      <Field type="hidden" name="code" :value="code"/>
      <Field type="hidden" name="email" :value="email"/>
      <div class="row">

        <div class="col-lg-12 col-md-12"><label> Password</label> 
          <Field name="password" type="password" placeholder="Type Password" :class="{ 'has-error': errors.password }" />
          <ErrorMessage name="password" class="text-danger" />
        </div>

        <div class="col-lg-12 col-md-12"><label>Confirm Password</label> 
        <Field name="cpassword" type="password" placeholder="Type Password" :class="{ 'has-error': errors.cpassword }" />
          <ErrorMessage name="cpassword" class="text-danger" />
        </div>
      </div>  
       <input type="submit" value="Reset Password">
     </Form>  
     

       
    </div>  
  </div>
</template>

<script>
    import { Field, Form, ErrorMessage } from 'vee-validate'
    import * as yup from 'yup'
    import { mapState, mapActions } from 'vuex'
    import router from '@/routes'
    //import router from '@/routes'
    import { useRoute } from 'vue-router'
    export default {
        name: "userLogin",
        data () {
            const schema = yup.object({
                password: yup.string().required().min(6).label('The password'),
                cpassword: yup.string().required().oneOf([yup.ref('password'), null], 'Passwords must match')
            });
            return {
                schema,
                code:'',
                email:'',
            };
        },

        components: {
            Field,
            Form,
            ErrorMessage,
        },
        computed:{
           ...mapState({
             formLoader: state => state.authModule.formLoader,
            }),
        },
        methods: {
            ...mapActions({
                resetpassword: 'authModule/reset',
            }),

            updatepassword (params, { setFieldError }) {
                const vm = this;
                params.setFieldError = setFieldError;
                vm.resetpassword(params);
            },
        },

       beforeRouteEnter(to, from, next) {
            const route=useRoute();
            //alert(to.params.key)
            if(to.params.key=='' || to.params.login==''){
                next('/log-in');
            }
            else {
                 next();
            }
        },
        async created () {
              const route=useRoute();
              this.code=route.params.key;
              this.email=route.params.login;             
        }
    }
</script>